













































































import { Component, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvBadge, LvContainer, LvField, LvIcon, LvLightContainer, LvTag } from "@fastoche/ui-kit/components";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { UserContext, UserContextStore } from "@/modules/user/UserContext";
import { InjectState } from "@fastoche/ui-core/store";

export const COMPONENT_NAME = "UserReferralInfoPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        PageHeader,
        LvContainer,
        LvLightContainer,
        LvTag,
        LvField,
        LvIcon,
        LvBadge
    },
})
export default class UserReferralInfoPage extends BaseComponent {
    @InjectState(UserContextStore)
    private readonly userContext!: UserContext;

    @Ref()
    private readonly referralCodeInput!: HTMLInputElement;

    @Ref()
    private readonly referralLinkInput!: HTMLInputElement;

    private referralCodeCopied = false;
    private referralLinkCopied = false;

    get referralCode() {
        return this.userContext.referralCode;
    }

    get referralLink() {
        const appUrl = location.origin;
        return `${appUrl}/invite/${this.userContext.referralCode}`;
    }

    copyReferralCode() {
        this.referralCodeInput.focus();
        this.referralCodeInput.select();
        this.referralCodeCopied = document.execCommand("copy");
    }

    copyReferralLink() {
        this.referralLinkInput.focus();
        this.referralLinkInput.select();
        this.referralLinkCopied = document.execCommand("copy");
    }
}
