import { injectable } from "inversify";
import { UserInfo } from "../domain";
import { Bad, Ok, OkVal } from "@leavy/result";

@injectable()
export abstract class UserService {
    abstract getUserInfo(): Promise<OkVal<UserInfo> | Bad<"USER_NOT_FOUND">>;

    abstract deleteUser(): Promise<Ok | Bad<"USER_NOT_DELETABLE">>;
}
