export function encodeQuery(obj: any, prefix?: string): string {
    const str: string[] = [];

    for (const p in obj) {
        if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
            const k = prefix ? prefix + "[" + p + "]" : p;
            const v = obj[p];

            str.push(v !== null && typeof v === "object" ?
                encodeQuery(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }

    return str.join("&");
}
