





















import { Component, ModelSync, Ref } from "vue-property-decorator";
import { LvLightContainer } from "@fastoche/ui-kit/components";
import { StepFormComponent } from "@/modules/onboarding/hl/StepForm";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import { amenities } from "@/modules/listing/domain";
import AmenityIcon from "@/app/components/amenity/icon/AmenityIcon.vue";
import { HlAccommodationAmenitiesModel } from "@/modules/onboarding/hl/model";
import { construct } from "@leavy/utils";
import { APP_LOGGER } from "@/app/logger";

const getDefaultModel = (): HlAccommodationAmenitiesModel => {
    return construct(HlAccommodationAmenitiesModel, {
        selected: [],
    });
};

export const COMPONENT_NAME = "AccommodationAmenitiesForm";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvForm,
        LvLightContainer,
        AmenityIcon,
    },
})
export default class AccommodationAmenitiesForm extends StepFormComponent<HlAccommodationAmenitiesModel> {
    @Ref()
    readonly form!: LvForm;

    @ModelSync("value", "change", { type: HlAccommodationAmenitiesModel, required: false, default: () => getDefaultModel() })
    model!: HlAccommodationAmenitiesModel;

    readonly amenities = amenities;

    isAmenitySelected(amenity: string) {
        return this.model.selected.includes(amenity);
    }

    toggleAmenity(amenity: string) {
        const existingIdx = this.model.selected.indexOf(amenity);

        if (existingIdx > -1) {
            this.model.selected.splice(existingIdx, 1);
        }
        else {
            this.model.selected.push(amenity);
        }
    }

    async submit() {
        if (!(await this.form.submit())) return;
        return this.model;
    }
}
