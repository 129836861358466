import { Command, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { IsUUID } from "@leavy/validator";
import { Bad, Ok } from "@leavy/result";

export type HlUserRejectPriceProposalCommandResult =
    | Ok
    | Bad<"MANAGEMENT_PERIOD_REQUEST_NOT_FOUND">
    | Bad<"MANAGEMENT_PERIOD_REQUEST_WRONG_STATE">
    | Bad<"PRICE_PROPOSAL_EXPIRED">;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_reject_price_proposal"
})
export class HlUserRejectPriceProposalCommand extends Command<HlUserRejectPriceProposalCommandResult> {
    @IsUUID()
    userId!: string;

    @IsUUID()
    managementPeriodRequestId!: string;
}
