import { injectable } from "inversify";
import { AnyUserOnboarding, OnboardingId, UserOnboardingStatus, UserOnboardingStepStatus, BaseUserOnboardingStep, BaseUserOnboardingExtraData } from "./domain";
import { HodUserOnboarding } from "../hod/domain";
import { HlUserOnboarding } from "../hl/domain";
import { plainToClassFromExist } from "@leavy/validator/lib/base/transformer";
import { MutableStore } from "@fastoche/ui-core/store";
import { UserContextStore } from "@/modules/user/UserContext";

export interface AllUserOnboardingsState {
    [OnboardingId.HL]?: HlUserOnboarding;
    [OnboardingId.HOD]?: HodUserOnboarding;
}

@injectable()
export class AllUserOnboardingsStore extends MutableStore<AllUserOnboardingsState> {
    constructor(
        private userContextStore: UserContextStore,
    ) {
        super({});

        this.userContextStore.value$.subscribe(userContext => {
            if (userContext == null) {
                this.set({});
            }
        });
    }

    updateOnboardingStatus(onboardingId: OnboardingId, status: UserOnboardingStatus) {
        this.mutate(state => {
            const onboarding = state[onboardingId];

            if (onboarding) {
                onboarding.status = status;
            }

            return state;
        });
    }

    updateStep(stepId: string, step: BaseUserOnboardingStep) {
        this.mutate(state => {
            const onboardings = Object.values(state).filter(x => x != undefined) as AnyUserOnboarding[];

            onboardings
                .flatMap(x => x.steps)
                .filter(x => x.stepId == stepId)
                .forEach(x => {
                    const updated = plainToClassFromExist(x, step);
                    Object.assign(x, updated);
                });

            return state;
        });
    }

    updateExtraData(dataTypeId: string, extraData: BaseUserOnboardingExtraData) {
        this.mutate(state => {
            const onboardings = Object.values(state).filter(x => x != undefined) as AnyUserOnboarding[];

            onboardings
                .flatMap(x => x.extraData)
                .filter(x => x.dataTypeId == dataTypeId)
                .forEach(x => {
                    const updated = plainToClassFromExist(x, extraData);
                    Object.assign(x, updated);
                });

            return state;
        });
    }
}
