import {GetHouseBookingSummaryQuery as query} from "../GetHouseBookingSummaryQuery";
import {BuildClientInvocation, LocaleAmbientValue, UserIdAmbientValue} from "@leavy/cq-core";

export class GetBookingRequestSummaryQuery extends BuildClientInvocation({
    type: query,
    ambientValues: {
        locale: LocaleAmbientValue,
        userId: UserIdAmbientValue
    }
}) {
}
