import { Query, RpcInvocable } from "@leavy/cq-core";
import { IsNotEmpty, IsOptional, IsString } from "@leavy/validator";
import { Bad, OkVal } from "@leavy/result";
import { TranslationDictionary } from "../model/TranslationDictionary";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

/**
 * This error occurs if the requested locale is not supported by the system.
 */
type LocaleNotSupportedError = Bad<"LOCALE_NOT_SUPPORTED">;

export type GetTranslationsForLocaleQueryResult =
    | OkVal<TranslationDictionary>
    | LocaleNotSupportedError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "get_translations_for_locale",
})
export class GetTranslationsForLocaleQuery extends Query<GetTranslationsForLocaleQueryResult> {
    @IsString()
    @IsNotEmpty()
    locale!: string;

    @IsString()
    @IsOptional()
    fallback: string = "en";
}
