



























































































import { Component, Ref } from "vue-property-decorator";
import { LvContainer } from "@fastoche/ui-kit/components";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { InjectService } from "@fastoche/ui-core/di";
import { CommonOnboardingPage } from "./CommonOnboardingPage";
import { OnboardingService } from "@/modules/onboarding/common/services/OnboardingService";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { CommonErrorDialogs } from "../../../ui/error/CommonErrorDialogs";
import { HlOnboardingStore } from "@/modules/onboarding/hl/HlOnboardingStore";
import AddressInput from "@/app/ui/address-input/AddressInput.vue";
import LvDatePicker from "@fastoche/ui-kit/components/date-picker/LvDatePicker.vue";
import { AnyHodOnboardingStepId } from "@/modules/onboarding/hod/domain";
import { AnyHlOnboardingStepId } from "@/modules/onboarding/hl/domain";
import { IdentityProofDocument, OnboardingId } from "@/modules/onboarding/common/domain";
import { IdentityProofDocumentModel, UserIdentityModel } from "@/modules/onboarding/common/model";
import { I18nLocalizedResourceService } from "@/modules/i18n/services/I18nLocalizedResourceService";
import { DateTime, Duration } from "luxon";
import { Memoize } from "lodash-decorators";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import LvFilePicker from "@fastoche/ui-kit/components/file-picker/LvFilePicker.vue";
import LvSelect from "@fastoche/ui-kit/components/select/LvSelect.vue";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvFilePreview from "@fastoche/ui-kit/components/file-preview/LvFilePreview.vue";
import { FileService } from "@/modules/file/services/FileService";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import OnboardingDocumentCollection from "@/app/components/onboarding/common/OnboardingDocumentCollection.vue";
import { ArrayMinSize, IsArray, Type, ValidateNested } from "@leavy/validator";
import { construct } from "@leavy/utils";
import HelpTooltip from "@/app/ui/help-tooltip/HelpTooltip.vue";

interface SelectableCountry {
    label: string;
    value: string;
}

class UserIdentityViewModel extends UserIdentityModel {
    @Type(() => IdentityProofDocumentModel)
    @IsArray()
    @ArrayMinSize(1)
    @ValidateNested({ each: true })
    identityDocuments: IdentityProofDocumentModel[] = [];
}

const MIN_LEGAL_AGE = 18;

export const COMPONENT_NAME = "OnboardingIdentityPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        OnboardingDocumentCollection,
        LvIcon,
        LvFilePreview,
        LvButton,
        LvField,
        LvFormItem,
        LvForm,
        LvSelect,
        LvFilePicker,
        PageHeader,
        LvContainer,
        AddressInput,
        LvDatePicker,
        HelpTooltip
    },
})
export default class OnboardingIdentityPage extends CommonOnboardingPage {
    @InjectService(OnboardingService)
    readonly onboardingService!: OnboardingService;

    @InjectService(HlOnboardingStore)
    readonly hlStore!: HlOnboardingStore;

    @InjectService(HodOnboardingStore)
    readonly hodStore!: HodOnboardingStore;

    @InjectService(I18nLocalizedResourceService)
    readonly i18nResourceService!: I18nLocalizedResourceService;

    @InjectService(FileService)
    readonly fileService!: FileService;

    @Ref()
    readonly form!: LvForm;

    countries: SelectableCountry[] = [];
    model: UserIdentityViewModel = new UserIdentityViewModel();

    get step() {
        return this.userOnboarding.identity;
    }

    created() {
        void this.loadModel();
        void this.loadCountries();
    }

    async loadModel() {
        await this.onboardingService.loadStep(AnyHlOnboardingStepId.IDENTITY);

        const model = new UserIdentityViewModel();

        const { data } = this.step;

        if (data) {
            model.firstName = data.firstName ?? null!;
            model.lastName = data.lastName ?? null!;
            model.address = data.address ?? null!;
            model.birthDate = data.birthDate ?? null!;
            model.cityOfBirth = data.cityOfBirth ?? null!;
            model.countryOfBirth = data.countryOfBirth ?? null!;
            model.nationality = data.nationality ?? null!;

            model.identityDocuments.push(
                ...data.proof.map(x =>
                    construct(IdentityProofDocumentModel, { fileId: x.fileId })
                )
            );
        }

        this.model = model;
    }

    getFileUrl(fileId: string) {
        return this.fileService.getFileUrl(fileId).url;
    }

    @Memoize()
    datePickerMaxDate() {
        return DateTime.local().minus(Duration.fromObject({
            years: MIN_LEGAL_AGE,
        })).toISO();
    }

    @LoaderTask
    async loadCountries() {
        const countries = await this.i18nResourceService.getCountries();

        this.countries = Object.keys(countries).map((code) => {
            const country = countries[code]!;

            return {
                label: country instanceof Array ? country[0]! : country,
                value: code,
            };
        });
    }

    @LoaderTask
    async addIdentityDocument(file: File) {
        try {
            const result = await this.onboardingService.addIdentityDocument(this.onboardingId, file);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_WRONG_STATE":
                    case "ONBOARDING_NOT_FOUND":
                        await this.$router.replace(this.backLink).catch(this.handleNavigationError);
                        return;
                }
            }
            else {
                const { fileId } = result.value;

                this.model.identityDocuments = [
                    ...this.model.identityDocuments,
                    construct(IdentityProofDocumentModel, { fileId })
                ];
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async removeIdentityDocument(doc: IdentityProofDocument) {
        try {
            const result = await this.onboardingService.removeIdentityDocument(this.onboardingId, doc.fileId);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_WRONG_STATE":
                    case "ONBOARDING_NOT_FOUND":
                        await this.$router.replace(this.backLink).catch(this.handleNavigationError);
                        return;
                }
            }
            else {
                const existingIdx = this.model.identityDocuments.findIndex(x => x.fileId == doc.fileId);

                if (existingIdx > -1) {
                    this.model.identityDocuments.splice(existingIdx, 1);
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async onSubmit() {
        try {
            if (!(await this.form.submit())) return;

            const result = await this.onboardingService.submitIdentityStep(
                this.userOnboarding.onboardingId,
                this.model,
            );

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.backLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
            else {
                await this.nextStep();
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async nextStep() {
        switch (this.onboardingId) {
            case OnboardingId.HL:
                const hlNextPage = this.hlStore.getNextStepLocation(AnyHlOnboardingStepId.IDENTITY);
                await this.$router.push(hlNextPage).catch(this.handleNavigationError);
                break;

            case OnboardingId.HOD:
                const hodNextPage = this.hodStore.getNextStepLocation(AnyHodOnboardingStepId.IDENTITY);
                await this.$router.push(hodNextPage).catch(this.handleNavigationError);
                break;
        }
    }
}
