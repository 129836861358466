import { injectable } from "inversify";
import { assert } from "@leavy/utils";
import { Bad, Ok } from "@leavy/result";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { LogoutCommand, RefreshTokenCommand } from "./command";
import { SessionService } from "@/modules/auth/services/SessionService";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";

@injectable()
export class CqSessionService extends SessionService {
    constructor(
        private readonly store: AuthenticationStore,
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    async extends() {
        if (!this.store.isAuthenticated()) {
            throw new Error("user not authenticated");
        }
        assert(this.store.value.token && this.store.value.refreshToken);

        const refreshResult = await this.cq.invoke(RefreshTokenCommand, {
            accessToken: this.store.value.token.bearer,
            clientId: this.store.value.clientId,
            deviceId: this.store.value.deviceId,
            refreshToken: this.store.value.refreshToken,
        });

        if (!refreshResult.success) {
            return Bad(refreshResult.reason);
        }

        this.store.setToken(refreshResult.value.user.id, {
            bearer: refreshResult.value.accessToken.bearer,
            tokenExpirationTime: new Date(refreshResult.value.accessToken.expirationDate).getTime(),
        }, refreshResult.value.refreshToken);

        return Ok();
    }

    async end() {
        if (!this.store.isAuthenticated()) {
            return;
        }
        assert(this.store.value.token);

        await this.cq.invoke(LogoutCommand, {
            accessToken: this.store.value.token.bearer,
            clientId: this.store.value.clientId,
            deviceId: this.store.value.deviceId,
        });

        this.store.clearToken();
    }
}
