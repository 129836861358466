import { Command, RpcInvocable } from "@leavy/cq-core";
import { Bad, Ok, OkVal } from "@leavy/result";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { IsEmail, IsNotEmpty, IsString, LowerCase, Trim } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

export type ResetPasswordCommandResult = Ok | Bad<"invalid_code" | "email_not_found">;

/**
 * ResetPasswordCommand command description
 */
@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "reset_password",
})
export class ResetPasswordCommand extends Command<ResetPasswordCommandResult, PasswordChangedEvent> {
    @IsEmail()
    @Trim()
    @LowerCase()
    email!: string;

    @IsString()
    @IsNotEmpty()
    password!: string;

    @IsString()
    @IsNotEmpty()
    recoveryCode!: string;
}

@DomainEvent({ namespace: HOMESHARING_BACKEND_SERVICE_NAME, name: "password_changed", version: 1 })
export class PasswordChangedEvent extends DomainEventBase {
    @EventProperty()
    email!: string;

    constructor(data: PayloadOf<PasswordChangedEvent>) {
        super(data);
    }
}
