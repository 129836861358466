








import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { APP_LOGGER } from "@/app/logger";
import VueRouter from 'vue-router'

const { isNavigationFailure, NavigationFailureType } = VueRouter;

export const COMPONENT_NAME = "ApplicationRoot";

@Component({
    name: COMPONENT_NAME,
})
export default class ApplicationRoot extends BaseComponent {
    private errorCaptured(error: Error, vm: Vue, info: string) {
        APP_LOGGER.fatal("Fatal error catched in application root", { error, info });

        if (this.$route.path != "/error") {
            void this.$router.push("/error").catch(e => this.handleNavigationError(e));
        }
    }
}
