import { injectable } from "inversify";
import { Settings } from "luxon";
import { LibraryLocaleAdapter } from "@fastoche/ui-core/i18n/libs/LibraryLocaleAdapter";
import { LocaleObject } from "@fastoche/ui-core/i18n/locale/Locale";

@injectable()
export class LuxonLocaleAdapter extends LibraryLocaleAdapter {
    protected onLocaleChange(locale: LocaleObject) {
        Settings.defaultLocale = locale.localeCode;
        return Promise.resolve();
    }
}
