






















import { Component, Ref, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import HlExplainedPopup from "@/app/ui/hl-explained-popup/HlExplainedPopup.vue";

export const COMPONENT_NAME = "HlExplainedBanner";

@Component({
    name: COMPONENT_NAME,
    components: { HlExplainedPopup },
})
export default class HlExplainedBanner extends BaseComponent {
    @Ref()
    readonly popup!: HlExplainedPopup;

    show() {
        this.popup.show();
    }
}
