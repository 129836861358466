import { IsLocale, IsNumber, IsUUID, Min, TryParseNumber } from "@leavy/validator";
import { Query, RpcInvocable } from "@leavy/cq-core";
import { OkVal } from "@leavy/result";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { PaginatedResult } from "../../common/model/PaginatedResult";
import { Booking } from "../domain/Booking";
import { BookedListing, ListingBooking } from "../domain/BookedListing";


export type ListBookingsQueryResult = OkVal<PaginatedResult<ListingBooking>>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "list_bookings",
})
export class ListBookingsQuery extends Query<ListBookingsQueryResult> {
    @IsUUID()
    userId!: string;

    @IsLocale()
    locale!: string;

    @IsNumber()
    @TryParseNumber()
    @Min(0)
    page: number = 0;

    @IsNumber()
    @TryParseNumber()
    @Min(1)
    perPage: number = 10;
}
