








































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvBadge, LvButton, LvControlPopover, LvIcon, LvNumberInput } from "@fastoche/ui-kit/components";
import { ListingFilterContext } from "@/modules/listing/context/ListingFilterContext";

export const COMPONENT_NAME = "ListingBedroomFilter";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvControlPopover,
        LvButton,
        LvNumberInput,
        LvBadge,
        LvIcon
    }
})
export default class ListingBedroomFilter extends BaseComponent {
    @Prop({ type: Object, required: true })
    readonly filters!: ListingFilterContext;

    @Ref()
    readonly popover!: LvControlPopover;

    get hasValue() {
        return this.filters.bedrooms != undefined && this.filters.bedrooms > 0;
    }

    show() {
        this.popover.show();
    }

    hide() {
        this.popover.hide();
    }

    reset() {
        this.filters.bedrooms = undefined;
        this.hide();
    }
}
