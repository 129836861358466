import { injectable } from "inversify";
import { Bad, Ok } from "@leavy/result";

export interface PasswordReset {
    recoveryCode: string;
    email: string;
    password: string;
}

export interface PasswordChange {
    currentPassword: string;
    newPassword: string;
}

@injectable()
export abstract class PasswordService {
    abstract change(model: PasswordChange): Promise<Ok | Bad<"WRONG_CURRENT_PASSWORD">>;

    abstract beginReset(email: string): Promise<Ok | Bad<"email_not_found" | "unexpected_failure">>;

    abstract endReset(model: PasswordReset): Promise<Ok | Bad<"invalid_code" | "email_not_found">>;
}
