import { Query, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { IsEnum, IsOptional, IsUUID } from "@leavy/validator";
import { OkVal } from "@leavy/result";
import { HlUserAccommodation } from "../domain/HlUserAccommodation";
import { HlManagementPeriodRequestId } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlManagementPeriodRequest";
import { HlUserManagementPeriodRequest } from "../domain/HlUserManagementPeriodRequest";
import { Currency } from "@leavy/lv-homesharing-pricing-srv/lib/money/domain/Currency";

export type HlUserGetManagementPeriodRequestByIdQueryResult =
    OkVal<HlUserManagementPeriodRequest | null>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_get_management_period_request_by_id",
})
export class HlUserGetManagementPeriodRequestByIdQuery extends Query<HlUserGetManagementPeriodRequestByIdQueryResult> {
    @IsUUID()
    userId!: string;

    @IsUUID()
    id!: HlManagementPeriodRequestId;

    @IsOptional()
    @IsEnum(Currency)
    currency?: Currency;
}
