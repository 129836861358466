
















import { Component, ModelSync, Ref } from "vue-property-decorator";
import { LvField, LvIcon, LvLightContainer } from "@fastoche/ui-kit/components";
import { StepFormComponent } from "@/modules/onboarding/hl/StepForm";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { currencySymbol } from "@fastoche/ui-core/i18n/currency/filters/currencySymbol";
import LvCheckbox from "@fastoche/ui-kit/components/checkbox/LvCheckbox.vue";
import { HlAccommodationRentModel } from "@/modules/onboarding/hl/model";
import { construct } from "@leavy/utils";
import { APP_LOGGER } from "@/app/logger";

const getDefaultModel = (): HlAccommodationRentModel => {
    return construct(HlAccommodationRentModel, {
        value: 0,
        currency: null!,
    });
};

export const COMPONENT_NAME = "AccommodationRentForm";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvButton,
        LvFormItem,
        LvForm,
        LvLightContainer,
        LvField,
        LvIcon,
        LvCheckbox,
    },
    filters: {
        currencySymbol,
    },
})
export default class AccommodationRentForm extends StepFormComponent<HlAccommodationRentModel> {
    @InjectState(LocalizationContext)
    readonly localizationContext!: StateOf<LocalizationContext>;

    @Ref()
    readonly form!: LvForm;

    @ModelSync("value", "change", { type: HlAccommodationRentModel, required: false, default: () => getDefaultModel() })
    model!: HlAccommodationRentModel;

    created() {
        if (!this.model.currency) {
            this.model.currency = this.localizationContext.currentCurrency;
        }
    }

    async submit() {
        if (!(await this.form.submit())) return;
        return this.model;
    }
}
