import { HlService } from "@/modules/hl/services/HlService";
import { inject, injectable } from "inversify";
import { HlUserAccommodation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodation";
import * as C from "./command";
import * as Q from "./query";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { Currency } from "@fastoche/ui-core/i18n/currency/Currency";
import { HlPaymentSchedulingOption } from "@leavy/lv-homesharing-pricing-srv/lib/pricing/domain/HlPaymentSchedulingOption";
import { PaginatedResult } from "@leavy/lv-homesharing-srv/lib/common/domain/Pagination";
import { HlUserManagementPeriodRequest } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriodRequest";
import { PaginationOptionsModel } from "@leavy/lv-homesharing-srv/lib/common/model/PaginationOptionsModel";
import { HlUserManagementSummary } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementSummary";
import { Ok } from "@leavy/result";
import { HlUserManagementPeriod } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriod";
import { HlUserManagementPeriodInfo } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriodInfo";
import { HlUserPayment } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserPayment";

@injectable()
export class CqHlService extends HlService {
    @inject(HttpRemoteCqInvoker)
    private readonly cq!: HttpRemoteCqInvoker;

    async getUserAccommodations(): Promise<HlUserAccommodation[]> {
        const results = await this.cq.invoke(Q.HlUserGetAccommodationsQuery, {});
        return results.value;
    }

    async getUserManagementPeriodRequests(pagination: PaginationOptionsModel): Promise<PaginatedResult<HlUserManagementPeriodRequest>> {
        const results = await this.cq.invoke(Q.HlUserGetManagementPeriodRequestsQuery, { pagination });
        return results.value;
    }

    async getUserManagementPeriods(pagination: PaginationOptionsModel): Promise<PaginatedResult<HlUserManagementPeriod>> {
        const results = await this.cq.invoke(Q.HlUserGetManagementPeriodsQuery, { pagination });
        return results.value;
    }

    async getUserPayments(pagination: PaginationOptionsModel): Promise<PaginatedResult<HlUserPayment>> {
        const results = await this.cq.invoke(Q.HlUserGetPaymentsQuery, { pagination });
        return results.value;
    }

    async getUserManagementPeriodRequestById(id: string, currency?: Currency): Promise<HlUserManagementPeriodRequest | null> {
        const result = await this.cq.invoke(Q.HlUserGetManagementPeriodRequestByIdQuery, { id, currency });
        return result.value;
    }

    async getUserManagementPeriodInfo(id: string): Promise<HlUserManagementPeriodInfo | null> {
        const result = await this.cq.invoke(Q.HlUserGetManagementPeriodInfoQuery, { id });
        return result.value;
    }

    async getUserManagementSummary(): Promise<HlUserManagementSummary> {
        const result = await this.cq.invoke(Q.HlUserGetManagementSummaryQuery, {});
        return result.value;
    }

    async getPeriodEstimationForUserAccommodation(
        accommodationId: string,
        departureDate: string,
        returnDate: string,
        currency?: Currency,
    ) {
        return await this.cq.invoke(Q.HlUserGetPeriodEstimationForAccommodationQuery, {
            accommodationId,
            departureDate,
            returnDate,
            currency,
        });
    }

    async requestNewManagementPeriod(
        accommodationId: string,
        departureDate: string,
        returnDate: string,
        paymentSchedulingOption: HlPaymentSchedulingOption,
    ) {
        return await this.cq.invoke(C.HlUserRequestNewManagementPeriodCommand, {
            accommodationId,
            departureDate,
            returnDate,
            paymentSchedulingOption,
        });
    }

    async cancelManagementPeriodRequest(managementPeriodRequestId: string) {
        await this.cq.invoke(C.HlUserCancelManagementPeriodRequestCommand, { managementPeriodRequestId });
    }

    async acceptManagementPeriodRequestPriceProposal(managementPeriodRequestId: string) {
        const result = await this.cq.invoke(C.HlUserAcceptPriceProposalCommand, { managementPeriodRequestId });
        if (!result.success) return result;
        return Ok();
    }

    async rejectManagementPeriodRequestPriceProposal(managementPeriodRequestId: string) {
        const result = await this.cq.invoke(C.HlUserRejectPriceProposalCommand, { managementPeriodRequestId });
        if (!result.success) return result;
        return Ok();
    }
}
