import axios from "axios";
import { APP_CONFIG } from "@/app/config";
import { encodeQuery } from "./utils/encodeQuery";
import { setupProgressBar } from "@/app/vendors/nprogress";

export const HOMESHARING_HTTP_CLIENT = axios.create({
    baseURL: APP_CONFIG.HOMESHARING_BACKEND_URL,
    paramsSerializer: encodeQuery
});

setupProgressBar(HOMESHARING_HTTP_CLIENT, { showSpinner: true });
