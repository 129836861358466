import { ArrayUtils } from "@leavy/utils";

export class IndexedStep<T> {
    constructor(
        public readonly workflow: ReadonlyArray<T>,
        public readonly step: T,
    ) {}

    get index() {
        return this.workflow.indexOf(this.step);
    }

    get isLast() {
        return this.index == this.workflow.length - 1;
    }

    get isFirst() {
        return this.index == 0;
    }

    get progress() {
        return Math.round((this.index + 1) / this.workflow.length * 100);
    }

    getPrevious() {
        const idx = Math.max(0, this.index - 1);
        return new IndexedStep<T>(this.workflow, ArrayUtils.getOrThrow(this.workflow as T[], idx));
    }

    getNext() {
        const idx = Math.min(this.workflow.length - 1, this.index + 1);
        return new IndexedStep<T>(this.workflow, ArrayUtils.getOrThrow(this.workflow as T[], idx));
    }
}

export const GetIndexedStep = <T extends string>(workflow: ReadonlyArray<T>) => (step: T) => new IndexedStep(workflow, step);
