























import { Component, Prop, Watch } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvButton, MessageBox } from "@fastoche/ui-kit/components";
import { ListingSearchModel, ListingSearchResult } from "@/modules/listing/model";
import { InjectState } from "@fastoche/ui-core/store";
import { ListingSearchContext} from "@/modules/listing/context/ListingSearchContext";
import ListingPeekCard from "@/app/components/listing/peek-card/ListingPeekCard.vue";
import { InjectService } from "@fastoche/ui-core/di";
import { ListingService } from "@/modules/listing/services/ListingService";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { GlobalTranslationStore } from "@fastoche/ui-core/i18n/global";
import { DateTime } from "luxon";
import { ListingSearchContextStore } from "@/modules/listing/context/ListingSearchContextStore";
import { ListingFilterContext } from "@/modules/listing/context/ListingFilterContext";
import { Toolbelt } from "@leavy/static-land";
import { classToPlain } from "@leavy/validator/lib/base/transformer";

export const COMPONENT_NAME = "ListingSearchResultsRowList";

@Component({
    name: COMPONENT_NAME,
    components: {
        ListingPeekCard,
        LvButton,
    },
})
export default class ListingSearchResultsRowList extends BaseComponent {
    @InjectState(ListingSearchContextStore)
    readonly searchContext!: ListingSearchContext;

    @InjectService(ListingSearchContextStore)
    readonly searchContextStore!: ListingSearchContextStore;

    @InjectService(ListingService)
    readonly listingService!: ListingService;

    @Prop({ type: String, required: false, default: null })
    readonly title?: string;

    @Prop({ type: Object, required: true })
    readonly filters!: Toolbelt.O.Required<ListingFilterContext, "type">;

    @Prop({ type: Number, required: false, default: 10 })
    readonly perPage!: number;

    private items: ListingSearchResult[] = [];
    private total: number = 0;
    private searchModel: ListingSearchModel | null = null;
    private preventLoadingMore = false;
    private isLoading = false;

    created() {
        this.$subscribeTo(
            this.searchContextStore.value$,
            x => this.onSearchContextChange(x as ListingSearchContext),
        );
    }

    @Watch("isLoading")
    onLoadingChange() {
        this.$emit("loading", this.isLoading);
    }

    private async onSearchContextChange(ctx: ListingSearchContext) {
        if (!ctx.isValid()) {
            this.searchModel = null;
            await this.resetList();
            return;
        }

        this.searchModel ??= new ListingSearchModel();

        this.searchModel.arrivalDate = ctx.from;
        this.searchModel.departureDate = ctx.to;
        this.searchModel.currency = ctx.currency;
        this.searchModel.location = ctx.location;
        this.searchModel.filters = {
            ...this.filters,
            occupancy: ctx.guests
        };
        this.searchModel.page = 0;
        this.searchModel.perPage = this.perPage;

        await this.resetList();
    }

    private async resetList() {
        this.total = 0;
        this.items = [];
        this.preventLoadingMore = true;

        if (this.searchModel) {
            try {
                this.isLoading = true;

                const results = await this.listingService.searchListingsByLocation(this.searchModel);

                if (results.success) {
                    const { value } = results;

                    this.total = value.total;
                    this.searchModel.page = value.page;
                    this.items = value.data;

                    this.preventLoadingMore = false;
                }
                else {
                    switch (results.reason) {
                        case "DURATION_NOT_SUPPORTED":
                            void MessageBox.prompt({
                                type: "warning",
                                confirmText: GlobalTranslationStore.$t("common.i_understand"),
                                isCancellable: false,
                                title: GlobalTranslationStore.$t("common.no_results"),
                                message: GlobalTranslationStore
                                    .$t("search.error.invalid_duration")
                                    .replace("{days}", this.searchDurationDays.toString()),
                            });

                            return;
                    }
                }
            }
            catch (e) {
                CommonErrorDialogs.unexpectedError();
            }
            finally {
                this.isLoading = false;
                this.$emit("has-results", this.total > 0);
            }
        }
    }

    private async tryLoadMore() {
        if (this.searchModel && this.canLoadMore) {
            try {
                this.isLoading = true;

                const results = await this.listingService.searchListingsByLocation({
                    ...this.searchModel,
                    page: this.searchModel.page + 1,
                });

                if (results.success) {
                    const { value } = results;

                    this.total = value.total;
                    this.searchModel.page = value.page;

                    this.items.push(...value.data);
                }
                else {
                    this.preventLoadingMore = true;
                }
            }
            catch (e) {
                this.preventLoadingMore = true;
            }
            finally {
                this.isLoading = false;
            }
        }
    }

    seeAll() {
        void this.$router.push({
            path: "/list",
            query: {
                search: this.searchContextStore.toQueryString() as any,
                filters: classToPlain(this.filters) as any
            },
        }).catch(this.handleNavigationError);
    }

    get searchDurationDays() {
        if (this.searchModel?.arrivalDate && this.searchModel?.departureDate) {
            const from = DateTime.fromISO(this.searchModel.arrivalDate).startOf("day");
            const to = DateTime.fromISO(this.searchModel.departureDate).startOf("day");

            return to.diff(from, "days").days;
        }
        else {
            return 0;
        }
    }

    get canLoadMore() {
        return (
            !this.preventLoadingMore
            && !this.isLoading
            && this.searchModel
            && (this.searchModel.page + 1) * this.perPage < this.total
        );
    }

    get isPrimary() {
        return this.total == 1;
    }

    get formattedTitle() {
        if (!this.title) return null;

        return this
            .title
            .replace("{count}", this.total.toString())
            .replace("{location}", this.searchContext.location?.query ?? "");
    }
}
