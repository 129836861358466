






import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvCurrencyPicker } from "@fastoche/ui-kit/components";
import { InjectService } from "@fastoche/ui-core/di";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { Currency } from "@fastoche/ui-core/i18n/currency/Currency";
import { SUPPORTED_CURRENCIES } from "@/modules/payment/domain";

export const COMPONENT_NAME = "CurrencySelector";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvCurrencyPicker
    }
})
export default class CurrencySelector extends BaseComponent {
    @InjectState(LocalizationContext)
    readonly state!: StateOf<LocalizationContext>;

    @InjectService(LocalizationContext)
    readonly context!: LocalizationContext;

    readonly currencies = SUPPORTED_CURRENCIES;

    get current() {
        return this.state.currentCurrency;
    }

    set current(value: Currency) {
        this.context.setCurrentCurrency(value);
        window.location.reload();
    }
}
