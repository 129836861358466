import { Query, RpcInvocable } from "@leavy/cq-core";
import { Bad, OkVal } from "@leavy/result";
import { IsUUID } from "@leavy/validator";
import { CommonBadResult } from "../results";
import { User } from "../domain/User";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { Money } from "../../money/domain/Money";

export interface GetUserInfoQueryOkResult extends User {
    referralCode?: string;

    intercom: {
        hash: string;
    };
}

export type GetUserInfoQueryResult =
    OkVal<GetUserInfoQueryOkResult>
    | Bad<CommonBadResult.USER_NOT_FOUND>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "get_user_info",
})
export class GetUserInfoQuery extends Query<GetUserInfoQueryResult> {
    @IsUUID("4")
    userId!: string;
}
