import { IsDateString, IsLocale, IsNotEmpty, IsNumber, IsString, IsUUID, Min, TryParseNumber } from "@leavy/validator";
import { Query, RpcInvocable } from "@leavy/cq-core";
import { Bad, OkVal } from "@leavy/result";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { ListingId } from "../../listing/domain/Listing";
import { HotelListingId } from "../../listing/domain/Hotel";
import { HouseBookingSummary } from "../model/HouseBookingSummary";

export type GetHouseBookingSummaryQueryResult =
    | OkVal<HouseBookingSummary>
    | Bad<"USER_NOT_HL">
    | Bad<"INVALID_LISTING_ID">
    | Bad<"LISTING_NOT_FOUND">
    | Bad<"LISTING_AVAILABLE">
    | Bad<"OCCUPANCY_EXCEEDED">
    ;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "get_booking_request_summary",
})
export class GetHouseBookingSummaryQuery extends Query<GetHouseBookingSummaryQueryResult> {
    @IsLocale()
    locale!: string;

    @IsUUID()
    userId!: string;

    @IsNumber()
    @Min(1)
    @TryParseNumber()
    guests!: number;

    @IsString()
    @IsNotEmpty()
    listingId!: ListingId | HotelListingId;

    @IsDateString()
    arrivalDate!: string;

    @IsDateString()
    departureDate!: string;
}
