































































import { Component, Ref, Watch } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import ListingSearchBar from "@/app/components/listing/search-bar/ListingSearchBar.vue";
import ListingFilterBar from "@/app/components/listing/filter-bar/ListingFilterBar.vue";
import { LvButton, LvContainer, LvFloatingSlideCard, LvIntersectionObserver, LvSpin, MessageBox } from "@fastoche/ui-kit/components";
import { InjectService } from "@fastoche/ui-core/di";
import { ListingService } from "@/modules/listing/services/ListingService";
import { ListingSearchModel, ListingSearchResult, PriceRange, SearchFiltersModel } from "@/modules/listing/model";
import ListingPeekCard from "@/app/components/listing/peek-card/ListingPeekCard.vue";
import { InjectState } from "@fastoche/ui-core/store";
import { ListingSearchContext } from "@/modules/listing/context/ListingSearchContext";
import { AccommodationType } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/AccommodationType";
import { Debounce } from "lodash-decorators";
import HlExplainedBanner from "@/app/ui/hl-explained-banner/HlExplainedBanner.vue";
import { GlobalTranslationStore } from "@fastoche/ui-core/i18n/global";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { DateTime } from "luxon";
import { ListingSearchContextStore } from "@/modules/listing/context/ListingSearchContextStore";
import { ListingFilterContext } from "@/modules/listing/context/ListingFilterContext";
import {BookingStore} from "@/modules/booking/BookingStore";
import {ListingStore} from "@/modules/listing/ListingStore";

export const COMPONENT_NAME = "ListingSearchSummaryPage";

@Component({
    name: COMPONENT_NAME,
    responsive: true,
    components: {
        HlExplainedBanner,
        ListingFilterBar,
        ListingSearchBar,
        LvContainer,
        ListingPeekCard,
        LvSpin,
        LvIntersectionObserver,
        LvFloatingSlideCard,
        LvButton
    },
})
export default class ListingSearchListPage extends BaseComponent {
    @InjectService(ListingService)
    readonly listingService!: ListingService;

    @InjectState(ListingSearchContextStore)
    readonly searchContext!: ListingSearchContext;

    @InjectService(ListingSearchContextStore)
    readonly searchContextStore!: ListingSearchContextStore;

    @InjectService(BookingStore)
    readonly bookingStore!: BookingStore;

    @InjectService(ListingStore)
    readonly listingStore!: ListingStore;

    @Ref()
    readonly filterBar!: ListingFilterBar;

    private readonly perPage = 8;
    private searchModel: ListingSearchModel | null = null;
    private filters: ListingFilterContext = new ListingFilterContext({ type: AccommodationType.HOUSE, priceRange: PriceRange.AFFORDABLE });
    private items: ListingSearchResult[] = [];
    private total: number = 0;
    private isLoading = false;
    private hasResults: boolean | null = null;
    private preventLoadingMore = false;

    mounted() {
        // reset listingStore states
        this.bookingStore.setBookingQuote(null);
        this.listingStore.setRoom(null);
        this.listingStore.setRooms(null);

        this.filters = this.filterBar.readQueryString() ??
            new ListingFilterContext({ type: AccommodationType.HOUSE, priceRange: PriceRange.AFFORDABLE });

        this.$subscribeTo(
            this.searchContextStore.value$,
            x => this.onSearchContextChange(x as ListingSearchContext),
        );
    }

    @Watch("filters", { deep: true })
    @Debounce(800)
    onFiltersChange(filters: SearchFiltersModel) {
        this.onSearchContextChange(this.searchContext);
    }

    private async onSearchContextChange(ctx: ListingSearchContext) {
        if (!ctx.isValid()) {
            this.searchModel = null;
            await this.resetList();
            return;
        }

        this.searchModel ??= new ListingSearchModel();

        this.searchModel.arrivalDate = ctx.from;
        this.searchModel.departureDate = ctx.to;
        this.searchModel.currency = ctx.currency;
        this.searchModel.location = ctx.location;
        this.searchModel.filters = {
            ...this.filters as any,
            occupancy: ctx.guests
        };
        this.searchModel.page = 0;
        this.searchModel.perPage = this.perPage;

        await this.resetList();
    }

    private async resetList() {
        this.items = [];
        this.total = 0;
        this.preventLoadingMore = true;
        this.hasResults = null;

        if (this.searchModel) {
            try {
                this.isLoading = true;

                const results = await this.listingService.searchListingsByLocation(this.searchModel);

                if (results.success) {
                    const { value } = results;

                    this.total = value.total;
                    this.searchModel.page = value.page;
                    this.items = value.data;
                    this.preventLoadingMore = false;
                }
                else {
                    switch (results.reason) {
                        case "DURATION_NOT_SUPPORTED":
                            void MessageBox.prompt({
                                type: "warning",
                                confirmText: GlobalTranslationStore.$t("common.i_understand"),
                                isCancellable: false,
                                title: GlobalTranslationStore.$t("common.no_results"),
                                message: GlobalTranslationStore
                                    .$t("search.error.invalid_duration")
                                    .replace("{days}", this.searchDurationDays.toString()),
                            });

                            return;
                    }
                }
            }
            catch (e) {
                CommonErrorDialogs.unexpectedError();
            }
            finally {
                this.hasResults = this.total > 0;
                this.isLoading = false;
            }
        }
    }

    private async tryLoadMore() {
        if (this.searchModel && this.canLoadMore) {
            try {
                this.isLoading = true;

                const results = await this.listingService.searchListingsByLocation({
                    ...this.searchModel,
                    page: this.searchModel.page + 1,
                });

                if (results.success) {
                    const { value } = results;

                    this.total = value.total;
                    this.searchModel.page = value.page;

                    this.items.push(...value.data);
                }
                else {
                    this.preventLoadingMore = true;
                }
            }
            catch (e) {
                this.preventLoadingMore = true;
            }
            finally {
                this.isLoading = false;
            }
        }
    }

    get searchDurationDays() {
        if (this.searchModel?.arrivalDate && this.searchModel?.departureDate) {
            const from = DateTime.fromISO(this.searchModel.arrivalDate).startOf("day");
            const to = DateTime.fromISO(this.searchModel.departureDate).startOf("day");

            return to.diff(from, "days").days;
        }
        else {
            return 0;
        }
    }

    get canLoadMore() {
        return (
            !this.preventLoadingMore
            && !this.isLoading
            && this.searchModel
            && (this.searchModel.page + 1) * this.perPage < this.total
        );
    }

    get isPrimary() {
        return this.total == 1;
    }

    get formattedTitle() {
        return this.$t("search.list_results.title")
            .replace("{count}", this.total.toString())
            .replace("{location}", this.searchContext.location?.query ?? "");
    }

    get hasNoResults() {
        return !this.isLoading && this.hasResults === false;
    }
}
