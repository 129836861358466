


















import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import SplitLayout from "@/app/ui/split-layout/SplitLayout.vue";

export const COMPONENT_NAME = "NotFoundErrorPage";

@Component({
    name: COMPONENT_NAME,
    components: { SplitLayout, LvIcon, LvButton },
})
export default class NotFoundErrorPage extends BaseComponent {

}
