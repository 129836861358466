import { injectable } from "inversify";
import { Bad, Ok } from "@leavy/result";

@injectable()
export abstract class EmailService {
    abstract sendEmailVerificationCode(): Promise<Ok | Bad<"USER_NOT_FOUND" | "regeneration_failed">>;

    abstract verifyEmail(email: string, verificationCode: string): Promise<Ok | Bad<"email_verification_failed" | "USER_NOT_FOUND">>;

    abstract updateEmail(email: string): Promise<Ok | Bad<"update_email_failed">>;
}
