





























































import { Component, Vue, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { LvButton, LvCard, LvContainer, LvIcon, LvIntersectionObserver, LvSpin, LvDropdown, LvTag, LvBadge, MessageBox } from "@fastoche/ui-kit/components";
import { lazyBgImg } from "@fastoche/ui-kit/directives";
import { DateTime } from "luxon";
import { currencySymbol } from "@fastoche/ui-core/i18n/currency/filters/currencySymbol";
import { money } from  "@/app/ui/filters/money.js";
import {Booking} from "@leavy/lv-homesharing-backend-srv/lib/booking/domain/Booking";
import {InjectService} from "@fastoche/ui-core/di";
import {ListingHouseService} from "@/modules/listing/services/ListingHouseService";
import {ListingHotelService} from "@/modules/listing/services/ListingHotelService";
import {InjectState, StateOf} from "@fastoche/ui-core/store";
import {LocalizationContext} from "@fastoche/ui-core/i18n/context";

const DEFAULT_PAGINATION_MODEL = { page: 0, perPage: 10, total: 0 };

export const COMPONENT_NAME = "BookingRequestPreviewCard";

@Component({
    name: COMPONENT_NAME,
    responsive: true,
    components: {
        PageHeader,
        LvContainer,
        LvButton,
        LvCard,
        LvIcon,
        LvIntersectionObserver,
        LvSpin,
        LvDropdown,
        LvTag,
        LvBadge,
    },
    directives: {
        lazyBgImg
    },
    filters: {
        currencySymbol,
        money
    }
})
export default class BookingRequestPreviewCard extends BaseComponent {
    @InjectService(ListingHouseService)
    private readonly listingHouseService!: ListingHouseService;

    @InjectService(ListingHotelService)
    private readonly listingHotelService!: ListingHotelService;

    @InjectState(LocalizationContext)
    readonly localizationContext!: StateOf<LocalizationContext>;

    @Prop({type:Object, required: true})
    private booking!: Booking;

    @Prop({type:Boolean, required: true})
    private selected!: boolean;

    windowWidth = document.documentElement.clientWidth;

    mounted() {
        window.addEventListener('resize', this.getWindowWidth);
    }

    getWindowWidth(){
        this.windowWidth = document.documentElement.clientWidth;
    }

    formatDate(date: string) {
        return DateTime.fromFormat(date, "yyyy-MM-dd").toLocaleString({
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    }

    getBookingUrl(id: string) {
        return `/app/my-bookings/${id}`;
    }
}
