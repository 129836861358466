import { Command, RpcInvocable } from "@leavy/cq-core";
import { Bad, Ok } from "@leavy/result";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { IsNotEmpty, IsString, MinLength } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

export type UpdateUserPasswordCommandResult = Ok | Bad<"WRONG_CURRENT_PASSWORD">;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "update_user_password",
})
export class UpdateUserPasswordCommand extends Command<UpdateUserPasswordCommandResult, UserPasswordUpdatedEvent> {
    @IsString()
    userId!: string;

    @IsString()
    @IsNotEmpty()
    currentPassword!: string;

    @IsString()
    @IsNotEmpty()
    @MinLength(6)
    newPassword!: string;
}

@DomainEvent({ namespace: HOMESHARING_BACKEND_SERVICE_NAME, name: "user_password_updated", version: 1 })
export class UserPasswordUpdatedEvent extends DomainEventBase {
    @EventProperty()
    userId!: string;

    constructor(data: PayloadOf<UserPasswordUpdatedEvent>) {
        super(data);
    }
}

