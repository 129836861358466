import { MutableStore } from "@fastoche/ui-core/store";
import { injectable } from "inversify";
import { UserService } from "@/modules/user/services/UserService";
import { UserInfo } from "./domain";
import { raise } from "@leavy/utils";
import { Subscription } from "rxjs";
import { skip } from "rxjs/operators";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";
import { SessionService } from "@/modules/auth/services/SessionService";

export interface UserContext extends UserInfo {}

@injectable()
export class UserContextStore extends MutableStore<UserContext | null> {
    private authStoreSubscription?: Subscription;

    constructor(
        private readonly userService: UserService,
        private readonly sessionService: SessionService,
        private readonly authStore: AuthenticationStore,
    ) {
        super(null);
    }

    registerListeners() {
        if (!this.authStoreSubscription) {
            this.authStoreSubscription = this.authStore.value$.pipe(skip(1)).subscribe(x => this.refreshUserInfo());
        }
    }

    async initialize() {
        if (!this.value) {
            await this.refreshUserInfo();
        }
    }

    isAuthenticated() {
        return this.authStore.isAuthenticated();
    }

    isHL() {
        return false;
    }

    async refreshUserInfo() {
        if (this.isAuthenticated()) {
            const getUserInfoResult = await this.userService.getUserInfo();

            if (getUserInfoResult.success) {
                this.set(getUserInfoResult.value);
            }
            else {
                switch (getUserInfoResult.reason) {
                    case "USER_NOT_FOUND":
                        this.authStore.clearToken();
                        break;
                }

                this.clearUserInfo();
            }
        }
        else {
            this.clearUserInfo();
        }
    }

    async getUserInfo() {
        if (!this.isAuthenticated()) return null;

        if (!this.value) {
            await this.refreshUserInfo();
        }

        return this.value ?? null;
    }

    async getUserInfoOrThrow() {
        return (await this.getUserInfo()) ?? raise(new Error("User infos expected"));
    }

    clearUserInfo() {
        this.mutate(() => null);
    }
}
