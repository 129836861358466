import { Query, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { IsUUID } from "@leavy/validator";
import { OkVal } from "@leavy/result";
import { HlUserManagementSummary } from "../domain/HlUserManagementSummary";

export type HlUserGetManagementSummaryQueryResult =
    OkVal<HlUserManagementSummary>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_get_management_summary",
})
export class HlUserGetManagementSummaryQuery extends Query<HlUserGetManagementSummaryQueryResult> {
    @IsUUID()
    userId!: string;
}
