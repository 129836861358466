

































import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { LvModal, LvIcon, LvBadge, LvTag, LvButton, LvCarousel, LvCarouselSlide } from "@fastoche/ui-kit";
import AmenityIcon from "@/app/components/amenity/icon/AmenityIcon.vue";
import {HotelRoom} from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/Listing";

export const COMPONENT_NAME = "HotelRoomModal";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvModal,
        LvIcon,
        LvBadge,
        LvTag,
        LvButton,
        LvCarousel,
        LvCarouselSlide,
        AmenityIcon,
    },
})
export default class HotelRoomModal extends Vue {
    @Ref()
    private readonly modal!: LvModal;
    room: HotelRoom | null = null;

    show(room) {
        this.room = room;
        this.modal.show();
    }

    hide() {
        this.modal.hide();
        this.room = null;
    }
}
