import { IsLocale, IsNotEmpty, IsString } from "@leavy/validator";
import { Query, RpcInvocable } from "@leavy/cq-core";
import { Bad, OkVal } from "@leavy/result";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { ListingId } from "../domain/Listing";
import { HotelQueryResult, HouseListingQueryResult } from "../model/ListingQueryResult";

export type GetHotelListingByIdQueryResult =
    | OkVal<HotelQueryResult>
    | OkVal<null>
    | Bad<"INVALID_ID">;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "get_hotel_listing_by_id_query",
})
export class GetHotelListingByIdQuery extends Query<GetHotelListingByIdQueryResult> {
    @IsLocale()
    locale!: string;

    @IsString()
    @IsNotEmpty()
    listingId!: ListingId;
}
