


















import { Component, Model, Prop, Ref, Watch } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { loadGoogleMaps } from "@/app/vendors/google-maps/lib";
import { AddressModel } from "@/modules/core/model";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import { LvIcon } from "@fastoche/ui-kit/components";

function findComponent(type: string, obj: google.maps.places.PlaceResult) {
    return obj.address_components!.find(x => x.types.includes(type));
}

export const COMPONENT_NAME = "AddressInput";

@Component({
    name: COMPONENT_NAME,
    components: { LvField, LvIcon },
})
export default class AddressInput extends BaseComponent {
    private addressAutocomplete: google.maps.places.Autocomplete | null = null;
    private internalModel: AddressModel = new AddressModel();
    private input: string = "";

    @Ref()
    readonly addressInput!: HTMLInputElement;

    @Prop({ type: String, required: false })
    placeholder?: string;

    @Model("change", { type: Object, required: false, default: () => null })
    readonly model?: AddressModel;

    async mounted() {
        try {
            const loaded = await loadGoogleMaps();
            if (!loaded) {
                CommonErrorDialogs.networkError();
            }
            else {
                this.createPlaceAutocomplete();
            }
        }
        catch (e) {}
    }

    beforeDestroy() {
        this.destroyPlaceAutocomplete();
    }

    @Watch("model", { immediate: true })
    onModelChange(newVal?: AddressModel, oldVal?: AddressModel) {
        if (!newVal || oldVal == newVal) return;

        this.internalModel = newVal;
        this.input = this.internalModel.address;
    }

    onInputBlur() {
        if (this.input !== this.internalModel.address) {
            this.$emit("change", null);
        }

        this.$emit("blur");
    }

    onInputChange() {
        if (!this.input) {
            this.$emit("change", null);
        }
    }

    private createPlaceAutocomplete() {
        if (this.addressAutocomplete != null) return;

        this.addressAutocomplete = new google.maps.places.Autocomplete(
            this.addressInput,
            { types: ["geocode"] },
        );

        this.addressAutocomplete.addListener("place_changed", () => {
            const place = this.addressAutocomplete!.getPlace();

            this.internalModel = new AddressModel();

            if (!place.geometry || !place.formatted_address || !place.address_components) {
                return;
            }

            this.internalModel = new AddressModel({
                address: place.formatted_address || "",
                additionalInformations: "",
                city: (
                    findComponent("locality", place) ||
                    findComponent("postal_town", place) ||
                    findComponent("sublocality", place)
                )?.long_name || "",
                postalCode: findComponent("postal_code", place)?.long_name || "",
                countryCode: findComponent("country", place)?.short_name || "",
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            });

            this.input = this.internalModel.address;

            this.$emit("change", this.internalModel);
        });
    }

    private destroyPlaceAutocomplete() {
        if (this.addressAutocomplete == null) return;

        google.maps.event.clearInstanceListeners(this.addressInput);

        this.addressAutocomplete.unbindAll();
        this.addressAutocomplete = null!;

        document.querySelector(".pac-container")?.remove();
    }
}
