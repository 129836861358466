import { BaseModule, Module, ModuleContainer, ApplicationReadyHook } from "@fastoche/core";
import { SegmentUserIdentityTracker } from "@/modules/tracking/SegmentUserIdentityTracker";

@Module()
export class AppTrackingModule extends BaseModule {
    registerServices(container: ModuleContainer) {
        container.bind(SegmentUserIdentityTracker).toSelf().inSingletonScope();
    }

    applicationReady(hook: ApplicationReadyHook): void | Promise<void> {
        const { rootContainer } = hook.applicationContext;

        const identityTracker = rootContainer.get(SegmentUserIdentityTracker);
        identityTracker.startTracking();
    }
}
