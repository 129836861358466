import { SubmitHouseBookingCommand as cmd } from "../SubmitHouseBookingCommand";
import { BuildClientInvocation, LocaleAmbientValue, UserIdAmbientValue } from "@leavy/cq-core";

export class RequestBookingCommand extends BuildClientInvocation({
    type: cmd,
    ambientValues: {
        userId: UserIdAmbientValue,
        locale: LocaleAmbientValue,
    },
}) {
}
