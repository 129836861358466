























import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { BaseQuizQuestionComponent } from "./BaseQuestion";
import { QuizBaseQuestion, QuizFormSchema, QuizModel } from "@/modules/onboarding/hod/model/vetting/model";
import CoverQuestion from "./CoverQuestion.vue";
import { QUIZ_FORM_COMPONENTS } from "./components";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";

export const COMPONENT_NAME = "QuizForm";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvButton,
        CoverQuestion,
    },
})
export default class QuizForm extends BaseComponent {
    @Prop({ type: Object, required: true })
    schema!: QuizFormSchema;

    @Prop({ type: Object, required: true })
    model!: QuizModel;

    @Ref()
    questionComponent!: BaseQuizQuestionComponent<QuizBaseQuestion>;

    currentIdx = 0;
    status: "can_validate" | "validated" | null = null;

    mounted() {
        for (const question of this.schema.questions) {
            if (!this.model.answers.some(x => x.questionId == question.id)) {
                this.model.answers.push({ questionId: question.id, answer: [], correct: false });
            }
        }

        this.checkIfCanValidate();
    }

    get current() {
        const schema = this.schema.questions[this.currentIdx]!;
        const model = this.model.answers.find(x => x.questionId == schema.id);

        return { schema, model };
    }

    get isLast() {
        return this.currentIdx == this.schema.questions.length - 1;
    }

    get nextButtonText() {
        return this.status == "can_validate" ? this.$t("common.validate") :
            this.status == "validated" ? this.$t("common.next") :
            null;
    }

    get canPrevious() {
        return this.currentIdx > 0;
    }

    getComponent(question: QuizBaseQuestion) {
        return (QUIZ_FORM_COMPONENTS as any)[question.format];
    }

    checkIfCanValidate() {
        const { model, schema } = this.current;

        if (!model) this.status = null;
        else if (schema.type == "single") this.status = model.answer.length == 1 ? "can_validate" : null;
        else this.status = model.answer.length > 0 ? "can_validate" : null;

        if (this.status == "can_validate" && schema.expected.length == model?.answer.length) {
            this.validate();
        }
    }

    validate() {
        const { model, schema } = this.current;
        if (!model) return;

        this.status = "validated";

        model.correct =
            model.answer.length == schema.expected.length &&
            schema.expected.every(x => model.answer.includes(x));

        this.$emit("validated");
    }

    onNextButtonClick() {
        if (this.status == "can_validate") this.validate();
        else if (this.status == "validated") this.next();
    }

    next() {
        if (!this.isLast) {
            this.currentIdx++;
            this.checkIfCanValidate();
        }
        else this.submit();
    }

    previous() {
        if (this.canPrevious) {
            this.currentIdx--;
            this.checkIfCanValidate();
        }
    }

    submit() {
        this.$emit("submit");
    }
}
