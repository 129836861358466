import { BuildClientInvocation, UserIdAmbientValue } from "@leavy/cq-core";
import * as OnboardingService from "@leavy/lv-onboarding-srv/lib/hl/command";
import { AddInventorySubjectPhotoLocalCommand } from "./AddInventorySubjectPhotoLocalCommand";
import { SaveAccommodationPhotoStepLocalCommand } from "./SaveAccommodationPhotoStepLocalCommand";
import { DeleteInventorySubjectPhotoLocalCommand } from "./DeleteInventorySubjectPhotoLocalCommand";
import { DeleteInventorySubjectLocalCommand } from "./DeleteInventorySubjectLocalCommand";
import { DeleteAccommodationPhotoLocalCommand } from "./DeleteAccommodationPhotoLocalCommand";
import { AddAdministrativeDocumentLocalCommand } from "./AddAdministrativeDocumentLocalCommand";
import { RemoveAdministrativeDocumentLocalCommand } from "./RemoveAdministrativeDocumentLocalCommand";
import { SubmitRevenueEstimationStepLocalCommand } from "./SubmitRevenueEstimationStepLocalCommand";
import { ForwardedOnboardingCommand } from "../../ForwardedOnboardingCommand";

export class SubmitUpcomingDepartureStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitUpcomingDepartureStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SaveAccommodationDescriptionStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SaveAccommodationDescriptionStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitAccommodationDescriptionStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitAccommodationDescriptionStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitAccommodationPhotosStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitAccommodationPhotosStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class AddInventorySubjectCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.AddInventorySubjectCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class EditInventorySubjectCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.EditInventorySubjectCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitInventoryStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitInventoryStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitAdministrativeInfoStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitAdministrativeInfoStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitPaymentPreferencesStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitPaymentPreferencesStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitAppointmentRequestStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitAppointmentRequestStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SaveAccommodationPhotoStepCommand extends BuildClientInvocation({
    type: SaveAccommodationPhotoStepLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class DeleteAccommodationPhotoCommand extends BuildClientInvocation({
    type: DeleteAccommodationPhotoLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class AddInventorySubjectPhotoCommand extends BuildClientInvocation({
    type: AddInventorySubjectPhotoLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class DeleteInventorySubjectPhotoCommand extends BuildClientInvocation({
    type: DeleteInventorySubjectPhotoLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class DeleteInventorySubjectCommand extends BuildClientInvocation({
    type: DeleteInventorySubjectLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class AddAdministrativeDocumentCommand extends BuildClientInvocation({
    type: AddAdministrativeDocumentLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class RemoveAdministrativeDocumentCommand extends BuildClientInvocation({
    type: RemoveAdministrativeDocumentLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitRevenueEstimationStepCommand extends BuildClientInvocation({
    type: SubmitRevenueEstimationStepLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
