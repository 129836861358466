import { RouteConfig } from "vue-router";
import { RouterContainer } from "@/app/pages/RouterContainer";
import { HOD_ONBOARDING_ROUTES } from "@/app/pages/onboarding/hod/routes";

export const HOD_ROUTES: RouteConfig[] = [
    {
        path: "",
        redirect: "onboarding",
    },
    {
        path: "onboarding",
        component: RouterContainer,
        children: [...HOD_ONBOARDING_ROUTES],
    },
];
