import { BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { HlPricingService } from "@/modules/pricing/services/HlPricingService";
import { CqHlPricingService } from "@/modules/pricing/platform/cq/CqHlPricingService";

@Module()
export class PricingModule extends BaseModule {
    registerServices(container: ModuleContainer, config: undefined): void | Promise<void> {
        container.bind(HlPricingService).to(CqHlPricingService).inSingletonScope();
    }
}
