import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { RegistrationService } from "@/modules/registration/services/RegistrationService";
import { RegisterUserCommand } from "./command";
import { UserRegistration } from "@/modules/registration/model";

@injectable()
export class CqRegistrationService extends RegistrationService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    register(registration: UserRegistration) {
        return this.cq.invoke(RegisterUserCommand, { ...registration });
    }
}
