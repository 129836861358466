import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { HotelQueryResult } from "@/modules/listing/model";
import { Bad, Ok, OkVal } from "@leavy/result";
import { FindAvailableHotelRoomsQuery } from "@leavy/lv-homesharing-backend-srv/lib/listing/query/FindAvailableHotelRoomsQuery";
import { GetHotelListingByIdQuery } from "@leavy/lv-homesharing-backend-srv/lib/listing/query/GetHotelListingByIdQuery";
import { Currency } from "@/modules/payment/domain";
import { BookingQuoteViewModel } from "@/modules/booking/model/BookingQuoteViewModel";
import { HotelRoom } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/Listing";
import { ListingHotelService } from "@/modules/listing/services/ListingHotelService";
import { LocalizationOptionsStore } from "@fastoche/ui-core/i18n/options/LocalizationOptions";

@injectable()
export class CqListingHotelService extends ListingHotelService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
        private readonly localizationStore: LocalizationOptionsStore,
    ) {
        super();
    }

    async getHotelListingById(id: string): Promise<OkVal<HotelQueryResult> | Bad<"NOT_FOUND">> {
        const result = await this.cq.invoke(
            GetHotelListingByIdQuery,
            {
                listingId: id,
                locale: this.localizationStore.value.defaultLocale.localeCode,
            },
        );

        if (result.success) {
            const value = result.value;

            if (value == null) {
                return Bad("NOT_FOUND");
            }
            else {
                return Ok(value);
            }
        }
        else {
            switch (result.reason) {
                case "INVALID_ID":
                    return Bad("NOT_FOUND");
            }
        }
    }

    async getHotelRooms(hotelId: string, model: BookingQuoteViewModel, currency: Currency): Promise<OkVal<HotelRoom[]> | Bad<"INVALID_DATE_INTERVAL">> {
        if (model.from && model.to) {
            const result = await this.cq.invoke(
                FindAvailableHotelRoomsQuery,
                {
                    hotelId: hotelId,
                    arrivalDate: model.from,
                    departureDate: model.to,
                    guests: model.guests,
                    currency: currency,
                },
            );

            if (result.success) {
                const value = result.value;
                return Ok(value);
            }
            else {
                switch (result.reason) {
                    case "INVALID_DATE_INTERVAL":
                        return Bad("INVALID_DATE_INTERVAL");
                }
            }
        }
        else {
            return Bad("INVALID_DATE_INTERVAL");
        }
    }
}
