import { RouteConfig } from "vue-router";
import { applyCategoryMeta } from "@fastoche/ui-tracking/core/router/applyCategoryMeta";
import NotFoundErrorPage from "@/app/pages/error/NotFoundErrorPage.vue";
import UnexpectedErrorPage from "@/app/pages/error/UnexpectedErrorPage.vue";

export const ERROR_ROUTES: RouteConfig[] = [
    {
        path: "",
        name: "UnexpectedErrorPage",
        component: UnexpectedErrorPage,
    },
    {
        path: "not-found",
        name: "NotFoundErrorPage",
        component: NotFoundErrorPage,
    },
].map(route => applyCategoryMeta("Error", route));
