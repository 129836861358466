import { Equals, IsDefined, IsEnum, IsOptional, IsString } from "@leavy/validator";
import { StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { OnboardingId } from "@leavy/lv-onboarding-srv/lib/common/domain/OnboardingId";
import { BinaryFile, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../../base";
import { BaseFile } from "../../../common/BaseFile";
import { OkVal } from "@leavy/result";
import { AdministrativeDocumentType } from "@leavy/lv-onboarding-srv/lib/hl/domain/administrative/AdministrativeInfo";
import { CommonEditOnboardingError } from "../../ForwardedOnboardingCommand";

export type AddAdministrativeDocumentLocalCommandResult =
    | OkVal<{ fileId: string }>
    | CommonEditOnboardingError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "upload_hl_administrative_document",
})
export class AddAdministrativeDocumentLocalCommand extends StepCommand<AddAdministrativeDocumentLocalCommandResult> {
    @Equals(OnboardingId.HL)
    onboardingId: OnboardingId.HL = OnboardingId.HL;

    @BinaryFile()
    @IsDefined()
    document!: BaseFile;

    @IsEnum(AdministrativeDocumentType)
    documentType!: AdministrativeDocumentType;

    @IsOptional()
    @IsString()
    label?: string;
}
