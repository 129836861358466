


















































































import { Component } from "vue-property-decorator";
import { LvContainer } from "@fastoche/ui-kit/components";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { InjectService } from "@fastoche/ui-core/di";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { OnboardingService } from "@/modules/onboarding/common/services/OnboardingService";
import { HOD_ONBOARDING_END_LOCATION, HOD_ONBOARDING_WORKFLOW } from "@/modules/onboarding/hod/workflow";
import { AnyHodOnboardingStepId } from "@/modules/onboarding/hod/domain";
import { OnboardingId, UserOnboardingStatus, UserOnboardingStepStatus } from "@/modules/onboarding/common/domain";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import { assert } from "@leavy/utils";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import LvProgressCircle from "@fastoche/ui-kit/components/progress-circle/LvProgressCircle.vue";
import { APP_LOGGER } from "@/app/logger";

export const COMPONENT_NAME = "HodOnboardingOverviewPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvProgressCircle,
        LvIcon,
        LvButton,
        PageHeader,
        LvContainer,
    },
})
export default class HodOnboardingOverviewPage extends BaseHodOnboardingPage {
    @InjectState(HodOnboardingStore)
    private readonly state!: StateOf<HodOnboardingStore>;

    @InjectService(HodOnboardingStore)
    private readonly store!: HodOnboardingStore;

    @InjectService(OnboardingService)
    private readonly onboardingService!: OnboardingService;

    private readonly workflow = HOD_ONBOARDING_WORKFLOW;

    private readonly stepTitles = {
        [AnyHodOnboardingStepId.EMAIL_VERIFICATION]: () => this.$t("app.onboarding.steps.email_verification"),
        [AnyHodOnboardingStepId.IDENTITY]: () => this.$t("app.onboarding.steps.identity_proof"),
        [AnyHodOnboardingStepId.HOD_MATCHING]: () => this.$t("app.onboarding.hod.steps.about_you"),
        [AnyHodOnboardingStepId.HOD_GET_TO_KNOW_US]: () => this.$t("app.onboarding.hod.steps.get_to_know_us"),
        [AnyHodOnboardingStepId.HOD_ADMINISTRATIVE_DOCS]: () => this.$t("app.onboarding.hod.steps.administrative_documents"),
        [AnyHodOnboardingStepId.PAYMENT_INFO]: () => this.$t("app.onboarding.steps.payment_informations"),
        [AnyHodOnboardingStepId.HOD_MEETING]: () => this.$t("app.onboarding.hod.steps.online_meeting"),
    };

    private get progress() {
        return this.state?.progress ?? 0;
    }

    private get progressStatus() {
        if (this.progress < 100) return "active";
        else {
            if (this.canSubmit) return "active";
            else return "success";
        }
    }

    private get hasPending() {
        if (!this.state) return false;
        return this.state.hasPendingSteps;
    }

    private get isEditable() {
        return this.state?.status == UserOnboardingStatus.STARTED;
    }

    private get isWaiting() {
        return this.state?.status == UserOnboardingStatus.SUBMITTED;
    }

    private get canSubmit() {
        return this.state?.onboarding.canSubmit === true && this.state.onboarding.status == UserOnboardingStatus.STARTED;
    }

    private mounted() {
        if (this.canSubmit) {
            void this.trySubmit();
        }
    }

    private onStepLinkClick(ev: MouseEvent, stepId: AnyHodOnboardingStepId) {
        void this.$track("HodOnboardingStepClickEvent", { stepId });
        void this.goToStepLocation(stepId);
    }

    private onStartButtonClick(ev: MouseEvent) {
        void this.$track("HodOnboardingStartClickEvent");
        void this.startAndResume();
    }

    private onResumeButtonClick(ev: MouseEvent) {
        void this.$track("HodOnboardingResumeClickEvent");
        void this.resume();
    }

    private onSubmitButtonClick(ev: MouseEvent) {
        void this.$track("HodOnboardingSubmitClickEvent");
        void this.submit();
    }

    private getStepTitle(step: AnyHodOnboardingStepId) {
        return this.stepTitles[step]();
    }

    private getStepStatus(step: AnyHodOnboardingStepId) {
        const stepModel = this.state?.steps.find((x) => x.stepId == step);
        return stepModel?.status ?? UserOnboardingStepStatus.NOT_STARTED;
    }

    @LoaderTask
    private async goToStepLocation(stepId: AnyHodOnboardingStepId) {
        try {
            if (!this.isEditable) return;
            if (!this.state) await this.start();

            const location = this.store.getStepLocation(stepId);
            await this.$router.push(location).catch(this.handleNavigationError);
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    private async start() {
        try {
            const startResult = await this.onboardingService.startOnboarding(OnboardingId.HOD);

            if (!startResult.success) {
                switch (startResult.reason) {
                    case "ONBOARDING_ALREADY_STARTED": {
                        await this.onboardingService.loadStore();
                        break;
                    }

                    default:
                        throw new Error("Unexpected error when starting onboarding");
                }
            }

            assert(this.state, "State should be set if starting onboarding was successful");
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    private async startAndResume() {
        await this.start();
        await this.resume();
    }

    @LoaderTask
    private async resume() {
        if (!this.state || !this.hasPending) return;
        const firstPending = this.state.firstPendingStep!;
        await this.goToStepLocation(firstPending.stepId as AnyHodOnboardingStepId);
    }

    @LoaderTask
    private async trySubmit() {
        try {
            await this.onboardingService.submitOnboarding(OnboardingId.HOD);
        }
        catch (error) {
            APP_LOGGER.warn("Trying to submit the onboarding has failed", { error });
        }
    }

    @LoaderTask
    private async submit() {
        try {
            const result = await this.onboardingService.submitOnboarding(OnboardingId.HOD);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_INCOMPLETE":
                        await this.onboardingService.loadStore();
                        await this.resume();
                        break;
                }
            }
            else {
                await this.$router.replace(HOD_ONBOARDING_END_LOCATION).catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
