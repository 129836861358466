
















































import { Component, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvButton, LvIcon, LvModal } from "@fastoche/ui-kit/components";

export const COMPONENT_NAME = "HlExplainedPopup";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvModal,
        LvButton,
        LvIcon
    },
})
export default class HlExplainedPopup extends BaseComponent {
    @Ref()
    readonly modal!: LvModal;

    readonly slides = [
        {
            picture: require("@/app/assets/pricing-popup/slide_1.jpg"),
            title: "hl_explained.popup.slide_1.title",
            description: "hl_explained.popup.slide_1.message",
        },
        {
            picture: require("@/app/assets/pricing-popup/slide_2_3.jpg"),
            title: "hl_explained.popup.slide_2.title",
            description: "hl_explained.popup.slide_2.message",
        },
        {
            picture: require("@/app/assets/pricing-popup/slide_4.jpg"),
            title: "hl_explained.popup.slide_3.title",
            description: "hl_explained.popup.slide_3.message",
        },
        {
            picture: require("@/app/assets/pricing-popup/slide_5.jpg"),
            title: "hl_explained.popup.slide_4.title",
            description: "hl_explained.popup.slide_4.message",
        },
        {
            picture: require("@/app/assets/pricing-popup/slide_6.jpg"),
            title: "hl_explained.popup.slide_5.title",
            description: "hl_explained.popup.slide_5.message",
        },
        {
            picture: require("@/app/assets/pricing-popup/slide_7.jpg"),
            title: "hl_explained.popup.slide_6.title",
            description: "hl_explained.popup.slide_6.message",
        },
    ];

    currentSlideIndex = 0;

    get currentSlide() {
        return this.slides[this.currentSlideIndex]!;
    }

    get isLast() {
        return this.currentSlideIndex == this.slides.length - 1;
    }

    next() {
        if (!this.isLast) {
            this.currentSlideIndex++;
        }
    }

    prev() {
        if (this.currentSlideIndex > 0) {
            this.currentSlideIndex--;
        }
    }

    show() {
        this.currentSlideIndex = 0;
        this.modal.show();
    }

    hide() {
        this.modal.hide();
    }
}
