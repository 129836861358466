import {
    IsArray,
    IsDateString,
    IsDefined,
    IsEnum,
    IsIn,
    IsISO31661Alpha2,
    IsLocale,
    IsNumber,
    IsOptional,
    IsString,
    IsUUID,
    Max,
    Min,
    TryParseNumber,
    ValidateNested,
} from "@leavy/validator";
import { Query, RpcInvocable } from "@leavy/cq-core";
import { Bad, OkVal } from "@leavy/result";
import {Currency, SUPPORTED_CURRENCIES} from "../../money/domain/Currency";
import { ListingSearchResult } from "../model/ListingSearchResult";
import { AccommodationType } from "../domain/AccommodationType";
import { Type } from "class-transformer";
import { AmenityId } from "../domain/Amenity";
import { ListingFilters, PriceRange } from "../model/ListingQueryResult";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { PaginatedResult } from "../../common/model/PaginatedResult";
import { Alpha2CountryCode } from "../domain/Country";

export type FindListingByLocationQueryResult =
    | OkVal<PaginatedResult<ListingSearchResult>>
    | Bad<"INVALID_QUERY">
    | Bad<"INVALID_DATE_INTERVAL">
    | Bad<"MISSING_RENT_OR_CURRENCY">
    ;

export class FindListingFilters implements ListingFilters {
    @IsEnum(AccommodationType)
    type!: AccommodationType;

    @IsNumber()
    @TryParseNumber()
    @IsOptional()
    minPrice?: number;

    @IsNumber()
    @TryParseNumber()
    @IsOptional()
    maxPrice?: number;

    @IsNumber()
    @IsOptional()
    @TryParseNumber()
    occupancy?: number;

    @IsNumber()
    @IsOptional()
    @TryParseNumber()
    bedrooms?: number;

    @IsArray()
    @IsOptional()
    @Type(() => String)
    amenities?: AmenityId[];
}

export class FindListingLocation {
    @IsString()
    @IsOptional()
    query?: string;

    @IsNumber()
    @IsOptional()
    @TryParseNumber()
    lat?: number;

    @IsNumber()
    @IsOptional()
    @TryParseNumber()
    lon?: number;

    @IsString()
    @IsOptional()
    @IsISO31661Alpha2()
    countryCode?: Alpha2CountryCode;
}

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "find_listing_by_location_query",
})
export class FindListingByLocationQuery extends Query<FindListingByLocationQueryResult> {
    @IsUUID()
    @IsOptional()
    userId?: string;

    @IsLocale()
    locale!: string;

    @IsString()
    @IsDateString()
    arrivalDate!: string;

    @IsString()
    @IsDateString()
    departureDate!: string;

    @ValidateNested()
    @Type(() => FindListingLocation)
    @IsDefined()
    location!: FindListingLocation;

    @IsIn(SUPPORTED_CURRENCIES)
    currency!: Currency;

    @IsDefined()
    @ValidateNested()
    @Type(() => FindListingFilters)
    filters!: FindListingFilters;

    @IsNumber()
    @Min(0)
    @TryParseNumber()
    page: number = 0;

    @IsNumber()
    @Min(1)
    @Max(100)
    @TryParseNumber()
    perPage: number = 10;
}
