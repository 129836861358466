


























































































































































import { Component, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvButton, LvCard, LvContainer, LvDateRangePicker, LvForm, LvFormItem, LvIcon } from "@fastoche/ui-kit";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import BulletStepper, { BulletStep } from "@/app/ui/bullet-stepper/BulletStepper.vue";
import HlUserAccommodationSelector from "@/app/components/hl/accommodation/HlUserAccommodationSelector.vue";
import { IsArray, IsDateString, IsEnum, IsInstance, IsUUID, Type, ValidateNested } from "@leavy/validator";
import { HlPaymentSchedulingOption } from "@leavy/lv-homesharing-pricing-srv/lib/pricing/domain/HlPaymentSchedulingOption";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { LvDateRangePickerInterval } from "@fastoche/ui-kit/components/date-range-picker/model";
import { DateTime } from "luxon";
import { InjectService } from "@fastoche/ui-core/di";
import { HlService } from "@/modules/hl/services/HlService";
import { HlUserAccommodationPeriodEstimation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodationPeriodEstimation";
import { MessageBox } from "@fastoche/ui-kit/components";
import { GlobalTranslationStore } from "@fastoche/ui-core/i18n/global";
import HlUserPaymentPlanSelector from "@/app/components/hl/payment-plan/HlUserPaymentPlanSelector.vue";
import { PaymentSchedulingOption } from "@/modules/onboarding/hl/domain";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { hugeLocaleDate } from "@/app/filters/date";

type RequestNewManagementPeriodSteps = "accommodation" | "dates" | "payment_preferences" | "summary";

class SelectAccommodationFormModel {
    @IsUUID()
    accommodationId!: string;
}

class SelectDatesFormModel {
    @IsArray()
    @IsDateString(undefined, { each: true })
    dates: [string | null, string | null] | null = null;

    @IsDateString()
    get departureDate() {
        if (!this.dates) return null;
        const [departureDate] = this.dates;
        return departureDate;
    }

    @IsDateString()
    get returnDate() {
        if (!this.dates) return null;
        const [, returnDate] = this.dates;
        return returnDate;
    }
}

class SelectPaymentPreferencesFormModel {
    @IsEnum(HlPaymentSchedulingOption)
    schedulingOption!: HlPaymentSchedulingOption;
}

class NewManagementPeriodFormModel {
    @Type(() => SelectAccommodationFormModel)
    @IsInstance(SelectAccommodationFormModel)
    @ValidateNested()
    accommodation = new SelectAccommodationFormModel();

    @Type(() => SelectDatesFormModel)
    @IsInstance(SelectDatesFormModel)
    @ValidateNested()
    dates = new SelectDatesFormModel();

    @Type(() => SelectPaymentPreferencesFormModel)
    @IsInstance(SelectPaymentPreferencesFormModel)
    @ValidateNested()
    paymentPreferences = new SelectPaymentPreferencesFormModel();
}

export const COMPONENT_NAME = "HlRequestNewManagementPeriodPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        HlUserPaymentPlanSelector,
        HlUserAccommodationSelector,
        BulletStepper,
        PageHeader,
        LvContainer,
        LvCard,
        LvButton,
        LvIcon,
        LvForm,
        LvFormItem,
        LvDateRangePicker,
    },
    filters: {
        price,
        hugeLocaleDate,
    },
})
export default class HlRequestNewManagementPeriodPage extends BaseComponent {
    @InjectService(HlService)
    readonly hlService!: HlService;

    @Ref() readonly rootForm!: LvForm;
    @Ref() readonly accommodationForm!: LvForm;
    @Ref() readonly datesForm!: LvForm;
    @Ref() readonly paymentPreferencesForm!: LvForm;

    private readonly minBookingDuration: LvDateRangePickerInterval = { value: 1, unit: "days" };
    private readonly minDate = DateTime.local().startOf("day").plus({ day: 1 }).toISO();
    private currentStep: RequestNewManagementPeriodSteps = "accommodation";
    private model = new NewManagementPeriodFormModel();
    private estimation: HlUserAccommodationPeriodEstimation | null = null;

    get steps(): BulletStep<RequestNewManagementPeriodSteps>[] {
        return [
            {
                id: "accommodation",
                label: this.$t("hl.new_management_period.steps.accommodation"),
            },
            {
                id: "dates",
                label: this.$t("hl.new_management_period.steps.dates"),
            },
            {
                id: "payment_preferences",
                label: this.$t("hl.new_management_period.steps.payment_preferences"),
            },
            {
                id: "summary",
                label: this.$t("hl.new_management_period.steps.summary"),
            },
        ];
    }

    get paymentPlanNames(): Record<PaymentSchedulingOption, string> {
        return {
            [PaymentSchedulingOption.PERIOD_START]: this.$t("hl.payment_plan.options.period_start"),
            [PaymentSchedulingOption.PERIOD_MIDDLE]: this.$t("hl.payment_plan.options.period_middle"),
            [PaymentSchedulingOption.PERIOD_END]: this.$t("hl.payment_plan.options.period_end"),
        };
    }

    get selectedPaymentPlan() {
        return this.estimation?.possiblePaymentPlans.find(x => x.schedulingOption == this.model.paymentPreferences.schedulingOption);
    }

    get firstPayment() {
        return this.selectedPaymentPlan?.schedule[0];
    }

    @LoaderTask
    async onAccommodationStepSubmit() {
        if (!await this.accommodationForm.submit()) return;
        this.currentStep = "dates";
    }

    @LoaderTask
    async onDatesStepSubmit() {
        if (!await this.datesForm.submit()) return;

        try {
            const getEstimationResult = await this.hlService.getPeriodEstimationForUserAccommodation(
                this.model.accommodation.accommodationId,
                this.model.dates.departureDate!,
                this.model.dates.returnDate!,
                this.$i18n.currency,
            );

            if (getEstimationResult.success) {
                this.estimation = getEstimationResult.value;
                this.currentStep = "payment_preferences";
            }
            else {
                switch (getEstimationResult.reason) {
                    case "ACCOMMODATION_NOT_FOUND":
                        await this.$router.replace("/app/happy-leaver").catch(this.handleNavigationError);
                        return;

                    case "ESTIMATION_FAILED":
                    case "INVALID_LOCATION":
                        void MessageBox.prompt({
                            type: "warning",
                            confirmText: GlobalTranslationStore.$t("common.i_understand"),
                            isCancellable: false,
                            title: GlobalTranslationStore.$t("hl_estimation.error.title"),
                            message: GlobalTranslationStore.$t("hl_estimation.error.message"),
                        });
                        return;
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async onPaymentPreferencesStepSubmit() {
        if (!await this.paymentPreferencesForm.submit()) return;
        this.currentStep = "summary";
    }

    @LoaderTask
    async onFormSubmit() {
        if (!await this.rootForm.submit()) {
            CommonErrorDialogs.validationError();
            return;
        }

        try {
            const result = await this.hlService.requestNewManagementPeriod(
                this.model.accommodation.accommodationId,
                this.model.dates.departureDate!,
                this.model.dates.returnDate!,
                this.model.paymentPreferences.schedulingOption,
            );

            if (result.success) {
                await this.$router.replace("/app/happy-leaver").catch(this.handleNavigationError);
                return;
            }
            else {
                switch (result.reason) {
                    case "ACCOMMODATION_NOT_FOUND":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace("/app/happy-leaver").catch(this.handleNavigationError);
                        return;

                    case "INVALID_MANAGEMENT_PERIOD_DURATION":
                    case "INVALID_SCHEDULING_OPTION_FOR_MANAGEMENT_PERIOD":
                        CommonErrorDialogs.validationError();
                        return;

                    case "MANAGEMENT_PERIOD_DATES_CONFLICT":
                        MessageBox.prompt({
                            type: "warning",
                            title: this.$t("hl.new_management_period.error.date_conflict.title"),
                            message: this.$t("hl.new_management_period.error.date_conflict.message"),
                            isCancellable: false,
                            confirmText: this.$t("common.i_understand"),
                        });

                        this.currentStep = "dates";

                        return;
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
