import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { BrowserFormDataSerializer } from "@leavy/cq-client/lib/rpc/browser";
import { HOMESHARING_HTTP_CLIENT } from "@/modules/core/http";
import { APP_CONFIG } from "@/app/config";

export const HOMESHARING_CQ_INVOKER = new HttpRemoteCqInvoker({
    baseUrl: `${APP_CONFIG.HOMESHARING_BACKEND_URL}/cq`,
    formDataSerializer: new BrowserFormDataSerializer(),
    axiosInstance: HOMESHARING_HTTP_CLIENT,
});
