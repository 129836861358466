



































import { Component, ModelSync, Ref } from "vue-property-decorator";
import { StepFormComponent } from "@/modules/onboarding/hl/StepForm";
import { HlAccommodationAccessDetailsModel, HlAccommodationAccessesModel } from "@/modules/onboarding/hl/model";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import { LvField, LvFormItem, LvIcon, LvSwitch, LvValidator } from "@fastoche/ui-kit/components";
import { construct } from "@leavy/utils";
import { APP_LOGGER } from "@/app/logger";

interface AccessDetailsEntry {
    property: keyof HlAccommodationAccessesModel;
    titleRes: string;
    placeholderRes: string;
    icon: string;
}

const getDefaultModel = (): HlAccommodationAccessesModel => {
    const getDefaultAccessDetailsModel = (): HlAccommodationAccessDetailsModel => {
        return construct(HlAccommodationAccessDetailsModel, {
            notes: null!,
            isPresent: true,
        });
    };

    return construct(HlAccommodationAccessesModel, {
        trash: getDefaultAccessDetailsModel(),
        gas: getDefaultAccessDetailsModel(),
        circuitBreaker: getDefaultAccessDetailsModel(),
        waterMeter: getDefaultAccessDetailsModel(),
        wifi: getDefaultAccessDetailsModel(),
    });
};

export const COMPONENT_NAME = "AccommodationAccessesForm";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvForm,
        LvIcon,
        LvField,
        LvSwitch,
        LvFormItem,
    },
})
export default class AccommodationAccessesForm extends StepFormComponent<HlAccommodationAccessesModel> {
    @Ref()
    form!: LvForm;

    @ModelSync("value", "change", { type: HlAccommodationAccessesModel, required: false, default: () => getDefaultModel() })
    model!: HlAccommodationAccessesModel;

    entries: AccessDetailsEntry[] = [
        {
            property: "trash",
            titleRes: "app.onboarding.hl.flat_description.access.trash",
            placeholderRes: "app.onboarding.hl.flat_description.access.trash.placeholder",
            icon: "la-trash",
        },
        {
            property: "gas",
            titleRes: "app.onboarding.hl.flat_description.access.gas",
            placeholderRes: "app.onboarding.hl.flat_description.access.gas.placeholder",
            icon: "la-burn",
        },
        {
            property: "circuitBreaker",
            titleRes: "app.onboarding.hl.flat_description.access.circuit_breaker",
            placeholderRes: "app.onboarding.hl.flat_description.access.circuit_breaker.placeholder",
            icon: "la-bolt",
        },
        {
            property: "waterMeter",
            titleRes: "app.onboarding.hl.flat_description.access.water_meter",
            placeholderRes: "app.onboarding.hl.flat_description.access.water_meter.placeholder",
            icon: "la-tint",
        },
        {
            property: "wifi",
            titleRes: "app.onboarding.hl.flat_description.access.wifi",
            placeholderRes: "app.onboarding.hl.flat_description.access.wifi.placeholder",
            icon: "la-wifi",
        },
    ];

    async submit() {
        if (!(await this.form.submit())) return;
        return this.model;
    }
}
