




































































import { Component, ModelSync, Ref } from "vue-property-decorator";
import { LvLightContainer, LvNumberInput } from "@fastoche/ui-kit/components";
import { StepFormComponent } from "@/modules/onboarding/hl/StepForm";
import { HlAccommodationCapacityModel } from "@/modules/onboarding/hl/model";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import LvCheckbox from "@fastoche/ui-kit/components/checkbox/LvCheckbox.vue";
import { construct } from "@leavy/utils";
import { APP_LOGGER } from "@/app/logger";

const getDefaultModel = (): HlAccommodationCapacityModel => {
    return Object.assign(new HlAccommodationCapacityModel(), {
        surfaceArea: 0,
        bedrooms: 0,
        bathrooms: 0,
        simpleBeds: 0,
        doubleBeds: 0,
        sofaBeds: 0,
    });
};

export const COMPONENT_NAME = "AccommodationCapacityForm";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvCheckbox,
        LvField,
        LvFormItem,
        LvForm,
        LvLightContainer,
        LvNumberInput,
    },
})
export default class AccommodationCapacityForm extends StepFormComponent<HlAccommodationCapacityModel> {
    @Ref()
    readonly form!: LvForm;

    @ModelSync("value", "change", { type: HlAccommodationCapacityModel, required: false, default: () => getDefaultModel() })
    model!: HlAccommodationCapacityModel;

    async submit() {
        if (!(await this.form.submit())) return;
        return this.model;
    }
}
