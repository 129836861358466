import { Equals, IsDefined, IsEnum } from "@leavy/validator";
import { CommonStepError, StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { OnboardingId } from "@leavy/lv-onboarding-srv/lib/common/domain/OnboardingId";
import { AdministrativeDocumentType } from "@leavy/lv-onboarding-srv/lib/hod/domain/administrative/AdministrativeDocumentType";
import { RpcInvocable, BinaryFile } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../../base";
import { BaseFile } from "../../../common/BaseFile";
import { OkVal } from "@leavy/result";
import { CommonEditOnboardingError } from "../../ForwardedOnboardingCommand";

export type AddAdministrativeDocumentLocalCommandResult =
    | OkVal<{ fileId: string }>
    | CommonEditOnboardingError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "upload_hod_administrative_document",
})
export class AddAdministrativeDocumentLocalCommand extends StepCommand<AddAdministrativeDocumentLocalCommandResult> {
    @Equals(OnboardingId.HOD)
    onboardingId: OnboardingId.HOD = OnboardingId.HOD;

    @BinaryFile()
    @IsDefined()
    document!: BaseFile;

    @IsEnum(AdministrativeDocumentType)
    documentType!: AdministrativeDocumentType;
}
