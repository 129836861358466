


































































import { Component } from "vue-property-decorator";
import { LvContainer } from "@fastoche/ui-kit/components";
import { InjectService } from "@fastoche/ui-core/di";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { HodOnboardingService } from "@/modules/onboarding/hod/services/HodOnboardingService";
import { AnyHodOnboardingStepId, DayTime, WeekDay } from "@/modules/onboarding/hod/domain";
import { MeetingDayPreferenceModel, MeetingPreferenceModel } from "@/modules/onboarding/hod/model";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";

export const COMPONENT_NAME = "OnboardingOnlineMeetingPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvIcon,
        LvButton,
        PageHeader,
        LvContainer,
    },
})
export default class OnboardingOnlineMeetingPage extends BaseHodOnboardingPage {
    @InjectService(HodOnboardingStore)
    hodStore!: HodOnboardingStore;

    @InjectState(HodOnboardingStore)
    hodState!: NonNullable<StateOf<HodOnboardingStore>>;

    @InjectService(HodOnboardingService)
    hodService!: HodOnboardingService;

    days: WeekDay[] = Object.values(WeekDay);
    times: DayTime[] = Object.values(DayTime);

    model: MeetingPreferenceModel = new MeetingPreferenceModel();

    created() {
        this.loadModel();
    }

    loadModel() {
        const model = new MeetingPreferenceModel();
        const { data } = this.hodState.onboarding.meeting;

        if (data) {
            model.selectedDays = data.selectedDays.map(x => {
                const selectedDay = new MeetingDayPreferenceModel();
                selectedDay.day = x.day!;
                selectedDay.times = x.times!;
                return selectedDay;
            });
        }

        this.model = model;
    }

    isDaySelected(day: WeekDay) {
        return this.model.selectedDays.some(x => x.day == day);
    }

    toggleDay(day: WeekDay) {
        const idx = this.model.selectedDays.findIndex(x => x.day == day);

        if (idx > -1) this.model.selectedDays.splice(idx, 1);
        else {
            const selectedDay = new MeetingDayPreferenceModel();
            selectedDay.times = [];
            selectedDay.day = day;
            this.model.selectedDays.push(selectedDay);
        }
    }

    getSelectedDay(day: WeekDay) {
        return this.model.selectedDays.find(x => x.day == day);
    }

    isTimeSelected(day: WeekDay, time: DayTime) {
        const selectedDay = this.getSelectedDay(day)!;
        return selectedDay.times.includes(time);
    }

    toggleTime(day: WeekDay, time: DayTime) {
        const selectedDay = this.getSelectedDay(day)!;
        const idx = selectedDay.times.indexOf(time);
        if (idx > -1) selectedDay.times.splice(idx, 1);
        else selectedDay.times.push(time);
    }

    isAnytime(day: WeekDay) {
        const selectedDay = this.getSelectedDay(day)!;
        return this.times.every(x => selectedDay.times.includes(x));
    }

    setAnytime(day: WeekDay) {
        const selectedDay = this.getSelectedDay(day)!;

        if (this.isAnytime(day)) selectedDay.times = [];
        else selectedDay.times = [...this.times];
    }

    get isValid() {
        return this.model.selectedDays.filter(x => x.times.length > 0).length > 0;
    }

    @LoaderTask
    async nextStep() {
        try {
            this.model.selectedDays = this.model.selectedDays.filter(x => x.times.length > 0);

            const result = await this.hodService.submitMeetingStep(this.model);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
            else {
                const nextPage = this.hodStore.getNextStepLocation(AnyHodOnboardingStepId.HOD_MEETING);
                await this.$router.push(nextPage).catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
