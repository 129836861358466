





















import { Component } from "vue-property-decorator";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { UserOnboardingStatus } from "@/modules/onboarding/common/domain";
import { LvContainer, LvIcon } from "@fastoche/ui-kit/components";

export const COMPONENT_NAME = "HodOnboardingRejectedPage";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvIcon,
        LvContainer,
    },
})
export default class HodOnboardingRejectedPage extends BaseHodOnboardingPage {
    @InjectState(HodOnboardingStore)
    hodState!: NonNullable<StateOf<HodOnboardingStore>>;

    created() {
        if (!this.hodState || this.hodState.status != UserOnboardingStatus.REJECTED) {
            void this.$router.replace("/app").catch(this.handleNavigationError);
            return;
        }
    }
}
