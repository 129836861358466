






































import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvButton, LvFilePicker, LvFilePreview, LvIcon } from "@fastoche/ui-kit/components";

export const COMPONENT_NAME = "OnboardingDocumentCollection";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvFilePicker,
        LvFilePreview,
        LvButton,
        LvIcon
    }
})
export default class OnboardingDocumentCollection<TDoc> extends BaseComponent {
    @Prop({ type: Function, required: true })
    readonly addDocument!: (file: File) => void;

    @Prop({ type: Function, required: true })
    readonly removeDocument!: (doc: TDoc) => void;

    @Prop({ type: Function, required: true })
    readonly getPreviewUrl!: (doc: TDoc) => string;

    @Prop()
    readonly documents!: TDoc[];

    @Prop({ type: Array, required: false, default: () => [] })
    readonly fileTypes!: string[];

    @Prop({ type: Number, required: false, default: () => null })
    readonly maxSize!: number | null;

    private onFilePickerChange(file: File) {
        this.addDocument(file);
    }
}
