import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, Min, Trim, TryParseNumber } from "@leavy/validator";
import { Currency } from "../../money/domain/Currency";

export class HlSimpleRevenueEstimationRequestModel {
    @IsString()
    @IsNotEmpty()
    @Trim()
    address!: string;

    @TryParseNumber()
    @IsNumber()
    @Min(0)
    bedrooms!: number;

    @TryParseNumber()
    @IsOptional()
    @IsNumber()
    @Min(0)
    bathrooms?: number;

    @TryParseNumber()
    @IsOptional()
    @IsNumber()
    @Min(1)
    beds?: number;

    @TryParseNumber()
    @IsNumber()
    @Min(1)
    maxOccupancy!: number;

    @IsEnum(Currency)
    @IsOptional()
    currency?: Currency;
}
