



















import { Component } from "vue-property-decorator";
import { LvContainer } from "@fastoche/ui-kit/components";
import { CommonOnboardingPage } from "../common/CommonOnboardingPage";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { InjectService } from "@fastoche/ui-core/di";
import { OnboardingService } from "@/modules/onboarding/common/services/OnboardingService";
import { CommonErrorDialogs } from "../../../ui/error/CommonErrorDialogs";
import ClockIcon from "../../../ui/svg/ClockIcon.vue";
import { UserOnboardingStatus } from "@leavy/lv-onboarding-srv/lib/common/domain/UserOnboardingStatus";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";

export const COMPONENT_NAME = "HodOnboardingPendingPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        PageHeader,
        ClockIcon,
        LvContainer,
    },
})
export default class HodOnboardingPendingPage extends CommonOnboardingPage {
    @InjectService(OnboardingService)
    onboardingService!: OnboardingService;

    mounted() {
        void this.ensureOnboardingSubmitted();
    }

    @LoaderTask
    async ensureOnboardingSubmitted() {
        const userOnboarding = this.userOnboarding;

        switch (userOnboarding.status) {
            case UserOnboardingStatus.STARTED: {
                const result = await this.onboardingService.submitOnboarding(userOnboarding.onboardingId);

                if (!result.success) {
                    switch (result.reason) {
                        case "ONBOARDING_INCOMPLETE":
                            await this.$router.replace(this.backLink).catch(this.handleNavigationError);
                            return;

                        default:
                            CommonErrorDialogs.unexpectedError();
                            await this.$router.replace("/app").catch(this.handleNavigationError);
                            return;
                    }
                }

                break;
            }

            case UserOnboardingStatus.SUBMITTED:
                return;

            default:
                await this.$router.replace("/app").catch(this.handleNavigationError);
                return;
        }
    }
}
