import { Bad, Ok } from "@leavy/result";
import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { CredentialsLoginCommand } from "./command";
import { AuthenticationService, UserCredentials } from "@/modules/auth/services/AuthenticationService";
import { SessionService } from "@/modules/auth/services/SessionService";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";

@injectable()
export class CqAuthenticationService extends AuthenticationService {
    constructor(
        private readonly store: AuthenticationStore,
        private readonly sessionService: SessionService,
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    async authenticate(credentials: UserCredentials): Promise<Ok | Bad<"invalid_credentials" | "unexpected_failure">> {
        const loginResult = await this.cq.invoke(CredentialsLoginCommand, {
            ...credentials,
            deviceId: this.store.value.deviceId,
            clientId: this.store.value.clientId,
            locale: "en",
        });

        if (!loginResult.success) {
            if (loginResult.reason === "invalid_credentials") {
                return Bad("invalid_credentials");
            }
            return Bad("unexpected_failure");
        }

        this.store.setToken(loginResult.value.user.id, {
            bearer: loginResult.value.accessToken.bearer,
            tokenExpirationTime: new Date(loginResult.value.accessToken.expirationDate).getTime(),
        }, loginResult.value.refreshToken);

        return Ok();
    }
}
