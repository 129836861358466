






































































































import { Component } from "vue-property-decorator";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { AnyHlOnboardingStepId, PaymentSchedulingOption, PaymentPlan } from "@/modules/onboarding/hl/domain";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { LvButton, LvContainer, LvDatePicker, LvIcon } from "@fastoche/ui-kit/components";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import * as _ from "lodash";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import HlRegistrationSteps from "@/app/ui/hl-registration-steps/HlRegistrationSteps.vue";
import { DateTime } from "luxon";
import { fullLocaleDate, hugeLocaleDate } from "@/app/filters/date";

export const COMPONENT_NAME = "OnboardingPaymentPreferencesPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        HlRegistrationSteps,
        PageHeader,
        LvContainer,
        LvDatePicker,
        LvIcon,
        LvButton,
    },
    filters: {
        price,
        hugeLocaleDate,
        fullLocaleDate,
    },
})
export default class OnboardingPaymentPreferencesPage extends BaseHlOnboardingPage {
    readonly plansDisplayOrder = [
        PaymentSchedulingOption.PERIOD_END,
        PaymentSchedulingOption.PERIOD_MIDDLE,
        PaymentSchedulingOption.PERIOD_START,
    ];

    readonly schedulesDisplayState = Object
        .values(PaymentSchedulingOption)
        .map(option => ({ option, expanded: false }));

    readonly maxDisplayedPaymentRows = 3;

    showAllPaymentRows = false;
    paymentPlans: PaymentPlan[] = [];

    get isOnboardingSubmitted() {
        return this.hlState.status == "SUBMITTED";
    }

    created() {
        void this.loadModel();
    }

    get paymentPlanIcons(): Record<PaymentSchedulingOption, string> {
        return {
            [PaymentSchedulingOption.PERIOD_START]: require("@/app/assets/emoji/high-voltage.svg"),
            [PaymentSchedulingOption.PERIOD_MIDDLE]: require("@/app/assets/emoji/winking-face.svg"),
            [PaymentSchedulingOption.PERIOD_END]: require("@/app/assets/emoji/partying-face.svg"),
        };
    }

    get paymentPlanNames(): Record<PaymentSchedulingOption, string> {
        return {
            [PaymentSchedulingOption.PERIOD_START]: this.$t("hl.payment_plan.options.period_start"),
            [PaymentSchedulingOption.PERIOD_MIDDLE]: this.$t("hl.payment_plan.options.period_middle"),
            [PaymentSchedulingOption.PERIOD_END]: this.$t("hl.payment_plan.options.period_end"),
        };
    }

    isCurrentPlan(plan: PaymentSchedulingOption) {
        return this.hlState.onboarding.paymentPreferences.data?.schedulingOption === plan;
    }

    getScheduledPayments(schedulingOption: PaymentSchedulingOption) {
        const scheduledPayments = this.paymentPlans.find(x => x.schedulingOption == schedulingOption)?.schedule ?? [];

        if (this.showAllPaymentRows) {
            return scheduledPayments;
        }
        else {
            return scheduledPayments.slice(0, this.maxDisplayedPaymentRows);
        }
    }

    @LoaderTask
    async loadModel() {
        await this.onboardingService.loadStep(AnyHlOnboardingStepId.HL_PAYMENT_PREFERENCES);

        const possiblePaymentPlansResult = await this.hlOnboardingService.getPossiblePaymentPlans(this.$i18n.currency);

        if (
            !possiblePaymentPlansResult.success
            || possiblePaymentPlansResult.value.length == 0
        ) {
            await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
            return;
        }

        this.paymentPlans = _.orderBy(
            possiblePaymentPlansResult.value,
            x => this.plansDisplayOrder.indexOf(x.schedulingOption),
            "asc",
        );
    }

    @LoaderTask
    async selectSchedulingOption(schedulingOption: PaymentSchedulingOption) {
        try {
            const result = await this.hlOnboardingService.submitPaymentPreferencesStep({
                schedulingOption,
            });

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
            else {
                const nextPage = this.isOnboardingSubmitted ?
                    this.hlStore.getNextStepLocation(AnyHlOnboardingStepId.HL_PAYMENT_PREFERENCES) :
                    this.hlStore.getStepLocation(AnyHlOnboardingStepId.HL_APPOINTMENT_REQUEST);

                await this.$router.push(nextPage).catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
