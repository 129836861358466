

























import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { ListingSearchResult } from "@/modules/listing/model";
import { price } from "@fastoche/ui-core/i18n/currency/filters/price";
import { money } from  "@/app/ui/filters/money.js";
import { lazyBgImg } from "@fastoche/ui-kit/directives";
import {InjectService} from "@fastoche/ui-core/di";
import {ListingSearchContextStore} from "@/modules/listing/context/ListingSearchContextStore";
import {InjectState} from "@fastoche/ui-core/store";
import {ListingSearchContext} from "@/modules/listing/context/ListingSearchContext";

export const COMPONENT_NAME = "ListingPeekCard";

@Component({
    name: COMPONENT_NAME,
    filters: {
        price,
        money,
    },
    directives: {
        lazyBgImg,
    },
})
export default class ListingPeekCard extends BaseComponent {
    @InjectState(ListingSearchContextStore)
    readonly searchContext!: ListingSearchContext;

    @Prop({ type: Object, required: true })
    readonly model!: ListingSearchResult;

    @Prop({ type: Boolean, required: false, default: false })
    readonly primary!: boolean;

    get coverPhoto() {
        if (this.model.type == "HOTEL") {
            const width = this.primary ? 1200 : 500;
            return `${this.model.coverPhoto}?w=${width}`;
        }
        else {
            return this.model.coverPhoto;
        }
    }

    get link(){
        if(this.model.type == "HOTEL" && this.searchContext.location) {
            return `listing/hotel/${this.model.id}`;
        }
        else{
            return `listing/house/${this.model.id}`;
        }
    }
}
