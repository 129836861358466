import { UserOnboardingState } from "../common/UserOnboardingState";
import { HodUserOnboarding, AnyHodOnboardingStepId } from "./domain";
import { HOD_ONBOARDING_WORKFLOW } from "./workflow";

export class HodOnboardingState extends UserOnboardingState<HodUserOnboarding, AnyHodOnboardingStepId> {
    constructor(
        onboarding: HodUserOnboarding,
    ) {
        super(onboarding, HOD_ONBOARDING_WORKFLOW);
    }
}
