import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import {
    HouseListingQueryResult,
    ListingPriceQueryResult,
    ListingPriceRequestModel,
} from "@/modules/listing/model";
import { Bad, Ok, OkVal } from "@leavy/result";
import { GetHouseListingByIdQuery } from "@leavy/lv-homesharing-backend-srv/lib/listing/query/clients/GetHouseListingByIdQuery.client";
import { ListingHouseService } from "@/modules/listing/services/ListingHouseService";
import { GetHouseListingPriceQuery } from "@leavy/lv-homesharing-backend-srv/lib/listing/query/GetHouseListingPriceQuery";
import { BookingQuoteViewModel } from "@/modules/booking/model/BookingQuoteViewModel";
import { Currency } from "@/modules/payment/domain";
import { DateTime } from "luxon";
import { DateHelper } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/calendar/Date";

@injectable()
export class CqListingHouseService extends ListingHouseService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    async getHouseListingById(id: string): Promise<OkVal<HouseListingQueryResult | null> | Bad<"NOT_FOUND">> {
        const result = await this.cq.invoke(
            GetHouseListingByIdQuery,
            {
                listingId: id,
            },
        );

        if (result.success) {
            const value = result.value;

            if (value == null) {
                return Bad("NOT_FOUND");
            }
            else {
                return Ok(value);
            }
        }
        else {
            switch (result.reason) {
                case "INVALID_ID":
                    return Bad("NOT_FOUND");
            }
        }
    }

    async getHouseListingPrice(listingId: string, model: BookingQuoteViewModel, currency: Currency): Promise<OkVal<ListingPriceQueryResult> | Bad<"NOT_AVAILABLE"> | Bad<"DURATION_NOT_SUPPORTED">> {
        if (model.from && model.to) {
            const result = await this.cq.invoke(
                GetHouseListingPriceQuery,
                {
                    listingId: listingId,
                    arrivalDate: model.from,
                    departureDate: model.to,
                    guests: model.guests,
                    currency: currency,
                },
            );

            if (result.success) {
                const value = result.value;

                if (value == null) {
                    return Bad("NOT_AVAILABLE");
                }
                else {
                    return Ok(value);
                }
            }
            else {
                switch (result.reason) {
                    case "INVALID_ID":
                        return Bad("NOT_AVAILABLE");

                    case "INVALID_DATE_INTERVAL":
                        return Bad("DURATION_NOT_SUPPORTED");

                    case "INVALID_RENT":
                    case "MISSING_RENT_OR_CURRENCY":
                        throw new Error(`Unexpected case: ${result.reason}`);
                }
            }
        }
        else {
            return Bad("DURATION_NOT_SUPPORTED");
        }
    }
}
