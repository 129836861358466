import { Query, RpcInvocable } from "@leavy/cq-core";
import { Bad, OkVal } from "@leavy/result";
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, Min, TryParseNumber, Trim } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../base";
import { Currency } from "../money/domain/Currency";
import { HlSimpleRevenueEstimationRequestModel } from "./model/HlSimpleRevenueEstimationRequestModel";
import { Address } from "../common/domain/Address";

export type HlSimpleRevenueEstimationQueryResult =
    | OkVal<HlSimpleRevenueEstimation>
    | Bad<"INVALID_LOCATION">
    | Bad<"ESTIMATION_FAILED">;

export interface HlSimpleRevenueEstimation {
    address: Address;
    perDay: number;
    perMonth: number;
    currency: Currency;
}

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "get_hl_simple_revenue_estimation",
})
export class HlSimpleRevenueEstimationQuery extends Query<HlSimpleRevenueEstimationQueryResult> implements HlSimpleRevenueEstimationRequestModel {
    @IsString()
    @IsNotEmpty()
    @Trim()
    address!: string;

    @TryParseNumber()
    @IsNumber()
    @Min(0)
    bedrooms!: number;

    @TryParseNumber()
    @IsOptional()
    @IsNumber()
    @Min(0)
    bathrooms?: number;

    @TryParseNumber()
    @IsOptional()
    @IsNumber()
    @Min(1)
    beds?: number;

    @TryParseNumber()
    @IsNumber()
    @Min(1)
    maxOccupancy!: number;

    @IsEnum(Currency)
    @IsOptional()
    currency?: Currency;
}
