




























import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import LoginForm from "@/app/components/auth/login/LoginForm.vue";
import RegistrationForm, { UserExistsEvent, UserRegisteredEvent } from "@/app/components/auth/register/RegistrationForm.vue";
import { LvContainer } from "@fastoche/ui-kit/components";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader/index";
import { APP_LOGGER } from "@/app/logger";
import { InjectService } from "@fastoche/ui-core/di";
import { AuthenticationService } from "@/modules/auth/services/AuthenticationService";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { HodOnboardingService } from "@/modules/onboarding/hod/services/HodOnboardingService";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";

export const COMPONENT_NAME = "HodRegistrationPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        RegistrationForm,
        LoginForm,
        LvContainer,
    },
})
export default class HodRegistrationPage extends BaseComponent {
    @InjectService(AuthenticationService)
    private readonly authService!: AuthenticationService;

    @InjectService(AuthenticationStore)
    private readonly authStore!: AuthenticationStore;

    @InjectService(HodOnboardingService)
    private readonly hodOnboardingService!: HodOnboardingService;

    @InjectState(HodOnboardingStore)
    private readonly hodOnboardingState!: StateOf<HodOnboardingStore>;

    private registerOrLogin: "register" | "login" = "register";
    private existingUserEmail: string | null = null;

    mounted() {
        if (this.authStore.isAuthenticated()) {
            void this.onUserLoggedIn();
        }
    }

    private onRegisterFormSubmit() {
        void this.$track("HodRegisterSubmitEvent");
    }

    private onUserExists(ev: UserExistsEvent) {
        this.existingUserEmail = ev.email;
        this.setRegisterOrLogin("login");
    }

    private setRegisterOrLogin(value: "register" | "login") {
        this.registerOrLogin = value;
        window.scrollTo({ top: 0 });
    }

    @LoaderTask
    private async onUserRegistered(ev: UserRegisteredEvent) {
        try {
            const authenticationResult = await this.authService.authenticate({
                email: ev.credentials.email,
                password: ev.credentials.password,
            });

            if (!authenticationResult.success) {
                throw new Error(`Authentication failed with reason: ${authenticationResult.reason}`);
            }
            else {
                void this.onUserLoggedIn();
            }
        }
        catch (e) {
            APP_LOGGER.warn("Authentication failed just after user registration", e);

            this.existingUserEmail = ev.credentials.email;
            this.registerOrLogin = "login";
        }
    }

    @LoaderTask
    private async onUserLoggedIn() {
        await this.hodOnboardingService.ensureStore();

        if (this.hodOnboardingState) {
            await this.$router.replace("/app/host-on-demand").catch(this.handleNavigationError);
        }
        else {
            const startOnboardingResult = await this.hodOnboardingService.startOnboarding();
            const isStarted = startOnboardingResult.success || startOnboardingResult.reason == "ONBOARDING_ALREADY_STARTED";

            if (isStarted) {
                await this.$router.replace("/app/host-on-demand").catch(this.handleNavigationError);
            }
            else {
                await this.$router.replace("/app").catch(this.handleNavigationError);
            }
        }
    }
}
