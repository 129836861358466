import { Command, RpcInvocable } from "@leavy/cq-core";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { Bad, OkVal } from "@leavy/result";
import { IsEmail, IsString, LowerCase, Trim } from "@leavy/validator";
import { LoginFailureReason, User } from "@leavy/authentication";
import { SessionBadResult, TokenInfo } from "../domain/Session";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

export interface LoginResult {
    user: User;
    accessToken: TokenInfo;
    refreshToken: string;
}

export type LoginCommandResult =
    OkVal<LoginResult>
    | Bad<LoginFailureReason.InvalidCredentials | SessionBadResult | "user_not_localy_registered">;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "credentials_login",
})
export class CredentialsLoginCommand extends Command<LoginCommandResult, never> {
    @IsString()
    deviceId!: string;

    @IsString()
    clientId!: string;

    @IsString()
    locale!: string;

    @IsEmail()
    @Trim()
    @LowerCase()
    email!: string;

    @IsString()
    password!: string;
}

@DomainEvent({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    name: "user_stored",
    version: 1,
})
export class UserStoredEvent extends DomainEventBase {
    @EventProperty()
    id!: string;

    @EventProperty()
    email!: string;

    @EventProperty()
    firstName!: string;

    @EventProperty()
    lastName!: string;

    constructor(data: PayloadOf<UserStoredEvent>) {
        super(data);
    }
}
