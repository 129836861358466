import Vue from "vue";
import { Component } from "vue-property-decorator";
import { InvocationValidationError, NetworkError } from "@leavy/cq-client";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { APP_LOGGER } from "@/app/logger";
import VueRouter from "vue-router";
import { handleNavigationError } from "@/modules/core/utils/router";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

@Component
export class BaseComponent extends Vue {
    handleNavigationError(e: unknown) {
        handleNavigationError(e);
    }

    catchAndNotifyError(error: Error) {
        if (error instanceof InvocationValidationError) {
            APP_LOGGER.error("Validation error catched by component", { error });
            CommonErrorDialogs.validationError();
        }
        else if (error instanceof NetworkError) {
            APP_LOGGER.error("Network error catched by component", { error });
            CommonErrorDialogs.networkError();
        }
        else {
            throw error;
        }
    }

    redirectNotFound() {
        return (
            this.$router.replace("/error/not-found").catch(this.handleNavigationError)
        );
    }
}
