import { BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { RegistrationService } from "@/modules/registration/services/RegistrationService";
import { CqRegistrationService } from "@/modules/registration/platform/cq/CqRegistrationService";

@Module()
export class RegistrationModule extends BaseModule {
    registerServices(container: ModuleContainer): void | Promise<void> {
        container.bind(RegistrationService).to(CqRegistrationService).inSingletonScope();
    }
}
