import { Query, RpcInvocable } from "@leavy/cq-core";
import { OkVal } from "@leavy/result";
import { LocalizationOptions } from "../model/LocalizationOptions";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

export type GetLocalizationOptionsQueryResult =
    | OkVal<LocalizationOptions>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "get_localization_options",
})
export class GetLocalizationOptionsQuery extends Query<GetLocalizationOptionsQueryResult> {}
