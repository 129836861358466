import { StartBookingCheckoutCommand } from "@leavy/lv-homesharing-backend-srv/lib/booking/command/StartBookingCheckout";
import { StartBookingCheckoutCommand as StartBookingCheckoutCommandClient } from "@leavy/lv-homesharing-backend-srv/lib/booking/command/clients/StartBookingCheckout.client";
import { Bad, Ok, OkVal } from "@leavy/result";
import { GetCheckoutInfoQuery } from "@leavy/lv-homesharing-backend-srv/lib/booking/query/GetCheckoutInfoQuery";
import { GetCheckoutInfoQuery as GetCheckoutInfoQueryClient } from "@leavy/lv-homesharing-backend-srv/lib/booking/query/clients/GetCheckoutInfoQuery.clients";
import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { CheckoutService } from "@/modules/checkout/services/CheckoutService";
import { BookListingCommand } from "@leavy/lv-homesharing-backend-srv/lib/booking/command/BookListingCommand";
import { BookHouseListingCommand as BookListingCommandClient } from "@leavy/lv-homesharing-backend-srv/lib/booking/command/clients/BookHouseListingCommand.client";
import { AuthenticationStore } from "../../auth/AuthenticationStore";
import { LocalizationOptionsStore } from "@fastoche/ui-core/i18n/options/LocalizationOptions";

@injectable()
export class CqCheckoutService extends CheckoutService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
        private readonly authStore: AuthenticationStore,
        private readonly localizationStore: LocalizationOptionsStore,
    ) {
        super();
    }

    async startBookingCheckout(model: StartBookingCheckoutCommandClient): Promise<OkVal<string> | Bad<"INVALID_DATES"> | Bad<"UNAVAILABLE_HOTEL_ROOM"> | Bad<"MISSING_HOTEL_ROOM"> | Bad<"INVALID_LISTING">> {
        const result = await this.cq.invoke(StartBookingCheckoutCommand, {
            userId: this.authStore.value.clientId,
            listingId: model.listingId,
            arrivalDate: model.arrivalDate,
            departureDate: model.departureDate,
            guests: model.guests,
            currency: model.currency,
            accommodationType: model.accommodationType,
            roomId: model.roomId,
        });

        if (result.success) {
            return Ok(result.value.checkoutId);
        }
        else {
            switch (result.reason) {
                case "INVALID_DATES":
                    return Bad("INVALID_DATES");

                case "UNAVAILABLE_HOTEL_ROOM":
                    return Bad("UNAVAILABLE_HOTEL_ROOM");

                case "MISSING_HOTEL_ROOM":
                    return Bad("MISSING_HOTEL_ROOM");

                case "INVALID_LISTING":
                    return Bad("INVALID_LISTING");
            }
        }
    }

    async getBookingCheckoutInfo(model: GetCheckoutInfoQueryClient) {
        const result = await this.cq.invoke(GetCheckoutInfoQuery, {
            userId: this.authStore.value.clientId,
            checkoutId: model.checkoutId,
            locale: this.localizationStore.value.defaultLocale.localeCode,
        });

        if (result.success) {
            return Ok(result.value);
        }
        else {
            return;
        }
    }

    async bookingListing(model: BookListingCommandClient): Promise<Ok | Bad<"CHECKOUT_NOT_FOUND"> | Bad<"CHECKOUT_EXPIRED"> | Bad<"INVALID_LISTING_TYPE"> | Bad<"PAYMENT_FAILED"> | Bad<"USER_MISMATCH">> {
        const result = await this.cq.invoke(BookListingCommand, {
            userId: this.authStore.value.clientId,
            locale: this.localizationStore.value.defaultLocale.localeCode,
            checkoutId: model.checkoutId,
            cardToken: model.cardToken,
        });

        if (result.success) {
            return Ok();
        }
        else {
            switch (result.reason) {
                case "CHECKOUT_NOT_FOUND":
                    return Bad("CHECKOUT_NOT_FOUND");

                case "CHECKOUT_EXPIRED":
                    return Bad("CHECKOUT_EXPIRED");

                case "INVALID_LISTING_TYPE":
                    return Bad("INVALID_LISTING_TYPE");

                case "PAYMENT_FAILED":
                    return Bad("PAYMENT_FAILED");

                case "USER_MISMATCH":
                    return Bad("USER_MISMATCH");
            }
        }
    }
}
