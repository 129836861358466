

















































import { Component, Prop, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import BookingPriceExplainedPopup from "@/app/ui/booking-price-explained-popup/BookingPriceExplainedPopup.vue";
import { LvButton, LvCard, LvIcon, LvTag } from "@fastoche/ui-kit/components";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { currencySymbol } from "@fastoche/ui-core/i18n/currency/filters/currencySymbol";
import { InjectService } from "@fastoche/ui-core/di";
import { UserContext, UserContextStore } from "@/modules/user/UserContext";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { money } from "@/app/ui/filters/money.js";
import SelectHotelRoom from "@/app/components/listing/room/SelectHotelRoom.vue";
import { HotelRoom, ListingPrice } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/Listing";
import { ListingHotelService } from "@/modules/listing/services/ListingHotelService";
import { BookingStore } from "@/modules/booking/BookingStore";
import { ListingStore } from "@/modules/listing/ListingStore";

export const COMPONENT_NAME = "TotalSummaryCard";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        BookingPriceExplainedPopup,
        LvCard,
        LvButton,
        LvIcon,
        LvTag,
        SelectHotelRoom,
    },
    filters: {
        currencySymbol,
        price,
        money,
    },
})
export default class TotalSummary extends BaseComponent {
    @InjectState(LocalizationContext)
    readonly localizationContext!: StateOf<LocalizationContext>;

    @InjectState(UserContextStore)
    readonly userContext?: UserContext;

    @InjectService(ListingHotelService)
    readonly listingService!: ListingHotelService;

    @InjectState(BookingStore)
    readonly bookingState!: StateOf<BookingStore>;

    @InjectState(ListingStore)
    readonly listingState!: StateOf<ListingStore>;

    @Prop({ type: String, required: true })
    readonly type!: string;

    @Prop({ type: String, required: true })
    readonly listingId!: string;

    @Prop({ required: false })
    readonly bookingPrice!: ListingPrice;
}
