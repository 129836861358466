


















import { Component, Model, Prop, Ref, Watch } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { loadGoogleMaps } from "@/app/vendors/google-maps/lib";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import { Alpha2CountryCode } from "@/modules/listing/domain";
import { SearchLocationModel } from "@/modules/listing/model";
import { LvIcon } from "@fastoche/ui-kit/components";

function findComponent(type: string, obj: google.maps.places.PlaceResult) {
    return obj.address_components!.find(x => x.types.includes(type));
}

export const COMPONENT_NAME = "DestinationInput";

@Component({
    name: COMPONENT_NAME,
    components: { LvField, LvIcon },
})
export default class DestinationInput extends BaseComponent {
    private addressAutocomplete: google.maps.places.Autocomplete | null = null;
    private internalModel: SearchLocationModel = new SearchLocationModel();
    private input: string = "";

    @Ref()
    readonly addressInput!: HTMLInputElement;

    @Prop({ type: String, required: false })
    placeholder?: string;

    @Model("change", { type: Object, required: false, default: () => null })
    readonly model?: SearchLocationModel;

    async mounted() {
        try {
            const loaded = await loadGoogleMaps();

            if (!loaded) {
                CommonErrorDialogs.networkError();
            }
            else {
                this.createPlaceAutocomplete();
            }
        }
        catch (e) {}
    }

    beforeDestroy() {
        this.destroyPlaceAutocomplete();
    }

    @Watch("model", { immediate: true })
    onModelChange(newVal?: SearchLocationModel, oldVal?: SearchLocationModel) {
        if (!newVal || oldVal == newVal) return;

        this.internalModel = newVal;
        this.input = this.internalModel.query ?? "";
    }

    onInputBlur() {
        if (this.input !== this.internalModel.query) {
            this.$emit("change", null);
        }

        this.$emit("blur");
    }

    onInputChange() {
        if (!this.input) {
            this.$emit("change", null);
        }
    }

    private createPlaceAutocomplete() {
        if (this.addressAutocomplete != null) return;

        this.addressAutocomplete = new google.maps.places.Autocomplete(
            this.addressInput,
            { types: ["geocode"] },
        );

        this.addressAutocomplete.addListener("place_changed", () => {
            const place = this.addressAutocomplete!.getPlace();

            if (!place.geometry || !place.formatted_address || !place.address_components) {
                return;
            }

            this.internalModel = new SearchLocationModel();

            if (place.types?.includes("country")) {
                const countryCode = findComponent("country", place)?.short_name as Alpha2CountryCode | undefined ;
                if (countryCode) this.internalModel.countryCode = countryCode;
            }
            else {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();

                this.internalModel.lat = lat;
                this.internalModel.lon = lng;
            }

            this.internalModel.query = place.formatted_address;

            this.input = this.internalModel.query;

            this.$emit("change", this.internalModel);
            this.$emit("selected");
        });
    }

    private destroyPlaceAutocomplete() {
        if (this.addressAutocomplete == null) return;

        google.maps.event.clearInstanceListeners(this.addressInput);

        this.addressAutocomplete.unbindAll();
        this.addressAutocomplete = null!;

        document.querySelector(".pac-container")?.remove();
    }
}
