




























































import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { InjectService } from "@fastoche/ui-core/di";
import { HlService } from "@/modules/hl/services/HlService";
import { HlUserPayment } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserPayment";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { LvButton, LvContainer, LvIcon, LvIntersectionObserver, LvSpin, LvTag } from "@fastoche/ui-kit";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { ManagementPeriodPaymentStatus } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlManagementPeriodPayment";
import { hugeLocaleDate } from "@/app/filters/date";
import { price } from "@fastoche/ui-core/i18n/currency/filters";

export const COMPONENT_NAME = "HlPaymentListPage";

@Component({
    name: COMPONENT_NAME,
    components: {
        PageHeader,
        LvIntersectionObserver,
        LvContainer,
        LvTag,
        LvSpin,
        LvButton,
        LvIcon
    },
    filters: {
        hugeLocaleDate,
        price
    }
})
export default class HlPaymentListPage extends BaseComponent {
    @InjectService(HlService)
    readonly hlService!: HlService;

    paginationOptions = {
        page: 0,
        perPage: 25,
    };

    data: HlUserPayment[] = [];
    isLoading = false;
    canLoadMore = false;

    mounted() {
        void this.initialize();
    }

    @LoaderTask
    async initialize() {
        const { data } = await this.hlService.getUserPayments(this.paginationOptions);
        this.data = data;
        this.canLoadMore = data.length > 0;
    }

    async loadMore() {
        if (this.isLoading || !this.canLoadMore) return;

        this.isLoading = true;
        this.paginationOptions.page++;

        const { data } = await this.hlService.getUserPayments(this.paginationOptions);
        this.canLoadMore = data.length > 0;
        this.data.push(...data);

        this.isLoading = false;
    }

    getPaymentStatusColor(payment: HlUserPayment) {
        switch (payment.status) {
            case ManagementPeriodPaymentStatus.CANCELED:
                return "danger";

            case ManagementPeriodPaymentStatus.EXECUTED:
                return "success";

            case ManagementPeriodPaymentStatus.SCHEDULED:
                return "primary";
        }
    }
}
