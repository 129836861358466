import { Command, RpcInvocable } from "@leavy/cq-core";
import { Bad, OkVal } from "@leavy/result";
import { IsString } from "@leavy/validator";
import { LoginResult } from "./CredentialsLoginCommand";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { SessionBadResult } from "../domain/Session";

export type RefreshTokenCommandResult =
    | OkVal<LoginResult>
    | Bad<SessionBadResult>
    ;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "refresh_token_command",
})
export class RefreshTokenCommand extends Command<RefreshTokenCommandResult, never> {
    @IsString()
    accessToken!: string;

    @IsString()
    refreshToken!: string;

    @IsString()
    deviceId!: string;

    @IsString()
    clientId!: string;
}
