import { FileResult, FileService } from "@/modules/file/services/FileService";
import { injectable } from "inversify";
import { APP_CONFIG } from "@/app/config";

@injectable()
export class CqFileService extends FileService {
    getFileUrl(fileId: string): FileResult {
        return { url: `${APP_CONFIG.HOMESHARING_BACKEND_URL}/file/${fileId}` };
    }
}
