import { ReadonlyStore } from "@fastoche/ui-core/store";
import { HlUserAccommodation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodation";
import { injectable } from "inversify";
import { HlService } from "@/modules/hl/services/HlService";
import { UserContextStore } from "@/modules/user/UserContext";
import { Subscription } from "rxjs";

@injectable()
export class HlUserAccommodationStore extends ReadonlyStore<HlUserAccommodation[]> {
    private userContextStoreSubscription?: Subscription;

    constructor(
        private readonly hlService: HlService,
        private userContextStore: UserContextStore,
    ) {
        super([]);
    }

    registerListeners() {
        this.userContextStoreSubscription ??= this.userContextStore.value$.subscribe(userContext => {
            void this.refresh();
        });
    }

    async refresh() {
        return await this.mutateAsync(async state => {
            if (this.userContextStore.isAuthenticated()) {
                return await this.hlService.getUserAccommodations();
            }
            else {
                return [];
            }
        });
    }

    async getUserAccommodationById(id: string) {
        const existing = this.value.find(x => x.id == id);

        if (existing) return existing;
        else await this.refresh();

        return this.value.find(x => x.id == id) ?? null;
    }
}
