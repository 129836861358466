

































































import { Component, Ref, Model } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvButton, LvDateRangePicker, LvField, LvForm, LvFormItem, LvIcon, LvNumberInput } from "@fastoche/ui-kit/components";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { HlSimpleRevenueEstimationRequestModel } from "@/modules/pricing/model";
import TextAddressInput from "@/app/ui/address-input/TextAddressInput.vue";

export class HlEstimationRequestFormViewModel extends HlSimpleRevenueEstimationRequestModel {
    bedrooms: number = 0;
    maxOccupancy: number = 0;
}

export const COMPONENT_NAME = "HlEstimationRequestForm";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        TextAddressInput,
        LvForm,
        LvFormItem,
        LvButton,
        LvNumberInput,
        LvField,
        LvDateRangePicker,
        LvIcon,
    },
})
export default class HlEstimationRequestForm extends BaseComponent {
    @Model("submit", { type: Object, required: true })
    readonly model!: HlSimpleRevenueEstimationRequestModel;

    @Ref()
    private readonly form!: LvForm;

    private mode: "simple" | "full" = "simple";

    setFullMode() {
        this.model.bathrooms = 0;
        this.model.beds = 0;
        this.mode = "full";
    }

    @LoaderTask
    private async onSubmit() {
        try {
            if (!(await this.form.submit())) return;

            this.$emit("submit", this.model);
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
