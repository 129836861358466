
























































import {Component, Prop, Ref, Watch} from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvIcon, LvButton } from "@fastoche/ui-kit/components";
import HotelRoomModal from "@/app/components/listing/room/HotelRoomModal.vue";
import {HotelRoom} from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/Listing";
import AmenityIcon from "@/app/components/amenity/icon/AmenityIcon.vue";
import {price} from "@fastoche/ui-core/i18n/currency/filters/price";
import { money } from  "@/app/ui/filters/money.js";
import {InjectService} from "@fastoche/ui-core/di";
import {ListingStore} from "@/modules/listing/ListingStore";
import {BookingStore} from "@/modules/booking/BookingStore";
import {InjectState, StateOf} from "@fastoche/ui-core/store";

export const COMPONENT_NAME = "RoomPreviewCard";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    filters: {
        price,
        money,
    },
    components: {
        LvIcon,
        HotelRoomModal,
        AmenityIcon,
        LvButton,
    }
})

export default class RoomPreviewCard extends BaseComponent {
    @InjectService(ListingStore)
    readonly listingStore!: ListingStore;
    @InjectState(ListingStore)
    readonly listingState!: StateOf<ListingStore>;

    @InjectService(BookingStore)
    readonly bookingStore!: BookingStore;
    @InjectState(BookingStore)
    readonly bookingState!: StateOf<BookingStore>;

    largeCard = false;
    borderAnimationState: 'border-animation-enter' | 'border-animation-leave' | '' = '';
    slideAnimationState: 'slide-animation-enter' | 'slide-animation-leave' | '' = '';

    @Ref()
    modal!: HotelRoomModal;

    @Prop()
    room!: HotelRoom;

    @Prop()
    selected!: boolean;

    mounted() {
        this.formatCard();
        window.addEventListener('resize', this.formatCard);
    }

    formatCard(){
        //@ts-ignore
        let cartWidth = this.$el.offsetWidth;
        this.largeCard = cartWidth > 450 && cartWidth < 700;
    }
    @Watch('selected')
    onRoomChange(){
        if(this.selected){
            this.borderAnimationState = 'border-animation-enter';
            setTimeout(()=>{
                this.borderAnimationState = '';
            }, 500);
        }
        else{
            this.borderAnimationState = 'border-animation-leave';
            setTimeout(()=>{
                this.borderAnimationState = '';
            }, 500);
        }
    }

    toggleSelectRoom(){
        if(this.selected){
            this.unselectRoom();
        }
        else{
            this.selectRoom();
        }
    }

    selectRoom(){
        this.listingStore.setRoom(this.room);
        this.listingStore.setRoomId(this.room.roomId);
        this.bookingStore.setBookingPrice(this.room.price);
        this.listingStore.setAvailability(true);
    }
    unselectRoom(){
        this.listingStore.setRoom(null);
        this.listingStore.setRoomId(null);
        this.bookingStore.setBookingPrice(null);
    }

    openRoomModal(e){
        this.modal.show(this.room);
        e.stopPropagation();
    }

    showRoomAmenities(e){
        this.openRoomModal(e);
        setTimeout(function (){
            const roomContent = document.querySelector(`#room-content`);
            if(roomContent){
                // @ts-ignore
                const roomAmenitiesYPos = window.scrollY + document.querySelector(`#room-amenities`).getBoundingClientRect().top;
                const margin = 200;

                roomContent.scrollTo({ top: roomAmenitiesYPos - margin, behavior: "smooth" });
            }
        }, 500)
    }

    handleSummaryLength(text, length){
        if(text.length > length){
            return text.substr(0, length)+" ...";
        }
        else{
            return text;
        }
    }
}
