import { Command, RpcInvocable } from "@leavy/cq-core";
import { Bad, Ok, OkVal } from "@leavy/result";
import { IsDateString, IsLocale, IsNotEmpty, IsNumber, IsString, IsUUID, Min } from "@leavy/validator";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { ListingId } from "../../listing/domain/Listing";
import { Booking } from "../domain/Booking";


export type RequestBookingCommandResult =
    | OkVal<Booking>
    | Bad<"USER_NOT_HL">
    | Bad<"INVALID_DATE_INTERVAL">
    | Bad<"INVALID_LISTING_ID">
    | Bad<"LISTING_UNAVAILABLE_OR_NOT_FOUND">
    | Bad<"BOOKING_OVERLAP">
    | Bad<"OCCUPANCY_EXCEEDED">
    ;

/**
 * RequestBookingCommand command description
 */
@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "submit_house_booking_command",
})
export class SubmitHouseBookingCommand extends Command<RequestBookingCommandResult, HouseBookingSubmitted> {
    @IsUUID()
    userId!: string;

    @IsLocale()
    locale!: string;

    @IsNumber()
    @Min(1)
    guests!: number;

    @IsString()
    @IsNotEmpty()
    listingId!: ListingId;

    @IsDateString()
    arrivalDate!: string;

    @IsDateString()
    departureDate!: string;
}

@DomainEvent({ name: "house_booking_submitted", version: 1 })
export class HouseBookingSubmitted extends DomainEventBase {
    @EventProperty()
    bookingId!: string;

    @EventProperty()
    userId!: string;

    constructor(data: PayloadOf<HouseBookingSubmitted>) {
        super(data);
    }
}

