










































import { Component, ModelSync, Ref } from "vue-property-decorator";
import { LvLightContainer, LvNumberInput } from "@fastoche/ui-kit/components";
import { StepFormComponent } from "@/modules/onboarding/hl/StepForm";
import { HlAccommodationBeddingModel } from "@/modules/onboarding/hl/model";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import { construct } from "@leavy/utils";
import { APP_LOGGER } from "@/app/logger";

const getDefaultModel = (): HlAccommodationBeddingModel => {
    return construct(HlAccommodationBeddingModel, {
        duvets: 0,
        duvetCovers: 0,
        pillows: 0,
        pillowCases: 0,
        bedSheets: 0,
        towels: 0,
    });
};

export const COMPONENT_NAME = "AccommodationBeddingForm";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvFormItem,
        LvForm,
        LvLightContainer,
        LvNumberInput,
    },
})
export default class AccommodationBeddingForm extends StepFormComponent<HlAccommodationBeddingModel> {
    @Ref()
    readonly form!: LvForm;

    @ModelSync("value", "change", { type: HlAccommodationBeddingModel, required: false, default: () => getDefaultModel() })
    model!: HlAccommodationBeddingModel;

    async submit() {
        if (!(await this.form.submit())) return;
        return this.model;
    }
}
