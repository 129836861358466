import { AuthenticationState } from "./AuthenticationState";
import { injectable } from "inversify";
import { TypedLocalStorage } from "@fastoche/ui-core/storage";

@injectable()
export class AuthenticationStorage extends TypedLocalStorage<AuthenticationState | undefined> {
    static LOCAL_STORAGE_KEY = "lv.authentication";

    constructor() {
        super(AuthenticationStorage.LOCAL_STORAGE_KEY, undefined);
    }
}
