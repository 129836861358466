import { RouteConfig } from "vue-router";
import { HodMatchingSubstep } from "@/modules/onboarding/hod/model/matching";
import { applyCategoryMeta } from "@fastoche/ui-tracking/core/router/applyCategoryMeta";
import OnboardingMatchingPersonalityPage from "@/app/pages/onboarding/hod/matching/OnboardingMatchingPersonalityPage.vue";
import HodOnboardingMatchingRoot from "@/app/pages/onboarding/hod/matching/HodOnboardingMatchingRoot.vue";
import OnboardingMatchingFavoriteActivitiesPage from "@/app/pages/onboarding/hod/matching/OnboardingMatchingFavoriteActivitiesPage.vue";
import OnboardingMatchingMissionFrequencyPage from "@/app/pages/onboarding/hod/matching/OnboardingMatchingMissionFrequencyPage.vue";
import OnboardingMatchingCityKnowledgePage from "@/app/pages/onboarding/hod/matching/OnboardingMatchingCityKnowledgePage.vue";
import OnboardingMatchingFavoriteFlatsPage from "@/app/pages/onboarding/hod/matching/OnboardingMatchingFavoriteFlatsPage.vue";
import OnboardingMatchingLanguagesPage from "@/app/pages/onboarding/hod/matching/OnboardingMatchingLanguagesPage.vue";
import OnboardingMatchingTransportsPage from "@/app/pages/onboarding/hod/matching/OnboardingMatchingTransportsPage.vue";

export const HOD_ONBOARDING_MATCHING_ROUTES: RouteConfig[] = [
    {
        path: "about-you",
        component: HodOnboardingMatchingRoot,
        children: [
            {
                path: "",
                redirect: "activities",
            },
            {
                path: HodMatchingSubstep.ACTIVITIES,
                name: "HodOnboardingMatchingFavoriteActivitiesPage",
                component: OnboardingMatchingFavoriteActivitiesPage,
            },
            {
                path: HodMatchingSubstep.PERSONALITY,
                name: "HodOnboardingMatchingPersonalityPage",
                component: OnboardingMatchingPersonalityPage,
            },
            {
                path: HodMatchingSubstep.LANGUAGES,
                name: "HodOnboardingMatchingLanguagesPage",
                component: OnboardingMatchingLanguagesPage,
            },
            {
                path: HodMatchingSubstep.TRANSPORTS,
                name: "HodOnboardingMatchingTransportsPage",
                component: OnboardingMatchingTransportsPage,
            },
            {
                path: HodMatchingSubstep.CITY_KNOWLEDGE,
                name: "HodOnboardingMatchingCityKnowledgePage",
                component: OnboardingMatchingCityKnowledgePage,
            },
            {
                path: HodMatchingSubstep.FAVORITE_FLATS,
                name: "HodOnboardingMatchingFavoriteFlatsPage",
                component: OnboardingMatchingFavoriteFlatsPage,
            },
            {
                path: HodMatchingSubstep.FREQUENCY,
                name: "HodOnboardingMatchingMissionFrequencyPage",
                component: OnboardingMatchingMissionFrequencyPage,
            },
        ].map(route => applyCategoryMeta("HodOnboarding", route)),
    },
].map(route => applyCategoryMeta("HodOnboarding", route));
