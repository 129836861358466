import { BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { AllUserOnboardingsStore } from "./common/AllUserOnboardingsStore";
import { OnboardingService } from "./common/services/OnboardingService";
import { CqOnboardingService } from "./common/platform/cq/CqOnboardingService";
import { HodOnboardingService } from "./hod/services/HodOnboardingService";
import { CqHodOnboardingService } from "./hod/platform/cq/CqHodOnboardingService";
import { HodOnboardingStore } from "./hod/HodOnboardingStore";
import { HlOnboardingService } from "./hl/services/HlOnboardingService";
import { HlOnboardingStore } from "./hl/HlOnboardingStore";
import { CqHlOnboardingService } from "./hl/platform/cq/CqHlOnboardingService";

@Module()
export class OnboardingModule extends BaseModule {
    registerServices(container: ModuleContainer): void {
        container.bind(AllUserOnboardingsStore).toSelf().inSingletonScope();
        container.bind(OnboardingService).to(CqOnboardingService).inSingletonScope();

        container.bind(HodOnboardingStore).toSelf().inSingletonScope();
        container.bind(HodOnboardingService).to(CqHodOnboardingService).inSingletonScope();

        container.bind(HlOnboardingStore).toSelf().inSingletonScope();
        container.bind(HlOnboardingService).to(CqHlOnboardingService).inSingletonScope();
    }
}
