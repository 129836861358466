import { injectable } from "inversify";

export interface FileResult {
    url: string;
}

@injectable()
export abstract class FileService {
    abstract getFileUrl(fileId: string): FileResult;
}
