import { IsArray, IsEnum, IsNumber, IsOptional, TryParseNumber, Type } from "@leavy/validator";
import { PriceRange } from "@leavy/lv-homesharing-backend-srv/lib/listing/model/ListingQueryResult";
import { AmenityId } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/Amenity";
import { AccommodationType } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/AccommodationType";

export class ListingFilterContext {
    constructor(from?: Partial<ListingFilterContext>) {
        if (from) {
            for (const k in from) {
                this[k] = from[k];
            }
        }
    }

    @IsOptional()
    @IsEnum(AccommodationType)
    type?: AccommodationType = undefined;

    @IsEnum(PriceRange)
    @IsOptional()
    priceRange?: PriceRange = undefined;

    @IsNumber()
    @IsOptional()
    @TryParseNumber()
    bedrooms?: number = undefined;

    @IsArray()
    @IsOptional()
    @Type(() => String)
    amenities?: AmenityId[] = undefined;
}
