





























































import { Component, Ref } from "vue-property-decorator";
import { LvContainer } from "@fastoche/ui-kit/components";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { InjectService } from "@fastoche/ui-core/di";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { FileService } from "@/modules/file/services/FileService";
import { AnyHlOnboardingStepId } from "@/modules/onboarding/hl/domain";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import LvFilePicker from "@fastoche/ui-kit/components/file-picker/LvFilePicker.vue";
import { ArrayMinSize } from "@leavy/validator";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";

class AccomodationPhotosViewModel {
    @ArrayMinSize(1)
    photos: string[] = [];
}

export const COMPONENT_NAME = "OnboardingAccommodationPhotosPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvFormItem,
        LvForm,
        LvFilePicker,
        LvIcon,
        LvButton,
        PageHeader,
        LvContainer,
    },
})
export default class OnboardingAccommodationPhotosPage extends BaseHlOnboardingPage {
    @Ref()
    form!: LvForm;

    @InjectService(FileService)
    fileService!: FileService;

    model: AccomodationPhotosViewModel = new AccomodationPhotosViewModel();

    created() {
        void this.loadModel();
    }

    @LoaderTask
    async loadModel() {
        await this.onboardingService.loadStep(AnyHlOnboardingStepId.HL_ACCOMMODATION_PHOTOS);

        const model = new AccomodationPhotosViewModel();
        const { data } = this.hlState.onboarding.photos;

        if (data) {
            model.photos = data.photos;
        }

        this.model = model;
    }

    getFileUrl(fileId: string) {
        const { url } = this.fileService.getFileUrl(fileId);
        return url;
    }

    @LoaderTask
    async addFile(file: File) {
        try {
            const result = await this.hlOnboardingService.addAccomodationPhoto(file);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_WRONG_STATE":
                    case "ONBOARDING_NOT_FOUND":
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "DUPLICATE_FILE":
                        // TODO: show error message
                        return;
                }
            }
            else {
                this.model.photos = [...this.model.photos, result.value.fileId];
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async removeFile(fileId: string) {
        try {
            const result = await this.hlOnboardingService.removeAccomodationPhoto(fileId);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_WRONG_STATE":
                    case "ONBOARDING_NOT_FOUND":
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;
                }
            }
            else {
                this.model.photos.splice(
                    this.model.photos.indexOf(fileId),
                    1,
                );
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async onSubmit() {
        try {
            if (!(await this.form.submit())) return;

            const result = await this.hlOnboardingService.submitAccomodationPhotoStep();

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
            else {
                const hlNextPage = this.hlStore.getNextStepLocation(AnyHlOnboardingStepId.HL_ACCOMMODATION_PHOTOS);
                await this.$router.push(hlNextPage).catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
