export interface PaginatedResult<T> {
    data: T[];
    page: number;
    perPage: number;
    total: number;
}

export class DefaultPaginatedResult<T> implements PaginatedResult<T> {
    data: T[] = [];
    page: number = 0;
    perPage: number = 0;
    total: number = 0;
}

export interface PaginationQuery {
    page: number;
    perPage: number;
}
