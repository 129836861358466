
























import { Component, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { PasswordService } from "@/modules/auth/services/PasswordService";
import { InjectService } from "@fastoche/ui-core/di";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import { IsEmail } from "@leavy/validator";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import { MessageBox } from "@fastoche/ui-kit/components";

class PasswordLostViewModel {
    @IsEmail()
    email!: string;
}

export const COMPONENT_NAME = "PasswordLostPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: { LvButton, LvField, LvFormItem, LvForm },
})
export default class PasswordLostPage extends BaseComponent {
    @Ref()
    form!: LvForm;

    @InjectService(PasswordService)
    passwordService!: PasswordService;

    model = new PasswordLostViewModel();

    @LoaderTask
    async onSubmit() {
        try {
            if (!await this.form.submit()) return;

            const result = await this.passwordService.beginReset(this.model.email);

            if (!result.success) {
                void MessageBox.prompt({
                    type: "warning",
                    confirmText: this.$t("common.confirm"),
                    isCancellable: false,
                    title: this.$t("password_lost.error.title"),
                    message: this.$t("password_lost.error.message"),
                });
            }
            else {
                void MessageBox.prompt({
                    type: "success",
                    confirmText: this.$t("common.confirm"),
                    isCancellable: false,
                    title: this.$t("password_lost.success.title"),
                    message: this.$t("password_lost.success.message"),
                });

                void this.$router.replace("/").catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
