

































import { Component } from "vue-property-decorator";
import { BaseQuizQuestionComponent } from "./BaseQuestion";
import { QuizBaseChoice, QuizCoverQuestion } from "@/modules/onboarding/hod/model/vetting/model";

export const COMPONENT_NAME = "ButtonPhotoQuestion";

@Component({
    name: COMPONENT_NAME,
})
export default class CoverQuestion extends BaseQuizQuestionComponent<QuizCoverQuestion> {
    get cover() {
        return require(`@/app/assets/forms/${this.schema.resId}.jpg`);
    }

    getChoiceClass(choice: QuizBaseChoice) {
        return {
            "active": this.isSelected(choice),
            "correct": this.status == "validated" && this.schema.expected.includes(choice.id),
            "wrong": this.status == "validated" && !this.schema.expected.includes(choice.id) && !!this.model?.answer.includes(choice.id),
            "hidden": this.status == "validated" && !this.schema.expected.includes(choice.id) && !this.model?.answer.includes(choice.id),
        };
    }
}
