import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { GetLocalizationOptionsQuery } from "@leavy/lv-homesharing-backend-srv/lib/i18n/query";
import { LocalizationOptions, LocalizationOptionsProvider } from "@fastoche/ui-core/i18n/options/LocalizationOptionsProvider";
import { LocaleObject, WellKnownLocaleObject } from "@fastoche/ui-core/i18n/locale/Locale";
import { Currency } from "@fastoche/ui-core/i18n/currency/Currency";

@injectable()
export class DefaultLocalizationOptionsProvider extends LocalizationOptionsProvider {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    async getOptions(): Promise<LocalizationOptions> {
        const result = await this.cq.invoke(GetLocalizationOptionsQuery, {});
        if (!result.success) throw new Error("Can't load localization options");

        const { wellKnownLocales, defaultLocale } = result.value;

        return Promise.resolve({
            defaultCurrency: Currency.EUR, // TODO: from server
            defaultLocale: new LocaleObject(defaultLocale.langCode, defaultLocale.countryCode),
            wellKnownLocales:  wellKnownLocales.map(x => new WellKnownLocaleObject(x.displayName, x.langCode, x.countryCode)),
        });
    }
}
