



















import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { FavoriteTransport } from "@/modules/onboarding/hod/domain";
import { HodMatchingSubstep, MatchingDataModel } from "@/modules/onboarding/hod/model";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";

const CHOICE_ICONS: Record<FavoriteTransport, string> = {
    [FavoriteTransport.METRO_BUS]: require("../../../../assets/icons/bus.svg"),
    [FavoriteTransport.CAR]: require("../../../../assets/icons/car.svg"),
    [FavoriteTransport.SCOOTER]: require("../../../../assets/icons/scooter.svg"),
    [FavoriteTransport.BIKE]: require("../../../../assets/icons/bike.svg"),
};

export const COMPONENT_NAME = "OnboardingMatchingTransportsPage";

@Component({
    name: COMPONENT_NAME,
    components: { LvButton },
    loader: true,
})
export default class OnboardingMatchingTransportsPage extends BaseHodOnboardingPage {
    choices: FavoriteTransport[] = Object.values(FavoriteTransport);
    selected: FavoriteTransport[] = [];

    @Prop({ type: Object })
    matchingData!: MatchingDataModel;

    mounted() {
        if (this.matchingData.transport != undefined) {
            this.selected.push(...this.matchingData.transport);
        }
    }

    getChoiceIcon(choice: FavoriteTransport) {
        return CHOICE_ICONS[choice];
    }

    isSelected(choice: FavoriteTransport) {
        return this.selected.includes(choice);
    }

    toggle(choice: FavoriteTransport) {
        const idx = this.selected.indexOf(choice);
        if (idx < 0) this.selected.push(choice);
        else this.selected.splice(idx, 1);
    }

    get isValid() {
        return this.selected.length > 0;
    }

    nextStep() {
        this.matchingData.transport = [...this.selected];
        this.$emit("next", { from: HodMatchingSubstep.TRANSPORTS });
    }
}
