import {
    IsDateString,
    IsIn,
    IsNotEmpty,
    IsNumber,
    IsString,
    Min,
    TryParseNumber,
} from "@leavy/validator";
import { Query, RpcInvocable } from "@leavy/cq-core";
import { Bad, OkVal } from "@leavy/result";
import { ListingId } from "../domain/Listing";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { Currency, SUPPORTED_CURRENCIES } from "../../money/domain/Currency";
import { ListingPriceQueryResult } from "../model/ListingPriceQueryResult";

export type GetHouseListingPriceQueryResult =
    | OkVal<ListingPriceQueryResult | null>
    | Bad<"INVALID_ID">
    | Bad<"INVALID_RENT">
    | Bad<"INVALID_DATE_INTERVAL">
    | Bad<"MISSING_RENT_OR_CURRENCY">
    ;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "get_house_listing_price",
})
export class GetHouseListingPriceQuery extends Query<GetHouseListingPriceQueryResult> {
    @IsString()
    @IsNotEmpty()
    listingId!: ListingId;

    @IsString()
    @IsDateString()
    arrivalDate!: string;

    @IsString()
    @IsDateString()
    departureDate!: string;

    @IsNumber()
    @Min(1)
    @TryParseNumber()
    guests!: number;

    @IsIn(SUPPORTED_CURRENCIES)
    currency!: Currency;
}
