















































import { Component, Ref } from "vue-property-decorator";
import { LvContainer, LvLightContainer } from "@fastoche/ui-kit/components";
import { CommonOnboardingPage } from "./CommonOnboardingPage";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { InjectService } from "@fastoche/ui-core/di";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { OnboardingService } from "@/modules/onboarding/common/services/OnboardingService";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { HlOnboardingStore } from "@/modules/onboarding/hl/HlOnboardingStore";
import { OnboardingId } from "@/modules/onboarding/common/domain";
import { AnyHlOnboardingStepId } from "@/modules/onboarding/hl/domain";
import { AnyHodOnboardingStepId } from "@/modules/onboarding/hod/domain";
import { EmailService } from "@/modules/auth/services/EmailService";
import { UserService } from "@/modules/user/services/UserService";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import EmailVerifiedIcon from "@/app/ui/svg/EmailVerifiedIcon.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import LvTag from "@fastoche/ui-kit/components/tag/LvTag.vue";
import { MessageBox } from "@fastoche/ui-kit/components";
import { InjectState } from "@fastoche/ui-core/store";
import { UserContext, UserContextStore } from "@/modules/user/UserContext";

export const COMPONENT_NAME = "OnboardingEmailVerifyPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvTag,
        LvIcon,
        LvButton,
        EmailVerifiedIcon,
        PageHeader,
        LvContainer,
    },
})
export default class OnboardingEmailVerifyPage extends CommonOnboardingPage {
    @InjectService(OnboardingService)
    readonly onboardingService!: OnboardingService;

    @InjectService(EmailService)
    readonly emailService!: EmailService;

    @InjectService(HodOnboardingStore)
    readonly hodStore!: HodOnboardingStore;

    @InjectService(HlOnboardingStore)
    readonly hlStore!: HlOnboardingStore;

    @InjectService(UserContextStore)
    readonly userContextStore!: UserContextStore;

    isEmailVerified = false;
    userEmail: string = "";

    get subtitle() {
        return this.isEmailVerified ?
            this.$t("app.onboarding.steps.email_verification.title.subtitle.verification_successfull") :
            this.$t("app.onboarding.steps.email_verification.title.subtitle.verification_pending");
    }

    mounted() {
        void this.loadModel();
    }

    @LoaderTask
    async loadModel() {
        try {
            await this.onboardingService.loadStep(AnyHlOnboardingStepId.EMAIL_VERIFICATION);

            const user = await this.userContextStore.getUserInfoOrThrow();
            this.userEmail = user.email;
            this.isEmailVerified = this.userOnboarding.emailVerification.data?.emailVerified == true;
        }
        catch(e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async sendVerificationEmail() {
        if (this.isEmailVerified) return;

        try {
            const result = await this.emailService.sendEmailVerificationCode();

            if (!result.success) {
                CommonErrorDialogs.unexpectedError();
            }
            else {
                void MessageBox.prompt({
                    type: "success",
                    confirmText: this.$t("common.confirm"),
                    isCancellable: false,
                    title: this.$t("app.onboarding.steps.email_verification.email_sent"),
                    message: this.$t("app.onboarding.steps.email_verification.email_sent.description"),
                });
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async nextStep() {
        switch (this.onboardingId) {
            case OnboardingId.HL:
                const hlNextPage = this.hlStore.getNextStepLocation(AnyHlOnboardingStepId.EMAIL_VERIFICATION);
                await this.$router.push(hlNextPage).catch(this.handleNavigationError);
                break;

            case OnboardingId.HOD:
                const hodNextPage = this.hodStore.getNextStepLocation(AnyHodOnboardingStepId.EMAIL_VERIFICATION);
                await this.$router.push(hodNextPage).catch(this.handleNavigationError);
                break;
        }
    }
}
