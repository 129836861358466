import { BaseComponent } from "@/app/BaseComponent";
import { Component } from "vue-property-decorator";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { AllUserOnboardingsStore } from "@/modules/onboarding/common/AllUserOnboardingsStore";
import { raise } from "@leavy/utils";
import { HOD_ONBOARDING_ROOT_LOCATION } from "@/modules/onboarding/hod/workflow";
import { OnboardingId } from "@leavy/lv-onboarding-srv/lib/common/domain/OnboardingId";

const ONBOARDING_HL_ROUTE_PREFIX = "/app/happy-leaver/onboarding";
const ONBOARDING_HOD_ROUTE_PREFIX = HOD_ONBOARDING_ROOT_LOCATION;

const ONBOARDING_HL_BACK_LINK = "/app/happy-leaver/onboarding";
const ONBOARDING_HOD_BACK_LINK = HOD_ONBOARDING_ROOT_LOCATION;

@Component
export class CommonOnboardingPage extends BaseComponent {
    @InjectState(AllUserOnboardingsStore)
    readonly onboardingState!: StateOf<AllUserOnboardingsStore>;

    get userOnboarding() {
        return this.onboardingState[this.onboardingId]
            ?? raise(new Error("User onboarding not found"));
    }

    get onboardingId(): OnboardingId {
        if (this.$route.path.includes(ONBOARDING_HL_ROUTE_PREFIX)) return OnboardingId.HL;
        else if (this.$route.path.includes(ONBOARDING_HOD_ROUTE_PREFIX)) return OnboardingId.HOD;
        else throw new Error("Unknown onboarding type");
    }

    get backLink() {
        switch (this.onboardingId) {
            case OnboardingId.HOD:
                return ONBOARDING_HOD_BACK_LINK;

            case OnboardingId.HL:
                return ONBOARDING_HL_BACK_LINK;
        }
    }
}
