
















import { Component, Model, Prop, Ref, Watch, ModelSync } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { loadGoogleMaps } from "@/app/vendors/google-maps/lib";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import { LvIcon } from "@fastoche/ui-kit/components";

function findComponent(type: string, obj: google.maps.places.PlaceResult) {
    return obj.address_components!.find(x => x.types.includes(type));
}

export const COMPONENT_NAME = "AddressInput";

@Component({
    name: COMPONENT_NAME,
    components: { LvField, LvIcon },
})
export default class AddressInput extends BaseComponent {
    private addressAutocomplete: google.maps.places.Autocomplete | null = null;

    @Ref()
    readonly addressInput!: HTMLInputElement;

    @Prop({ type: String, required: false })
    placeholder?: string;

    @ModelSync("value", "change", { type: String, required: false, default: () => null })
    private model!: string | null;

    async mounted() {
        try {
            const loaded = await loadGoogleMaps();

            if (!loaded) {
                CommonErrorDialogs.networkError();
            }
            else {
                this.createPlaceAutocomplete();
            }
        }
        catch (e) {}
    }

    beforeDestroy() {
        this.destroyPlaceAutocomplete();
    }

    private createPlaceAutocomplete() {
        if (this.addressAutocomplete != null) return;

        this.addressAutocomplete = new google.maps.places.Autocomplete(
            this.addressInput,
            { types: ["geocode"] },
        );

        this.addressAutocomplete.addListener("place_changed", () => {
            const place = this.addressAutocomplete!.getPlace();

            if (!place.formatted_address) {
                this.model = null;
            }
            else {
                this.model = place.formatted_address;
            }
        });
    }

    private destroyPlaceAutocomplete() {
        if (this.addressAutocomplete == null) return;

        google.maps.event.clearInstanceListeners(this.addressInput);

        this.addressAutocomplete.unbindAll();
        this.addressAutocomplete = null!;

        document.querySelector(".pac-container")?.remove();
    }
}
