


















import { Component, Ref, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import LvControlPopover from "@fastoche/ui-kit/components/control-popover/LvControlPopover.vue";
import { LvIcon } from "@fastoche/ui-kit/components";

export const COMPONENT_NAME = "HelpTooltip";

@Component({
    name: COMPONENT_NAME,
    components: { LvControlPopover, LvIcon },
})
export default class HelpTooltip extends BaseComponent {
    @Ref()
    readonly popover!: LvControlPopover;
}
