import { RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../../base";
import { Ok } from "@leavy/result";
import { CommonEditOnboardingError } from "../../ForwardedOnboardingCommand";
import { StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { Equals, IsDefined, ValidateNested } from "@leavy/validator";
import { Type } from "class-transformer";
import { AdministrativeDocumentModel } from "@leavy/lv-onboarding-srv/lib/hod/model/administrative/AdministrativeDocument";
import { OnboardingId } from "@leavy/lv-onboarding-srv/lib/common/domain/OnboardingId";

export type RemoveAdministrativeDocumentLocalCommandResult =
    | Ok
    | CommonEditOnboardingError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "remove_hod_administrative_document",
})
export class RemoveAdministrativeDocumentLocalCommand extends StepCommand<RemoveAdministrativeDocumentLocalCommandResult> {
    @Equals(OnboardingId.HOD)
    override onboardingId: OnboardingId.HOD = OnboardingId.HOD;

    @Type(() => AdministrativeDocumentModel)
    @IsDefined()
    @ValidateNested()
    document!: AdministrativeDocumentModel;
}
