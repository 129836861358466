






































import { Component } from "vue-property-decorator";
import { LvButton, LvCard, LvContainer, LvIcon } from "@fastoche/ui-kit/components";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import ClockIcon from "@/app/ui/svg/ClockIcon.vue";
import { UserOnboardingStatus } from "@leavy/lv-onboarding-srv/lib/common/domain/UserOnboardingStatus";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import HlRegistrationSteps from "@/app/ui/hl-registration-steps/HlRegistrationSteps.vue";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";

export const COMPONENT_NAME = "HlOnboardingPendingPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        PageHeader,
        ClockIcon,
        LvContainer,
        HlRegistrationSteps,
        LvCard,
        LvButton,
        LvIcon
    },
})
export default class HlOnboardingPendingPage extends BaseHlOnboardingPage {
    mounted() {
        void this.ensureOnboardingSubmitted();
    }

    @LoaderTask
    async ensureOnboardingSubmitted() {
        const userOnboarding = this.hlState.onboarding;

        switch (userOnboarding.status) {
            case UserOnboardingStatus.STARTED: {
                const result = await this.onboardingService.submitOnboarding(userOnboarding.onboardingId);

                if (!result.success) {
                    switch (result.reason) {
                        case "ONBOARDING_INCOMPLETE":
                            await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                            return;

                        default:
                            CommonErrorDialogs.unexpectedError();
                            await this.$router.replace("/").catch(this.handleNavigationError);
                            return;
                    }
                }

                break;
            }

            case UserOnboardingStatus.SUBMITTED:
                return;

            default:
                await this.$router.replace("/").catch(this.handleNavigationError);
                return;
        }
    }
}
