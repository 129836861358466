import { IsDefined } from "@leavy/validator";
import { CommonStepError, StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { BinaryFile, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../../base";
import { BaseFile } from "../../../common/BaseFile";
import { OkVal } from "@leavy/result";
import { CommonEditOnboardingError } from "../../ForwardedOnboardingCommand";

export type AddIdentityDocumentLocalCommandResult =
    | OkVal<{ fileId: string }>
    | CommonEditOnboardingError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "upload_identity_document",
})
export class AddIdentityDocumentLocalCommand extends StepCommand<AddIdentityDocumentLocalCommandResult> {
    @BinaryFile()
    @IsDefined()
    document!: BaseFile;
}
