import { injectable } from "inversify";
import { UserContextStore } from "@/modules/user/UserContext";
import { UserInfo } from "@/modules/user/domain";
import { IntercomConfig } from "@/modules/intercom/config";
import { UtmParametersStore } from "@/modules/marketing/utm/UtmParametersStore";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { APP_CONFIG } from "@/app/config";

declare global {
    interface Window {
        Intercom: any;
    }
}

@injectable()
export class IntercomIdentityVerificationService {
    constructor(
        private readonly userContextStore: UserContextStore,
        private readonly utmParametersStore: UtmParametersStore,
        private readonly localizationContext: LocalizationContext,
        private readonly config: IntercomConfig,
    ) {
    }

    registerListeners() {
        if (APP_CONFIG.APP_ENV === "production") {
            this.userContextStore.value$.subscribe(x => {
                if (x == null) {
                    this.onUserLoggedOut();
                }
                else {
                    this.onUserLoggedIn(x as UserInfo);
                }
            });
        }
        else {
            window.Intercom("shutdown");

            window.Intercom("boot", {
                language_override: this.localizationContext.value.currentLocale.langCode,
            });
        }
    }

    protected onUserLoggedIn(info: UserInfo) {
        const utmState = this.utmParametersStore.value;

        window.Intercom("boot", {
            user_id: info.id,
            user_hash: info.intercom.hash,
            email: info.email,
            phone: info.phone,
            name: `${info.firstName} ${info.lastName}`,
            language_override: this.localizationContext.value.currentLocale.langCode,
            utm_campaign: utmState.utmCampaign,
            utm_medium: utmState.utmMedium,
            utm_source: utmState.utmSource,
        });
    }

    protected onUserLoggedOut() {
        const utmState = this.utmParametersStore.value;

        window.Intercom("shutdown");

        window.Intercom("boot", {
            language_override: this.localizationContext.value.currentLocale.langCode,
            utm_campaign: utmState.utmCampaign,
            utm_medium: utmState.utmMedium,
            utm_source: utmState.utmSource,
        });
    }
}
