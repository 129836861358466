import { injectable } from "inversify";
import { HotelQueryResult } from "@/modules/listing/model";
import { Bad, OkVal } from "@leavy/result";
import { BookingQuoteViewModel } from "@/modules/booking/model/BookingQuoteViewModel";
import { HotelRoom } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/Listing";

@injectable()
export abstract class ListingHotelService {
    abstract getHotelListingById(id: string): Promise<OkVal<HotelQueryResult> | Bad<"NOT_FOUND">>;

    abstract getHotelRooms(hotelId: string, model: BookingQuoteViewModel, currency): Promise<OkVal<HotelRoom[]> | Bad<"INVALID_DATE_INTERVAL">>;
}
