import { Analytics } from "@segment/analytics-next";
import { injectable } from "inversify";
import { BaseTrackingEventSegmentHandler } from "@fastoche/ui-tracking";
import { AmbientTrackingEvents } from "@fastoche/ui-tracking/core/TrackingEventAmbientRegistry";
import { AnyTrackingEventInstanceFromRegistry } from "@fastoche/ui-tracking/core/model/BaseTrackingEventRegistry";

type SegmentEventMetadata =
    { category: string; label: string };

const EMPTY_EVENT_METADATA: SegmentEventMetadata =
    { category: "", label: "" };

const SEGMENT_EVENTS_METADATA: Record<keyof AmbientTrackingEvents, SegmentEventMetadata> = {
    PageViewEvent: EMPTY_EVENT_METADATA,

    HlOnboardingStartClickEvent: {
        label: "HL_Onboarding_Click_Start",
        category: "Onboarding_HL_Events",
    },
    HlOnboardingResumeClickEvent: {
        label: "HL_Onboarding_Click_Resume",
        category: "Onboarding_HL_Events",
    },
    HlOnboardingSubmitClickEvent: {
        label: "HL_Onboarding_Click_Submit",
        category: "Onboarding_HL_Events",
    },
    HlOnboardingStepClickEvent: {
        label: "HL_Onboarding_Click_Step_Link",
        category: "Onboarding_HL_Events",
    },
    HodOnboardingStartClickEvent: {
        label: "HOD_Onboarding_Click_Start",
        category: "Onboarding_HOD_Events",
    },
    HodOnboardingResumeClickEvent: {
        label: "HOD_Onboarding_Click_Resume",
        category: "Onboarding_HOD_Events",
    },
    HodOnboardingSubmitClickEvent: {
        label: "HOD_Onboarding_Click_Submit",
        category: "Onboarding_HOD_Events",
    },
    HodOnboardingStepClickEvent: {
        label: "HOD_Onboarding_Click_Step_Link",
        category: "Onboarding_HOD_events",
    },
    AppNavigationClickEvent: {
        label: "App_Navigation_Click_Link",
        category: "App_Nav_Events",
    },
    FooterLinkClickEvent: {
        label: "Footer_Link_Click",
        category: "Footer_Events",
    },
    HlRegisterSubmitEvent: {
        label: "HL_Register_Submit",
        category: "Account_Events",
    },
    HodRegisterSubmitEvent: {
        label: "HOD_Register_Submit",
        category: "Account_Events",
    },
    UserConfirmLogOutEvent: {
        label: "User_Log_Out_Confirm",
        category: "Account_Events",
    },
    UserConfirmDeleteAccountEvent: {
        label: "User_Delete_Account_Confirm",
        category: "Account_Events",
    },
};

@injectable()
export class AppTrackingEventSegmentHandler extends BaseTrackingEventSegmentHandler<AmbientTrackingEvents> {
    async send(event: AnyTrackingEventInstanceFromRegistry<AmbientTrackingEvents>, analytics: Analytics) {
        switch (event.name) {
            case "PageViewEvent": {
                const { category, name } = event.value;
                await analytics.page(category, name);

                break;
            }

            default: {
                const { category, label } = SEGMENT_EVENTS_METADATA[event.name];

                await analytics.track(label, {
                    category,
                    ...event.value,
                });

                break;
            }
        }
    }
}
