






































































import {Component, Prop, Ref, Watch} from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import BookingPriceExplainedPopup from "@/app/ui/booking-price-explained-popup/BookingPriceExplainedPopup.vue";
import { LvButton, LvCard, LvDateRangePicker, LvField, LvForm, LvFormItem, LvBadge, LvIcon, LvNumberInput, LvRadioButtonGroup, LvRadioButton } from "@fastoche/ui-kit/components";
import HotelRoomModal from "@/app/components/listing/room/HotelRoomModal.vue";
import {HotelRoom} from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/Listing";
import AmenityIcon from "@/app/components/amenity/icon/AmenityIcon.vue";
import {price} from "@fastoche/ui-core/i18n/currency/filters/price";
import { money } from "@/app/ui/filters/money.js";

export class SelectHotelRoomModel {
    room = 0;
    bedType: 'simple' | 'double' = 'simple';
    price = 80;
}

export const COMPONENT_NAME = "SelectHotelRoom";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    filters: {
        price,
        money,
    },
    components: {
        BookingPriceExplainedPopup,
        LvCard,
        LvForm,
        LvFormItem,
        LvNumberInput,
        LvDateRangePicker,
        LvButton,
        LvField,
        LvIcon,
        LvRadioButtonGroup,
        LvRadioButton,
        LvBadge,
        HotelRoomModal,
        AmenityIcon,
    }
})
export default class SelectHotelRoom extends BaseComponent {
    model = new SelectHotelRoomModel();
    showedContent = false;
    borderAnimationState: 'border-animation-enter' | 'border-animation-leave' | '' = '';
    slideAnimationState: 'slide-animation-enter' | 'slide-animation-leave' | '' = '';

    @Ref()
    modal!: HotelRoomModal;

    @Prop()
    room!: HotelRoom;

    @Prop()
    selected!: boolean;

    @Watch('selected')
    onRoomChange(){
        if(this.selected){
            this.borderAnimationState = 'border-animation-enter';
            setTimeout(()=>{
                this.borderAnimationState = '';
            }, 500);
        }
        else{
            this.borderAnimationState = 'border-animation-leave';
            setTimeout(()=>{
                this.borderAnimationState = '';
            }, 500);
        }
    }

    toggleShowInputs(){
        if(this.showedContent){
            // closing
            this.slideAnimationState = 'slide-animation-leave';
            setTimeout(()=>{
                this.showedContent = false;
            }, 500);
        }
        else{
            // opening
            this.showedContent = true;
            this.slideAnimationState = 'slide-animation-enter';
        }
    }

    showRoomAmenities(){
        this.openRoomModal();
        setTimeout(function (){
            const roomContent = document.querySelector(`#room-content`);
            if(roomContent){
                // @ts-ignore
                const roomAmenitiesYPos = window.scrollY + document.querySelector(`#room-amenities`).getBoundingClientRect().top;
                const margin = 200;

                roomContent.scrollTo({ top: roomAmenitiesYPos - margin, behavior: "smooth" });
            }
        }, 500)

    }
    selectRoom(){
        this.$emit('select-room', this.room);
    }
    unselectRoom(){
        this.$emit('unselect-room');
    }
    openRoomModal(){
        this.modal.show(this.room);
    }
}
