import { injectable } from "inversify";
import { EmailService } from "@/modules/auth/services/EmailService";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { UpdateUserEmailCommand, VerifyEmailCommand, RegenerateEmailVerificationCodeCommand } from "./command";
import { Bad, Ok } from "@leavy/result";

@injectable()
export class CqEmailService extends EmailService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    sendEmailVerificationCode(): Promise<Ok | Bad<"USER_NOT_FOUND" | "regeneration_failed">> {
        return this.cq.invoke(RegenerateEmailVerificationCodeCommand, {});
    }

    verifyEmail(email: string, verificationCode: string): Promise<Ok | Bad<"email_verification_failed" | "USER_NOT_FOUND">> {
        return this.cq.invoke(VerifyEmailCommand, {
            email,
            code: verificationCode,
        });
    }

    updateEmail(email: string): Promise<Ok | Bad<"update_email_failed">> {
        return this.cq.invoke(UpdateUserEmailCommand, { email });
    }
}
