












import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { amenities, AmenityId } from "@/modules/listing/domain";

export const COMPONENT_NAME = "AmenityIcon";

@Component({
    name: COMPONENT_NAME,
})
export default class AmenityIcon extends BaseComponent {
    @Prop({ type: String, required: true })
    readonly name!: AmenityId;

    get resId() {
        return `app.amenities.${this.name.replace("_AMENITY", "")}`;
    }

    get isSupported() {
        return amenities.includes(this.name);
    }
}
