






























import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { Component, Prop, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { AuthenticationService } from "@/modules/auth/services/AuthenticationService";
import { InjectService } from "@fastoche/ui-core/di";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import { IsNotEmpty, IsString } from "@leavy/validator";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import { MessageBox } from "@fastoche/ui-kit/components";

class LoginFormViewModel {
    @IsString()
    @IsNotEmpty()
    email: string = "";

    @IsString()
    @IsNotEmpty()
    password: string = "";
}

export const COMPONENT_NAME = "LoginForm";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvButton,
        LvFormItem,
        LvField,
        LvForm,
    },
})
export default class LoginForm extends BaseComponent {
    @Ref()
    form!: LvForm;

    @InjectService(AuthenticationService)
    authService!: AuthenticationService;

    @Prop({ type: String, required: false, default: () => null })
    email?: string;

    private readonly model = new LoginFormViewModel();

    mounted() {
        if (this.email) {
            this.model.email = this.email;
        }
    }

    showRegisterForm() {
        this.$emit("showRegisterForm");
    }

    @LoaderTask
    private async onSubmit() {
        try {
            if (!(await this.form.submit())) return;

            const authenticationResult = await this.authService.authenticate(this.model);

            if (!authenticationResult.success) {
                if (authenticationResult.reason === "invalid_credentials") {
                    void MessageBox.prompt({
                        type: "warning",
                        confirmText: this.$t("common.confirm"),
                        isCancellable: false,
                        title: this.$t("login.error.title"),
                        message: this.$t("login.error.invalid_credentials.message"),
                    });
                }
                else {
                    void MessageBox.prompt({
                        type: "warning",
                        confirmText: this.$t("common.confirm"),
                        isCancellable: false,
                        title: this.$t("login.error.title"),
                        message: this.$t("common.error.unexpected.message"),
                    });
                }
            }
            else {
                this.$emit("loggedIn");
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
