




















import { Component } from "vue-property-decorator";
import { LvContainer } from "@fastoche/ui-kit/components";
import { InjectService } from "@fastoche/ui-core/di";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { HodMatchingIndexedSubstep, HodMatchingSubstep, MatchingDataModel, SpokenLanguageModel } from "@/modules/onboarding/hod/model";
import { AnyHodOnboardingStepId } from "@/modules/onboarding/hod/domain";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { HodOnboardingService } from "@/modules/onboarding/hod/services/HodOnboardingService";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import LvProgressBar from "@fastoche/ui-kit/components/progress-bar/LvProgressBar.vue";

type OnNextStepEvent = { from: HodMatchingSubstep };

export const COMPONENT_NAME = "HodOnboardingMatchingRoot";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvProgressBar,
        PageHeader,
        LvContainer,
    },
})
export default class HodOnboardingMatchingRoot extends BaseHodOnboardingPage {
    @InjectState(HodOnboardingStore)
    state!: NonNullable<StateOf<HodOnboardingStore>>;

    @InjectService(HodOnboardingStore)
    store!: HodOnboardingStore;

    @InjectService(HodOnboardingService)
    service!: HodOnboardingService;

    model: MatchingDataModel = new MatchingDataModel();

    created() {
        this.loadModel();
    }

    loadModel() {
        const model = new MatchingDataModel();
        const { data } = this.state.onboarding.matching;

        if (data) {
            model.activities = data.activities;
            model.cityKnowledge = data.cityKnowledge;
            model.favoriteFlats = data.favoriteFlats;
            model.frequency = data.frequency;
            model.personality = data.personality;
            model.transport = data.transport;
            model.languages = data.languages?.map(x => {
                const language = new SpokenLanguageModel();
                language.langCode = x.langCode!;
                language.level = x.level!;
                return language;
            });
        }

        this.model = model;
    }

    get progress() {
        const currentStep: HodMatchingSubstep | undefined = this.$route.path.split("/").pop() as HodMatchingSubstep | undefined;
        if (!currentStep) return 0;

        const currentIndexedStep = HodMatchingIndexedSubstep(currentStep);
        return currentIndexedStep.progress;
    }

    @LoaderTask
    async onNextStep(ev: OnNextStepEvent) {
        try {
            const result = await this.service.editMatchingStep(this.model);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_WRONG_STATE":
                    case "ONBOARDING_NOT_FOUND":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;
                }
            }
            else {
                const currentIndexedStep = HodMatchingIndexedSubstep(ev.from);

                if (currentIndexedStep.isLast) await this.onMatchingFormFinished();
                else {
                    const nextStep = currentIndexedStep.getNext().step;
                    await this.$router.push(nextStep).catch(this.handleNavigationError);
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async onMatchingFormFinished() {
        try {
            const result = await this.service.submitMatchingStep();

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
            else {
                const nextPage = this.store.getNextStepLocation(AnyHodOnboardingStepId.HOD_MATCHING);
                await this.$router.push(nextPage).catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
