















































import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvIcon } from "@fastoche/ui-kit/components";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";
import { HlOnboardingStore } from "@/modules/onboarding/hl/HlOnboardingStore";
import { InjectService } from "@fastoche/ui-core/di";
import { HlOnboardingService } from "@/modules/onboarding/hl/services/HlOnboardingService";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { orderBy } from "lodash-es";
import { MultiCurrencyMoneyHelper } from "@leavy/lv-homesharing-backend-srv/lib/money/domain/MultiCurrencyMoney";
import { MathUtils } from "@leavy/utils";
import { CURRENCY_MAX_DECIMAL_PRECISION } from "@leavy/lv-homesharing-backend-srv/lib/money/domain/Currency";

interface CurrentEstimation {
    value: number;
    currency: string;
    type: "PER_DAY" | "FOR_PERIOD";
}

type HlRegistrationStep =
    | "estimate"
    | "create_account"
    | "dates"
    | "finalize";

export const COMPONENT_NAME = "HlRegistrationSteps";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvIcon,
    },
    filters: {
        price,
    },
})
export default class HlRegistrationSteps extends BaseComponent {
    @InjectState(AuthenticationStore)
    private readonly authState!: StateOf<AuthenticationStore>;

    @InjectState(HlOnboardingStore)
    private readonly hlState!: StateOf<HlOnboardingStore>;

    @InjectService(HlOnboardingService)
    private readonly hlOnboardingService!: HlOnboardingService;

    @InjectState(LocalizationContext)
    private readonly localizationContext!: StateOf<LocalizationContext>;

    @Prop({ type: String, required: false, default: null })
    readonly state?: "warning" | "danger";

    @Prop({ type: String, required: true })
    readonly current!: HlRegistrationStep | "end";

    @Prop({ type: Boolean, required: false, default: false })
    readonly showEstimation?: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    readonly showBest?: boolean;

    private currentEstimation: CurrentEstimation | null = null;

    private get revenueEstimation() {
        return this.hlState?.onboarding.revenueEstimation.data;
    }

    private get paymentPlan() {
        return this.hlState?.onboarding.paymentPlan.value;
    }

    private mounted() {
        if (this.showEstimation) {
            void this.loadCurrentEstimation();
        }
    }

    private async loadCurrentEstimation() {
        if (this.revenueEstimation?.hasResult()) {
            if (this.showBest) {
                const possiblePaymentPlansResult = await this.hlOnboardingService.getPossiblePaymentPlans(this.localizationContext.currentCurrency);

                if (possiblePaymentPlansResult.success && possiblePaymentPlansResult.value.length > 0) {
                    const bestPlan = orderBy(possiblePaymentPlansResult.value, x => x.adjustedRevenue.value, "desc")[0];

                    if (bestPlan) {
                        const { value, currency } = bestPlan.adjustedRevenue;

                        this.currentEstimation = {
                            value,
                            currency,
                            type: this.revenueEstimation.result.type,
                        };

                        return;
                    }
                }
            }

            if (this.paymentPlan) {
                const result = await this.hlOnboardingService.getSelectedPaymentPlan(this.localizationContext.currentCurrency);

                if (result.success && result.value) {
                    const { value, currency } = result.value.adjustedRevenue;

                    this.currentEstimation = {
                        value,
                        currency,
                        type: this.revenueEstimation.result.type,
                    };

                    return;
                }
            }

            const result = await this.hlOnboardingService.getCurrentEstimation(this.localizationContext.currentCurrency);

            if (result.success && result.value) {
                const { type, value } = result.value;

                const estimationResult = MultiCurrencyMoneyHelper
                    .getCurrencyOrBase(value, this.localizationContext.currentCurrency);

                const estimationValue = type == "FOR_PERIOD" ? estimationResult.value :
                    MathUtils.round(
                        estimationResult.value * 365 / 12,
                        CURRENCY_MAX_DECIMAL_PRECISION,
                    );

                this.currentEstimation = {
                    value: estimationValue,
                    currency: estimationResult.currency,
                    type: result.value.type,
                };
            }
        }
    }

    private get isAuthenticated() {
        return this.authState.user != null;
    }

    private get steps() {
        return [
            { id: "estimate", label: this.$t("hl_registration_steps.estimate") },
            { id: "create_account", label: this.$t("hl_registration_steps.create_account") },
            { id: "dates", label: this.$t("hl_registration_steps.dates") },
            { id: "finalize", label: this.$t("hl_registration_steps.finalize") },
        ];
    }

    private shouldShowStep(step: HlRegistrationStep) {
        switch (step) {
            case "create_account":
                return !this.isAuthenticated || this.current != "estimate";

            case "estimate":
            case "dates":
            case "finalize":
                return true;
        }
    }

    private isDone(step: HlRegistrationStep) {
        return (
            this.current == "end" ||
            this.steps.findIndex(x => x.id == step) < this.steps.findIndex(x => x.id == this.current)
        );
    }
}
