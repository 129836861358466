export type AmenityId = `${string}_AMENITY`;

export const amenities: AmenityId[] = [
    "WIFI_AMENITY",
    "WASHER_AMENITY",
    "HAIRDRYER_AMENITY",
    "VACUUM_AMENITY",
    "OVEN_AMENITY",
    "FRIDGE_AMENITY",
    "IRON_AMENITY",
    "COFFEE_MACHINE_AMENITY",
    "KITCHEN_UTENSIL_AMENITY",
    "MICROWAVE_AMENITY",
    "DISHWASHER_AMENITY",
    "TV_AMENITY",
    "STOVE_AMENITY",
    "HEATER_AMENITY"
]
