import { Query, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { IsUUID } from "@leavy/validator";
import { OkVal } from "@leavy/result";
import { HlManagementPeriodId } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlManagementPeriod";
import { HlUserManagementPeriodInfo } from "../domain/HlUserManagementPeriodInfo";

export type HlUserGetManagementPeriodInfoQueryResult =
    OkVal<HlUserManagementPeriodInfo | null>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_get_management_period_info",
})
export class HlUserGetManagementPeriodInfoQuery extends Query<HlUserGetManagementPeriodInfoQueryResult> {
    @IsUUID()
    userId!: string;

    @IsUUID()
    id!: HlManagementPeriodId;
}
