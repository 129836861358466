import { ApplicationReadyHook, BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { AuthenticationStore } from "./AuthenticationStore";
import { AuthenticationStorage } from "./AuthenticationStorage";
import { CqSessionService } from "./platform/cq/CqSessionService";
import { CqPasswordService } from "./platform/cq/CqPasswordService";
import { CqAuthenticationService } from "@/modules/auth/platform/cq/CqAuthenticationService";
import { AuthenticationState } from "./AuthenticationState";
import { HOMESHARING_HTTP_CLIENT } from "@/modules/core/http";
import { AuthenticationService } from "@/modules/auth/services/AuthenticationService";
import { SessionService } from "@/modules/auth/services/SessionService";
import { PasswordService } from "@/modules/auth/services/PasswordService";
import { EmailService } from "@/modules/auth/services/EmailService";
import { CqEmailService } from "@/modules/auth/platform/cq/CqEmailService";
import { AxiosError } from "axios";
import { GlobalApplicationContext } from "@fastoche/ui-core/application/GlobalApplicationContext";

@Module()
export class AuthModule extends BaseModule {
    registerServices(container: ModuleContainer): void {
        container.bind(AuthenticationStore).toSelf().inSingletonScope();
        container.bind(AuthenticationStorage).toSelf().inSingletonScope();
        container.bind(AuthenticationService).to(CqAuthenticationService).inSingletonScope();
        container.bind(SessionService).to(CqSessionService).inSingletonScope();
        container.bind(PasswordService).to(CqPasswordService).inSingletonScope();
        container.bind(EmailService).to(CqEmailService).inSingletonScope();
    }

    async applicationReady(hook: ApplicationReadyHook): Promise<void> {
        let timeout: any | undefined;
        const store = hook.applicationContext.rootContainer.get(AuthenticationStore);
        const sessionService = hook.applicationContext.rootContainer.get(SessionService);

        const refreshSession = async () => {
            const extendsResult = await sessionService.extends();
            if (!extendsResult.success) {
                void sessionService.end();
            }
        };

        const scheduleRefresh = async (state: AuthenticationState) => {
            if (state.token && state.refreshToken) {
                const nextRefreshDelay = state.token.tokenExpirationTime - Date.now() - 120000;
                if (nextRefreshDelay < 1) {
                    await refreshSession();
                }
                else {
                    timeout = setTimeout(async () => {
                        await refreshSession();
                    }, nextRefreshDelay);
                }
            }
        };

        HOMESHARING_HTTP_CLIENT.interceptors.request.use((request) => {
            if (store.isAuthenticated()) {
                return {
                    ...request,
                    headers: {
                        Authorization: `Bearer ${store.value?.token?.bearer}`,
                    },
                };
            }

            return request;
        });

        HOMESHARING_HTTP_CLIENT.interceptors.response.use(undefined, (error?: AxiosError) => {
            if (error?.response && error.response.status === 401) {
                // Two scenarios: revoked session (if the server support access token revokation) or expired/invalid token
                // we suppose here that the user need to re authenticate.
                // If the problem comes from a missing refresh, it could be a bug on the refresh token strategy (client side indeed)
                store.clearToken();

                const currentUrl = new URL(location.href);
                const loginUrl = `/login${currentUrl.pathname && currentUrl.pathname !== "/" ? `?returnUrl=${encodeURIComponent(currentUrl.pathname)}` : ""}`;

                GlobalApplicationContext.default.router.replace(loginUrl);
            }

            return Promise.reject(error);
        });

        // schedule a refresh now in case of the token need to be refreshed immediately
        await scheduleRefresh(store.value);

        store.value$.subscribe(async state => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = undefined;
            }
            await scheduleRefresh(state);
        });
    }
}
