import { MultiCurrencyMoney } from "@leavy/lv-homesharing-pricing-srv/lib/money/domain/MultiCurrencyMoney";
import { Currency } from "./Currency";
import { Money } from "./Money";

export { MultiCurrencyMoney };

export namespace MultiCurrencyMoneyHelper {
    export function getCurrencyOrBase(multiCurrencyMoney: MultiCurrencyMoney, currency: Currency): Money {
        const { base, currencies } = multiCurrencyMoney;

        if (currencies[currency] != null) {
            return {
                value: currencies[currency]!,
                currency,
            };
        }
        else {
            return base;
        }
    }
}
