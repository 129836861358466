

































































import { Component, ModelSync, Ref } from "vue-property-decorator";
import { LvButton, LvLightContainer } from "@fastoche/ui-kit/components";
import { StepFormComponent } from "@/modules/onboarding/hl/StepForm";
import { HlAccommodationQualityModel } from "@/modules/onboarding/hl/model";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvRate from "@fastoche/ui-kit/components/rate/LvRate.vue";
import { construct } from "@leavy/utils";
import { APP_LOGGER } from "@/app/logger";

const getDefaultModel = (): HlAccommodationQualityModel => {
    return construct(HlAccommodationQualityModel, {
        standing: null!,
        luminosity: 3,
        furnishings: 3,
        bedding: 3,
        bathrooms: 3,
    });
};

export const COMPONENT_NAME = "AccommodationQualityForm";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvRate,
        LvFormItem,
        LvForm,
        LvLightContainer,
        LvButton,
    },
})
export default class AccommodationQualityForm extends StepFormComponent<HlAccommodationQualityModel> {
    @Ref()
    readonly form!: LvForm;

    @ModelSync("value", "change", { type: HlAccommodationQualityModel, required: false, default: () => getDefaultModel() })
    model!: HlAccommodationQualityModel;

    async submit() {
        if (!(await this.form.submit())) return;
        return this.model;
    }
}
