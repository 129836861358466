





































import { Component, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvContainer, LvLightContainer } from "@fastoche/ui-kit/components";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { PasswordService } from "@/modules/auth/services/PasswordService";
import { InjectService } from "@fastoche/ui-core/di";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import { IsNotEmpty, IsString, MinLength } from "@leavy/validator";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import { MessageBox } from "@fastoche/ui-kit/components";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { EqualsProperty } from "@fastoche/ui-core/validator/decorators/EqualsProperty";

class UpdateUserPasswordViewModel {
    @IsString()
    @IsNotEmpty()
    currentPassword!: string;

    @IsString()
    @IsNotEmpty()
    @MinLength(6)
    newPassword!: string;

    @IsString()
    @IsNotEmpty()
    @MinLength(6)
    @EqualsProperty("newPassword")
    confirmNewPassword!: string;
}

export const COMPONENT_NAME = "UpdateUserPasswordPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        PageHeader,
        LvButton,
        LvFormItem,
        LvField,
        LvForm,
        LvContainer,
    },
})
export default class UpdateUserPasswordPage extends BaseComponent {
    @Ref()
    form!: LvForm;

    @InjectService(PasswordService)
    passwordService!: PasswordService;

    model = new UpdateUserPasswordViewModel();

    @LoaderTask
    async onSubmit() {
        try {
            if (!(await this.form.submit())) return;

            const result = await this.passwordService.change({
                currentPassword: this.model.currentPassword,
                newPassword: this.model.newPassword,
            });

            if (result.success) {
                void MessageBox.prompt({
                    type: "success",
                    confirmText: this.$t("common.confirm"),
                    isCancellable: false,
                    title: this.$t("reset_password.success.title"),
                    message: this.$t("reset_password.success.message"),
                });
            }
            else {
                switch (result.reason) {
                    case "WRONG_CURRENT_PASSWORD": {
                        void MessageBox.prompt({
                            type: "warning",
                            confirmText: this.$t("common.confirm"),
                            isCancellable: false,
                            title: this.$t("password_form.wrong_current_password.title"),
                            message: this.$t("password_form.wrong_current_password.message"),
                        });
                    }
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
