

















































































import { Component, ModelSync, Prop } from "vue-property-decorator";
import { PaymentSchedulingOption } from "@/modules/onboarding/hl/domain";
import { LvButton, LvDatePicker, LvIcon } from "@fastoche/ui-kit/components";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { BaseComponent } from "@/app/BaseComponent";
import { HlUserPaymentPlan } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserPaymentPlan";
import * as _ from "lodash";
import { fullLocaleDate, hugeLocaleDate } from "@/app/filters/date";

export const COMPONENT_NAME = "HlUserPaymentPlanSelector";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvDatePicker,
        LvIcon,
        LvButton,
    },
    filters: {
        price,
        hugeLocaleDate,
        fullLocaleDate
    },
})
export default class HlUserPaymentPlanSelector extends BaseComponent {
    @Prop({ type: Array, required: true, default: () => [] })
    readonly paymentPlans!: HlUserPaymentPlan[];

    @ModelSync("selected", "change", { type: String })
    selectedSchedulingOption!: PaymentSchedulingOption | null;
    declare readonly selected: PaymentSchedulingOption | null;

    readonly plansDisplayOrder = [
        PaymentSchedulingOption.PERIOD_END,
        PaymentSchedulingOption.PERIOD_MIDDLE,
        PaymentSchedulingOption.PERIOD_START,
    ];

    readonly schedulesDisplayState = Object
        .values(PaymentSchedulingOption)
        .map(option => ({ option, expanded: false }));

    readonly maxDisplayedPaymentRows = 3;

    showAllPaymentRows = false;

    get orderedPaymentPlans() {
        return _.orderBy(
            this.paymentPlans,
            x => this.plansDisplayOrder.indexOf(x.schedulingOption),
            "asc"
        );
    }

    get paymentPlanIcons(): Record<PaymentSchedulingOption, string> {
        return {
            [PaymentSchedulingOption.PERIOD_START]: require("@/app/assets/emoji/high-voltage.svg"),
            [PaymentSchedulingOption.PERIOD_MIDDLE]: require("@/app/assets/emoji/winking-face.svg"),
            [PaymentSchedulingOption.PERIOD_END]: require("@/app/assets/emoji/partying-face.svg"),
        };
    }

    get paymentPlanNames(): Record<PaymentSchedulingOption, string> {
        return {
            [PaymentSchedulingOption.PERIOD_START]: this.$t("hl.payment_plan.options.period_start"),
            [PaymentSchedulingOption.PERIOD_MIDDLE]: this.$t("hl.payment_plan.options.period_middle"),
            [PaymentSchedulingOption.PERIOD_END]: this.$t("hl.payment_plan.options.period_end"),
        };
    }

    isCurrentPlan(plan: PaymentSchedulingOption) {
        return this.selectedSchedulingOption == plan;
    }

    getScheduledPayments(schedulingOption: PaymentSchedulingOption) {
        const scheduledPayments = this.paymentPlans.find(x => x.schedulingOption == schedulingOption)?.schedule ?? [];

        if (this.showAllPaymentRows) {
            return scheduledPayments;
        }
        else {
            return scheduledPayments.slice(0, this.maxDisplayedPaymentRows);
        }
    }

    selectSchedulingOption(schedulingOption: PaymentSchedulingOption) {
        const isConfirm = this.selectedSchedulingOption == schedulingOption;
        this.selectedSchedulingOption = schedulingOption;

        if (isConfirm) {
            this.$emit("confirm");
        }
    }
}
