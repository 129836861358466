import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { I18nLocalizedResourceService } from "@/modules/i18n/services/I18nLocalizedResourceService";
import { LocalizedCountries, LocalizedLanguages } from "../../domain";
import { GetLocalizedCountriesQuery, GetLocalizedLanguagesQuery } from "./query";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";

@injectable()
export class CqI18nLocalizedResourceService extends I18nLocalizedResourceService {
    constructor(
        private readonly localizationContext: LocalizationContext,
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    async getCountries(): Promise<LocalizedCountries> {
        const { currentLocale, defaultLocale } = this.localizationContext.value;

        const result = await this.cq.invoke(GetLocalizedCountriesQuery, {
            locale: currentLocale.toString(),
            fallback: defaultLocale.toString(),
        });

        if (!result.success) return {};
        else return result.value;
    }

    async getLanguages(): Promise<LocalizedLanguages> {
        const { currentLocale, defaultLocale } = this.localizationContext.value;

        const result = await this.cq.invoke(GetLocalizedLanguagesQuery, {
            locale: currentLocale.toString(),
            fallback: defaultLocale.toString(),
        });

        if (!result.success) return {};
        else return result.value;
    }
}
