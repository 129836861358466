import { injectable } from "inversify";
import { BookingRequestModel, BookingRequestSummaryResult, GetBookingsListModel, GetBookingRequestSummaryModel } from "../model";
import { ListBookingsQuery } from "@leavy/lv-homesharing-backend-srv/lib/booking/query/clients/ListBookingsQuery.client";
import { ListBookingsQueryResult } from "@leavy/lv-homesharing-backend-srv/lib/booking/query/ListBookingsQuery";
import { Booking } from "../domain";
import { Bad, OkVal } from "@leavy/result";
import { ListingBooking } from "@leavy/lv-homesharing-backend-srv/lib/booking/domain/BookedListing";

@injectable()
export abstract class BookingService {
    abstract getBookingRequestSummary(model: GetBookingRequestSummaryModel):
        Promise<OkVal<BookingRequestSummaryResult> | Bad<"USER_NOT_HL"> | Bad<"LISTING_NOT_FOUND"> | Bad<"LISTING_NOT_AVAILABLE"> | Bad<"OCCUPANCY_EXCEEDED">>;

    abstract getBookingsList(model: ListBookingsQuery):
        Promise<ListBookingsQueryResult>;

    abstract getBookingInfo(bookingId: string):
        Promise<ListingBooking | null>;

    abstract requestBooking(model: BookingRequestModel):
        Promise<OkVal<Booking> | Bad<"USER_NOT_HL"> | Bad<"DURATION_NOT_SUPPORTED"> | Bad<"LISTING_NOT_FOUND"> | Bad<"LISTING_NOT_AVAILABLE"> | Bad<"OCCUPANCY_EXCEEDED">>;
}
