import { HlPaymentSchedulingOption } from "@leavy/lv-homesharing-pricing-srv/lib/pricing/domain/HlPaymentSchedulingOption";
import { Money } from "@leavy/lv-homesharing-pricing-srv/lib/money/domain/Money";
import { HlPaymentPlan } from "@leavy/lv-homesharing-pricing-srv/lib/pricing/domain/HlPaymentPlan";

export interface HlUserPaymentPlan {
    schedulingOption: HlPaymentSchedulingOption;
    baseRevenue: Money;
    adjustedRevenue: Money;
    schedule: PaymentPlanScheduledPayment[];
}

export interface PaymentPlanScheduledPayment {
    paymentDate: string;
    amountDue: Money;
}

export namespace HlUserPaymentPlanMapper {
    export function fromPaymentPlan(plan: HlPaymentPlan): HlUserPaymentPlan {
        return {
            baseRevenue: plan.baseRevenue,
            adjustedRevenue: plan.adjustedRevenue,
            schedulingOption: plan.schedulingOption,
            schedule: plan.schedule.map(s => ({
                paymentDate: s.paymentDate,
                amountDue: s.amountDue,
            })),
        };
    }
}
