




























































import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import LoginForm from "@/app/components/auth/login/LoginForm.vue";
import RegistrationForm, { UserRegisteredEvent, UserExistsEvent } from "@/app/components/auth/register/RegistrationForm.vue";
import { LvContainer, MessageBox, LvIcon } from "@fastoche/ui-kit/components";
import HlEstimationRequestForm, { HlEstimationRequestFormViewModel } from "@/app/components/onboarding/hl/estimation/HlEstimationRequestForm.vue";
import HlRegistrationSteps from "@/app/ui/hl-registration-steps/HlRegistrationSteps.vue";
import { InjectService } from "@fastoche/ui-core/di";
import { HlPricingService } from "@/modules/pricing/services/HlPricingService";
import { HlSimpleRevenueEstimation, HlSimpleRevenueEstimationRequestModel } from "@/modules/pricing/model";
import { validate } from "@leavy/validator";
import { HlRegistrationPageQueryStringModel } from "@/modules/registration/hl/model";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { GlobalTranslationStore } from "@fastoche/ui-core/i18n/global";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { AuthenticationService } from "@/modules/auth/services/AuthenticationService";
import { APP_LOGGER } from "@/app/logger";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";
import { HlOnboardingService } from "@/modules/onboarding/hl/services/HlOnboardingService";
import { StateOf, InjectState } from "@fastoche/ui-core/store";
import { HlOnboardingStore } from "@/modules/onboarding/hl/HlOnboardingStore";

export const COMPONENT_NAME = "HlRegistrationPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        RegistrationForm,
        LoginForm,
        LvContainer,
        HlEstimationRequestForm,
        HlRegistrationSteps,
        LvIcon,
    },
    filters: {
        price,
    },
})
export default class HlRegistrationPage extends BaseComponent {
    @InjectService(HlPricingService)
    private readonly pricingService!: HlPricingService;

    @InjectService(AuthenticationService)
    private readonly authService!: AuthenticationService;

    @InjectService(AuthenticationStore)
    private readonly authStore!: AuthenticationStore;

    @InjectService(HlOnboardingService)
    private readonly hlOnboardingService!: HlOnboardingService;

    @InjectState(HlOnboardingStore)
    private readonly hlOnboardingState!: StateOf<HlOnboardingStore>;

    private estimationRequest: HlSimpleRevenueEstimationRequestModel | null = null;
    private estimationResult: HlSimpleRevenueEstimation | null = null;
    private registerOrLogin: "register" | "login" = "register";
    private existingUserEmail: string | null = null;
    private referrerCode: string | null = null;

    private get registrationStep() {
        return this.estimationResult ? "create_account" : "estimate";
    }

    private mounted() {
        void this.initialize();
    }

    @LoaderTask
    private async initialize() {
        const queryString = this.getValidatedQueryString();

        if (queryString?.estimation) {
            this.estimationRequest = Object.assign(new HlEstimationRequestFormViewModel(), {
                ...queryString.estimation,
            });
        }

        if (queryString?.referrerCode) {
            this.referrerCode = queryString.referrerCode;
        }

        if (this.authStore.isAuthenticated()) {
            await this.onUserAlreadyLoggedIn();
        }
        else {
            if (this.estimationRequest) {
                await this.loadPricingResult();
            }
            else {
                this.estimationRequest = new HlEstimationRequestFormViewModel();
            }
        }
    }

    @LoaderTask
    private async loadPricingResult() {
        if (!this.estimationRequest) return;

        try {
            const pricingResult = await this.pricingService.getPricingEstimation({
                ...this.estimationRequest,
            });

            if (pricingResult.success) {
                this.estimationResult = pricingResult.value;
            }
            else {
                void MessageBox.prompt({
                    type: "warning",
                    confirmText: GlobalTranslationStore.$t("common.i_understand"),
                    isCancellable: false,
                    title: GlobalTranslationStore.$t("hl_estimation.error.title"),
                    message: GlobalTranslationStore.$t("hl_estimation.error.message"),
                });
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }

        window.scrollTo({ top: 0 });
    }

    private getValidatedQueryString() {
        const validationResult = validate(HlRegistrationPageQueryStringModel, this.$route.query);

        if (validationResult.success) {
            return validationResult.value;
        }
        else {
            return null;
        }
    }

    private async redirectUserToOnboarding(toEstimation: boolean) {
        if(toEstimation) {
            await this.$router.replace({
                path: "/app/happy-leaver/onboarding/steps/estimation",
                query: {
                    estimation: { ...this.estimationRequest } as any,
                },
            }).catch(this.handleNavigationError);
        }
        else {
            await this.$router.replace("/app/happy-leaver/onboarding").catch(this.handleNavigationError);
        }
    }

    private setRegisterOrLogin(value: "register" | "login") {
        this.registerOrLogin = value;
        window.scrollTo({ top: 0 });
    }

    @LoaderTask
    private async onUserAlreadyLoggedIn() {
        await this.hlOnboardingService.ensureStore();

        if (this.hlOnboardingState) {
            const toEstimation = !this.hlOnboardingState.onboarding.revenueEstimation.isComplete();
            await this.redirectUserToOnboarding(toEstimation);
        }
        else {
            if (this.estimationRequest) {
                await this.loadPricingResult();
            }
            else {
                this.estimationRequest = new HlEstimationRequestFormViewModel();
            }
        }
    }

    private onUserExists(ev: UserExistsEvent) {
        this.existingUserEmail = ev.email;
        this.setRegisterOrLogin("login");
    }

    @LoaderTask
    private async onEstimationFormSubmit() {
        await this.loadPricingResult();
    }

    private onRegisterFormSubmit() {
        void this.$track("HlRegisterSubmitEvent");
    }

    @LoaderTask
    private async onUserRegistered(ev: UserRegisteredEvent) {
        try {
            const authenticationResult = await this.authService.authenticate({
                email: ev.credentials.email,
                password: ev.credentials.password,
            });

            if (!authenticationResult.success) {
                throw new Error(`Authentication failed with reason: ${authenticationResult.reason}`);
            }
            else {
                void this.onUserLoggedIn();
            }
        }
        catch (e) {
            APP_LOGGER.warn("Authentication failed just after user registration", e);

            this.existingUserEmail = ev.credentials.email;
            this.registerOrLogin = "login";
        }
    }

    @LoaderTask
    private async onUserLoggedIn() {
        await this.hlOnboardingService.ensureStore();

        if (this.hlOnboardingState) {
            const toEstimation = !this.hlOnboardingState.onboarding.revenueEstimation.isComplete();
            await this.redirectUserToOnboarding(toEstimation);
        }
        else {
            const startOnboardingResult = await this.hlOnboardingService.startOnboarding();
            const isStarted = startOnboardingResult.success || startOnboardingResult.reason == "ONBOARDING_ALREADY_STARTED";

            if (isStarted) {
                await this.redirectUserToOnboarding(true);
            }
            else {
                await this.$router.replace("/app").catch(this.handleNavigationError);
            }
        }
    }
}
