import { Command, RpcInvocable } from "@leavy/cq-core";
import { Bad, Ok } from "@leavy/result";
import { IsString } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

export type LogoutCommandResult =
    | Ok
    | Bad<"logout_failed">
    ;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "logout",
})
export class LogoutCommand extends Command<LogoutCommandResult, never> {
    @IsString()
    deviceId!: string;

    @IsString()
    clientId!: string;

    @IsString()
    accessToken!: string;
}
