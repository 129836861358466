import { BuildClientInvocation, UserIdAmbientValue } from "@leavy/cq-core";
import {
    GetHlOnboardingCurrentRevenueEstimationQuery as _GetHlOnboardingCurrentRevenueEstimationQuery,
    GetHlOnboardingPossiblePaymentPlansQuery as _GetHlOnboardingPossiblePaymentPlansQuery,
    GetHlOnboardingSelectedPaymentPlanQuery as _GetHlOnboardingSelectedPaymentPlanQuery,
} from "@leavy/lv-onboarding-srv/lib/hl/query";

export class GetHlOnboardingCurrentRevenueEstimationQuery extends BuildClientInvocation({
    type: _GetHlOnboardingCurrentRevenueEstimationQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class GetHlOnboardingPossiblePaymentPlansQuery extends BuildClientInvocation({
    type: _GetHlOnboardingPossiblePaymentPlansQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class GetHlOnboardingSelectedPaymentPlanQuery extends BuildClientInvocation({
    type: _GetHlOnboardingSelectedPaymentPlanQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
