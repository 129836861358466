





















import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { UserOnboardingStatus } from "@/modules/onboarding/common/domain";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { LvContainer, LvIcon } from "@fastoche/ui-kit/components";

export const COMPONENT_NAME = "HodOnboardingAcceptedPage";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvIcon,
        LvContainer
    }
})
export default class HodOnboardingAcceptedPage extends BaseHodOnboardingPage {
    @InjectState(HodOnboardingStore)
    hodState!: NonNullable<StateOf<HodOnboardingStore>>;

    created() {
        if (!this.hodState || this.hodState.status != UserOnboardingStatus.ACCEPTED) {
            void this.$router.replace("/app").catch(this.handleNavigationError);
            return;
        }
    }
}
