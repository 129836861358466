import { Command, RpcInvocable } from "@leavy/cq-core";
import { Bad, Ok, OkVal } from "@leavy/result";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { IsEmail, IsNotEmpty, IsString, LowerCase, Trim } from "@leavy/validator";
import { CommonBadResult } from "../results";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

export type VerifyEmailCommandBadResult = "email_verification_failed" | CommonBadResult.USER_NOT_FOUND;

export type VerifyEmailCommandResult = Ok | Bad<VerifyEmailCommandBadResult>;

/**
 * VerifyEmailCommand command description
 */
@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "verify_email",
})
export class VerifyEmailCommand extends Command<VerifyEmailCommandResult, EmailVerifiedEvent> {
    @IsEmail()
    @Trim()
    @LowerCase()
    email!: string;

    @IsString()
    @IsNotEmpty()
    code!: string;
}

@DomainEvent({ namespace: HOMESHARING_BACKEND_SERVICE_NAME, name: "email_verified", version: 1 })
export class EmailVerifiedEvent extends DomainEventBase {
    @EventProperty()
    userId!: string;

    @EventProperty()
    email!: string;

    constructor(data: PayloadOf<EmailVerifiedEvent>) {
        super(data);
    }
}

