





















































































import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { InjectService } from "@fastoche/ui-core/di";
import { HlService } from "@/modules/hl/services/HlService";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { HlUserAccommodationStore } from "@/modules/hl/stores/HlUserAccommodationStore";
import { HlUserAccommodation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodation";
import { LvButton, LvContainer, LvIcon, LvTag } from "@fastoche/ui-kit";
import { FileService } from "@/modules/file/services/FileService";
import { hugeLocaleDate } from "@/app/filters/date";
import { HlUserManagementPeriod } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriod";
import { HlUserPayment } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserPayment";
import { ManagementPeriodPaymentStatus } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlManagementPeriodPayment";

export const COMPONENT_NAME = "HlPeriodViewPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        PageHeader,
        LvIcon,
        LvContainer,
        LvButton,
        LvTag,
    },
    filters: {
        price,
        hugeLocaleDate,
    },
})
export default class HlPeriodViewPage extends BaseComponent {
    @InjectService(HlService)
    readonly hlService!: HlService;

    @InjectService(HlUserAccommodationStore)
    readonly hlUserAccommodationStore!: HlUserAccommodationStore;

    @InjectService(FileService)
    readonly fileService!: FileService;

    @Prop({ type: String, required: true })
    readonly id!: string;

    private period: HlUserManagementPeriod | null = null;
    private accommodation: HlUserAccommodation | null = null;
    private schedule: HlUserPayment[] = [];

    mounted() {
        void this.initialize();
    }

    get coverPhoto() {
        if (!this.accommodation) return;
        const [firstPhoto] = this.accommodation.photos;
        if (!firstPhoto) return;

        return this.fileService.getFileUrl(firstPhoto).url;
    }

    @LoaderTask
    async initialize() {
        const info = await this.hlService.getUserManagementPeriodInfo(this.id);
        if (info == null) {
            return await this.redirectNotFound();
        }

        const { period, schedule } = info;

        const accommodation = await this.hlUserAccommodationStore.getUserAccommodationById(period.accommodationId);
        if (accommodation == null) {
            return await this.redirectNotFound();
        }

        this.period = period;
        this.schedule = schedule;
        this.accommodation = accommodation as HlUserAccommodation;
    }

    getPaymentStatusColor(payment: HlUserPayment) {
        switch (payment.status) {
            case ManagementPeriodPaymentStatus.CANCELED:
                return "danger";

            case ManagementPeriodPaymentStatus.EXECUTED:
                return "success";

            case ManagementPeriodPaymentStatus.SCHEDULED:
                return "primary";
        }
    }
}
