import { injectable } from "inversify";
import { HlUserAccommodation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodation";
import { Currency } from "@fastoche/ui-core/i18n/currency/Currency";
import { Bad, BadVal, Ok, OkVal } from "@leavy/result";
import { HlUserAccommodationPeriodEstimation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodationPeriodEstimation";
import { HlPaymentSchedulingOption } from "@leavy/lv-homesharing-pricing-srv/lib/pricing/domain/HlPaymentSchedulingOption";
import { PaginatedResult } from "@leavy/lv-homesharing-srv/lib/common/domain/Pagination";
import { HlUserManagementPeriodRequest } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriodRequest";
import { PaginationOptionsModel } from "@leavy/lv-homesharing-srv/lib/common/model/PaginationOptionsModel";
import { HlUserManagementSummary } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementSummary";
import { HlUserManagementPeriod } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriod";
import { HlUserPayment } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserPayment";
import { HlUserManagementPeriodInfo } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriodInfo";
import { HlAccommodationManagementAvailability } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlAccommodation";

@injectable()
export abstract class HlService {
    abstract getUserAccommodations(): Promise<HlUserAccommodation[]>;

    abstract getUserManagementPeriodRequests(pagination: PaginationOptionsModel): Promise<PaginatedResult<HlUserManagementPeriodRequest>>;

    abstract getUserManagementPeriods(pagination: PaginationOptionsModel): Promise<PaginatedResult<HlUserManagementPeriod>>;

    abstract getUserPayments(pagination: PaginationOptionsModel): Promise<PaginatedResult<HlUserPayment>>;

    abstract getUserManagementPeriodRequestById(id: string, currency?: Currency): Promise<HlUserManagementPeriodRequest | null>;

    abstract getUserManagementPeriodInfo(id: string): Promise<HlUserManagementPeriodInfo | null>;

    abstract getUserManagementSummary(): Promise<HlUserManagementSummary>;

    abstract getPeriodEstimationForUserAccommodation(
        accommodationId: string,
        departureDate: string,
        returnDate: string,
        currency?: Currency,
    ): Promise<OkVal<HlUserAccommodationPeriodEstimation> | Bad<"ACCOMMODATION_NOT_FOUND"> | Bad<"INVALID_LOCATION"> | Bad<"ESTIMATION_FAILED">>;

    abstract requestNewManagementPeriod(
        accommodationId: string,
        departureDate: string,
        returnDate: string,
        paymentSchedulingOption: HlPaymentSchedulingOption,
    ): Promise<OkVal<{ managementPeriodRequestId: string }>
        | Bad<"ACCOMMODATION_NOT_FOUND">
        | Bad<"INVALID_MANAGEMENT_PERIOD_DURATION">
        | Bad<"INVALID_SCHEDULING_OPTION_FOR_MANAGEMENT_PERIOD">
        | BadVal<"MANAGEMENT_PERIOD_DATES_CONFLICT", HlAccommodationManagementAvailability>>;

    abstract cancelManagementPeriodRequest(managementPeriodRequestId: string): Promise<void>;

    abstract acceptManagementPeriodRequestPriceProposal(managementPeriodRequestId: string):
        Promise<Ok
            | Bad<"MANAGEMENT_PERIOD_REQUEST_NOT_FOUND">
            | Bad<"MANAGEMENT_PERIOD_REQUEST_WRONG_STATE">
            | Bad<"PRICE_PROPOSAL_EXPIRED">
            | BadVal<"MANAGEMENT_PERIOD_DATES_CONFLICT", HlAccommodationManagementAvailability>>;

    abstract rejectManagementPeriodRequestPriceProposal(managementPeriodRequestId: string):
        Promise<Ok
            | Bad<"MANAGEMENT_PERIOD_REQUEST_NOT_FOUND">
            | Bad<"MANAGEMENT_PERIOD_REQUEST_WRONG_STATE">
            | Bad<"PRICE_PROPOSAL_EXPIRED">>;
}
