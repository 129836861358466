








































import { Component, Prop, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvBadge, LvButton, LvControlPopover, LvIcon } from "@fastoche/ui-kit/components";
import { PriceRange } from "@/modules/listing/model";
import { ListingFilterContext } from "@/modules/listing/context/ListingFilterContext";

export const COMPONENT_NAME = "ListingPriceFilter";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvControlPopover,
        LvButton,
        LvBadge,
        LvIcon
    },
})
export default class ListingPriceFilter extends BaseComponent {
    @Prop({ type: Object, required: true })
    readonly filters!: ListingFilterContext;

    @Ref()
    readonly popover!: LvControlPopover;

    get hasValue() {
        return this.filters.priceRange != undefined;
    }

    set(type: PriceRange) {
        this.filters.priceRange = type;
    }

    isSelected(type: PriceRange) {
        return this.filters.priceRange == type;
    }

    show() {
        this.popover.show();
    }

    hide() {
        this.popover.hide();
    }

    reset() {
        this.filters.priceRange = undefined;
        this.hide();
    }
}
