import { UserOnboardingState } from "../common/UserOnboardingState";
import { HL_ONBOARDING_WORKFLOW } from "./workflow";
import { AnyHlOnboardingStepId, HlUserOnboarding } from "./domain";

export class HlOnboardingState extends UserOnboardingState<HlUserOnboarding, AnyHlOnboardingStepId> {
    constructor(
        onboarding: HlUserOnboarding,
    ) {
        super(onboarding, HL_ONBOARDING_WORKFLOW);
    }
}
