




















import { Component, Ref, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { LocaleObject } from "@fastoche/ui-core/i18n/locale/Locale";
import { InjectService } from "@fastoche/ui-core/di";
import LvDropdown from "@fastoche/ui-kit/components/dropdown/LvDropdown.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import { LvButton, LvButtonSize } from "@fastoche/ui-kit/components";

export const COMPONENT_NAME = "LocaleSelector";

@Component({
    name: COMPONENT_NAME,
    components: { LvIcon, LvDropdown, LvButton },
})
export default class LocaleSelector extends BaseComponent {
    @Prop({ type: String, required: false, default: null })
    readonly size!: LvButtonSize | null;

    @Ref()
    readonly dropdown!: LvDropdown;

    @InjectState(LocalizationContext)
    readonly state!: StateOf<LocalizationContext>;

    @InjectService(LocalizationContext)
    readonly context!: LocalizationContext;

    get selectableLocales() {
        return this.state.wellKnownLocales;
    }

    get current() {
        return this.state.wellKnownLocales.find(x => x.equals(this.state.currentLocale))!;
    }

    getFlag(locale: LocaleObject) {
        if (!locale.countryCode) return undefined;
        return require(`@/app/assets/flags/${locale.countryCode}.png`);
    }

    async setLocale(locale: LocaleObject) {
        this.context.setCurrentLocale(locale);
        this.dropdown.hide();
    }
}
