import { injectable } from "inversify";
import { MutableStore } from "@fastoche/ui-core/store";
import { ListingPrice } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/Listing";
import { BookingQuoteViewModel } from "@/modules/booking/model/BookingQuoteViewModel";

export interface BookingState {
    bookingQuote: BookingQuoteViewModel | null;
    bookingPrice: ListingPrice | null;
    bookingDuration: number;
}

@injectable()
export class BookingStore extends MutableStore<BookingState> {

    public reverse = false;

    constructor() {
        super({
            bookingQuote: null,
            bookingPrice: null,
            bookingDuration: 1,
        });
    }

    setBookingQuote(model: BookingQuoteViewModel | null) {
        this.mutate(currentState => {
            return {
                ...currentState,
                bookingQuote: model,
            };
        });
    }

    setBookingPrice(price: ListingPrice | null) {
        this.mutate(currentState => {
            return {
                ...currentState,
                bookingPrice: price,
            };
        });
    }

    setBookingDuration(duration: number) {
        this.mutate(currentState => {
            return {
                ...currentState,
                bookingDuration: duration,
            };
        });
    }
}
