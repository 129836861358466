import { Command, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { Bad, Ok } from "@leavy/result";
import { IsUUID } from "@leavy/validator";

export type HlUserCancelManagementPeriodRequestCommandResult =
    | Ok
    | Bad<"MANAGEMENT_PERIOD_REQUEST_NOT_FOUND">
    | Bad<"MANAGEMENT_PERIOD_REQUEST_WRONG_STATE">;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_cancel_management_period_request"
})
export class HlUserCancelManagementPeriodRequestCommand extends Command<HlUserCancelManagementPeriodRequestCommandResult> {
    @IsUUID()
    userId!: string;

    @IsUUID()
    managementPeriodRequestId!: string;
}
