import { Class, MetaType, SYM_META_TYPE } from "@leavy/static-land";
import { AmbientValuesMap, ClientInvocationDescriptor, CommandResult, readInvocationSettings, RpcInvocable, writeClientInvocationDescriptor } from "@leavy/cq-core";
import { StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { Bad } from "@leavy/result";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../base";
import { assert, ObjectUtils } from "@leavy/utils";
import { BaseOnboardingCommand } from "@leavy/lv-onboarding-srv/lib/common/command";

type OverwriteMetaType<THolder extends MetaType<any>, TNewType> =
    Omit<THolder, typeof SYM_META_TYPE> & MetaType<TNewType>;

type AugmentedCommand<T extends StepCommand<any, any>> =
    OverwriteMetaType<T, { O: CommandResult<T> | CommonEditOnboardingError; E: never }>;

export type ForwardedOnboardingCommandClass<TTarget extends StepCommand<any, any>, TAmbientValues extends AmbientValuesMap<TTarget>> =
    Class<Omit<AugmentedCommand<TTarget>, keyof TAmbientValues>>
    & { Remote: Class<TTarget> }
    & { Local: Class<AugmentedCommand<TTarget>> };

export type LocalOf<T extends ForwardedOnboardingCommandClass<any, any>> = InstanceType<T['Local']>;

export type RemoteOf<T extends ForwardedOnboardingCommandClass<any, any>> = InstanceType<T['Remote']>;

export type CommonEditOnboardingError =
    | Bad<"ONBOARDING_NOT_FOUND">
    | Bad<"ONBOARDING_WRONG_STATE">;

export function ForwardedOnboardingCommand<TTarget extends BaseOnboardingCommand<any, any>, TAmbientValues extends AmbientValuesMap<TTarget>>(descriptor: ClientInvocationDescriptor<TTarget, TAmbientValues>) {
    const RemoteCommand = descriptor.type;

    const baseInvocationSettings = readInvocationSettings(RemoteCommand);
    assert(baseInvocationSettings != null);

    const [namespace, alias] = baseInvocationSettings.name.split(".");
    assert(!!namespace && !!alias);

    const className = RemoteCommand.name;

    const localCommandHolder = {
        [className]: (class extends RemoteCommand {}),
    };

    const LocalCommand = localCommandHolder[className]!;
    type LocalCommandClass = Class<AugmentedCommand<TTarget>>;

    RpcInvocable({
        namespace: HOMESHARING_BACKEND_SERVICE_NAME,
        alias,
    })(LocalCommand);

    const clientCommandHolder = {
        [className]: (class extends LocalCommand {}),
    };

    type ClientCommandClass = Class<Omit<InstanceType<LocalCommandClass>, keyof TAmbientValues>>;
    const ClientCommand = clientCommandHolder[className] as ClientCommandClass;

    ObjectUtils.defineField(ClientCommand, "Local", { value: LocalCommand as LocalCommandClass });
    ObjectUtils.defineField(ClientCommand, "Remote", { value: RemoteCommand });

    writeClientInvocationDescriptor(ClientCommand, {
        type: LocalCommand,
        ambientValues: descriptor.ambientValues,
    });

    return ClientCommand as ForwardedOnboardingCommandClass<TTarget, TAmbientValues>;
}
