import { BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { CheckoutService } from "@/modules/checkout/services/CheckoutService";
import { CqCheckoutService } from "@/modules/checkout/platform/CqCheckoutService";

@Module()
export class CheckoutModule extends BaseModule {
    registerServices(container: ModuleContainer) {
        container.bind(CheckoutService).to(CqCheckoutService).inSingletonScope();
    }
}
