import { BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { ListingService } from "@/modules/listing/services/ListingService";
import { CqListingService } from "@/modules/listing/platform/cq/CqListingService";
import { ListingSearchContextStore } from "@/modules/listing/context/ListingSearchContextStore";
import { ListingHotelService } from "@/modules/listing/services/ListingHotelService";
import { CqListingHotelService } from "@/modules/listing/platform/cq/CqListingHotelService";
import { ListingHouseService } from "@/modules/listing/services/ListingHouseService";
import { CqListingHouseService } from "@/modules/listing/platform/cq/CqListingHouseService";
import { ListingStore } from "./ListingStore";

@Module()
export class ListingModule extends BaseModule {
    registerServices(container: ModuleContainer) {
        container.bind(ListingSearchContextStore).toSelf().inSingletonScope();
        container.bind(ListingStore).toSelf().inSingletonScope();
        container.bind(ListingService).to(CqListingService).inSingletonScope();
        container.bind(ListingHotelService).to(CqListingHotelService).inSingletonScope();
        container.bind(ListingHouseService).to(CqListingHouseService).inSingletonScope();
    }
}
