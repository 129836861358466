










































import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import BookingPriceExplainedPopup from "@/app/ui/booking-price-explained-popup/BookingPriceExplainedPopup.vue";
import {
    LvButton,
    LvCard,
    LvDateRangePicker,
    LvField,
    LvForm,
    LvFormItem,
    LvIcon,
    LvNumberInput,
    MessageBox,
} from "@fastoche/ui-kit/components";
import { ListingSearchContext } from "@/modules/listing/context/ListingSearchContext";
import { DateTime } from "luxon";
import { ListingPriceQueryResult } from "@/modules/listing/model";
import { BookingQuoteViewModel } from "@/modules/booking/model/BookingQuoteViewModel";
import { LvDateRangePickerInterval } from "@fastoche/ui-kit/components/date-range-picker/model";
import { ListingSearchContextStore } from "@/modules/listing/context/ListingSearchContextStore";
import { InjectService } from "@fastoche/ui-core/di";
import { ListingHouseService } from "@/modules/listing/services/ListingHouseService";
import { ListingHotelService } from "@/modules/listing/services/ListingHotelService";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { BookingStore } from "@/modules/booking/BookingStore";
import { AccommodationType } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/AccommodationType";
import { ListingStore } from "@/modules/listing/ListingStore";

export const COMPONENT_NAME = "BookingForm";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        BookingPriceExplainedPopup,
        LvCard,
        LvForm,
        LvFormItem,
        LvNumberInput,
        LvDateRangePicker,
        LvButton,
        LvField,
        LvIcon,
    },
})
export default class BookingForm extends BaseComponent {
    @InjectState(ListingSearchContextStore)
    readonly searchContext!: ListingSearchContext;

    @InjectService(BookingStore)
    readonly bookingStore!: BookingStore;
    @InjectState(BookingStore)
    readonly bookingState!: StateOf<BookingStore>;

    @InjectService(ListingStore)
    readonly listingStore!: ListingStore;
    @InjectState(ListingStore)
    readonly listingState!: StateOf<ListingStore>;

    @InjectState(LocalizationContext)
    readonly localizationContext!: StateOf<LocalizationContext>;

    @InjectService(ListingHouseService)
    private readonly listingHouseService!: ListingHouseService;

    @InjectService(ListingHotelService)
    private readonly listingHotelService!: ListingHotelService;

    @Prop({ type: String, required: true })
    readonly listingId!: string;

    @Prop({ type: String, required: true })
    readonly type!: AccommodationType;

    @Ref()
    readonly quoteForm!: LvForm;

    loading = true;

    readonly minBookingDuration: LvDateRangePickerInterval = { value: 1, unit: "days" };
    readonly minDate = DateTime.local().startOf("day").plus({ day: 1 }).toISO();

    model = new BookingQuoteViewModel();
    bookingDuration = 0;
    result: ListingPriceQueryResult | null = null;

    mounted() {
        this.model.from = this.searchContext.from ?? null;
        this.initFormModel();
    }

    initFormModel() {
        if (this.searchContext.from && this.searchContext.to) {
            this.model.from = this.searchContext.from;
            this.model.to = this.searchContext.to;
            this.model.dates = [this.model.from, this.model.to];

            this.formSubmit();
        }
    }

    @Watch("model.from")
    @Watch("model.to")
    onChangeDates() {
        this.getBookingDuration();
    }

    @Watch("model.from")
    @Watch("model.to")
    @Watch("model.guests")
    checkAccommodationAvailability() {
        if (this.model.from && this.model.to && this.model.guests) {
            this.formSubmit();
        }
    }

    getBookingDuration() {
        if (this.model.from && this.model.to) {
            const from = DateTime.fromISO(this.model.from).startOf("day");
            const to = DateTime.fromISO(this.model.to).startOf("day");

            this.bookingDuration = to.diff(from, "days").days;
        }
        else {
            this.bookingDuration = 0;
        }
    }

    async formSubmit() {
        this.bookingStore.setBookingQuote(this.model);

        if (this.type == "HOTEL") {
            await this.getAvailableRooms(this.model);
        }
        else if (this.type == "HOUSE") {
            await this.listingPrice(this.model);
        }
    }

    async listingPrice(booking: BookingQuoteViewModel) {
        if (booking.from && booking.to) {
            const result = await this.listingHouseService.getHouseListingPrice(this.listingId, booking, this.localizationContext.currentCurrency);

            if (result.success) {
                this.bookingStore.setBookingPrice(result.value.price);
                this.listingStore.setAvailability(true);
            }
            else {
                switch (result.reason) {
                    case "NOT_AVAILABLE":
                        this.listingStore.setAvailability(false);
                        this.bookingStore.setBookingPrice(null);
                        return;
                }
            }
        }
    }

    async getAvailableRooms(booking) {
        this.listingStore.setRoomsLoadingResult(true);

        const result = await this.listingHotelService.getHotelRooms(this.listingId, booking, this.localizationContext.currentCurrency);

        this.$emit("scroll-to-rooms-results");

        if (result.success) {
            this.listingStore.setRoomsLoadingResult(false);
            this.listingStore.setRooms(result.value);

            if (result.value.length == 0) {
                this.listingStore.setAvailability(false);
            }
            else if (this.listingState.room) {
                let roomSelectedStillAvailable = false;

                for (let room of result.value) {
                    if (room.roomId.split("_")[0] == this.listingState.selectedRoomId) {
                        this.listingStore.setRoom(room);
                        this.bookingStore.setBookingPrice(room.price);
                        this.listingStore.setAvailability(true);
                        roomSelectedStillAvailable = true;
                    }
                }

                if (!roomSelectedStillAvailable) {
                    this.listingStore.setAvailability(false);
                }
            }
        }
        else {
            this.listingStore.setRoomsLoadingResult(false);

            switch (result.reason) {
                case "INVALID_DATE_INTERVAL":
                    this.listingStore.setRooms(null);
                    this.listingStore.setRoom(null);
                    this.bookingStore.setBookingPrice(null);
                    return;
            }
        }
    }
}
