import { Equals, IsDefined } from "@leavy/validator";
import { OnboardingId } from "@leavy/lv-onboarding-srv/lib/common/domain/OnboardingId";
import { Bad } from "@leavy/result";
import { BinaryFile, RpcInvocable } from "@leavy/cq-core";
import { StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { FileUploadResult } from "../../common/FileUploadResult";
import { BaseFile } from "../../../common/BaseFile";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../../base";
import { CommonEditOnboardingError } from "../../ForwardedOnboardingCommand";

export type SaveAccommodationPhotoStepLocalCommandResult =
    | FileUploadResult
    | Bad<"PHOTO_ALREADY_ADDED">
    | CommonEditOnboardingError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "save_accommodation_photo_step",
})
export class SaveAccommodationPhotoStepLocalCommand extends StepCommand<SaveAccommodationPhotoStepLocalCommandResult> {
    @Equals(OnboardingId.HL)
    onboardingId: OnboardingId.HL = OnboardingId.HL;

    @BinaryFile()
    @IsDefined()
    photo!: BaseFile;
}


