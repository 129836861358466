import { RouteConfig } from "vue-router";
import { applyCategoryMeta } from "@fastoche/ui-tracking/core/router/applyCategoryMeta";
import ChatPage from "@/app/pages/customer-service/ChatPage.vue";

export const CUSTOMER_SERVICE_ROUTES: RouteConfig[] = [
    {
        path: "",
        name: "ChatPage",
        component: ChatPage,
    },
].map(route => applyCategoryMeta("CustomerService", route));
