import { RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../../base";
import { CommonEditOnboardingError } from "../../ForwardedOnboardingCommand";
import { StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { Equals, IsUUID } from "@leavy/validator";
import { Bad, Ok } from "@leavy/result";
import { OnboardingId } from "@leavy/lv-onboarding-srv/lib/common/domain/OnboardingId";

export type DeleteAccommodationPhotoLocalCommandResult =
    | Ok
    | Bad<"FILE_NOT_FOUND">
    | CommonEditOnboardingError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "delete_accommodation_photo",
})
export class DeleteAccommodationPhotoLocalCommand extends StepCommand<DeleteAccommodationPhotoLocalCommandResult> {
    @Equals(OnboardingId.HL)
    override onboardingId: OnboardingId.HL = OnboardingId.HL;

    @IsUUID("4")
    fileId!: string;
}
