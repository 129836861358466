





















import { Component } from "vue-property-decorator";
import { LvContainer } from "@fastoche/ui-kit/components";
import { InjectService } from "@fastoche/ui-core/di";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import QuizForm from "../../../components/onboarding/hod/vetting/QuizForm.vue";
import { QuizModel } from "@/modules/onboarding/hod/model/vetting/model";
import { HodOnboardingService } from "@/modules/onboarding/hod/services/HodOnboardingService";
import { CommonErrorDialogs } from "../../../ui/error/CommonErrorDialogs";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { AnyHodOnboardingStepId } from "@/modules/onboarding/hod/domain";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import LvProgressBar from "@fastoche/ui-kit/components/progress-bar/LvProgressBar.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";

const schema = require("@/modules/onboarding/hod/model/vetting/schema.json");

export const COMPONENT_NAME = "OnboardingVettingPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvButton,
        LvProgressBar,
        PageHeader,
        QuizForm,
        LvContainer,
    },
})
export default class OnboardingVettingPage extends BaseHodOnboardingPage {
    @InjectState(HodOnboardingStore)
    hodState!: StateOf<HodOnboardingStore>;

    @InjectService(HodOnboardingStore)
    hodStore!: HodOnboardingStore;

    @InjectService(HodOnboardingService)
    hodService!: HodOnboardingService;

    form = new QuizModel();
    schema = schema;
    canNext = false;

    onSubmit() {
        this.canNext = true;
        void this.nextStep();
    }

    get progress() {
        return Math.round(
            (this.form.answers.filter((x) => x.answer.length > 0).length / this.schema.questions.length) * 100,
        );
    }

    @LoaderTask
    async nextStep() {
        try {
            const result = await this.hodService.submitGetToKnowUsStep();

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
            else {
                const nextPage = this.hodStore.getNextStepLocation(AnyHodOnboardingStepId.HOD_GET_TO_KNOW_US);
                await this.$router.push(nextPage).catch(this.handleNavigationError);
            }
        }
        catch(e) {
            this.catchAndNotifyError(e);
        }
    }
}
