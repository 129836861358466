import { ApplicationReadyHook, BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { UtmParametersStore } from "@/modules/marketing/utm/UtmParametersStore";

@Module()
export class MarketingModule extends BaseModule {
    registerServices(container: ModuleContainer): void | Promise<void> {
        container.bind(UtmParametersStore).toSelf().inSingletonScope();
    }

    applicationReady(hook: ApplicationReadyHook): void | Promise<void> {
        const { rootContainer } = hook.applicationContext;

        const utmParametersStore = rootContainer.get(UtmParametersStore);
        utmParametersStore.snapshotFromCurrentUrl();
    }
}
