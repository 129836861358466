



































import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { FavoriteFlatType } from "@/modules/onboarding/hod/domain";
import { HodMatchingSubstep, MatchingDataModel } from "@/modules/onboarding/hod/model";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";

type DifficultyLevel = 1 | 2 | 3;

const FLAT_PHOTOS: Record<FavoriteFlatType, string> = {
    [FavoriteFlatType.MINIMALIST]: require("@/app/assets/flats/flat_minimalist.jpg"),
    [FavoriteFlatType.FAMILY]: require("@/app/assets/flats/flat_family.jpg"),
    [FavoriteFlatType.BIG_HOUSES]: require("@/app/assets/flats/flat_big.jpg"),
};

const FLAT_DIFFICULTY_LEVELS: Record<FavoriteFlatType, DifficultyLevel> = {
    [FavoriteFlatType.MINIMALIST]: 1,
    [FavoriteFlatType.FAMILY]: 1,
    [FavoriteFlatType.BIG_HOUSES]: 3,
};

export const COMPONENT_NAME = "OnboardingMatchingFavoriteFlatsPage";

@Component({
    name: COMPONENT_NAME,
    components: { LvIcon, LvButton },
    loader: true,
})
export default class OnboardingMatchingFavoriteFlatsPage extends BaseHodOnboardingPage {
    choices: FavoriteFlatType[] = Object.values(FavoriteFlatType);
    selected: FavoriteFlatType[] = [];

    @Prop({ type: Object })
    matchingData!: MatchingDataModel;

    mounted() {
        if (this.matchingData.favoriteFlats != undefined) {
            this.selected.push(...this.matchingData.favoriteFlats);
        }
    }

    getFlatPhoto(choice: FavoriteFlatType) {
        return FLAT_PHOTOS[choice];
    }

    getDifficultyLevel(choice: FavoriteFlatType) {
        return FLAT_DIFFICULTY_LEVELS[choice];
    }

    getDifficultyLevelIcon(choice: FavoriteFlatType) {
        const lvl = this.getDifficultyLevel(choice);

        switch (lvl) {
            case 1:
                return require("@/app/assets/icons/level_1.svg");

            case 3:
                return require("@/app/assets/icons/level_3.svg");

            default:
                return undefined;
        }
    }

    isSelected(choice: FavoriteFlatType) {
        return this.selected.includes(choice);
    }

    toggle(choice: FavoriteFlatType) {
        const idx = this.selected.indexOf(choice);
        if (idx < 0) this.selected.push(choice);
        else this.selected.splice(idx, 1);
    }

    get isValid() {
        return this.selected.length > 0;
    }

    nextStep() {
        this.matchingData.favoriteFlats = [...this.selected];
        this.$emit("next", { from: HodMatchingSubstep.FAVORITE_FLATS });
    }
}
