import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { HL_ONBOARDING_ROOT_LOCATION } from "@/modules/onboarding/hl/workflow";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { HlOnboardingStore } from "@/modules/onboarding/hl/HlOnboardingStore";
import { InjectService } from "@fastoche/ui-core/di";
import { HlOnboardingService } from "@/modules/onboarding/hl/services/HlOnboardingService";
import { OnboardingService } from "@/modules/onboarding/common/services/OnboardingService";

@Component
export class BaseHlOnboardingPage extends BaseComponent {
    onboardingRootLink = HL_ONBOARDING_ROOT_LOCATION;

    @InjectState(HlOnboardingStore)
    protected readonly hlState!: NonNullable<StateOf<HlOnboardingStore>>;

    @InjectService(HlOnboardingStore)
    protected readonly hlStore!: HlOnboardingStore;

    @InjectService(HlOnboardingService)
    protected readonly hlOnboardingService!: HlOnboardingService;

    @InjectService(OnboardingService)
    protected readonly onboardingService!: OnboardingService;
}

