import { ListingService } from "@/modules/listing/services/ListingService";
import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { ListingPriceQueryResult, ListingPriceRequestModel, ListingSearchModel, ListingSearchResult } from "@/modules/listing/model";
import { Bad, Ok, OkVal } from "@leavy/result";
import { DateTime } from "luxon";
import { DefaultPaginatedResult, PaginatedResult} from "@/modules/core/model";
import { FindListingByLocationQuery } from "@leavy/lv-homesharing-backend-srv/lib/listing/query/clients/FindListingByLocationQuery.client";
import { GetHouseListingPriceQuery } from "@leavy/lv-homesharing-backend-srv/lib/listing/query/GetHouseListingPriceQuery";

@injectable()
export class CqListingService extends ListingService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    async searchListingsByLocation(model: ListingSearchModel): Promise<OkVal<PaginatedResult<ListingSearchResult>> | Bad<"DURATION_NOT_SUPPORTED">> {
        const results = await this.cq.invoke(
            FindListingByLocationQuery,
            {
                location: {
                    ...model.location,
                },
                page: model.page,
                perPage: model.perPage,
                currency: model.currency,
                filters: {
                    ...model.filters,
                },
                arrivalDate: DateTime.fromISO(model.arrivalDate).toFormat("yyyy-MM-dd"),
                departureDate: DateTime.fromISO(model.departureDate).toFormat("yyyy-MM-dd"),
            },
        );

        if (!results.success) {
            switch (results.reason) {
                case "INVALID_DATE_INTERVAL":
                    return Bad("DURATION_NOT_SUPPORTED");
                default:
                    return Ok(
                        new DefaultPaginatedResult<ListingSearchResult>()
                    );
            }
        } else {
            return Ok(results.value);
        }
    }
}
