


















import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { MissionFrequency } from "@/modules/onboarding/hod/domain";
import { HodMatchingSubstep, MatchingDataModel } from "@/modules/onboarding/hod/model";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";

export const COMPONENT_NAME = "OnboardingMatchingMissionFrequencyPage";

@Component({
    name: COMPONENT_NAME,
    components: { LvButton },
    loader: true,
})
export default class OnboardingMatchingMissionFrequencyPage extends BaseHodOnboardingPage {
    choices: MissionFrequency[] = Object.values(MissionFrequency);
    selected: MissionFrequency | null = null;

    @Prop({ type: Object })
    matchingData!: MatchingDataModel;

    mounted() {
        if (this.matchingData.frequency != undefined) {
            this.selected = this.matchingData.frequency;
        }
    }

    isSelected(choice: MissionFrequency) {
        return this.selected == choice;
    }

    toggle(choice: MissionFrequency) {
        if (this.selected == choice) this.selected = null;
        else this.selected = choice;
    }

    get isValid() {
        return this.selected != null;
    }

    nextStep() {
        this.matchingData.frequency = this.selected ?? undefined;
        this.$emit("next", { from: HodMatchingSubstep.FREQUENCY });
    }
}
