

























import { Component, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvContainer, LvLightContainer } from "@fastoche/ui-kit/components";
import { InjectService } from "@fastoche/ui-core/di";
import { UserService } from "@/modules/user/services/UserService";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { EmailService } from "@/modules/auth/services/EmailService";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import { IsEmail } from "@leavy/validator";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { MessageBox } from "@fastoche/ui-kit/components";
import { UserContextStore } from "@/modules/user/UserContext";

class UpdateUserEmailViewModel {
    @IsEmail()
    email!: string;
}

export const COMPONENT_NAME = "UpdateUserEmailPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        PageHeader,
        LvButton,
        LvField,
        LvFormItem,
        LvForm,
        LvContainer,
    },
})
export default class UpdateUserEmailPage extends BaseComponent {
    @InjectService(UserContextStore)
    readonly userContextStore!: UserContextStore;

    @InjectService(EmailService)
    emailService!: EmailService;

    @Ref()
    form!: LvForm;

    initialEmail!: string;
    model = new UpdateUserEmailViewModel();

    mounted() {
        void this.loadModel();
    }

    @LoaderTask
    async loadModel() {
        try {
            const user = await this.userContextStore.getUserInfoOrThrow();

            const model = new UpdateUserEmailViewModel();
            model.email = user.email;

            this.model = model;
            this.initialEmail = user.email;
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async onSubmit() {
        try {
            if (!(await this.form.submit())) return;

            const newEmail = this.model.email.trim().toLowerCase();
            if (newEmail == this.initialEmail) return;

            const result = await this.emailService.updateEmail(newEmail);

            if (!result.success) {
                CommonErrorDialogs.unexpectedError();
            }
            else {
                this.initialEmail = newEmail;

                void MessageBox.prompt({
                    type: "success",
                    confirmText: this.$t("common.confirm"),
                    isCancellable: false,
                    title: this.$t("app.onboarding.steps.email_verification.email_changed"),
                    message: this.$t("app.onboarding.steps.email_verification.email_changed.description"),
                });
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

}
