import Vue from "vue";
import { InversifyPlugin } from "@fastoche/ui-core/di";
import { I18nPlugin } from "@fastoche/ui-core/i18n/plugin";
import { LoaderPlugin } from "@fastoche/ui-kit/plugins/loader";
import { APP_CONFIG } from "@/app/config";
import { ResponsivePlugin } from "@fastoche/ui-kit/plugins/responsive/plugin";
import { APP_LOGGER } from "@/app/logger";
import { TrackingPlugin } from "@fastoche/ui-tracking";

APP_LOGGER.debug("Install InversifyPlugin", { InversifyPlugin });
Vue.use(InversifyPlugin());

APP_LOGGER.debug("Install I18nPlugin", { I18nPlugin });
Vue.use(I18nPlugin({ env: APP_CONFIG.APP_ENV }));

APP_LOGGER.debug("Install LoaderPlugin", { LoaderPlugin });
Vue.use(LoaderPlugin());

APP_LOGGER.debug("Install ResponsivePlugin", { ResponsivePlugin });
Vue.use(ResponsivePlugin());

APP_LOGGER.debug("Install TrackingPlugin", { TrackingPlugin });
Vue.use(TrackingPlugin({ logger: APP_LOGGER }));
