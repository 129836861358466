import { RouteConfig } from "vue-router";
import { RouterContainer } from "@/app/pages/RouterContainer";
import { HL_ONBOARDING_ROUTES } from "@/app/pages/onboarding/hl/routes";
import { GlobalApplicationContext } from "@fastoche/ui-core/application/GlobalApplicationContext";
import { HlOnboardingService } from "@/modules/onboarding/hl/services/HlOnboardingService";
import { HlOnboardingStore } from "@/modules/onboarding/hl/HlOnboardingStore";
import HlOverviewPage from "@/app/pages/hl/HlOverviewPage.vue";
import { UserContextStore } from "@/modules/user/UserContext";
import HlRequestNewManagementPeriodPage from "@/app/pages/hl/HlRequestNewManagementPeriodPage.vue";
import HlRequestViewPage from "@/app/pages/hl/HlRequestViewPage.vue";
import { Route } from "vue-router/types/router";
import HlPeriodViewPage from "@/app/pages/hl/HlPeriodViewPage.vue";
import HlPaymentListPage from "@/app/pages/hl/HlPaymentListPage.vue";
import HlPeriodListPage from "@/app/pages/hl/HlPeriodListPage.vue";

export const HL_ROUTES: RouteConfig[] = [
    {
        path: "",
        component: RouterContainer,
        beforeEnter: async (to, from, next) => {
            const { rootContainer } = GlobalApplicationContext.default.fastoche;
            const userContextStore = rootContainer.get(UserContextStore);
            const hlOnboardingService = rootContainer.get(HlOnboardingService);
            const hlOnboardingStore = rootContainer.get(HlOnboardingStore);

            if (userContextStore.isHL()) {
                next();
            }
            else {
                await hlOnboardingService.ensureStore();

                if (hlOnboardingStore.value?.status == "ACCEPTED") {
                    next();
                }
                else {
                    next("/app/happy-leaver/onboarding");
                }
            }
        },
        children: [
            {
                path: "",
                component: HlOverviewPage,
            },
            {
                path: "management-period/list",
                component: HlPeriodListPage,
            },
            {
                path: "management-period/new",
                component: HlRequestNewManagementPeriodPage,
            },
            {
                path: "management-period/request/:id",
                props: (route: Route) => {
                    const { id } = route.params;
                    return { id };
                },
                component: HlRequestViewPage,
            },
            {
                path: "management-period/period/:id",
                props: (route: Route) => {
                    const { id } = route.params;
                    return { id };
                },
                component: HlPeriodViewPage,
            },
            {
                path: "payment/list",
                component: HlPaymentListPage,
            },
        ],
    },
    {
        path: "onboarding",
        component: RouterContainer,
        beforeEnter: async (to, from, next) => {
            const { rootContainer } = GlobalApplicationContext.default.fastoche;
            const hlOnboardingService = rootContainer.get(HlOnboardingService);
            const hlOnboardingStore = rootContainer.get(HlOnboardingStore);

            await hlOnboardingService.ensureStore();

            if (!hlOnboardingStore.value) {
                next("/register/hl");
            }
            else if (hlOnboardingStore.value.status == "ACCEPTED") {
                next("/app/happy-leaver");
            }
            else {
                next();
            }
        },
        children: [...HL_ONBOARDING_ROUTES],
    },
];
