








import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import SplitLayout from "@/app/ui/split-layout/SplitLayout.vue";

export const COMPONENT_NAME = "AuthLayout";

@Component({
    name: COMPONENT_NAME,
    components: {
        SplitLayout,
    },
})
export default class AuthLayout extends BaseComponent {
}
