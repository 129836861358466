import { Query, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { IsInstance, IsOptional, IsUUID, ValidateNested } from "@leavy/validator";
import { Type } from "class-transformer";
import { PaginationOptionsModel } from "@leavy/lv-homesharing-srv/lib/common/model/PaginationOptionsModel";
import { HlUserManagementPeriodFiltersModel } from "../model/HlUserManagementPeriodFiltersModel";
import { OkVal } from "@leavy/result";
import { PaginatedResult } from "@leavy/lv-homesharing-srv/lib/common/domain/Pagination";
import { HlUserManagementPeriod } from "../domain/HlUserManagementPeriod";

export type HlUserGetManagementPeriodsQueryResult =
    OkVal<PaginatedResult<HlUserManagementPeriod>>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_get_management_periods"
})
export class HlUserGetManagementPeriodsQuery extends Query<HlUserGetManagementPeriodsQueryResult> {
    @IsUUID()
    userId!: string;

    @Type(() => PaginationOptionsModel)
    @IsInstance(PaginationOptionsModel)
    @ValidateNested()
    pagination!: PaginationOptionsModel;

    @Type(() => HlUserManagementPeriodFiltersModel)
    @IsOptional()
    @IsInstance(HlUserManagementPeriodFiltersModel)
    @ValidateNested()
    filters?: HlUserManagementPeriodFiltersModel;
}
