import { BuildClientInvocation, UserIdAmbientValue } from "@leavy/cq-core";
import * as OnboardingService from "@leavy/lv-onboarding-srv/lib/common/query";

export class GetAllUserOnboardingsQuery extends BuildClientInvocation({
    type: OnboardingService.GetAllUserOnboardingsQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class GetUserOnboardingByIdQuery extends BuildClientInvocation({
    type: OnboardingService.GetUserOnboardingByIdQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class GetUserOnboardingStepQuery extends BuildClientInvocation({
    type: OnboardingService.GetUserOnboardingStepQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class GetUserOnboardingExtraDataQuery extends BuildClientInvocation({
    type: OnboardingService.GetUserOnboardingExtraDataQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
