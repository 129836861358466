import { Money } from "@leavy/lv-homesharing-pricing-srv/lib/money/domain/Money";
import { HlAccommodationPhotoId } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlAccommodationPhoto";
import { HlAccommodation, HlAccommodationId } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlAccommodation";
import { HlAccommodationAddress } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlAccommodationAddress";
import { HlAccommodationAmenityId } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlAccommodationAmenity";
import { HlAccommodationAccesses } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlAccommodationAccesses";

export interface HlUserAccommodation {
    id: HlAccommodationId;
    creationDate: string;
    lastModificationDate: string;
    address: HlAccommodationAddress;
    monthlyRent?: Money;
    surfaceArea: number;
    bedrooms: number;
    bathrooms: number;
    simpleBeds: number;
    doubleBeds: number;
    sofaBeds: number;
    hostingCapacity: number;
    amenities: HlAccommodationAmenityId[];
    accesses: HlAccommodationAccesses;
    photos: HlAccommodationPhotoId[];
}

export namespace HlUserAccommodationMapper {
    export function fromAccommodation(acc: HlAccommodation): HlUserAccommodation {
        return {
            id: acc.id,
            creationDate: acc.creationDate as unknown as string,
            lastModificationDate: acc.lastModificationDate as unknown as string,
            monthlyRent: acc.monthlyRent,
            address: acc.address,
            accesses: acc.accesses,
            amenities: acc.amenities,
            hostingCapacity: acc.hostingCapacity,
            sofaBeds: acc.sofaBeds,
            doubleBeds: acc.doubleBeds,
            bathrooms: acc.bathrooms,
            bedrooms: acc.bedrooms,
            surfaceArea: acc.surfaceArea,
            photos: acc.photos,
            simpleBeds: acc.simpleBeds,
        };
    }
}
