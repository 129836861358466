import { Currency } from "./Currency";
import { CurrencyRateProvider } from "../service/CurrencyRateProvider";
import { assert, Guard, MathUtils } from "@leavy/utils";
import { Money } from "@leavy/lv-homesharing-pricing-srv/lib/money/domain/Money";
import { Expose, ReadonlyGetter } from "@leavy/validator";

export type { Money };

export enum MoneyRounding {
    DEFAULT = "DEFAULT",
    UPPER = "UPPER",
    LOWER = "LOWER"
}

export class MoneyObject<T extends Currency = Currency> implements Money<T> {
    public static readonly MAXIMUM_ALLOWED_AMOUNT = 99999999;
    private static currencyRateProvider?: CurrencyRateProvider;

    private readonly _value: number;
    private readonly _currency: T;

    constructor(value: number, currency: T);
    constructor(money: Money<T>);
    constructor(
        valueOrMoney: number | Money<T>, currency?: T,
    ) {
        if (typeof valueOrMoney === "number") {
            if (Number.isNaN(valueOrMoney)) {
                throw Error("Provided amount can not be NaN");
            }

            this._value = valueOrMoney;
            assert(currency);
            this._currency = currency;
        }
        else {
            this._value = valueOrMoney.value;
            this._currency = valueOrMoney.currency as T;
        }

        this.ensureValidity(this);
    }

    static isValid(money: Money) {
        return money.value > -1 && money.value <= MoneyObject.MAXIMUM_ALLOWED_AMOUNT && !!Currency[money.currency];
    }

    static setCurrencyRateProvider(currencyRateProvider: CurrencyRateProvider) {
        this.currencyRateProvider = currencyRateProvider;
    }

    @ReadonlyGetter()
    get value() {
        return this._value;
    }

    @ReadonlyGetter()
    get currency() {
        return this._currency;
    }

    plus(money: Money): MoneyObject<T> {
        this.ensureValidity(money);
        money = this.ensureCurrency(money);

        const sum = this.value + money.value;
        if (sum > MoneyObject.MAXIMUM_ALLOWED_AMOUNT) {
            throw new Error("Can not sum: maximum allowed money amount exceeded");
        }

        return new MoneyObject({
            value: sum,
            currency: this.currency,
        });
    }

    divideBy(nb: number): MoneyObject<T> {
        if (!nb || Number.isNaN(nb)) {
            throw new Error("Must be defined and greater than 0");
        }

        return new MoneyObject({
            value: this.value / nb,
            currency: this.currency,
        });
    }

    multiplyBy(nb: number): MoneyObject<T> {
        if (Number.isNaN(nb)) {
            throw new Error("Must be defined and greater than 0");
        }

        const product = this.value * nb;
        if (product > MoneyObject.MAXIMUM_ALLOWED_AMOUNT) {
            throw new Error(`Can not multiplyBy ${nb}: maximum allowed money amount exceeded`);
        }

        return new MoneyObject({
            value: product,
            currency: this.currency,
        });
    }

    minus(money: Money): MoneyObject<T> {
        this.ensureValidity(money);
        money = this.ensureCurrency(money);

        const sub = this.value - money.value;
        return new MoneyObject({
            value: sub < 0 ? 0 : sub,
            currency: this.currency,
        });
    }

    convertTo<TCurr extends Currency>(targetCurrency: TCurr): MoneyObject<TCurr> {
        if (!MoneyObject.currencyRateProvider) {
            throw new Error("No currency provider configured");
        }

        const rate = MoneyObject.currencyRateProvider.getRate(this.currency, targetCurrency);
        return new MoneyObject<TCurr>(this.value * rate, targetCurrency);
    }

    round(round: MoneyRounding = MoneyRounding.DEFAULT): MoneyObject<T> {
        const val = this._value;
        const precisionFactor = 10;
        switch (round) {
            case MoneyRounding.DEFAULT:
                return new MoneyObject(Math.round(val), this.currency);
            case MoneyRounding.UPPER:
                return new MoneyObject(Math.ceil(val * precisionFactor) / precisionFactor, this.currency);
            case MoneyRounding.LOWER:
                return new MoneyObject(Math.floor(val * precisionFactor) / precisionFactor, this.currency);
            default:
                throw new Error("Invalid round parameter");
        }
    }

    private ensureValidity(money: Money) {
        if (!MoneyObject.isValid(this)) {
            throw Error(`Invalid parameters for Money: ${money.value}, ${money.currency}`);
        }
    }


    private ensureCurrency(money: Money) {
        if (this.currency !== money.currency) {
            let goodCurrMoney = money;
            if (!(money instanceof MoneyObject)) {
                goodCurrMoney = new MoneyObject(money);
            }
            goodCurrMoney = (money as MoneyObject).convertTo(this.currency);
            return goodCurrMoney;
        }

        return money;
    }
}
