import { MutableStore } from "@fastoche/ui-core/store";
import { injectable } from "inversify";
import { HOD_ONBOARDING_END_LOCATION, HOD_ONBOARDING_ROOT_LOCATION, HOD_ONBOARDING_STEPS_LOCATIONS, HOD_ONBOARDING_WORKFLOW } from "./workflow";
import { AllUserOnboardingsStore } from "../common/AllUserOnboardingsStore";
import { GetIndexedStep } from "../common/model";
import { AnyHodOnboardingStepId } from "./domain";
import { OnboardingId, UserOnboardingStatus } from "../common/domain";
import { HodOnboardingState } from "./HodOnboardingState";

@injectable()
export class HodOnboardingStore extends MutableStore<HodOnboardingState | null> {
    constructor(
        private readonly onboardingStore: AllUserOnboardingsStore,
    ) {
        super(null);

        onboardingStore.value$.subscribe(value => {
            const hodOnboarding = value[OnboardingId.HOD];
            if (hodOnboarding) this.set(new HodOnboardingState(hodOnboarding));
            else this.set(null);
        });
    }

    getStepLocation(step: AnyHodOnboardingStepId) {
        return HOD_ONBOARDING_STEPS_LOCATIONS[step];
    }

    getNextStepLocation(currentStep: AnyHodOnboardingStepId) {
        if (!this.value) return HOD_ONBOARDING_ROOT_LOCATION;

        if (!this.value.hasPendingSteps) {
            return HOD_ONBOARDING_END_LOCATION;
        }

        const indexedStep = GetIndexedStep(HOD_ONBOARDING_WORKFLOW)(currentStep);

        if (indexedStep.isLast) {
            if (this.value.hasPendingSteps) return HOD_ONBOARDING_ROOT_LOCATION;
            else return HOD_ONBOARDING_END_LOCATION;
        }

        if (this.value.status == UserOnboardingStatus.SUBMITTED) {
            return HOD_ONBOARDING_END_LOCATION;
        }

        const nextStep = indexedStep.getNext();
        return this.getStepLocation(nextStep.step);
    }
}
