import { Query, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { IsInstance, IsOptional, IsUUID, ValidateNested } from "@leavy/validator";
import { Type } from "class-transformer";
import { PaginationOptionsModel } from "@leavy/lv-homesharing-srv/lib/common/model/PaginationOptionsModel";
import { HlUserPaymentFiltersModel } from "../model/HlUserPaymentFiltersModel";
import { OkVal } from "@leavy/result";
import { PaginatedResult } from "@leavy/lv-homesharing-srv/lib/common/domain/Pagination";
import { HlUserPayment } from "../domain/HlUserPayment";

export type HlUserGetPaymentsQueryResult =
    OkVal<PaginatedResult<HlUserPayment>>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_get_payments",
})
export class HlUserGetPaymentsQuery extends Query<HlUserGetPaymentsQueryResult> {
    @IsUUID()
    userId!: string;

    @Type(() => PaginationOptionsModel)
    @IsInstance(PaginationOptionsModel)
    @ValidateNested()
    pagination!: PaginationOptionsModel;

    @Type(() => HlUserPaymentFiltersModel)
    @IsOptional()
    @IsInstance(HlUserPaymentFiltersModel)
    @ValidateNested()
    filters?: HlUserPaymentFiltersModel;
}
