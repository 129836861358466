








































import { Component, Ref } from "vue-property-decorator";
import { LvContainer } from "@fastoche/ui-kit/components";
import { InjectService } from "@fastoche/ui-core/di";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { HodOnboardingService } from "@/modules/onboarding/hod/services/HodOnboardingService";
import { AdministrativeDocumentType, AnyHodOnboardingStepId } from "@/modules/onboarding/hod/domain";
import { AdministrativeDocumentModel } from "@/modules/onboarding/hod/model";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import LvFilePicker from "@fastoche/ui-kit/components/file-picker/LvFilePicker.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import { ArrayMinSize } from "@leavy/validator";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import { FileService } from "@/modules/file/services/FileService";
import LvFilePreview from "@fastoche/ui-kit/components/file-preview/LvFilePreview.vue";
import OnboardingDocumentCollection from "@/app/components/onboarding/common/OnboardingDocumentCollection.vue";

class AdministrativeDocumentsViewModel {
    @ArrayMinSize(1)
    [AdministrativeDocumentType.CRIMINAL_RECORD]: AdministrativeDocumentModel[] = [];

    @ArrayMinSize(1)
    [AdministrativeDocumentType.CERTIFICATE_OF_INCORPORATION]: AdministrativeDocumentModel[] = [];

    @ArrayMinSize(1)
    [AdministrativeDocumentType.COMPLIANCE_FORM]: AdministrativeDocumentModel[] = [];
}

export const COMPONENT_NAME = "OnboardingAdministrativeDocsPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvFilePreview,
        LvIcon,
        LvFormItem,
        LvForm,
        LvButton,
        LvFilePicker,
        PageHeader,
        LvContainer,
        OnboardingDocumentCollection
    },
})
export default class OnboardingAdministrativeDocsPage extends BaseHodOnboardingPage {
    @InjectService(HodOnboardingStore)
    readonly hodStore!: HodOnboardingStore;

    @InjectState(HodOnboardingStore)
    readonly hodState!: NonNullable<StateOf<HodOnboardingStore>>;

    @InjectService(HodOnboardingService)
    readonly hodOnboardingService!: HodOnboardingService;

    @InjectService(FileService)
    readonly fileService!: FileService;

    @Ref()
    readonly form!: LvForm;

    documentTypes: AdministrativeDocumentType[] = Object.values(AdministrativeDocumentType);
    model: AdministrativeDocumentsViewModel = new AdministrativeDocumentsViewModel();

    created() {
        this.loadModel();
    }

    loadModel() {
        const model = new AdministrativeDocumentsViewModel();
        const { data } = this.hodState.onboarding.administrativeDocuments;

        if (data) {
            for (const doc of data.documents) {
                if (!doc.isComplete()) continue;

                const documentModel = new AdministrativeDocumentModel();
                documentModel.documentType = doc.documentType;
                documentModel.fileId = doc.fileId;

                model[doc.documentType].push(documentModel);
            }
        }

        this.model = model;
    }

    getDocumentsOfType(documentType: AdministrativeDocumentType) {
        return this.model[documentType];
    }

    isRequired(documentType: AdministrativeDocumentType) {
        switch (documentType) {
            case AdministrativeDocumentType.CERTIFICATE_OF_INCORPORATION:
            case AdministrativeDocumentType.COMPLIANCE_FORM:
            case AdministrativeDocumentType.CRIMINAL_RECORD:
                return true;
        }
    }

    getFileUrl(doc: AdministrativeDocumentModel) {
        return this.fileService.getFileUrl(doc.fileId).url;
    }

    @LoaderTask
    async addAdministrativeDocument(documentType: AdministrativeDocumentType, file: File) {
        try {
            const result = await this.hodOnboardingService.addAdministrativeDocument(file, documentType);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE": {
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;
                    }
                }
            }
            else {
                const { fileId } = result.value;

                const docModel = new AdministrativeDocumentModel();
                docModel.documentType = documentType;
                docModel.fileId = fileId;

                this.model[documentType] = [
                    ...this.model[documentType],
                    docModel
                ];
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async removeAdministrativeDocument(doc: AdministrativeDocumentModel) {
        try {
            const result = await this.hodOnboardingService.removeAdministrativeDocument({
                ...doc
            });

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE": {
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;
                    }
                }
            }
            else {
                const documentList = this.getDocumentsOfType(doc.documentType);

                const existingIdx = documentList.findIndex(x => x.fileId == doc.fileId);

                if (existingIdx > -1) {
                    documentList.splice(existingIdx, 1);
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async onSubmit() {
        try {
            if (!(await this.form.submit())) return;

            const result = await this.hodOnboardingService.submitAdministrativeDocumentsStep();

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
            else {
                const nextPage = this.hodStore.getNextStepLocation(AnyHodOnboardingStepId.HOD_ADMINISTRATIVE_DOCS);
                await this.$router.push(nextPage).catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
