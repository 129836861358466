import { Command, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { Bad, BadVal, OkVal } from "@leavy/result";
import { HlManagementPeriodId } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlManagementPeriod";
import { IsUUID } from "@leavy/validator";
import { HlAccommodationManagementAvailability } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlAccommodation";

export type HlUserAcceptPriceProposalCommandResult =
    | OkVal<{ managementPeriodId: HlManagementPeriodId }>
    | Bad<"MANAGEMENT_PERIOD_REQUEST_NOT_FOUND">
    | Bad<"MANAGEMENT_PERIOD_REQUEST_WRONG_STATE">
    | Bad<"PRICE_PROPOSAL_EXPIRED">
    | BadVal<"MANAGEMENT_PERIOD_DATES_CONFLICT", HlAccommodationManagementAvailability>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_accept_price_proposal"
})
export class HlUserAcceptPriceProposalCommand extends Command<HlUserAcceptPriceProposalCommandResult> {
    @IsUUID()
    userId!: string;

    @IsUUID()
    managementPeriodRequestId!: string;
}
