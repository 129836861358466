import * as uuid from "uuid";
import { AccessToken, AuthenticationState } from "./AuthenticationState";
import { APP_CONFIG } from "../../app/config";
import { AuthenticationStorage } from "./AuthenticationStorage";
import { injectable } from "inversify";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MutableStore } from "@fastoche/ui-core/store";

@injectable()
export class AuthenticationStore extends MutableStore<AuthenticationState> {
    static readonly MIN_TOKEN_DURATION: number = 300000;  // 5 minutes
    static readonly MAX_TOKEN_DURATION: number = 604800000;  // 7 days

    constructor(
        private readonly storage: AuthenticationStorage,
    ) {
        super(storage.getValue() ?? {
            deviceId: uuid.v4(),
            clientId: APP_CONFIG.CLIENT_APP_CLIEND_ID,
        });

        this.value$.subscribe(state => {
            this.storage.setValue(state);
        });
    }

    setToken(userId: string, token: AccessToken, refreshToken: string) {
        const duration = token.tokenExpirationTime - Date.now();

        if (duration < AuthenticationStore.MIN_TOKEN_DURATION || duration >= AuthenticationStore.MAX_TOKEN_DURATION) {
            throw new Error("Access token duration must be greater than or equal to 5 minutes and less than 7 days");
        }

        this.mutate(s => {
            return {
                ...s,
                token,
                user: {
                    id: userId,
                },
                refreshToken,
            };
        });
    }

    clearToken() {
        this.mutate(s => {
            return {
                ...s,
                token: undefined,
                user: undefined,
                refreshToken: undefined,
            };
        });
    }

    isAuthenticated(): boolean {
        return !!this.value.refreshToken && !!this.value.token && !!this.value.user;
    }

    isAuthenticated$: Observable<boolean> = this.value$.pipe(map(s => !!this.value.refreshToken && !!this.value.token && !!this.value.user));
}
