



















import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { FavoriteActivity } from "@/modules/onboarding/hod/domain";
import { HodMatchingSubstep, MatchingDataModel } from "@/modules/onboarding/hod/model";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";

const CHOICE_ICONS: Record<FavoriteActivity, string> = {
    [FavoriteActivity.SPORT]: require("@/app/assets/icons/sport.svg"),
    [FavoriteActivity.ART]: require("@/app/assets/icons/art.svg"),
    [FavoriteActivity.FOOD]: require("@/app/assets/icons/food.svg"),
    [FavoriteActivity.NIGHT_LIFE]: require("@/app/assets/icons/dj.svg"),
    [FavoriteActivity.CULTURE]: require("@/app/assets/icons/museum.svg"),
    [FavoriteActivity.HISTORY]: require("@/app/assets/icons/history.svg"),
    [FavoriteActivity.TRAVELS]: require("@/app/assets/icons/travel.svg"),
    [FavoriteActivity.SHOPPING]: require("@/app/assets/icons/shopping.svg"),
    [FavoriteActivity.WELLNESS]: require("@/app/assets/icons/yoga.svg"),
    [FavoriteActivity.PETS]: require("@/app/assets/icons/cat.svg"),
};

export const COMPONENT_NAME = "OnboardingMatchingFavoriteActivitiesPage";

@Component({
    name: COMPONENT_NAME,
    components: { LvButton },
    loader: true,
})
export default class OnboardingMatchingFavoriteActivitiesPage extends BaseHodOnboardingPage {
    choices: FavoriteActivity[] = Object.values(FavoriteActivity);
    selected: FavoriteActivity[] = [];

    @Prop({ type: Object })
    matchingData!: MatchingDataModel;

    mounted() {
        if (this.matchingData.activities !== undefined) {
            this.selected.push(...this.matchingData.activities);
        }
    }

    getChoiceIcon(choice: FavoriteActivity) {
        return CHOICE_ICONS[choice];
    }

    isSelected(choice: FavoriteActivity) {
        return this.selected.includes(choice);
    }

    toggle(choice: FavoriteActivity) {
        const idx = this.selected.indexOf(choice);
        if (idx < 0) this.selected.push(choice);
        else this.selected.splice(idx, 1);
    }

    get isValid() {
        return this.selected.length > 0;
    }

    nextStep() {
        this.matchingData.activities = [...this.selected];
        this.$emit("next", { from: HodMatchingSubstep.ACTIVITIES });
    }
}
