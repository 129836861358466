

















































































import { Component } from "vue-property-decorator";
import { LvContainer } from "@fastoche/ui-kit/components";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { AnyHlOnboardingStepId, AppointmentDayTime, AppointmentWeekDay } from "@/modules/onboarding/hl/domain";
import { AppointmentDayPreferenceModel, HlAppointmentRequestModel } from "@/modules/onboarding/hl/model";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";
import HlRegistrationSteps from "@/app/ui/hl-registration-steps/HlRegistrationSteps.vue";
import { HL_ONBOARDING_END_LOCATION } from "@/modules/onboarding/hl/workflow";

export const COMPONENT_NAME = "OnboardingAppointmentRequestPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        HlRegistrationSteps,
        LvIcon,
        LvButton,
        PageHeader,
        LvContainer,
    },
})
export default class OnboardingAppointmentRequestPage extends BaseHlOnboardingPage {
    days: AppointmentWeekDay[] = Object.values(AppointmentWeekDay);
    times: AppointmentDayTime[] = Object.values(AppointmentDayTime);

    model: HlAppointmentRequestModel = new HlAppointmentRequestModel();

    get isOnboardingSubmitted() {
        return this.hlState.status == "SUBMITTED";
    }

    created() {
        void this.loadModel();
    }

    @LoaderTask
    async loadModel() {
        await this.onboardingService.loadStep(AnyHlOnboardingStepId.HL_APPOINTMENT_REQUEST);

        const model = new HlAppointmentRequestModel();
        const { data } = this.hlState.onboarding.appointmentRequest;

        if (data) {
            model.preferredDays = data.preferredDays.map(x => {
                const selectedDay = new AppointmentDayPreferenceModel();
                selectedDay.day = x.day!;
                selectedDay.times = x.times!;
                return selectedDay;
            });
        }

        this.model = model;
    }

    isDaySelected(day: AppointmentWeekDay) {
        return this.model.preferredDays.some(x => x.day == day);
    }

    toggleDay(day: AppointmentWeekDay) {
        const idx = this.model.preferredDays.findIndex(x => x.day == day);

        if (idx > -1) {
            this.model.preferredDays.splice(idx, 1);
        }
        else {
            const selectedDay = new AppointmentDayPreferenceModel();
            selectedDay.times = [];
            selectedDay.day = day;
            this.model.preferredDays.push(selectedDay);
        }
    }

    getSelectedDay(day: AppointmentWeekDay) {
        return this.model.preferredDays.find(x => x.day == day);
    }

    isTimeSelected(day: AppointmentWeekDay, time: AppointmentDayTime) {
        const selectedDay = this.getSelectedDay(day)!;
        return selectedDay.times.includes(time);
    }

    toggleTime(day: AppointmentWeekDay, time: AppointmentDayTime) {
        const selectedDay = this.getSelectedDay(day)!;
        const idx = selectedDay.times.indexOf(time);
        if (idx > -1) {
            selectedDay.times.splice(idx, 1);
        }
        else {
            selectedDay.times.push(time);
        }
    }

    isAnytime(day: AppointmentWeekDay) {
        const selectedDay = this.getSelectedDay(day)!;
        return this.times.every(x => selectedDay.times.includes(x));
    }

    setAnytime(day: AppointmentWeekDay) {
        const selectedDay = this.getSelectedDay(day)!;

        if (this.isAnytime(day)) {
            selectedDay.times = [];
        }
        else {
            selectedDay.times = [...this.times];
        }
    }

    get isValid() {
        return this.model.preferredDays.filter(x => x.times.length > 0).length > 0;
    }

    @LoaderTask
    async nextStep() {
        try {
            this.model.preferredDays = this.model.preferredDays.filter(x => x.times.length > 0);

            const result = await this.hlOnboardingService.submitAppointmentRequestStep(this.model);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;
                }
            }
            else {
                const nextPage = this.isOnboardingSubmitted ?
                    this.hlStore.getNextStepLocation(AnyHlOnboardingStepId.HL_APPOINTMENT_REQUEST) :
                    HL_ONBOARDING_END_LOCATION;

                await this.$router.push(nextPage).catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
