import { Query, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { IsDateString, IsEnum, IsOptional, IsUUID } from "@leavy/validator";
import { Currency } from "../../money/domain/Currency";
import { Bad, OkVal } from "@leavy/result";
import { HlUserAccommodationPeriodEstimation } from "../domain/HlUserAccommodationPeriodEstimation";

export type HlUserGetPeriodEstimationForAccommodationQueryResult =
    | OkVal<HlUserAccommodationPeriodEstimation>
    | Bad<"ACCOMMODATION_NOT_FOUND">
    | Bad<"INVALID_LOCATION">
    | Bad<"ESTIMATION_FAILED">;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_get_period_estimation_for_accommodation",
})
export class HlUserGetPeriodEstimationForAccommodationQuery extends Query<HlUserGetPeriodEstimationForAccommodationQueryResult> {
    @IsUUID()
    userId!: string;

    @IsUUID()
    accommodationId!: string;

    @IsDateString()
    departureDate!: string;

    @IsDateString()
    returnDate!: string;

    @IsOptional()
    @IsEnum(Currency)
    currency?: Currency;
}
