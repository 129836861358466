import { BuildClientInvocation, UserIdAmbientValue } from "@leavy/cq-core";
import * as OnboardingService from "@leavy/lv-onboarding-srv/lib/common/command";
import { StartOnboardingLocalCommand } from "./StartOnboardingLocalCommand";

export class StartOnboardingCommand extends BuildClientInvocation({
    type: StartOnboardingLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class RestartOnboardingCommand extends BuildClientInvocation({
    type: OnboardingService.RestartOnboardingCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitOnboardingCommand extends BuildClientInvocation({
    type: OnboardingService.SubmitOnboardingCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
