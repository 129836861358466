import { IndexedStep } from "@/modules/onboarding/common/model/IndexedStep";

export enum HodMatchingSubstep {
    ACTIVITIES = "activities",
    PERSONALITY = "personality",
    LANGUAGES = "languages",
    TRANSPORTS = "transports",
    CITY_KNOWLEDGE = "city-knowledge",
    FAVORITE_FLATS = "favorite-flats",
    FREQUENCY = "frequency"
}

export const HOD_MATCHING_WORKFLOW: ReadonlyArray<HodMatchingSubstep> = [
    HodMatchingSubstep.ACTIVITIES,
    HodMatchingSubstep.PERSONALITY,
    HodMatchingSubstep.LANGUAGES,
    HodMatchingSubstep.TRANSPORTS,
    HodMatchingSubstep.CITY_KNOWLEDGE,
    HodMatchingSubstep.FAVORITE_FLATS,
    HodMatchingSubstep.FREQUENCY
];

export const HodMatchingIndexedSubstep = (step: HodMatchingSubstep) => new IndexedStep<HodMatchingSubstep>(HOD_MATCHING_WORKFLOW, step);
