import { injectable } from "inversify";
import { StartBookingCheckoutCommand } from "@leavy/lv-homesharing-backend-srv/lib/booking/command/clients/StartBookingCheckout.client";
import { Bad, Ok, OkVal } from "@leavy/result";
import { GetCheckoutInfoQuery } from "@leavy/lv-homesharing-backend-srv/lib/booking/query/clients/GetCheckoutInfoQuery.clients";
import { BookHouseListingCommand } from "@leavy/lv-homesharing-backend-srv/lib/booking/command/clients/BookHouseListingCommand.client";

@injectable()
export abstract class CheckoutService {
    abstract startBookingCheckout(model: StartBookingCheckoutCommand):
        Promise<OkVal<string> | Bad<"INVALID_DATES"> | Bad<"UNAVAILABLE_HOTEL_ROOM"> | Bad<"MISSING_HOTEL_ROOM"> | Bad<"INVALID_LISTING">>;

    abstract getBookingCheckoutInfo(model: GetCheckoutInfoQuery);

    abstract bookingListing(model: BookHouseListingCommand):
        Promise<Ok | Bad<"CHECKOUT_NOT_FOUND"> | Bad<"CHECKOUT_EXPIRED"> | Bad<"INVALID_LISTING_TYPE"> | Bad<"PAYMENT_FAILED"> | Bad<"USER_MISMATCH">>;
}
