import { ListingSearchModel, SearchLocationModel } from "../model";
import { SerializeDate } from "@fastoche/ui-core/validator/decorators/SerializeDate";
import { IsDate, IsDateString, IsEnum, IsNumber, IsOptional, Min, TryParseNumber, Type, ValidateNested } from "@leavy/validator";
import { validateSync } from "@leavy/validator/lib/base/validator";
import { Currency } from "@/modules/payment/domain";

export class ListingSearchContext {
    @IsOptional()
    @IsDateString()
    from?: string = undefined;

    @IsOptional()
    @IsDateString()
    to?: string = undefined;

    @Type(() => SearchLocationModel)
    @IsOptional()
    @ValidateNested()
    location?: SearchLocationModel = undefined;

    @IsNumber()
    @IsOptional()
    @TryParseNumber()
    guests?: number = undefined;

    @TryParseNumber()
    @IsOptional()
    @IsEnum(Currency)
    currency?: Currency = undefined;

    hasRequired(): this is Required<ListingSearchContext> {
        return (
            this.from != null &&
            this.to != null &&
            this.location != null &&
            this.guests != null &&
            this.currency != null
        );
    }

    isValid(): this is Required<ListingSearchContext> {
        return this.hasRequired() && validateSync(this).length == 0;
    }
}

