import "./styles.scss";
import * as NProgress from "nprogress";
import { AxiosInstance } from "axios";

const calculatePercentage = (loaded: number, total: number) => (Math.floor(loaded * 1.0) / total);

const setupStartProgress = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.request.use(
response => {
            NProgress.start();
            return response;
        },
        error => {
            NProgress.done();
            return Promise.reject(error);
        }
    );
};

const setupUpdateProgress = (axiosInstance: AxiosInstance) => {
    axiosInstance.defaults.onDownloadProgress = e => {
        const percentage = calculatePercentage(e.loaded, e.total);
        NProgress.set(percentage);
    };

    axiosInstance.defaults.onUploadProgress = e => {
        const percentage = calculatePercentage(e.loaded, e.total);
        NProgress.set(percentage);
    };
};

const setupStopProgress = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.response.use(
response => {
            NProgress.done();
            return response;
        },
        error => {
            NProgress.done();
            return Promise.reject(error);
        }
    );
};

export const setupProgressBar = (axiosInstance: AxiosInstance, config?: Partial<NProgress.NProgressOptions>) => {
    NProgress.configure(config ?? {});

    setupStartProgress(axiosInstance);
    setupUpdateProgress(axiosInstance);
    setupStopProgress(axiosInstance);
};
