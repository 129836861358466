import { BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { BookingService } from "@/modules/booking/services/BookingService";
import { CqBookingService } from "@/modules/booking/platform/cq/CqBookingService";
import { BookingStore } from "./BookingStore";

@Module()
export class BookingModule extends BaseModule {
    registerServices(container: ModuleContainer) {
        container.bind(BookingService).to(CqBookingService).inSingletonScope();
        container.bind(BookingStore).toSelf().inSingletonScope();
    }
}
