export function money(value, locale, currency, options) {
    try {
        //value = value/100;
        const localeCode = typeof locale === "string" ? locale : locale.localeCode;
        let isIntegerNumber =  Number.isInteger(value);

        return new Intl.NumberFormat(localeCode, {
            style: "currency",
            currency,
            currencyDisplay: "narrowSymbol",
            minimumFractionDigits: isIntegerNumber ? 0 : 2,
            ...options
        }).format(value);
    }
    catch (e) {
        return `${value} ${currency}`;
    }
}
