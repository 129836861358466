
















import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import LocaleSelector from "@/app/ui/locale-selector/LocaleSelector.vue";

export const COMPONENT_NAME = "AuthLayout";

@Component({
    name: COMPONENT_NAME,
    responsive: true,
    components: {
        LocaleSelector,
    },
})
export default class SplitLayout extends BaseComponent {
}
