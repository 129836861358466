import { injectable } from "inversify";
import { UserContextStore } from "@/modules/user/UserContext";
import { UserInfo } from "@/modules/user/domain";
import { APP_LOGGER } from "@/app/logger";
import { SegmentAnalyticsProvider } from "@fastoche/ui-tracking";

@injectable()
export class SegmentUserIdentityTracker {
    private isTracking = false;

    constructor(
        private readonly userContextStore: UserContextStore,
        private readonly segment: SegmentAnalyticsProvider,
    ) {
    }

    startTracking() {
        if (this.isTracking) return;

        this.userContextStore.value$.subscribe(x => {
            if (x == null) {
                void this.onUserLoggedOut();
            }
            else {
                void this.onUserLoggedIn(x as UserInfo);
            }
        });

        this.isTracking = true;
    }

    async onUserLoggedOut() {
        try {
            await this.segment.run(analytics => {
                analytics.reset();
            });
        }
        catch (e) {
            APP_LOGGER.warn("Error happened while logging out user from Segment", e);
        }
    }

    async onUserLoggedIn(info: UserInfo) {
        try {
            await this.segment.run(async analytics => {
                await analytics.identify(info.id);
            });
        }
        catch (e) {
            APP_LOGGER.warn("Error happened while logging in user on Segment", e);
        }
    }
}
