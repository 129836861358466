import { injectable } from "inversify";
import { MutableStore } from "@fastoche/ui-core/store";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { Currency } from "@leavy/lv-homesharing-backend-srv/lib/money/domain/Currency";
import { validate } from "@leavy/validator";
import { classToPlain } from "@leavy/validator/lib/base/transformer";
import { ListingSearchContext } from "@/modules/listing/context/ListingSearchContext";

@injectable()
export class ListingSearchContextStore extends MutableStore<ListingSearchContext> {
    constructor(
        private readonly localizationContext: LocalizationContext,
    ) {
        super(new ListingSearchContext());

        this.localizationContext.value$.subscribe(x => {
            this.setCurrency(x.currentCurrency);
        });
    }

    setCurrency(currency: Currency) {
        this.mutate(state => {
            state.currency = currency;
            return state;
        });
    }

    updateFromQueryString(query: Record<string, any>) {
        const validationResult = validate(
           ListingSearchContext,
           {...query},
        );

        if (validationResult.success) {
           this.mutate(() => validationResult.value);
        }
    }

    toQueryString(): Record<string, any> {
        return classToPlain(this.value);
    }
}
