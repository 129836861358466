



















































import { Component, Ref } from "vue-property-decorator";
import { LvContainer, LvValidationResult } from "@fastoche/ui-kit/components";
import { CommonOnboardingPage } from "./CommonOnboardingPage";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { InjectService } from "@fastoche/ui-core/di";
import { OnboardingService } from "@/modules/onboarding/common/services/OnboardingService";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { HlOnboardingStore } from "@/modules/onboarding/hl/HlOnboardingStore";
import { AnyHodOnboardingStepId } from "@/modules/onboarding/hod/domain";
import { AnyHlOnboardingStepId } from "@/modules/onboarding/hl/domain";
import { OnboardingId, PaymentMethodType } from "@/modules/onboarding/common/domain";
import { IbanPaymentMethodModel, PaymentInfoModel } from "@/modules/onboarding/common/model";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";

export const COMPONENT_NAME = "OnboardingPaymentInfoPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvField,
        LvFormItem,
        LvForm,
        LvIcon,
        LvButton,
        PageHeader,
        LvContainer,
        LvValidationResult,
    },
})
export default class OnboardingPaymentInfoPage extends CommonOnboardingPage {
    @InjectService(OnboardingService)
    onboardingService!: OnboardingService;

    @InjectService(HlOnboardingStore)
    hlStore!: HlOnboardingStore;

    @InjectService(HodOnboardingStore)
    hodStore!: HodOnboardingStore;

    @Ref()
    form!: LvForm;

    model: PaymentInfoModel = new PaymentInfoModel();

    mounted() {
        void this.loadModel();
    }

    @LoaderTask
    async loadModel() {
        await this.onboardingService.loadStep(AnyHlOnboardingStepId.PAYMENT_INFO);

        const model = new PaymentInfoModel();
        const { data } = this.userOnboarding.paymentInfo;

        if (data) {
            model.methods = data.methods.map(x => {
                switch (x.type) {
                    case PaymentMethodType.IBAN: {
                        const method = new IbanPaymentMethodModel();
                        method.iban = x.iban!;
                        method.bic = x.bic!;
                        return method;
                    }
                }
            });
        }

        this.model = model;
    }

    addIban() {
        const model = new IbanPaymentMethodModel();
        this.model.methods.push(model);
    }

    @LoaderTask
    async onSubmit() {
        try {
            if (!(await this.form.submit())) return;

            const result = await this.onboardingService.submitPaymentInfoStep(this.userOnboarding.onboardingId, this.model);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.backLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
            else {
                await this.nextStep();
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async nextStep() {
        switch (this.onboardingId) {
            case OnboardingId.HL:
                const hlNextPage = this.hlStore.getNextStepLocation(AnyHlOnboardingStepId.PAYMENT_INFO);
                await this.$router.push(hlNextPage).catch(this.handleNavigationError);
                break;

            case OnboardingId.HOD:
                const hodNextPage = this.hodStore.getNextStepLocation(AnyHodOnboardingStepId.PAYMENT_INFO);
                await this.$router.push(hodNextPage).catch(this.handleNavigationError);
                break;
        }
    }
}
