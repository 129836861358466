



















import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { Personality } from "@/modules/onboarding/hod/domain";
import { HodMatchingSubstep, MatchingDataModel } from "@/modules/onboarding/hod/model";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";

const CHOICE_ICONS: Record<Personality, string> = {
    [Personality.OUTGOING]: require("@/app/assets/icons/dancing.svg"),
    [Personality.INTROVERT]: require("@/app/assets/icons/teacher.svg"),
    [Personality.FRIENDLY]: require("@/app/assets/icons/hug.svg"),
    [Personality.FUNNY]: require("@/app/assets/icons/clown.svg"),
};

export const COMPONENT_NAME = "OnboardingMatchingPersonalityPage";

@Component({
    name: COMPONENT_NAME,
    components: { LvButton },
    loader: true,
})
export default class OnboardingMatchingPersonalityPage extends BaseHodOnboardingPage {
    choices: Personality[] = Object.values(Personality);
    selected: Personality[] = [];

    @Prop({ type: Object })
    matchingData!: MatchingDataModel;

    mounted() {
        if (this.matchingData.personality != undefined) {
            this.selected.push(...this.matchingData.personality);
        }
    }

    getChoiceIcon(choice: Personality) {
        return CHOICE_ICONS[choice];
    }

    isSelected(choice: Personality) {
        return this.selected.includes(choice);
    }

    toggle(choice: Personality) {
        const idx = this.selected.indexOf(choice);
        if (idx < 0) this.selected.push(choice);
        else this.selected.splice(idx, 1);
    }

    get isValid() {
        return this.selected.length > 0;
    }

    nextStep() {
        this.matchingData.personality = [...this.selected];
        this.$emit("next", { from: HodMatchingSubstep.PERSONALITY });
    }
}
