











































import { Component, Prop, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvBadge, LvButton, LvControlPopover, LvIcon } from "@fastoche/ui-kit/components";
import { amenities, AmenityId } from "@/modules/listing/domain";
import AmenityIcon from "@/app/components/amenity/icon/AmenityIcon.vue";
import { ListingFilterContext } from "@/modules/listing/context/ListingFilterContext";

export const COMPONENT_NAME = "ListingAmenityFilter";

@Component({
    name: COMPONENT_NAME,
    components: {
        AmenityIcon,
        LvControlPopover,
        LvButton,
        LvBadge,
        LvIcon
    },
})
export default class ListingAmenityFilter extends BaseComponent {
    @Prop({ type: Object, required: true })
    readonly filters!: ListingFilterContext;

    @Ref()
    readonly popover!: LvControlPopover;

    private readonly amenities = amenities;

    get hasValue() {
        return (this.filters.amenities?.length ?? 0) > 0;
    }

    show() {
        this.popover.show();
    }

    hide() {
        this.popover.hide();
    }

    isSelected(amenity: AmenityId) {
        return this.filters.amenities?.includes(amenity) === true;
    }

    toggle(amenity: AmenityId) {
        if (this.filters.amenities == undefined) {
            this.$set(this.filters, "amenities", []);
            this.filters.amenities = [];
        }

        const idx = this.filters.amenities.indexOf(amenity);

        if (idx > -1) {
            this.filters.amenities.splice(idx, 1);
        }
        else {
            this.filters.amenities.push(amenity);
        }
    }

    reset() {
        this.filters.amenities = undefined;
        this.hide();
    }
}
