import { AnyHlOnboardingStepId } from "./domain";

export const HL_ONBOARDING_WORKFLOW: readonly AnyHlOnboardingStepId[] = [
    //AnyHlOnboardingStepId.HL_REVENUE_ESTIMATION,
    AnyHlOnboardingStepId.HL_UPCOMING_DEPARTURE,
    AnyHlOnboardingStepId.HL_ACCOMMODATION_DESCRIPTION,
    AnyHlOnboardingStepId.HL_ACCOMMODATION_PHOTOS,
    AnyHlOnboardingStepId.HL_INVENTORY,
    AnyHlOnboardingStepId.HL_ADMINISTRATIVE_INFO,
    AnyHlOnboardingStepId.EMAIL_VERIFICATION,
    AnyHlOnboardingStepId.IDENTITY,
    AnyHlOnboardingStepId.PAYMENT_INFO,
    AnyHlOnboardingStepId.HL_PAYMENT_PREFERENCES,
    AnyHlOnboardingStepId.HL_APPOINTMENT_REQUEST,
];

export const HL_ONBOARDING_ROOT_LOCATION = "/app/happy-leaver/onboarding";
export const HL_ONBOARDING_SUMMARY_LOCATION = `${HL_ONBOARDING_ROOT_LOCATION}/summary`;
export const HL_ONBOARDING_STEPS_LOCATION = `${HL_ONBOARDING_ROOT_LOCATION}/steps`;
export const HL_ONBOARDING_END_LOCATION = `${HL_ONBOARDING_ROOT_LOCATION}/pending`;
export const HL_ONBOARDING_REJECTED_LOCATION = `${HL_ONBOARDING_ROOT_LOCATION}/rejected`;

export const HL_ONBOARDING_STEPS_LOCATIONS: Record<AnyHlOnboardingStepId, string> = {
    [AnyHlOnboardingStepId.HL_REVENUE_ESTIMATION]: `${HL_ONBOARDING_STEPS_LOCATION}/estimation`,
    [AnyHlOnboardingStepId.HL_UPCOMING_DEPARTURE]: `${HL_ONBOARDING_STEPS_LOCATION}/next-departure`,
    [AnyHlOnboardingStepId.HL_ACCOMMODATION_DESCRIPTION]: `${HL_ONBOARDING_STEPS_LOCATION}/accommodation/description`,
    [AnyHlOnboardingStepId.HL_ACCOMMODATION_PHOTOS]: `${HL_ONBOARDING_STEPS_LOCATION}/accommodation/photos`,
    [AnyHlOnboardingStepId.HL_INVENTORY]: `${HL_ONBOARDING_STEPS_LOCATION}/inventory`,
    [AnyHlOnboardingStepId.HL_ADMINISTRATIVE_INFO]: `${HL_ONBOARDING_STEPS_LOCATION}/administrative`,
    [AnyHlOnboardingStepId.EMAIL_VERIFICATION]: `${HL_ONBOARDING_STEPS_LOCATION}/email-verification`,
    [AnyHlOnboardingStepId.IDENTITY]: `${HL_ONBOARDING_STEPS_LOCATION}/identity`,
    [AnyHlOnboardingStepId.PAYMENT_INFO]: `${HL_ONBOARDING_STEPS_LOCATION}/payment/info`,
    [AnyHlOnboardingStepId.HL_PAYMENT_PREFERENCES]: `${HL_ONBOARDING_STEPS_LOCATION}/payment/plan`,
    [AnyHlOnboardingStepId.HL_APPOINTMENT_REQUEST]: `${HL_ONBOARDING_STEPS_LOCATION}/appointment-request`,
};
