import { CreateElement, VNode } from "vue";
import { BaseComponent } from "@/app/BaseComponent";
import { Component } from "vue-property-decorator";

@Component({
    name: "RouterContainer",
})
export class RouterContainer extends BaseComponent {
    render(h: CreateElement): VNode {
        return (
            <router-view></router-view>
        );
    }
}
