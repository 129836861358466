












import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import LoginForm from "@/app/components/auth/login/LoginForm.vue";

export const COMPONENT_NAME = "LoginPage";

@Component({
    name: COMPONENT_NAME,
    components: {
        LoginForm,
    },
})
export default class LoginPage extends BaseComponent {
    email: string | null = null;

    created() {
        if (this.$route.query.email) {
            this.email = this.$route.query.email as string;
        }
    }

    showRegisterForm() {
        this.$router.replace("/register").catch(this.handleNavigationError);
    }

    onAuthenticated() {
        const location = this.$route.query.returnUrl as string | undefined ?? "/app";
        this.$router.replace(location).catch(this.handleNavigationError);
    }
}
