






















































import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { currencySymbol } from "@fastoche/ui-core/i18n/currency/filters/currencySymbol";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { LvCard, LvButton } from "@fastoche/ui-kit/components";
import TotalSummary from "@/app/components/listing/booking-card/TotalSummary.vue";
import BookingForm from "@/app/components/listing/booking-card/BookingForm.vue";
import { AccommodationType } from "@/modules/listing/domain";
import { BookingStore } from "@/modules/booking/BookingStore";
import { ListingStore } from "@/modules/listing/ListingStore";
import { LvIcon } from "@fastoche/ui-kit";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";
import { AuthenticationState } from "@/modules/auth/AuthenticationState";

export const COMPONENT_NAME = "BookingCard";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    responsive: true,
    components: {
        TotalSummary,
        BookingForm,
        LvCard,
        LvButton,
        LvIcon
    },
    filters: {
        currencySymbol,
        price,
    },
})
export default class BookingCard extends BaseComponent {
    @InjectState(BookingStore)
    readonly bookingState!: StateOf<BookingStore>;

    @InjectState(ListingStore)
    readonly listingState!: StateOf<ListingStore>;

    @InjectState(AuthenticationStore)
    readonly authState!: AuthenticationState;

    @Prop({ type: String, required: true })
    readonly listingId!: string;

    @Prop({ type: String, required: true })
    readonly type!: AccommodationType;

    get isAuthenticated() {
        return this.authState.user != null;
    }

    scrollToRoomsResults() {
        this.$emit("scroll-to-rooms-results");
    }

    book() {
        this.$emit("book");
    }
}
