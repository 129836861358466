import {FindListingByLocationQuery as query} from "../FindListingByLocationQuery";
import {BuildClientInvocation, LocaleAmbientValue, OptionalUserIdAmbientValue} from "@leavy/cq-core";

export class FindListingByLocationQuery extends BuildClientInvocation({
    type: query,
    ambientValues: {
        userId: OptionalUserIdAmbientValue,
        locale: LocaleAmbientValue
    }
}) {
}
