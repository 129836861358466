import { UserService } from "../../services/UserService";
import { injectable } from "inversify";
import { UserInfo } from "../../domain";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { GetUserInfoQuery } from "./query";
import { DeleteUserCommand } from "./command";
import { Bad, Ok, OkVal } from "@leavy/result";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";

@injectable()
export class CqUserService extends UserService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
        private readonly authStore: AuthenticationStore,
    ) {
        super();
    }

    async getUserInfo(): Promise<OkVal<UserInfo> | Bad<"USER_NOT_FOUND">> {
        return this.cq.invoke(GetUserInfoQuery, {});
    }

    async deleteUser(): Promise<Ok | Bad<"USER_NOT_DELETABLE">> {
        const result = await this.cq.invoke(DeleteUserCommand, {});

        if (result.success) {
            this.authStore.clearToken();
            return Ok();
        }
        else {
            switch (result.reason) {
                case "USER_NOT_DELETABLE":
                    return Bad("USER_NOT_DELETABLE");
            }
        }
    }
}
