





















































































































































import { Component, Prop, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvBadge, LvButton, LvDateRangePicker, LvDropdown, LvField, LvForm, LvFormItem, LvIcon, LvNumberInput, LvValidationResult, LvValidator } from "@fastoche/ui-kit/components";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { ListingSearchContext} from "@/modules/listing/context/ListingSearchContext";
import { InjectService } from "@fastoche/ui-core/di";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import DestinationInput from "@/app/ui/destination-input/DestinationInput.vue";
import { getCurrencySymbol } from "@fastoche/ui-core/i18n/currency";
import VueRouter from "vue-router";
import { LvDateRangePickerInterval } from "@fastoche/ui-kit/components/date-range-picker/model";
import { SearchBarViewModel } from "./model";
import { DateTime } from "luxon";
import { ListingSearchContextStore } from "@/modules/listing/context/ListingSearchContextStore";
import { UserContext, UserContextStore } from "@/modules/user/UserContext";

export const COMPONENT_NAME = "ListingSearchBar";

@Component({
    name: COMPONENT_NAME,
    components: {
        DestinationInput,
        LvButton,
        LvDropdown,
        LvForm,
        LvValidator,
        LvFormItem,
        LvField,
        LvNumberInput,
        LvIcon,
        LvValidationResult,
        LvBadge,
        LvDateRangePicker
    },
})
export default class ListingSearchBar extends BaseComponent {
    @InjectState(ListingSearchContextStore)
    readonly context!: ListingSearchContext;

    @InjectService(ListingSearchContextStore)
    readonly contextStore!: ListingSearchContextStore;

    @InjectState(LocalizationContext)
    readonly localizationContext!: StateOf<LocalizationContext>;

    @InjectState(UserContextStore)
    readonly userContext?: UserContext;

    @Prop({ type: String, required: false, default: null })
    readonly syncQueryString?: string;

    @Ref()
    readonly form!: LvForm;

    @Ref()
    readonly destinationDropdown!: LvDropdown;

    @Ref()
    readonly datesDropdown!: LvDropdown;

    @Ref()
    readonly travelersDropdown!: LvDropdown;

    private readonly minBookingDuration: LvDateRangePickerInterval = { value: 1, unit: "days" };
    private model: SearchBarViewModel = new SearchBarViewModel();
    private minDate = DateTime.local().startOf("day").plus({ day: 1 }).toISO();

    get currencySymbol() {
        return getCurrencySymbol(this.localizationContext.currentCurrency);
    }

    get canSearch() {
        return (
            this.model.location &&
            this.model.from &&
            this.model.to &&
            this.model.guests
        );
    }

    mounted() {
        if (this.syncQueryString) {
            this.readQueryString();
        }

        this.$subscribeTo(
            this.contextStore.value$,
            x => this.onSearchContextChange(x as ListingSearchContext),
        );
    }

    private onSearchContextChange(context: ListingSearchContext) {
        this.model.from = context.from ?? null;
        this.model.to = context.to ?? null;
        this.model.location = context.location;
        this.model.guests = context.guests ?? 1;

        if (this.syncQueryString) {
            this.updateQueryString();
        }
    }

    readQueryString() {
        if (!this.syncQueryString) return;

        if (this.syncQueryString in this.$route.query) {
            const queryProp = this.$route.query[this.syncQueryString];

            if (typeof queryProp == "object" && !(queryProp instanceof Array)) {
                this.contextStore.updateFromQueryString(queryProp);
            }
        }
    }

    updateQueryString() {
        if (!this.syncQueryString) return;

        this.$router
            .replace({
                query: {
                    ...this.$route.query,
                    [this.syncQueryString]: this.contextStore.toQueryString(),
                } as any,
            })
            .catch(this.handleNavigationError);
    }

    updateSearchContext() {
        this.contextStore.mutate(state => {
            state.from = this.model.from ?? undefined;
            state.to = this.model.to ?? undefined;
            state.location = this.model.location;
            state.guests = this.model.guests;

            return state;
        });
    }

    private async onSubmit() {
        if (!(await this.form.submit())) return;
        this.updateSearchContext();
    }
}
