import { injectable } from "inversify";
import { HouseListingQueryResult, ListingPriceQueryResult } from "@/modules/listing/model";
import { Bad, OkVal } from "@leavy/result";
import { BookingQuoteViewModel } from "@/modules/booking/model/BookingQuoteViewModel";

@injectable()
export abstract class ListingHouseService {
    abstract getHouseListingById(id: string): Promise<OkVal<HouseListingQueryResult | null> | Bad<"NOT_FOUND">>;

    abstract getHouseListingPrice(id: string, model: BookingQuoteViewModel, currency): Promise<OkVal<ListingPriceQueryResult> | Bad<"NOT_AVAILABLE"> | Bad<"DURATION_NOT_SUPPORTED">>;
}
