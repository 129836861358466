import { RouteConfig } from "vue-router";
import { applyCategoryMeta } from "@fastoche/ui-tracking/core/router/applyCategoryMeta";
import MyBookingsListPage from "@/app/pages/booking/my-bookings/MyBookingsListPage.vue";
import MyBookingsViewPage from "@/app/pages/booking/my-bookings/MyBookingsViewPage.vue";

export const MY_BOOKINGS_ROUTES: RouteConfig[] = [
    {
        path: "",
        name: "MyBookingsListPage",
        component: MyBookingsListPage,
        props: (route) => ({
            id: null,
        }),
    },
    {
        path: ":id",
        name: "MyBookingsViewPageDetails",
        component: MyBookingsListPage,
        props: (route) => ({
            id: route.params.id,
        }),
    },
].map(route => applyCategoryMeta("UserBookingsManagement", route));
