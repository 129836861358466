import { BuildClientInvocation, UserIdAmbientValue } from "@leavy/cq-core";
import * as OnboardingService from "@leavy/lv-onboarding-srv/lib/hod/command";
import { AddAdministrativeDocumentLocalCommand } from "./AddAdministrativeDocumentLocalCommand";
import { RemoveAdministrativeDocumentLocalCommand } from "./RemoveAdministrativeDocumentLocalCommand";
import { ForwardedOnboardingCommand } from "../../ForwardedOnboardingCommand";

export class EditMatchingStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.EditMatchingStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitMatchingStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitMatchingStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitMeetingStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitMeetingStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitAdministrativeDocumentsStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitAdministrativeDocumentsStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class SubmitGetToKnowUsStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitGetToKnowUsStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class AddAdministrativeDocumentCommand extends BuildClientInvocation({
    type: AddAdministrativeDocumentLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class RemoveAdministrativeDocumentCommand extends BuildClientInvocation({
    type: RemoveAdministrativeDocumentLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
