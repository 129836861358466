import { BuildClientInvocation, UserIdAmbientValue } from "@leavy/cq-core";
import { UpdateUserEmailCommand as _UpdateUserEmailCommand } from "./UpdateUserEmailCommand";
import { RegenerateEmailVerificationCodeCommand as _RegenerateEmailVerificationCodeCommand } from "./RegenerateEmailVerificationCode";
import { DeleteUserCommand as _DeleteUserCommand } from "./DeleteUserCommand";
import { UpdateUserPasswordCommand as _UpdateUserPasswordCommand } from "./UpdateUserPasswordCommand";

export * from "./PasswordLostCommand";
export * from "./RegisterUserCommand";
export * from "./ResetPasswordCommand";
export * from "./VerifyEmailCommand";
export * from "./RefreshTokenCommand";
export * from "./CredentialsLoginCommand";
export * from "./LogoutCommand";

export class UpdateUserEmailCommand extends BuildClientInvocation({
    type: _UpdateUserEmailCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class UpdateUserPasswordCommand extends BuildClientInvocation({
    type: _UpdateUserPasswordCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class RegenerateEmailVerificationCodeCommand extends BuildClientInvocation({
    type: _RegenerateEmailVerificationCodeCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class DeleteUserCommand extends BuildClientInvocation({
    type: _DeleteUserCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
