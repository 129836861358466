




























import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { HlUserManagementPeriod } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriod";
import { InjectService } from "@fastoche/ui-core/di";
import { FileService } from "@/modules/file/services/FileService";
import { HlUserAccommodation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodation";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { hugeLocaleDate } from "@/app/filters/date";

export const COMPONENT_NAME = "HlUserManagementPeriodPreviewCard";

@Component({
    name: COMPONENT_NAME,
    components: {},
    filters: {
        price,
        hugeLocaleDate
    }
})
export default class HlUserManagementPeriodPreviewCard extends BaseComponent {
    @InjectService(FileService)
    private readonly fileService!: FileService;

    @Prop({ type: Object, required: true })
    readonly period!: HlUserManagementPeriod;

    @Prop({ type: Object, required: true })
    readonly accommodation!: HlUserAccommodation;

    @Prop({ type: Boolean, required: false, default: false })
    readonly clickable!: boolean;

    private get cardClassList() {
        return [
            this.clickable ? "clickable" : null,
        ].filter(x => x != null) as string[];
    }

    private get coverPhoto() {
        const [firstPhoto] = this.accommodation.photos;
        if (!firstPhoto) return null;
        return this.fileService.getFileUrl(firstPhoto).url;
    }

    private onCardClick(ev: MouseEvent) {
        if (this.clickable) {
            this.$emit("click", ev);
        }
    }
}
