import { OkVal, Bad } from "@leavy/result";
import { Command, RpcInvocable } from "@leavy/cq-core";
import { DomainEventBase, DomainEvent, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { IsEmail, IsNotEmpty, IsOptional, IsString, LowerCase, MinLength, Trim, IsEnum } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { OnboardingId } from "@leavy/lv-onboarding-srv/lib/common/domain/OnboardingId";

export type RegisteringUserCommandResult =
    | OkVal<{ id: string }>
    | Bad<"EMAIL_TAKEN" | "PHONE_TAKEN">;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "register_user",
})
export class RegisterUserCommand extends Command<RegisteringUserCommandResult, UserRegisteredEvent> {
    @IsOptional()
    @IsEnum(OnboardingId)
    onboardingId?: OnboardingId;

    @IsEmail()
    @Trim()
    @LowerCase()
    email!: string;

    @IsString()
    @IsNotEmpty()
    @Trim()
    phone!: string;

    @IsString()
    @IsNotEmpty()
    @Trim()
    firstName!: string;

    @IsString()
    @IsNotEmpty()
    @Trim()
    lastName!: string;

    @IsString()
    @IsNotEmpty()
    locale!: string;

    @IsString()
    @MinLength(6)
    password!: string;

    @IsOptional()
    @IsString()
    @Trim()
    referrerCode?: string;

    @IsOptional()
    @IsString()
    utmSource?: string;

    @IsOptional()
    @IsString()
    utmMedium?: string;

    @IsOptional()
    @IsString()
    utmCampaign?: string;
}

@DomainEvent({ namespace: HOMESHARING_BACKEND_SERVICE_NAME, name: "user_registered", version: 3 })
export class UserRegisteredEvent extends DomainEventBase {
    @EventProperty()
    id!: string;

    @EventProperty()
    email!: string;

    @EventProperty()
    phone!: string;

    @EventProperty()
    firstName!: string;

    @EventProperty()
    lastName!: string;

    @EventProperty()
    locale!: string;

    @EventProperty()
    emailVerificationCode!: string;

    @EventProperty({ optional: true })
    utmSource?: string;

    @EventProperty({ optional: true })
    utmMedium?: string;

    @EventProperty({ optional: true })
    utmCampaign?: string;

    constructor(data: PayloadOf<UserRegisteredEvent>) {
        super(data);
    }
}
