

































































































import { Component, Ref } from "vue-property-decorator";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { LvBadge, LvButton, LvContainer, LvField, LvForm, LvFormItem, LvIcon, LvModal, MessageBox } from "@fastoche/ui-kit/components";
import { HlOnboardingStepId, InventorySubject, InventorySubjectType } from "@/modules/onboarding/hl/domain";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import OnboardingDocumentCollection from "@/app/components/onboarding/common/OnboardingDocumentCollection.vue";
import { InjectService } from "@fastoche/ui-core/di";
import { FileService } from "@/modules/file/services/FileService";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { IsNotEmpty, IsString } from "@leavy/validator";
import { ArrayUtils, assert } from "@leavy/utils";

class AddEditInventorySubjectViewModel extends InventorySubject {
    @IsString()
    @IsNotEmpty()
    declare name: string;
}

export const COMPONENT_NAME = "OnboardingInventoryPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        PageHeader,
        LvContainer,
        LvBadge,
        LvButton,
        OnboardingDocumentCollection,
        LvIcon,
        LvFormItem,
        LvForm,
        LvModal,
        LvField
    },
})
export default class OnboardingInventoryPage extends BaseHlOnboardingPage {
    @InjectService(FileService)
    private readonly fileService!: FileService;

    @Ref()
    private readonly addEditForm!: LvForm;

    @Ref()
    private readonly addEditModal!: LvModal;

    private readonly subjectTypes: InventorySubjectType[] = [
        InventorySubjectType.ROOM,
        InventorySubjectType.VALUABLE_OBJECT,
    ];

    private subjects: InventorySubject[] = [];
    private selectedTypeFilter: InventorySubjectType = InventorySubjectType.ROOM;
    private addEditModalMode: "add" | "edit" = "add";
    private addEditModel: AddEditInventorySubjectViewModel | null = null;

    get textRes() {
        switch (this.selectedTypeFilter) {
            case InventorySubjectType.ROOM: {
                return {
                    addButton: this.$t("app.onboarding.steps.inventory_of_fixtures.add_room"),
                    modalAddTitle: this.$t("app.onboarding.steps.inventory_of_fixtures.add_room"),
                    modalEditTitle: this.$t("app.onboarding.steps.inventory_of_fixtures.rename_room"),
                    inputName: this.$t("app.onboarding.steps.inventory_of_fixtures.room_name"),
                    confirmDeletionTitle: this.$t("app.onboarding.steps.inventory_of_fixtures.delete.title"),
                    confirmDeletionContent: this.$t("app.onboarding.steps.inventory_of_fixtures.delete.content"),
                };
            }

            case InventorySubjectType.VALUABLE_OBJECT: {
                return {
                    addButton: this.$t("app.onboarding.steps.valuables.add_item"),
                    modalAddTitle: this.$t("app.onboarding.steps.valuables.add_item"),
                    modalEditTitle: this.$t("app.onboarding.steps.valuables.rename_item"),
                    inputName: this.$t("app.onboarding.steps.valuables.item_name"),
                    confirmDeletionTitle: this.$t("app.onboarding.steps.valuables.delete.title"),
                    confirmDeletionContent: this.$t("app.onboarding.steps.valuables.delete.content"),
                };
            }
        }
    }

    get filteredSubjects() {
        return this.subjects.filter(x => x.type == this.selectedTypeFilter);
    }

    get canSubmit() {
        return this.subjects.filter(x => x.type == "ROOM" && x.isComplete()).length > 0;
    }

    created() {
        void this.loadModel();
    }

    @LoaderTask
    async loadModel() {
        await this.onboardingService.loadStep(HlOnboardingStepId.HL_INVENTORY);

        const { data } = this.hlState.onboarding.inventory;

        if (data) {
            this.subjects = data.subjects.map(subject => new InventorySubject(subject.id, subject));
        }
    }

    getFileUrl(fileId: string) {
        const { url } = this.fileService.getFileUrl(fileId);
        return url;
    }

    getSubjectsCountByType(type: InventorySubjectType) {
        return this.subjects.filter(x => x.type == type).length;
    }

    selectTypeFilter(type: InventorySubjectType) {
        this.selectedTypeFilter = type;
    }

    openAddEditModal(mode: "add" | "edit") {
        this.addEditModalMode = mode;
        this.addEditModal.show();
    }

    closeAddEditModal() {
        this.addEditModal.hide();
    }

    startAddSubject() {
        this.addEditModel = new AddEditInventorySubjectViewModel("", {
            id: "",
            type: this.selectedTypeFilter,
            photos: [],
        });

        this.openAddEditModal("add");
    }

    startEditSubject(subject: InventorySubject.Complete) {
        this.addEditModel = new AddEditInventorySubjectViewModel(subject.id, subject);
        this.openAddEditModal("edit");
    }

    async startRemoveSubject(subject: InventorySubject.Complete) {
        const result = await MessageBox.prompt({
            type: "danger",
            title: this.textRes.confirmDeletionTitle,
            message: this.textRes.confirmDeletionContent,
            confirmText: this.$t("common.yes"),
            cancelText: this.$t("common.no"),
        });

        if (result == "confirm") {
            await this.removeSubject(subject);
        }
    }

    @LoaderTask
    async onSubmitAddEditForm() {
        try {
            if (!(await this.addEditForm.submit())) return;

            assert(this.addEditModel);

            switch (this.addEditModalMode) {
                case "add":
                    await this.addSubject(this.addEditModel);
                    break;

                case "edit":
                    await this.editSubject(this.addEditModel);
                    break;
            }

            this.closeAddEditModal();
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    async addSubject(subject: InventorySubject.Complete) {
        try {
            const result = await this.hlOnboardingService.addInventorySubject(
                subject.type,
                subject.name,
            );

            if (result.success) {
                const { subjectId } = result.value;

                this.subjects.push(new InventorySubject(subjectId, {
                    id: subjectId,
                    name: subject.name,
                    type: subject.type,
                    photos: [],
                }));
            }
            else {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    async editSubject(subject: InventorySubject.Complete) {
        try {
            const result = await this.hlOnboardingService.editInventorySubject(
                subject.id,
                subject.name,
            );

            if (result.success) {
                const existing = ArrayUtils.findOrThrow(this.subjects, x => x.id == subject.id);
                existing.name = subject.name;
            }
            else {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "SUBJECT_NOT_FOUND": {
                        const subjectIdx = this.subjects.findIndex(x => x.id == subject.id);
                        if (subjectIdx > -1) this.subjects.splice(subjectIdx, 1);
                        return;
                    }
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async removeSubject(subject: InventorySubject.Complete) {
        try {
            const result = await this.hlOnboardingService.removeInventorySubject(
                subject.id,
            );

            if (result.success) {
                const existingIdx = this.subjects.findIndex(x => x.id == subject.id);
                if (existingIdx > -1) this.subjects.splice(existingIdx, 1);
            }
            else {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    async addSubjectPhoto(subject: InventorySubject, file: File) {
        try {
            const result = await this.hlOnboardingService.addInventoryPhoto(
                subject.id,
                file,
            );

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_WRONG_STATE":
                    case "ONBOARDING_NOT_FOUND":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "DUPLICATE_FILE":
                        // TODO: show error message
                        return;

                    case "SUBJECT_NOT_FOUND":
                        CommonErrorDialogs.unexpectedError();
                        const subjectIdx = this.subjects.findIndex(x => x.id == subject.id);
                        if (subjectIdx > -1) this.subjects.splice(subjectIdx, 1);
                        return;
                }
            }
            else {
                subject.photos.push(result.value.fileId);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    async removeSubjectPhoto(subject: InventorySubject, fileId: string) {
        try {
            const result = await this.hlOnboardingService.removeInventoryPhoto(
                subject.id,
                fileId,
            );

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_WRONG_STATE":
                    case "ONBOARDING_NOT_FOUND":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "SUBJECT_NOT_FOUND":
                        CommonErrorDialogs.unexpectedError();
                        const subjectIdx = this.subjects.findIndex(x => x.id == subject.id);
                        if (subjectIdx > -1) this.subjects.splice(subjectIdx, 1);
                        return;
                }
            }
            else {
                const idx = subject.photos.indexOf(fileId);

                if (idx > -1) {
                    subject.photos.splice(idx, 1);
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async onSubmit() {
        try {
            for (const subject of this.subjects) {
                if (subject.photos.length == 0) {
                    await this.removeSubject(subject as InventorySubject.Complete);
                }
            }

            const result = await this.hlOnboardingService.submitInventoryStep();

            if (result.success) {
                const hlNextPage = this.hlStore.getNextStepLocation(HlOnboardingStepId.HL_INVENTORY);
                await this.$router.push(hlNextPage).catch(this.handleNavigationError);
            }
            else {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
