import { Command, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { IsDateString, IsEnum, IsUUID } from "@leavy/validator";
import { HlPaymentSchedulingOption } from "@leavy/lv-homesharing-pricing-srv/lib/pricing/domain/HlPaymentSchedulingOption";
import { Bad, BadVal, OkVal } from "@leavy/result";
import { HlAccommodationManagementAvailability } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlAccommodation";

export type HlUserRequestNewManagementPeriodCommandResult =
    | OkVal<{ managementPeriodRequestId: string }>
    | Bad<"ACCOMMODATION_NOT_FOUND">
    | Bad<"INVALID_MANAGEMENT_PERIOD_DURATION">
    | Bad<"INVALID_SCHEDULING_OPTION_FOR_MANAGEMENT_PERIOD">
    | BadVal<"MANAGEMENT_PERIOD_DATES_CONFLICT", HlAccommodationManagementAvailability>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_request_new_management_period"
})
export class HlUserRequestNewManagementPeriodCommand extends Command<HlUserRequestNewManagementPeriodCommandResult> {
    @IsUUID()
    userId!: string;

    @IsUUID()
    accommodationId!: string;

    @IsDateString()
    departureDate!: string;

    @IsDateString()
    returnDate!: string;

    @IsEnum(HlPaymentSchedulingOption)
    paymentSchedulingOption!: HlPaymentSchedulingOption;
}
