










































import { Component, Ref } from "vue-property-decorator";
import { LvContainer } from "@fastoche/ui-kit/components";
import AddressInput from "@/app/ui/address-input/AddressInput.vue";
import AccommodationRentForm from "@/app/components/onboarding/hl/accommodation/AccommodationRentForm.vue";
import AccommodationLocationForm from "@/app/components/onboarding/hl/accommodation/AccommodationLocationForm.vue";
import AccommodationAmenitiesForm from "@/app/components/onboarding/hl/accommodation/AccommodationAmenitiesForm.vue";
import AccommodationQualityForm from "@/app/components/onboarding/hl/accommodation/AccommodationQualityForm.vue";
import AccommodationCapacityForm from "@/app/components/onboarding/hl/accommodation/AccommodationCapacityForm.vue";
import AccommodationBeddingForm from "@/app/components/onboarding/hl/accommodation/AccommodationBeddingForm.vue";
import AccommodationAccessesForm from "@/app/components/onboarding/hl/accommodation/AccommodationAccessesForm.vue";
import { StepFormComponent } from "@/modules/onboarding/hl/StepForm";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { AnyHlOnboardingStepId } from "@/modules/onboarding/hl/domain";
import { HlAccommodationAccessDetailsModel, HlAccommodationAccessesModel, HlAccommodationAmenitiesModel, HlAccommodationBeddingModel, HlAccommodationCapacityModel, HlAccommodationDescriptionDataModel, HlAccommodationLocationModel, HlAccommodationQualityModel, HlAccommodationRentModel } from "@/modules/onboarding/hl/model";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import LvProgressBar from "@fastoche/ui-kit/components/progress-bar/LvProgressBar.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { AddressModel } from "@/modules/core/model";

interface DescriptionStep {
    property: string;
    component: string;
    titleRes: string;
}

export const COMPONENT_NAME = "OnboardingAccommodationDescriptionPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        AccommodationRentForm,
        AccommodationLocationForm,
        AccommodationAmenitiesForm,
        AccommodationQualityForm,
        AccommodationCapacityForm,
        AccommodationBeddingForm,
        AccommodationAccessesForm,
        LvButton,
        LvProgressBar,
        PageHeader,
        AddressInput,
        LvContainer,
    },
})
export default class OnboardingAccommodationDescriptionPage extends BaseHlOnboardingPage {
    @Ref()
    component!: StepFormComponent;

    @InjectState(LocalizationContext)
    readonly localizationContext!: StateOf<LocalizationContext>;

    currentStepIdx = 0;

    model: HlAccommodationDescriptionDataModel = new HlAccommodationDescriptionDataModel();

    get steps(): DescriptionStep[] {
        return [
            {
                property: "rent",
                component: "AccommodationRentForm",
                titleRes: this.$t("app.onboarding.steps.flat_description.rent.title"),
            },
            {
                property: "location",
                component: "AccommodationLocationForm",
                titleRes: this.$t("app.onboarding.steps.flat_description.location.title"),
            },
            {
                property: "amenities",
                component: "AccommodationAmenitiesForm",
                titleRes: this.$t("app.onboarding.steps.flat_description.amenities.title"),
            },
            {
                property: "quality",
                component: "AccommodationQualityForm",
                titleRes: this.$t("app.onboarding.steps.flat_description.ratings.title"),
            },
            {
                property: "capacity",
                component: "AccommodationCapacityForm",
                titleRes: this.$t("app.onboarding.steps.flat_description.beds.title"),
            },
            {
                property: "bedding",
                component: "AccommodationBeddingForm",
                titleRes: this.$t("app.onboarding.steps.flat_description.bedding.title"),
            },
            {
                property: "accesses",
                component: "AccommodationAccessesForm",
                titleRes: this.$t("app.onboarding.steps.flat_description.accesses.title"),
            },
        ];
    }

    get currentStep() {
        return this.steps[this.currentStepIdx]!;
    }

    get progress() {
        return Math.round((this.currentStepIdx / this.steps.length) * 100);
    }

    created() {
        void this.loadModel();
    }

    @LoaderTask
    async loadModel() {
        await this.onboardingService.loadStep(AnyHlOnboardingStepId.HL_ACCOMMODATION_DESCRIPTION);

        const model = new HlAccommodationDescriptionDataModel();
        const { data } = this.hlState.onboarding.description;

        if (data?.rent) {
            const rent = (model.rent = new HlAccommodationRentModel());
            rent.value = data.rent.value ?? 0;
            rent.currency = data.rent.currency ?? this.localizationContext.currentCurrency;
        }

        if (data?.location) {
            const location = (model.location = new HlAccommodationLocationModel());
            location.floor = data.location.floor ?? 0;
            location.elevator = data.location.elevator ?? false;
            location.address = data.location.address ? Object.assign(new AddressModel(), data.location.address) : null!;
        }

        if (data?.amenities) {
            const amenities = (model.amenities = new HlAccommodationAmenitiesModel());
            amenities.selected = data.amenities.selected ?? [];
        }

        if (data?.quality) {
            const quality = (model.quality = new HlAccommodationQualityModel());
            quality.standing = data.quality.standing ?? null!;
            quality.luminosity = data.quality.luminosity ?? 1;
            quality.furnishings = data.quality.furnishings ?? 1;
            quality.bedding = data.quality.bedding ?? 1;
            quality.bathrooms = data.quality.bathrooms ?? 1;
        }

        if (data?.capacity) {
            const capacity = (model.capacity = new HlAccommodationCapacityModel());
            capacity.surfaceArea = data.capacity.surfaceArea ?? 0;
            capacity.bedrooms = data.capacity.bedrooms ?? 0;
            capacity.bathrooms = data.capacity.bathrooms ?? 0;
            capacity.simpleBeds = data.capacity.simpleBeds ?? 0;
            capacity.doubleBeds = data.capacity.doubleBeds ?? 0;
            capacity.sofaBeds = data.capacity.sofaBeds ?? 0;
        }

        if (data?.bedding) {
            const bedding = (model.bedding = new HlAccommodationBeddingModel());
            bedding.duvets = data.bedding.duvets ?? 0;
            bedding.duvetCovers = data.bedding.duvetCovers ?? 0;
            bedding.pillows = data.bedding.pillows ?? 0;
            bedding.pillowCases = data.bedding.pillowCases ?? 0;
            bedding.bedSheets = data.bedding.bedSheets ?? 0;
            bedding.towels = data.bedding.towels ?? 0;
        }

        if (data?.accesses) {
            const accesses = (model.accesses = new HlAccommodationAccessesModel());

            if (data.accesses.trash) {
                accesses.trash = Object.assign(
                    new HlAccommodationAccessDetailsModel(),
                    data.accesses.trash ?? { isPresent: null!, notes: null! },
                );
            }

            if (data.accesses.gas) {
                accesses.gas = Object.assign(
                    new HlAccommodationAccessDetailsModel(),
                    data.accesses.gas ?? { isPresent: null!, notes: null! },
                );
            }

            if (data.accesses.circuitBreaker) {
                accesses.circuitBreaker = Object.assign(
                    new HlAccommodationAccessDetailsModel(),
                    data.accesses.circuitBreaker ?? { isPresent: null!, notes: null! },
                );
            }

            if (data.accesses.waterMeter) {
                accesses.waterMeter = Object.assign(
                    new HlAccommodationAccessDetailsModel(),
                    data.accesses.waterMeter ?? { isPresent: null!, notes: null! },
                );
            }

            if (data.accesses.wifi) {
                accesses.wifi = Object.assign(
                    new HlAccommodationAccessDetailsModel(),
                    data.accesses.wifi ?? { isPresent: null!, notes: null! },
                );
            }
        }

        this.model = model;
    }

    @LoaderTask
    async next() {
        const result = await this.component.submit();
        if (!result) return;

        (this.model as any)[this.currentStep.property] = result;

        if (this.currentStepIdx < this.steps.length) {
            const saveResult = await this.hlOnboardingService.saveAccommodationDescriptionStep(
                { [this.currentStep.property]: result } as any
            );

            if (saveResult.success) {
                this.currentStepIdx++;
            }
            else {
                switch (saveResult.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;
                }
            }
        }

        if (this.currentStepIdx === this.steps.length) {
            try {
                const submitResult = await this.hlOnboardingService.submitAccommodationDescriptionStep();

                if (submitResult.success) {
                    const hlNextPage = this.hlStore.getNextStepLocation(AnyHlOnboardingStepId.HL_ACCOMMODATION_DESCRIPTION);
                    await this.$router.push(hlNextPage).catch(this.handleNavigationError);
                }
                else {
                    switch (result.reason) {
                        case "ONBOARDING_NOT_FOUND":
                        case "ONBOARDING_WRONG_STATE":
                            await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                            return;

                        case "STEP_INCOMPLETE":
                            CommonErrorDialogs.validationError();
                            return;
                    }
                }
            }
            catch (e) {
                this.catchAndNotifyError(e);
            }
        }
    }

    prev() {
        if (this.currentStepIdx >= 0) {
            this.currentStepIdx--;
        }
    }
}
