











import { Component, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { UserContextStore } from "@/modules/user/UserContext";
import { InjectState, StateOf } from "@fastoche/ui-core/store";

const HTML_TEMPLATE = require("raw-loader!./template.html").default;

export const COMPONENT_NAME = "IntercomFrame";

@Component({
    name: COMPONENT_NAME,
    loader: true
})
export default class IntercomFrame extends BaseComponent {
    @Ref()
    readonly intercomFrame!: HTMLIFrameElement;

    @InjectState(UserContextStore)
    readonly userContext!: StateOf<UserContextStore>;

    private messageHandler: ((ev: WindowEventMap["message"]) => any) | null = null;

    beforeMount() {
        window.Intercom("hide");
    }

    mounted() {
        this.$loader.begin();

        if (this.loadFrameContent()) {
            this.setupFrame();
        }
        else {
            this.fallbackFrameNotAvailable();
            this.$loader.end();
        }
    }

    beforeDestroy() {
        if (this.messageHandler) {
            window.removeEventListener("message", this.messageHandler);
            this.messageHandler = null;
        }
    }

    loadFrameContent() {
        const frameDocument = this.intercomFrame.contentWindow?.document;

        if (frameDocument) {
            frameDocument.open();
            frameDocument.write(HTML_TEMPLATE);
            frameDocument.close();

            return true;
        }
        else {
            return false;
        }
    }

    setupFrame() {
        if (this.messageHandler == null) {
            this.messageHandler = this.onWindowMessage.bind(this);
            window.addEventListener("message", this.messageHandler);
        }
    }

    onWindowMessage(ev: WindowEventMap["message"]) {
        if (ev.origin != window.location.origin) return;

        const data = ev.data;
        if (!data || !data.type) return;

        switch (data.type) {
            case "onIntercomShow":
                this.$loader.end();
                break;

            case "onIntercomHide":
                this.$router.push(".").catch(this.handleNavigationError);
                break;
        }
    }

    fallbackFrameNotAvailable() {
        window.Intercom("show");
    }
}
