import { StartOnboardingCommand as StartOnboardingExternalCommand } from "@leavy/lv-onboarding-srv/lib/common/command";
import { RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "start_onboarding",
})
export class StartOnboardingLocalCommand extends StartOnboardingExternalCommand {

}
