import { Equals, IsDefined, IsUUID } from "@leavy/validator";
import { StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { OnboardingId } from "@leavy/lv-onboarding-srv/lib/common/domain/OnboardingId";
import { Bad } from "@leavy/result";
import { BinaryFile, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../../base";
import { FileUploadResult } from "../../common/FileUploadResult";
import { BaseFile } from "../../../common/BaseFile";
import { CommonEditOnboardingError } from "../../ForwardedOnboardingCommand";

export type AddInventorySubjectPhotoLocalCommandResult =
    | FileUploadResult
    | Bad<"SUBJECT_NOT_FOUND">
    | Bad<"PHOTO_ALREADY_ADDED">
    | CommonEditOnboardingError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "add_inventory_subject_photo",
})
export class AddInventorySubjectPhotoLocalCommand extends StepCommand<AddInventorySubjectPhotoLocalCommandResult> {
    @Equals(OnboardingId.HL)
    onboardingId: OnboardingId.HL = OnboardingId.HL;

    @IsUUID("4")
    subjectId!: string;

    @BinaryFile()
    @IsDefined()
    photo!: BaseFile;
}
