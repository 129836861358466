import { IsNotEmpty, IsOptional, IsString } from "@leavy/validator";
import { Query, RpcInvocable } from "@leavy/cq-core";
import { Bad, OkVal } from "@leavy/result";
import { LocalizedLanguageDictionary } from "../model/LocalizedLanguageDictionary";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

/**
 * This error occurs if the requested locale is not supported by the system.
 */
type LocaleNotSupportedError = Bad<"LOCALE_NOT_SUPPORTED">;

export type GetLocalizedLanguagesQueryResult =
    | OkVal<LocalizedLanguageDictionary>
    | LocaleNotSupportedError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "get_localization_languages",
})
export class GetLocalizedLanguagesQuery extends Query<GetLocalizedLanguagesQueryResult> {
    @IsString()
    @IsNotEmpty()
    locale!: string;

    @IsString()
    @IsOptional()
    fallback: string = "en";
}
