import { Query, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { IsInstance, IsUUID, ValidateNested } from "@leavy/validator";
import { Type } from "class-transformer";
import { PaginationOptionsModel } from "@leavy/lv-homesharing-srv/lib/common/model/PaginationOptionsModel";
import { OkVal } from "@leavy/result";
import { PaginatedResult } from "@leavy/lv-homesharing-srv/lib/common/domain/Pagination";
import { HlUserManagementPeriodRequest } from "../domain/HlUserManagementPeriodRequest";

export type HlUserGetManagementPeriodRequestsQueryResult =
    OkVal<PaginatedResult<HlUserManagementPeriodRequest>>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_get_management_period_requests"
})
export class HlUserGetManagementPeriodRequestsQuery extends Query<HlUserGetManagementPeriodRequestsQueryResult> {
    @IsUUID()
    userId!: string;

    @Type(() => PaginationOptionsModel)
    @IsInstance(PaginationOptionsModel)
    @ValidateNested()
    pagination!: PaginationOptionsModel;
}
