import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { GetTranslationsForLocaleQuery } from "@leavy/lv-homesharing-backend-srv/lib/i18n/query";
import { TranslationProvider } from "@fastoche/ui-core/i18n/translation/provider/TranslationProvider";
import { TranslationMap } from "@fastoche/ui-core/i18n/translation/model";
import { LocaleObject } from "@fastoche/ui-core/i18n/locale/Locale";

@injectable()
export class BackendTranslationProvider extends TranslationProvider {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    async getTranslationsForLocale(locale: LocaleObject): Promise<TranslationMap> {
        const result = await this.cq.invoke(GetTranslationsForLocaleQuery, { locale: locale.localeCode, fallback: "en" });
        if (!result.success || !result.hasValue) throw new Error("Can't load translations for locale");
        const dictionary: Record<string, string> = result.value;
        return new Map(Object.entries(dictionary));
    }
}
