import { Command, RpcInvocable } from "@leavy/cq-core";
import { Bad, Ok } from "@leavy/result";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { IsString } from "@leavy/validator";
import { CommonBadResult } from "../results";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

export type RegenerateEmailVerificationCodeCommandResult =
    | Ok
    | Bad<CommonBadResult.USER_NOT_FOUND | "regeneration_failed">
    ;

/**
 * RegenerateEmailVerificationCodeCommand command description
 */
@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "regenerate_email_verification_code",
})
export class RegenerateEmailVerificationCodeCommand extends Command<RegenerateEmailVerificationCodeCommandResult, EmailVerificationCodeRegeneratedEvent> {
    @IsString()
    userId!: string;
}

@DomainEvent({ namespace: HOMESHARING_BACKEND_SERVICE_NAME, name: "email_verification_code_regenerated", version: 1 })
export class EmailVerificationCodeRegeneratedEvent extends DomainEventBase {
    @EventProperty()
    userId!: string;

    @EventProperty()
    email!: string;

    @EventProperty()
    verificationCode!: string;

    constructor(data: PayloadOf<EmailVerificationCodeRegeneratedEvent>) {
        super(data);
    }
}
