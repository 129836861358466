import { IsString, IsOptional, Type, ValidateNested } from "@leavy/validator";
import { HlSimpleRevenueEstimationRequestModel } from "@/modules/pricing/model";

export class HlRegistrationPageQueryStringModel {
    @Type(() => HlSimpleRevenueEstimationRequestModel)
    @IsOptional()
    @ValidateNested()
    estimation?: HlSimpleRevenueEstimationRequestModel;

    @IsOptional()
    @IsString()
    referrerCode?: string;
}
