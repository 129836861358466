









































































import { Component } from "vue-property-decorator";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";
import { LvButton, LvCard, LvContainer, LvIcon } from "@fastoche/ui-kit/components";
import HlRegistrationSteps from "@/app/ui/hl-registration-steps/HlRegistrationSteps.vue";
import { HlOnboardingRejectionReason } from "@leavy/lv-onboarding-srv/lib/hl/domain/HlUserOnboardingRejectionInfo";
import { DateTime } from "luxon";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { HL_ONBOARDING_ROOT_LOCATION } from "@/modules/onboarding/hl/workflow";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { shortLocaleDate } from "@/app/filters/date";

export const COMPONENT_NAME = "HlOnboardingRejectedPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvIcon,
        LvContainer,
        HlRegistrationSteps,
        LvCard,
        LvButton,
    },
    filters: {
        shortLocaleDate
    },
})
export default class HlOnboardingRejectedPage extends BaseHlOnboardingPage {
    get title() {
        return this.canRestart ?
            this.$t("onboarding.hl.rejected.title") :
            this.$t("onboarding.hl.rejected_definitive.title");
    }

    get subtitle() {
        return this.$t("onboarding.hl.rejected.subtitle");
    }

    get rejectionInfo() {
        return this.hlState.onboarding.rejectionInfo;
    }

    get canRestart() {
        return this.rejectionInfo?.isDefinitive !== true;
    }

    get rejectionDate() {
        return this.rejectionInfo?.rejectionDate ?? this.hlState.onboarding.lastModificationDate;
    }

    get rejectionDetails() {
        return this.rejectionInfo?.details || "-";
    }

    get rejectionReason() {
        if (this.rejectionInfo?.reason) {
            switch (this.rejectionInfo.reason) {
                case HlOnboardingRejectionReason.NOT_INTERESTED:
                    return this.$t("onboarding.hl.rejected.reasons.user_not_interested");

                case HlOnboardingRejectionReason.ZONE_NOT_SUPPORTED_YET:
                    return this.$t("onboarding.hl.rejected.reasons.zone_not_yet_supported");

                case HlOnboardingRejectionReason.DUPLICATE:
                    return this.$t("onboarding.hl.rejected.reasons.is_duplicate");

                case HlOnboardingRejectionReason.FAKE_USER:
                case HlOnboardingRejectionReason.SUSPICIOUS_USER:
                case HlOnboardingRejectionReason.OTHER:
                    return this.$t("onboarding.hl.rejected.reasons.other");
            }
        }

        return this.$t("onboarding.hl.rejected.reasons.other");
    }

    @LoaderTask
    async restart() {
        try {
            const result = await this.hlOnboardingService.restartOnboarding();

            if (result.success) {
                await this.$router.replace(HL_ONBOARDING_ROOT_LOCATION).catch(this.handleNavigationError);
            }
            else {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                    case "ONBOARDING_DEFINITIVE_REJECTION":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace("/").catch(this.handleNavigationError);
                        break;
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
