import { BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { FileService } from "@/modules/file/services/FileService";
import { CqFileService } from "@/modules/file/platform/cq/CqFileService";

@Module()
export class FileModule extends BaseModule {
    registerServices(container: ModuleContainer): void {
        container.bind(FileService).to(CqFileService).inSingletonScope();
    }
}
