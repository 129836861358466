import { AmenityId } from "../domain/Amenity";
import { HotelRoom, ListingId } from "../domain/Listing";
import { AccommodationType } from "../domain/AccommodationType";
import { HotelId, HotelListingId, HotelRoomId } from "../domain/Hotel";
import { Alpha3CountryCode } from "../domain/Country";

export interface ListingQueryResult {
    id: string;
    title: string;
    type: AccommodationType;
    description: string;
    photos: string[];
    amenities: AmenityId[];
    location: {
        city: string;
        country: string;
        countryCode: Alpha3CountryCode;
        lat: number;
        lon: number;
    };
}

export interface HouseListingQueryResult extends ListingQueryResult {
    id: ListingId;
    type: AccommodationType.HOUSE;
    occupancy: number;
    beds: number;
    bathrooms: number;
}


export interface HotelQueryResult extends ListingQueryResult {
    id: HotelId;
    type: AccommodationType.HOTEL;
}

export interface HotelRoomQueryResult extends ListingQueryResult {
    id: HotelId;
    type: AccommodationType.HOTEL;
    room: {
        roomId: HotelRoomId;
        tile: string;
        summary: string;
        occupancy: number;
        amenities: AmenityId[];
        photos: string[];
    };
}

export enum PriceRange {
    FREE = "FREE",
    AFFORDABLE = "AFFORDABLE"
}

export interface ListingFilters {
    priceRange?: PriceRange;
    occupancy?: number;
    bedrooms?: number;
    amenities?: AmenityId[];
}
