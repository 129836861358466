


























































import { Component, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvBadge, LvContainer, LvField, LvIcon, LvLightContainer, LvTag } from "@fastoche/ui-kit/components";
import { SessionService } from "@/modules/auth/services/SessionService";
import { InjectService } from "@fastoche/ui-core/di";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { UserService } from "@/modules/user/services/UserService";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { MessageBox } from "@fastoche/ui-kit/components";
import { UserContext, UserContextStore } from "@/modules/user/UserContext";
import { InjectState } from "@fastoche/ui-core/store";

export const COMPONENT_NAME = "UserHomePage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        PageHeader,
        LvContainer,
        LvLightContainer,
        LvTag,
        LvField,
        LvIcon,
        LvBadge
    },
})
export default class UserHomePage extends BaseComponent {
    @InjectService(SessionService)
    private readonly sessionService!: SessionService;

    @InjectService(UserService)
    private readonly userService!: UserService;

    @InjectState(UserContextStore)
    private readonly userContext!: UserContext;

    get initials() {
        return [
            this.userContext.firstName,
            this.userContext.lastName,
        ].map(x => x[0] ?? "").join("");
    }

    onUserConfirmLogout() {
        void this.$track("UserConfirmLogOutEvent");
        void this.logout();
    }

    onUserConfirmDelete() {
        void this.$track("UserConfirmDeleteAccountEvent");
        void this.deleteUser();
    }

    async confirmLogout() {
        const result = await MessageBox.prompt({
            type: "info",
            confirmText: this.$t("common.yes"),
            cancelText: this.$t("common.no"),
            isCancellable: true,
            title: this.$t("app.logout_confirm.title"),
            message: this.$t("app.logout_confirm.message"),
        });

        if (result == "confirm") {
            this.onUserConfirmLogout();
        }
    }

    async confirmDeleteUser() {
        const result = await MessageBox.prompt({
            type: "danger",
            confirmText: this.$t("common.yes"),
            cancelText: this.$t("common.no"),
            isCancellable: true,
            title: this.$t("app.user_delete_confirm.title"),
            message: this.$t("app.user_delete_confirm.message"),
        });

        if (result == "confirm") {
            this.onUserConfirmDelete();
        }
    }

    @LoaderTask
    async logout() {
        try {
            await this.sessionService.end();
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async deleteUser() {
        try {
            const result = await this.userService.deleteUser();

            if (!result.success) {
                CommonErrorDialogs.unexpectedError();
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
