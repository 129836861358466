import { AnyHodOnboardingStepId } from "./domain";

export const HOD_ONBOARDING_WORKFLOW: readonly AnyHodOnboardingStepId[] = [
    AnyHodOnboardingStepId.EMAIL_VERIFICATION,
    AnyHodOnboardingStepId.IDENTITY,
    AnyHodOnboardingStepId.HOD_MATCHING,
    AnyHodOnboardingStepId.HOD_GET_TO_KNOW_US,
    AnyHodOnboardingStepId.HOD_ADMINISTRATIVE_DOCS,
    AnyHodOnboardingStepId.PAYMENT_INFO,
    AnyHodOnboardingStepId.HOD_MEETING,
];

export const HOD_ONBOARDING_ROOT_LOCATION = "/app/host-on-demand/onboarding";
export const HOD_ONBOARDING_STEPS_LOCATION = `${HOD_ONBOARDING_ROOT_LOCATION}/steps`;
export const HOD_ONBOARDING_END_LOCATION = `${HOD_ONBOARDING_ROOT_LOCATION}/pending`;
export const HOD_ONBOARDING_ACCEPTED_LOCATION = `${HOD_ONBOARDING_ROOT_LOCATION}/accepted`;
export const HOD_ONBOARDING_REJECTED_LOCATION = `${HOD_ONBOARDING_ROOT_LOCATION}/rejected`;

export const HOD_ONBOARDING_STEPS_LOCATIONS: Record<AnyHodOnboardingStepId, string> = {
    [AnyHodOnboardingStepId.EMAIL_VERIFICATION]: `${HOD_ONBOARDING_STEPS_LOCATION}/email-verification`,
    [AnyHodOnboardingStepId.IDENTITY]: `${HOD_ONBOARDING_STEPS_LOCATION}/identity`,
    [AnyHodOnboardingStepId.HOD_MATCHING]: `${HOD_ONBOARDING_STEPS_LOCATION}/about-you`,
    [AnyHodOnboardingStepId.HOD_GET_TO_KNOW_US]: `${HOD_ONBOARDING_STEPS_LOCATION}/get-to-know-us`,
    [AnyHodOnboardingStepId.HOD_ADMINISTRATIVE_DOCS]: `${HOD_ONBOARDING_STEPS_LOCATION}/administrative-docs`,
    [AnyHodOnboardingStepId.PAYMENT_INFO]: `${HOD_ONBOARDING_STEPS_LOCATION}/payment/info`,
    [AnyHodOnboardingStepId.HOD_MEETING]: `${HOD_ONBOARDING_STEPS_LOCATION}/online-meeting`,
};
