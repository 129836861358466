





















































































import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { distinctUntilChanged } from "rxjs/operators";
import { InjectService } from "@fastoche/ui-core/di";
import { SessionService } from "@/modules/auth/services/SessionService";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";
import PageFooter from "@/app/ui/page-footer/PageFooter.vue";
import { APP_CONFIG } from "@/app/config";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { UserOnboardingStatus } from "@/modules/onboarding/common/domain";
import { HlOnboardingStore } from "@/modules/onboarding/hl/HlOnboardingStore";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";

type AppNavigationTarget = "hl" | "hod" | "my_bookings" | "my_account" | "customer_service";

export const COMPONENT_NAME = "AppLayout";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvIcon,
        PageFooter,
    },
})
export default class AppLayout extends BaseComponent {
    @InjectService(AuthenticationStore)
    private readonly authStore!: AuthenticationStore;

    @InjectService(SessionService)
    private readonly sessionService!: SessionService;

    @InjectState(HlOnboardingStore)
    private readonly hlOnboarding!: StateOf<HlOnboardingStore>;

    @InjectState(HodOnboardingStore)
    private readonly hodOnboarding!: StateOf<HodOnboardingStore>;

    private folded = false;
    private readonly enableOldTravelApp = APP_CONFIG.ENABLE_OLD_TRAVEL_APP;

    private created() {
        this.$subscribeTo(
            this.authStore.isAuthenticated$.pipe(distinctUntilChanged()),
            (isAuthenticated) => this.onAutenticationChanged(isAuthenticated),
        );
    }

    @LoaderTask
    private async onAutenticationChanged(isAuthenticated: boolean) {
        if (!isAuthenticated && this.$router.currentRoute.path !== "/login") {
            await this.$router.replace({
                path: "/login",
                query: {
                    returnUrl: this.$route.path,
                    ...this.$route.query,
                },
            }).catch(this.handleNavigationError);
        }
    }

    private get showHod() {
        const hasNoOnboardingStarted = !this.hlOnboarding && !this.hodOnboarding;
        const isHodOnboardingStarted = !!this.hodOnboarding;
        const isInHodSection = this.$route.fullPath.startsWith("/app/host-on-demand");

        return (
            hasNoOnboardingStarted
            || isHodOnboardingStarted
            || isInHodSection
        );
    }

    private onNavigationClick(ev: MouseEvent, target: AppNavigationTarget) {
        void this.$track("AppNavigationClickEvent", { target });
    }

    toggle() {
        this.folded = !this.folded;
    }

    close() {
        this.folded = true;
    }
}
