import "reflect-metadata";

import "./vendors";
import "./global";
import "./styles/theme.scss";

import Vue from "vue";

import ApplicationRoot from "./ApplicationRoot.vue";
import router from "./router";
import { DefaultLocalizationOptionsProvider } from "@/modules/i18n/platform/cq/DefaultLocalizationOptionsProvider";
import { BackendTranslationProvider } from "@/modules/i18n/platform/cq/BackendTranslationProvider";
import { OnboardingModule } from "@/modules/onboarding/module";
import { UserModule } from "@/modules/user/module";
import { CoreModule } from "@/modules/core";
import { AppI18nModule } from "@/modules/i18n/module";
import { FileModule } from "@/modules/file/module";
import { NavigatorLocaleSource } from "@fastoche/ui-core/i18n/locale/sources/NavigatorLocaleSource";
import { CoreI18nModule } from "@fastoche/ui-core/i18n";
import { UrlQueryLocaleSource } from "@fastoche/ui-core/i18n/locale/sources/UrlQueryLocaleSource";
import { VueApplicationBuilder } from "@fastoche/ui-core/application";
import { WellKnownLocaleObject } from "@fastoche/ui-core/i18n/locale/Locale";
import { BookingModule } from "@/modules/booking/module";
import { ListingModule } from "@/modules/listing/module";
import { Currency } from "@fastoche/ui-core/i18n/currency/Currency";
import { IntercomModule } from "@/modules/intercom/module";
import { APP_CONFIG } from "@/app/config";
import { PricingModule } from "@/modules/pricing/module";
import { RegistrationModule } from "@/modules/registration/module";
import { AuthModule } from "@/modules/auth/module";
import { MarketingModule } from "@/modules/marketing/module";
import { AppTrackingEvents } from "@/modules/tracking/events";
import { APP_LOGGER } from "@/app/logger";
import { AppTrackingEventSegmentHandler } from "@/modules/tracking/AppTrackingEventSegmentHandler";
import { AppTrackingModule } from "@/modules/tracking/module";
import { CoreTrackingModule, CoreSegmentModule } from "@fastoche/ui-tracking";
import {CheckoutModule} from "@/modules/checkout/module";
import { HlModule } from "@/modules/hl/module";

Vue.config.productionTip = false;

VueApplicationBuilder
    .create(APP_LOGGER)
    .module(CoreModule)
    .module(CoreI18nModule, {
        locale: {
            default: new WellKnownLocaleObject("English", "en"),
            detection: {
                sources: [
                    UrlQueryLocaleSource.withConfig({ params: ["locale"] }),
                    NavigatorLocaleSource,
                ],
            },
        },
        currency: {
            default: Currency.EUR,
        },
        options: {
            provider: DefaultLocalizationOptionsProvider,
        },
        translations: {
            provider: BackendTranslationProvider,
        },
    })
    .module(AppI18nModule)
    .module(AuthModule)
    .module(FileModule)
    .module(UserModule)
    .module(RegistrationModule)
    .module(PricingModule)
    .module(ListingModule)
    .module(BookingModule)
    .module(OnboardingModule)
    .module(CheckoutModule)
    .module(IntercomModule, {
        appId: APP_CONFIG.INTERCOM_APP_ID,
    })
    .module(MarketingModule)
    .module(CoreTrackingModule, {
        events: AppTrackingEvents,
        handlers: [
            AppTrackingEventSegmentHandler,
        ],
        trackNavigation: true,
    })
    .module(CoreSegmentModule, {
        writeKey: APP_CONFIG.SEGMENT_WRITE_KEY,
        initialPageView: false,
    })
    .module(AppTrackingModule)
    .module(HlModule)
    .root({
        el: "#app",
        router: router,
        render: h => h(ApplicationRoot),
    })
    .start()
    .then((app) => APP_LOGGER.info("Application started"))
    .catch(e => APP_LOGGER.fatal("Error happened during application setup", e));
