


















import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvIcon } from "@fastoche/ui-kit/components";

export interface BulletStep<T extends string = string> {
    id: T;
    label: string;
}

export const COMPONENT_NAME = "BulletStepper";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvIcon,
    },
})
export default class BulletStepper<TStep extends string = string> extends BaseComponent {
    @Prop({ type: String, required: false, default: null })
    readonly state?: "warning" | "danger";

    @Prop({ type: String, required: true })
    readonly current!: TStep | "end";

    @Prop({ type: Array, required: true })
    readonly steps!: BulletStep<TStep>[];

    private isDone(step: TStep) {
        return (
            this.current == "end" ||
            this.steps.findIndex(x => x.id == step) < this.steps.findIndex(x => x.id == this.current)
        );
    }
}
