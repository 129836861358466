import { RouteConfig } from "vue-router";
import { applyCategoryMeta } from "@fastoche/ui-tracking/core/router/applyCategoryMeta";
import UserHomePage from "@/app/pages/user/UserHomePage.vue";
import UpdateUserEmailPage from "@/app/pages/user/UpdateUserEmailPage.vue";
import UpdateUserPasswordPage from "@/app/pages/user/UpdateUserPasswordPage.vue";
import UserReferralInfoPage from "@/app/pages/user/UserReferralInfoPage.vue";

export const USER_ROUTES: RouteConfig[] = [
    {
        path: "",
        name: "UserHomePage",
        component: UserHomePage,
    },
    {
        path: "email",
        name: "UpdateUserEmailPage",
        component: UpdateUserEmailPage,
    },
    {
        path: "password",
        name: "UpdateUserPasswordPage",
        component: UpdateUserPasswordPage,
    },
    {
        path: "referral",
        alias: "/app/my-referral",
        name: "UserReferralInfoPage",
        component: UserReferralInfoPage,
    },
].map(route => applyCategoryMeta("UserInfoManagement", route));

