






































import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import LoginForm from "@/app/components/auth/login/LoginForm.vue";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { InjectService } from "@fastoche/ui-core/di";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { EmailService } from "@/modules/auth/services/EmailService";
import { LvButton, LvIcon } from "@fastoche/ui-kit/components";

export const COMPONENT_NAME = "EmailVerificationPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LoginForm,
        LvButton,
        LvIcon
    },
})
export default class EmailVerificationPage extends BaseComponent {
    @InjectService(EmailService)
    emailService!: EmailService;

    verification: "pending" | "success" | "failure" = "pending";

    async mounted() {
        if (!this.$route.query.code || !this.$route.query.email) {
            this.$router.replace("/").catch(this.handleNavigationError);
        }
        else {
            this.processVerification();
        }
    }

    @LoaderTask
    async processVerification() {
        try {
            const result = await this.emailService.verifyEmail(
                this.$route.query.email as string,
                this.$route.query.code as string,
            );

            this.verification = result.success ? "success" : "failure";
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    showRegisterForm() {
        this.$router.replace("/register").catch(this.handleNavigationError);
    }

    onAuthenticated() {
        this.$router.replace("/app").catch(this.handleNavigationError);
    }
}
