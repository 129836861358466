import { PaginationModel } from "./PaginationModel";
import { SearchFiltersModel, SearchLocationModel } from "./imports";
import { IsDate, IsDateString, IsDefined, IsEnum, IsNumber, Max, Min, Type, ValidateNested } from "@leavy/validator";
import { Currency } from "@/modules/payment/domain";
import { DateTime } from "luxon";

export class ListingSearchModel implements PaginationModel {
    @IsDateString()
    arrivalDate: string = DateTime.now().toISO();

    @IsDateString()
    departureDate!: string;

    @Type(() => SearchLocationModel)
    @IsDefined()
    @ValidateNested()
    location!: SearchLocationModel;

    @IsEnum(Currency)
    currency!: Currency;

    @Type(() => SearchFiltersModel)
    @IsDefined()
    @ValidateNested()
    filters!: SearchFiltersModel;

    @IsNumber()
    @Min(0)
    page: number = 0;

    @IsNumber()
    @Min(1)
    @Max(100)
    perPage: number = 10;
}
