import { HlOnboardingState } from "./HlOnboardingState";
import { injectable } from "inversify";
import { AllUserOnboardingsStore } from "../common/AllUserOnboardingsStore";
import { HL_ONBOARDING_END_LOCATION, HL_ONBOARDING_ROOT_LOCATION, HL_ONBOARDING_STEPS_LOCATIONS, HL_ONBOARDING_WORKFLOW } from "./workflow";
import { GetIndexedStep } from "../common/model/IndexedStep";
import { MutableStore } from "@fastoche/ui-core/store";
import { OnboardingId, UserOnboardingStatus } from "../common/domain";
import { AnyHlOnboardingStepId } from "./domain";

@injectable()
export class HlOnboardingStore extends MutableStore<HlOnboardingState | null> {
    constructor(
        private readonly onboardingStore: AllUserOnboardingsStore,
    ) {
        super(null);

        onboardingStore.value$.subscribe(value => {
            const hlOnboarding = value[OnboardingId.HL];
            if (hlOnboarding) this.set(new HlOnboardingState(hlOnboarding));
            else this.set(null);
        });
    }

    getStepLocation(step: AnyHlOnboardingStepId) {
        return HL_ONBOARDING_STEPS_LOCATIONS[step];
    }

    getNextStepLocation(currentStep: AnyHlOnboardingStepId) {
        const indexedStep = GetIndexedStep(HL_ONBOARDING_WORKFLOW)(currentStep);

        if (indexedStep.isLast) {
            return HL_ONBOARDING_END_LOCATION
        }

        const nextStep = indexedStep.getNext();
        return this.getStepLocation(nextStep.step);
    }
}
