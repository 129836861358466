












































import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { InjectService } from "@fastoche/ui-core/di";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { SpokenLanguage, SpokenLanguageLevel } from "@/modules/onboarding/hod/domain";
import { HodMatchingSubstep, MatchingDataModel, SpokenLanguageModel } from "@/modules/onboarding/hod/model";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { I18nLocalizedResourceService } from "@/modules/i18n/services/I18nLocalizedResourceService";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import LvSelect from "@fastoche/ui-kit/components/select/LvSelect.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvRate from "@fastoche/ui-kit/components/rate/LvRate.vue";

interface SelectableLanguage {
    value: string;
    label: string;
}

const DEFAULT_LEVEL: SpokenLanguageLevel = 3;

export const COMPONENT_NAME = "OnboardingMatchingLanguagesPage";

@Component({
    name: COMPONENT_NAME,
    components: { LvRate, LvForm, LvFormItem, LvSelect, LvIcon, LvButton },
    loader: true,
})
export default class OnboardingMatchingLanguagesPage extends BaseHodOnboardingPage {
    @InjectState(LocalizationContext)
    localizationCtx!: StateOf<LocalizationContext>;

    @InjectService(I18nLocalizedResourceService)
    i18nResourceService!: I18nLocalizedResourceService;

    @Prop({ type: Object })
    matchingData!: MatchingDataModel;

    languages: SelectableLanguage[] = [];
    loadingLanguages = false;
    spokenLanguages: SpokenLanguageModel[] = [];

    mounted() {
        if (this.matchingData.languages != undefined) {
            this.spokenLanguages.push(...this.matchingData.languages);
        }
        else {
            this.spokenLanguages.push({ langCode: "", level: DEFAULT_LEVEL });
        }

        void this.loadLanguages();
    }

    @LoaderTask
    async loadLanguages() {
        const languages = await this.i18nResourceService.getLanguages();

        this.languages = Object
            .keys(languages)
            .map(code => ({
                label: languages[code]!,
                value: code,
            }));
    }

    addNewEntry() {
        this.spokenLanguages.push({ langCode: "", level: DEFAULT_LEVEL });
    }

    get isValid() {
        return this.spokenLanguages
            .filter(x => !!x.langCode)
            .length > 0;
    }

    nextStep() {
        this.spokenLanguages = this.spokenLanguages.filter(x => !!x.langCode);
        this.matchingData.languages = [...this.spokenLanguages];
        this.$emit("next", { from: HodMatchingSubstep.LANGUAGES });
    }
}
