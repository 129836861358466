import { HlUserGetPaymentsQuery as _HlUserGetPaymentsQuery } from "./HlUserGetPaymentsQuery";
import { HlUserGetManagementPeriodRequestsQuery as _HlUserGetManagementPeriodRequestsQuery } from "./HlUserGetManagementPeriodRequestsQuery";
import { HlUserGetManagementPeriodsQuery as _HlUserGetManagementPeriodsQuery } from "./HlUserGetManagementPeriodsQuery";
import { HlUserGetPeriodEstimationForAccommodationQuery as _HlUserGetPeriodEstimationForAccommodationQuery } from "./HlUserGetPeriodEstimationForAccommodationQuery";
import { HlUserGetAccommodationsQuery as _HlUserGetAccommodationsQuery } from "./HlUserGetAccommodationsQuery";
import { HlUserGetManagementSummaryQuery as _HlUserGetManagementSummaryQuery } from "./HlUserGetManagementSummaryQuery";
import { BuildClientInvocation, UserIdAmbientValue } from "@leavy/cq-core";
import { HlUserGetManagementPeriodRequestByIdQuery as _HlUserGetManagementPeriodRequestByIdQuery } from "./HlUserGetManagementPeriodRequestByIdQuery";
import { HlUserGetManagementPeriodInfoQuery as _HlUserGetManagementPeriodInfoQuery } from "./HlUserGetManagementPeriodInfoQuery";

export class HlUserGetPaymentsQuery extends BuildClientInvocation({
    type: _HlUserGetPaymentsQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class HlUserGetManagementPeriodRequestsQuery extends BuildClientInvocation({
    type: _HlUserGetManagementPeriodRequestsQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class HlUserGetManagementSummaryQuery extends BuildClientInvocation({
    type: _HlUserGetManagementSummaryQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class HlUserGetManagementPeriodsQuery extends BuildClientInvocation({
    type: _HlUserGetManagementPeriodsQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class HlUserGetPeriodEstimationForAccommodationQuery extends BuildClientInvocation({
    type: _HlUserGetPeriodEstimationForAccommodationQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class HlUserGetAccommodationsQuery extends BuildClientInvocation({
    type: _HlUserGetAccommodationsQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class HlUserGetManagementPeriodRequestByIdQuery extends BuildClientInvocation({
    type: _HlUserGetManagementPeriodRequestByIdQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class HlUserGetManagementPeriodInfoQuery extends BuildClientInvocation({
    type: _HlUserGetManagementPeriodInfoQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
