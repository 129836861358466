
























import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import { LvContainer } from "@fastoche/ui-kit/components";

export const COMPONENT_NAME = "PageHeader";

@Component({
    name: COMPONENT_NAME,
    components: { LvIcon, LvContainer },
})
export default class PageHeader extends BaseComponent {
    @Prop({ type: String, required: true })
    title!: string;

    @Prop({ type: String, required: false, default: () => null })
    subtitle!: string | null;

    @Prop({ type: String, required: false, default: () => null })
    backLink!: string | null;
}
