












import { Component, Ref, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvModal } from "@fastoche/ui-kit/components";

export const COMPONENT_NAME = "BookingPriceExplainedPopup";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvModal
    }
})
export default class BookingPriceExplainedPopup extends BaseComponent {
    @Ref()
    readonly modal!: LvModal;

    show() {
        this.modal.show();
    }

    hide() {
        this.modal.hide();
    }
}
