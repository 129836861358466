import { injectable } from "inversify";
import { MutableStore } from "@fastoche/ui-core/store";
import { HotelRoom } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/Listing";
import { HotelQueryResult, HouseListingQueryResult } from "@leavy/lv-homesharing-backend-srv/lib/listing/model/ListingQueryResult";
import { AccommodationType } from "@leavy/lv-homesharing-backend-srv/lib/listing/domain/AccommodationType";

export interface ListingState {
    listingId: string;
    listingType: AccommodationType;
    house: HouseListingQueryResult;
    hotel: HotelQueryResult;
    rooms: HotelRoom[] | null;
    selectedRoomId: string | null;
    room: HotelRoom | null;
    roomsResultLoading: boolean;
    availability: boolean;
}

@injectable()
export class ListingStore extends MutableStore<ListingState> {
    public reverse = false;

    constructor() {
        super({
            listingId: "",
            listingType: AccommodationType.HOUSE,
            house: {} as HouseListingQueryResult,
            hotel: {} as HotelQueryResult,
            rooms: null,
            selectedRoomId: null,
            room: null,
            availability: true,
            roomsResultLoading: false,
        });
    }

    setListingId(id: string) {
        this.mutate(currentState => {
            return {
                ...currentState,
                listingId: id,
            };
        });
    }

    setListingType(type: AccommodationType) {
        this.mutate(currentState => {
            return {
                ...currentState,
                listingType: type,
            };
        });
    }

    setHouse(house: HouseListingQueryResult) {
        this.mutate(currentState => {
            return {
                ...currentState,
                house: house,
            };
        });
    }

    setHotel(hotel: HotelQueryResult) {
        this.mutate(currentState => {
            return {
                ...currentState,
                hotel: hotel,
            };
        });
    }

    setRoomsLoadingResult(value: boolean) {
        this.mutate(currentState => {
            return {
                ...currentState,
                roomsResultLoading: value,
            };
        });
    }

    setRooms(rooms: HotelRoom[] | null) {
        this.mutate(currentState => {
            return {
                ...currentState,
                rooms: rooms,
            };
        });
    }

    setRoom(room: HotelRoom | null) {
        this.mutate(currentState => {
            return {
                ...currentState,
                room: room,
            };
        });
    }

    setRoomId(roomId: string | null) {
        if (roomId !== null) {
            // @ts-ignore
            this.mutate(currentState => {
                return {
                    ...currentState,
                    selectedRoomId: roomId.split("_")[0],
                };
            });
        }
        else {
            this.mutate(currentState => {
                return {
                    ...currentState,
                    selectedRoomId: roomId,
                };
            });
        }
    }

    setAvailability(value: boolean) {
        this.mutate(currentState => {
            return {
                ...currentState,
                availability: value,
            };
        });
    }
}
