import { HlManagementPeriod, HlManagementPeriodId, ManagementPeriodStatus } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlManagementPeriod";
import { Money } from "@leavy/lv-homesharing-pricing-srv/lib/money/domain/Money";
import { HlAccommodationId } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlAccommodation";

export interface HlUserManagementPeriod {
    id: HlManagementPeriodId;
    status: ManagementPeriodStatus;
    creationDate: string;
    accommodationId: HlAccommodationId;
    startDate: string;
    endDate: string;
    guaranteedRevenue: Money;
}

export namespace HlUserManagementPeriodMapper {
    export function fromManagementPeriod(period: HlManagementPeriod): HlUserManagementPeriod {
        return {
            id: period.id,
            status: period.status,
            guaranteedRevenue: period.guaranteedRevenue,
            creationDate: period.creationDate as unknown as string,
            startDate: period.startDate as unknown as string,
            endDate: period.endDate as unknown as string,
            accommodationId: period.accommodationId,
        };
    }
}
