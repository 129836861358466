

































































import { Component, Ref, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { RegistrationService } from "@/modules/registration/services/RegistrationService";
import { InjectService } from "@fastoche/ui-core/di";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import { Equals, IsBoolean, IsEmail, IsNotEmpty, IsOptional, IsString, MinLength } from "@leavy/validator";
import LvSwitch from "@fastoche/ui-kit/components/switch/LvSwitch.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import { MessageBox } from "@fastoche/ui-kit/components";
import { UtmParametersStore } from "@/modules/marketing/utm/UtmParametersStore";
import { UserRegistration } from "@/modules/registration/model";
import { OnboardingId } from "@/modules/onboarding/common/domain";

class RegistrationFormViewModel implements UserRegistration {
    @IsEmail()
    email!: string;

    @IsString()
    @IsNotEmpty()
    firstName!: string;

    @IsString()
    @IsNotEmpty()
    lastName!: string;

    @IsString()
    @IsNotEmpty()
    locale: string = "en-US";

    @IsString()
    @IsNotEmpty()
    @MinLength(6)
    password!: string;

    @IsString()
    @IsNotEmpty()
    phone!: string;

    @IsOptional()
    @IsString()
    referrerCode?: string;

    @IsBoolean()
    @Equals(true)
    tosAccepted!: boolean;
}

export type UserExistsEvent = { email: string };

export type UserRegisteredEvent = {
    userId: string;
    credentials: {
        email: string;
        password: string;
    };
};

export const COMPONENT_NAME = "RegisterForm";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvButton,
        LvSwitch,
        LvField,
        LvFormItem,
        LvForm,
    },
})
export default class RegistrationForm extends BaseComponent {
    @Prop({ type: String, required: true })
    readonly type!: OnboardingId;

    @Prop({ type: String, required: false, default: null })
    readonly referrerCode?: string;

    @Ref()
    readonly form!: LvForm;

    @InjectService(RegistrationService)
    readonly registrationService!: RegistrationService;

    @InjectState(LocalizationContext)
    readonly localization!: StateOf<LocalizationContext>;

    @InjectState(UtmParametersStore)
    readonly utmParametersState!: StateOf<UtmParametersStore>;

    private model = new RegistrationFormViewModel();

    mounted() {
        this.model = new RegistrationFormViewModel();
        this.model.referrerCode = this.referrerCode;
    }

    showLoginForm() {
        this.$emit("showLoginForm");
    }

    @LoaderTask
    private async onSubmit() {
        this.$emit("submit");

        try {
            if (!(await this.form.submit())) return;

            this.model.locale = this.localization.currentLocale.toString();

            const registrationResult = await this.registrationService.register({
                ...this.model,
                onboardingId: this.type,
                referrerCode: this.referrerCode ?? undefined,
                utmCampaign: this.utmParametersState.utmCampaign,
                utmMedium: this.utmParametersState.utmMedium,
                utmSource: this.utmParametersState.utmSource,
            });

            if (!registrationResult.success) {
                switch (registrationResult.reason) {
                    case "EMAIL_TAKEN":
                        void MessageBox.prompt({
                            type: "info",
                            confirmText: this.$t("common.confirm"),
                            isCancellable: false,
                            title: this.$t("register.error.title"),
                            message: this.$t("register.error.email_already_taken.message"),
                        });

                        const event: UserExistsEvent = { email: this.model.email };
                        this.$emit("userExists", event);

                        return;

                    case "PHONE_TAKEN":
                        void MessageBox.prompt({
                            type: "info",
                            confirmText: this.$t("common.confirm"),
                            isCancellable: false,
                            title: this.$t("register.error.title"),
                            message: this.$t("register.error.phone_already_taken.message"),
                        });

                        return;

                    default:
                        CommonErrorDialogs.unexpectedError();
                        return;
                }
            }
            else {
                const event: UserRegisteredEvent = {
                    userId: registrationResult.value.id,
                    credentials: {
                        email: this.model.email,
                        password: this.model.password
                    }
                };

                this.$emit("registered", event);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
