import { RouteConfig } from "vue-router";
import { applyCategoryMeta } from "@fastoche/ui-tracking/core/router/applyCategoryMeta";
import ListingAccommodationViewPage from "@/app/pages/listing/ListingAccommodationViewPage.vue";
import ListingSearchListPage from "@/app/pages/listing/ListingSearchListPage.vue";
import ListingSearchSummaryPage from "@/app/pages/listing/ListingSearchSummaryPage.vue";
import ListingAccommodationTestViewPage from "@/app/pages/listing/ListingAccommodationTestViewPage.vue";

export const LISTING_ROUTES: RouteConfig[] = [
    {
        path: "",
        name: "ListingSearchSummaryPage",
        component: ListingSearchSummaryPage,
    },
    {
        path: "list",
        name: "ListingSearchListPage",
        component: ListingSearchListPage,
    },
    {
        path: "listing/:type/:id",
        name: "ListingAccommodationViewPage",
        component: ListingAccommodationViewPage,
        props: (route) => ({
            listingId: route.params.id,
            type: route.params.type,
        }),
    }

].map(route => applyCategoryMeta("Listing", route));
