import { Query, RpcInvocable } from "@leavy/cq-core";
import { Bad, OkVal } from "@leavy/result";
import { IsLocale, IsUUID } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { BookingCheckoutInfo } from "../model/BookingCheckoutInfo";

export type GetCheckoutInfoQueryResult =
    | OkVal<BookingCheckoutInfo>
    | Bad<"CHECKOUT_NOT_FOUND">
    | Bad<"LISTING_NOT_AVAILABLE">
    | Bad<"LISTING_ROOM_NOT_AVAILABLE">
    ;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "get_checkout_info_query",
})
export class GetCheckoutInfoQuery extends Query<GetCheckoutInfoQueryResult> {
    @IsUUID()
    userId!: string;

    @IsLocale()
    locale!: string;

    @IsUUID()
    checkoutId!: string;
}
