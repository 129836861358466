
























































































































































import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { DateTime } from "luxon";
import { InjectService } from "@fastoche/ui-core/di";
import { HlService } from "@/modules/hl/services/HlService";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { HlUserAccommodationStore } from "@/modules/hl/stores/HlUserAccommodationStore";
import { HlUserManagementPeriodRequest } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriodRequest";
import { HlUserAccommodation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodation";
import { LvButton, LvContainer, LvIcon } from "@fastoche/ui-kit";
import { FileService } from "@/modules/file/services/FileService";
import { ManagementPeriodRequestStatus } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlManagementPeriodRequest";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { hugeLocaleDate } from "@/app/filters/date";
import { MessageBox } from "@fastoche/ui-kit/components";

export const COMPONENT_NAME = "HlRequestViewPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        PageHeader,
        LvIcon,
        LvContainer,
        LvButton,
    },
    filters: {
        price,
        hugeLocaleDate
    },
})
export default class HlRequestViewPage extends BaseComponent {
    @InjectService(HlService)
    readonly hlService!: HlService;

    @InjectService(HlUserAccommodationStore)
    readonly hlUserAccommodationStore!: HlUserAccommodationStore;

    @InjectService(FileService)
    readonly fileService!: FileService;

    @Prop({ type: String, required: true })
    readonly id!: string;

    private request: HlUserManagementPeriodRequest | null = null;
    private accommodation: HlUserAccommodation | null = null;

    mounted() {
        void this.initialize();
    }

    get coverPhoto() {
        if (!this.accommodation) return;
        const [firstPhoto] = this.accommodation.photos;
        if (!firstPhoto) return;

        return this.fileService.getFileUrl(firstPhoto).url;
    }

    get statusColor() {
        switch (this.request?.status) {
            case ManagementPeriodRequestStatus.PRICE_PROPOSED:
                return "violet";

            case ManagementPeriodRequestStatus.PRICE_ACCEPTED:
                return "success";

            case ManagementPeriodRequestStatus.PRICE_REJECTED:
                return "danger";

            default:
                return null;
        }
    }

    get revenue() {
        return this.request?.proposal?.guaranteedRevenue ?? this.request?.estimation?.paymentPlan.adjustedRevenue;
    }

    get schedule() {
        return this.request?.proposal?.schedule
            ?? this.request?.estimation?.paymentPlan.schedule.map(x => ({ date: x.paymentDate, amount: x.amountDue }))
            ?? [];
    }

    get isCancellable() {
        return (
            this.request?.status == "PENDING_PRICE_PROPOSAL"
            || this.request?.status == "PRICE_PROPOSED"
            || this.request?.status == "PRICE_REJECTED"
        );
    }

    @LoaderTask
    async initialize() {
        const request = await this.hlService.getUserManagementPeriodRequestById(this.id, this.$i18n.currency);
        if (request == null) {
            return await this.redirectNotFound();
        }

        const accommodation = await this.hlUserAccommodationStore.getUserAccommodationById(request.accommodationId);
        if (accommodation == null) {
            return await this.redirectNotFound();
        }

        this.request = request;
        this.accommodation = accommodation as HlUserAccommodation;
    }

    @LoaderTask
    async acceptPriceProposal() {
        const result = await this.hlService.acceptManagementPeriodRequestPriceProposal(this.request!.id);

        if (result.success) {
            return await this.$router.replace("/app/happy-leaver");
        }
        else {
            switch (result.reason) {
                case "MANAGEMENT_PERIOD_REQUEST_NOT_FOUND":
                    return await this.redirectNotFound();

                case "MANAGEMENT_PERIOD_REQUEST_WRONG_STATE":
                case "PRICE_PROPOSAL_EXPIRED":
                    CommonErrorDialogs.unexpectedError();
                    return;

                case "MANAGEMENT_PERIOD_DATES_CONFLICT":
                    MessageBox.prompt({
                        type: "warning",
                        title: this.$t("hl.new_management_period.error.date_conflict.title"),
                        message: this.$t("hl.new_management_period.error.date_conflict.message"),
                        isCancellable: false,
                        confirmText: this.$t("common.i_understand"),
                    });
            }
        }
    }

    @LoaderTask
    async rejectPriceProposal() {
        const result = await this.hlService.rejectManagementPeriodRequestPriceProposal(this.request!.id);

        if (result.success) {
            return await this.$router.replace("/app/happy-leaver");
        }
        else {
            switch (result.reason) {
                case "MANAGEMENT_PERIOD_REQUEST_NOT_FOUND":
                    return await this.redirectNotFound();

                case "MANAGEMENT_PERIOD_REQUEST_WRONG_STATE":
                case "PRICE_PROPOSAL_EXPIRED":
                    CommonErrorDialogs.unexpectedError();
                    return;
            }
        }
    }

    @LoaderTask
    async cancelRequest() {
        await this.hlService.cancelManagementPeriodRequest(this.request!.id);
        return await this.$router.replace("/app/happy-leaver");
    }
}
