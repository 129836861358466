
















































































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvSpin, LvCard, LvIcon, LvBadge, MessageBox } from "@fastoche/ui-kit/components";
import { lazyBgImg } from "@fastoche/ui-kit/directives";
import { currencySymbol } from "@fastoche/ui-core/i18n/currency/filters/currencySymbol";
import { money } from "@/app/ui/filters/money.js";
import { InjectService } from "@fastoche/ui-core/di";
import { BookingService } from "@/modules/booking/services/BookingService";
import { ListingBooking } from "@leavy/lv-homesharing-backend-srv/lib/booking/domain/BookedListing";
import { DateTime } from "luxon";
import { ListingHouseService } from "@/modules/listing/services/ListingHouseService";
import { ListingHotelService } from "@/modules/listing/services/ListingHotelService";
import { APP_CONFIG } from "@/app/config";
import {
    HotelQueryResult,
    HouseListingQueryResult,
} from "@leavy/lv-homesharing-backend-srv/lib/listing/model/ListingQueryResult";
import InvoiceModal from "@/app/components/booking/my-bookings/invoice/InvoiceModal.vue";

const DEFAULT_PAGINATION_MODEL = { page: 0, perPage: 10, total: 0 };

export const COMPONENT_NAME = "BookingDetails";

@Component({
    name: COMPONENT_NAME,
    responsive: true,
    components: {
        LvSpin,
        LvCard,
        LvIcon,
        LvBadge,
        InvoiceModal,
    },
    directives: {
        lazyBgImg,
    },
    filters: {
        currencySymbol,
        money,
    },
})
export default class BookingDetails extends BaseComponent {
    @InjectService(BookingService)
    readonly bookingService!: BookingService;

    @InjectService(ListingHouseService)
    private readonly listingHouseService!: ListingHouseService;

    @InjectService(ListingHotelService)
    private readonly listingHotelService!: ListingHotelService;

    @Ref()
    private readonly invoiceModal!: InvoiceModal;

    @Prop({ required: true })
    private bookingId!: string | null;

    private readonly mapConfig = {
        apiKey: APP_CONFIG.GOOGLE_MAPS_API_KEY,
        options: {
            center: { lat: 48.8581952, lng: 2.3504018 },
            zoom: 15,
        },
    };

    private listing: HouseListingQueryResult | HotelQueryResult | null = null;

    booking: ListingBooking | null = null;
    gmapUrl = "";
    isLoading = false;

    get cssProps() {
        const key = `booking.status.${this.booking?.status}`;
        return {
            "--status-details": `"${this.$t(`${key}`)}"`,
        };
    }

    mounted() {
        this.getBookingInfo();
    }

    unselectBooking() {
        this.$router.push("/app/my-bookings").catch(this.handleNavigationError);
    }

    formatDate(date: string) {
        return DateTime.fromFormat(date, "yyyy-MM-dd").toLocaleString({
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
        });
    }

    getListingUrl(id: string) {
        return `/listing/${this.booking?.checkout.accommodationType.toLowerCase()}/${id}`;
    }

    async getBookingInfo() {
        this.isLoading = true;

        if (this.bookingId !== null)
            try {
                await this.bookingService.getBookingInfo(this.bookingId).then((resp) => {
                    this.booking = resp;
                });

                this.isLoading = false;
                await this.getLocation();
            }
            catch (e) {
                this.catchAndNotifyError(e);
            }
            finally {
                this.isLoading = false;
            }
    }

    async getLocation() {
        try {
            let result;
            if (this.booking?.listing.accommodationType == "HOUSE") {
                result = await this.listingHouseService.getHouseListingById(this.booking.listing.listingId);
            }
            else if (this.booking?.listing.accommodationType == "HOTEL") {
                result = await this.listingHotelService.getHotelListingById((this.booking.listing.listingId));
            }

            if (result.success) {
                this.listing = result.value;

                if (this.listing) {
                    this.gmapUrl = `https://www.google.com/maps/search/?api=1&query=${this.listing.location.lat},${this.listing.location.lon}`;
                    this.mapConfig.options.center = { lat: this.listing.location.lat, lng: this.listing.location.lon };
                }
            }
            else {
                switch (result.reason) {
                    case "NOT_FOUND":
                        await this.redirectNotFound();
                        return;
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    async cancelBooking() {
        const result = await MessageBox.prompt({
            title: this.$t("booking.cancel"),
            message: this.$t("booking.cancel.request"),
            type: "info",
            confirmText: "Ok",
            isCancellable: false,
        });

        void this.$router.push("/app/support").catch(this.handleNavigationError);
    }

    showInvoice() {
        this.invoiceModal.show();
    }
}
