import { BaseComponent } from "@/app/BaseComponent";
import { Prop } from "vue-property-decorator";
import { QuizBaseChoice, QuizBaseQuestion, QuizAnswerModel } from "@/modules/onboarding/hod/model/vetting/model";

export abstract class BaseQuizQuestionComponent<T extends QuizBaseQuestion> extends BaseComponent {
    @Prop({ type: Object, required: true })
    schema!: T;

    @Prop({ type: Object, required: true })
    model!: QuizAnswerModel;

    @Prop({ type: String, required: false, default: null })
    status!: "can_validate" | "validated" | null;

    isSelected(choice: QuizBaseChoice) {
        return this.model.answer.includes(choice.id);
    }

    selectChoice(choice: QuizBaseChoice) {
        this.selectChoiceById(choice.id);
    }

    selectChoiceById(id: string) {
        if (this.status == "validated") return;

        if (this.model.answer.includes(id)) {
            const idx = this.model.answer.indexOf(id);
            this.model.answer.splice(idx, 1);
        }
        else {
            if (this.schema.type == "single") this.model.answer = [id];
            else this.model.answer.push(id);
        }

        this.$emit("change");
    }
}
