import { Bad, Ok } from "@leavy/result";
import { injectable } from "inversify";

export interface UserCredentials {
    email: string;
    password: string;
}

@injectable()
export abstract class AuthenticationService {
    abstract authenticate(credentials: UserCredentials): Promise<Ok | Bad<"invalid_credentials" | "unexpected_failure">>;
}
