import { NavigationGuard } from "vue-router";
import { GlobalApplicationContext } from "@fastoche/ui-core/application/GlobalApplicationContext";
import { HodOnboardingStore } from "./HodOnboardingStore";
import { HOD_ONBOARDING_ACCEPTED_LOCATION, HOD_ONBOARDING_END_LOCATION, HOD_ONBOARDING_REJECTED_LOCATION, HOD_ONBOARDING_ROOT_LOCATION } from "./workflow";
import { OnboardingService } from "../common/services/OnboardingService";
import { UserOnboardingStatus } from "../common/domain";

export const hodOnboardingGuard: NavigationGuard = async (to, from, next) => {
    const { rootContainer } = GlobalApplicationContext.default.fastoche;
    const onboardingService = rootContainer.get(OnboardingService);
    const store = rootContainer.get(HodOnboardingStore);

    if (to.path == HOD_ONBOARDING_ROOT_LOCATION) {
        await onboardingService.loadStore();
    }

    if (store.value?.status == UserOnboardingStatus.SUBMITTED) {
        next(HOD_ONBOARDING_END_LOCATION);
    }
    else if (store.value?.status == UserOnboardingStatus.ACCEPTED) {
        next(HOD_ONBOARDING_ACCEPTED_LOCATION);
    }
    else if (store.value?.status == UserOnboardingStatus.REJECTED) {
        next(HOD_ONBOARDING_REJECTED_LOCATION);
    }
    else {
        next();
    }
};

export const hodOnboardingStepGuard: NavigationGuard = (to, from, next) => {
    const { rootContainer } = GlobalApplicationContext.default.fastoche;
    const store = rootContainer.get(HodOnboardingStore);

    if (!store.value) {
        next(HOD_ONBOARDING_ROOT_LOCATION);
    }
    else if (store.value.status == UserOnboardingStatus.STARTED) {
        next();
    }
    else {
        next(HOD_ONBOARDING_ROOT_LOCATION);
    }
};
