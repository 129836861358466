import { BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { IntercomIdentityVerificationService } from "@/modules/intercom/services/IntercomIdentityVerificationService";
import { IntercomConfig } from "@/modules/intercom/config";

@Module()
export class IntercomModule extends BaseModule<IntercomConfig> {
    registerServices(container: ModuleContainer, config: IntercomConfig) {
        container.bind(IntercomConfig).toConstantValue(config);
        container.bind(IntercomIdentityVerificationService).toSelf().inSingletonScope();
    }

    configureServices(container: ModuleContainer) {
        const identityVerificationService = container.get(IntercomIdentityVerificationService);
        identityVerificationService.registerListeners();
    }
}
