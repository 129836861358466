import { DateTime } from "luxon";

type AnyDateType = string | Date | DateTime;

function toDateTime(value?: AnyDateType) {
    if (typeof value === "string") {
        return DateTime.fromISO(value);
    }
    else if (value instanceof Date) {
        return DateTime.fromJSDate(value);
    }
    else if (value instanceof DateTime) {
        return value;
    }
    else {
        return null;
    }
}

export function hugeLocaleDate(value?: AnyDateType) {
    return toDateTime(value)?.toLocaleString(DateTime.DATE_HUGE) ?? "";
}

export function fullLocaleDate(value?: AnyDateType) {
    return toDateTime(value)?.toLocaleString(DateTime.DATE_FULL) ?? "";
}

export function shortLocaleDate(value?: AnyDateType) {
    return toDateTime(value)?.toLocaleString(DateTime.DATE_SHORT) ?? "";
}
