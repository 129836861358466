










import { LvIntersectionObserver } from "@fastoche/ui-kit/components";
import {Component, Prop, Vue} from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";

export const COMPONENT_NAME = "StickyElement";

@Component({
    name: COMPONENT_NAME,
    responsive: true,
    components: {
        LvIntersectionObserver,
    }
})
export default class StickyElement extends BaseComponent {

    stickyContainer = false;

    mounted() {
        this.pageOnScroll();
        document.addEventListener('scroll', this.pageOnScroll)
    }

    pageOnScroll(){
        const container = this.$el.getBoundingClientRect();
        this.stickyContainer = container.top < 90;
    }
}
