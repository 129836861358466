




import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import PublicLayout from "@/app/pages/PublicLayout.vue";

export const COMPONENT_NAME = "HodRegistrationFlowLayout";

@Component({
    name: COMPONENT_NAME,
    components: {
        PublicLayout,
    },
})
export default class HodRegistrationFlowLayout extends BaseComponent {
}
