import { ApplicationReadyHook, BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { HlService } from "@/modules/hl/services/HlService";
import { CqHlService } from "@/modules/hl/platform/CqHlService";
import { HlUserAccommodationStore } from "@/modules/hl/stores/HlUserAccommodationStore";

@Module()
export class HlModule extends BaseModule {
    registerServices(container: ModuleContainer) {
        container.bind(HlService).to(CqHlService).inSingletonScope();
        container.bind(HlUserAccommodationStore).toSelf().inSingletonScope();
    }

    async applicationReady(hook: ApplicationReadyHook) {
        const { rootContainer } = hook.applicationContext;

        const hlUserAccommodationStore = rootContainer.get(HlUserAccommodationStore);
        hlUserAccommodationStore.registerListeners();
    }
}
