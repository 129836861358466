
























import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import LocaleSelector from "../../../ui/locale-selector/LocaleSelector.vue";
import { LvIcon } from "@fastoche/ui-kit/components/icon";
import { InjectState } from "@fastoche/ui-core/store";
import CurrencySelector from "@/app/ui/currency-selector/CurrencySelector.vue";
import { LvButton } from "@fastoche/ui-kit/components";
import PageFooter from "@/app/ui/page-footer/PageFooter.vue";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";
import { AuthenticationState } from "@/modules/auth/AuthenticationState";

export const COMPONENT_NAME = "HlRegistrationFlowLayout";

@Component({
    name: COMPONENT_NAME,
    responsive: true,
    components: {
        LocaleSelector,
        LvIcon,
        LvButton,
        PageFooter
    },
})
export default class HlRegistrationFlowLayout extends BaseComponent {

}
