












































import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import ListingSearchBar from "@/app/components/listing/search-bar/ListingSearchBar.vue";
import ListingFilterBar from "@/app/components/listing/filter-bar/ListingFilterBar.vue";
import { LvContainer, LvSpin } from "@fastoche/ui-kit/components";
import ListingSearchResultsRowList from "@/app/components/listing/search-results-row-list/ListingSearchResultsRowList.vue";
import { InjectService } from "@fastoche/ui-core/di";
import { ListingService } from "@/modules/listing/services/ListingService";
import { PriceRange } from "@/modules/listing/model";
import { AccommodationType } from "@/modules/listing/domain";
import HlExplainedBanner from "@/app/ui/hl-explained-banner/HlExplainedBanner.vue";
import { Toolbelt } from "@leavy/static-land";
import { ListingFilterContext } from "@/modules/listing/context/ListingFilterContext";
import {BookingStore} from "@/modules/booking/BookingStore";
import {ListingStore} from "@/modules/listing/ListingStore";

interface RowModel {
    title: string;
    filters: Toolbelt.O.Required<ListingFilterContext, "type">;
    isLoading: boolean;
    hasResults?: boolean;
}

export const COMPONENT_NAME = "ListingSearchSummaryPage";

@Component({
    name: COMPONENT_NAME,
    components: { HlExplainedBanner, ListingSearchResultsRowList, ListingFilterBar, ListingSearchBar, LvContainer, LvSpin },
})
export default class ListingSearchSummaryPage extends BaseComponent {
    @InjectService(ListingService)
    readonly listingService!: ListingService;

    @InjectService(ListingStore)
    readonly listingStore!: ListingStore;

    @InjectService(BookingStore)
    readonly bookingStore!: BookingStore;

    private rows: RowModel[] = [
        {
            title: "search.filters.accommodation_type.flats_and_houses",
            filters: {
                type: AccommodationType.HOUSE,
            },
            isLoading: false,
            hasResults: undefined,
        },
        {
            title: "search.filters.accommodation_type.hotels",
            filters: {
                type: AccommodationType.HOTEL,
            },
            isLoading: false,
            hasResults: undefined,
        },
    ];

    private onRowLoading(row: RowModel, isLoading: boolean) {
        row.isLoading = isLoading;
        if (isLoading) row.hasResults = undefined;
    }

    private onRowHasResults(row: RowModel, hasResults: boolean) {
        row.hasResults = hasResults;
    }

    get hasAnyLoading() {
        return this.rows.some(x => x.isLoading);
    }

    get loadingPercentage() {
        return (this.rows.filter(x => !x.isLoading).length / this.rows.length) * 100;
    }

    get hasNoResults() {
        return !this.hasAnyLoading && this.rows.every(x => x.hasResults === false);
    }

    mounted() {
        // reset listingStore states
        this.bookingStore.setBookingQuote(null);
        this.listingStore.setRoom(null);
        this.listingStore.setRooms(null);
    }
}
