import { injectable, inject } from "inversify";
import { MatchingDataModel, MeetingPreferenceModel, AdministrativeDocumentModel } from "../model";
import { AdministrativeDocumentType } from "../domain";
import { CommonEditStepError, CommonSubmitStepError, OnboardingService } from "../../common/services/OnboardingService";
import { Bad, Ok, OkVal } from "@leavy/result";
import { OnboardingId } from "@/modules/onboarding/common/domain";
import { AllUserOnboardingsStore } from "@/modules/onboarding/common/AllUserOnboardingsStore";
import { HodOnboardingStore } from "@/modules/onboarding/hod/HodOnboardingStore";

@injectable()
export abstract class HodOnboardingService {
    @inject(AllUserOnboardingsStore)
    protected readonly rootStore!: AllUserOnboardingsStore;

    @inject(HodOnboardingStore)
    protected readonly hodStore!: HodOnboardingStore;

    @inject((OnboardingService))
    protected readonly onboardingService!: OnboardingService;

    async ensureStore() {
        if (this.hodStore.value != null) return;
        await this.onboardingService.loadStore();
    }

    async startOnboarding() {
        return this.onboardingService.startOnboarding(OnboardingId.HOD);
    }

    abstract editMatchingStep(model: MatchingDataModel): Promise<Ok | CommonEditStepError>;

    abstract submitMatchingStep(): Promise<Ok | CommonSubmitStepError>;

    abstract submitGetToKnowUsStep(): Promise<Ok | CommonSubmitStepError>;

    abstract addAdministrativeDocument(document: File, documentType: AdministrativeDocumentType): Promise<OkVal<{ fileId: string }> | CommonEditStepError>;

    abstract removeAdministrativeDocument(document: AdministrativeDocumentModel): Promise<Ok | CommonEditStepError>;

    abstract submitAdministrativeDocumentsStep(): Promise<Ok | CommonSubmitStepError>;

    abstract submitMeetingStep(model: MeetingPreferenceModel): Promise<Ok | CommonSubmitStepError>;
}
