import { HlUserAcceptPriceProposalCommand as _HlUserAcceptPriceProposalCommand } from "./HlUserAcceptPriceProposalCommand";
import { HlUserCancelManagementPeriodRequestCommand as _HlUserCancelManagementPeriodRequestCommand } from "./HlUserCancelManagementPeriodRequestCommand";
import { HlUserRejectPriceProposalCommand as _HlUserRejectPriceProposalCommand } from "./HlUserRejectPriceProposalCommand";
import { HlUserRequestNewManagementPeriodCommand as _HlUserRequestNewManagementPeriodCommand } from "./HlUserRequestNewManagementPeriodCommand";
import { BuildClientInvocation, UserIdAmbientValue } from "@leavy/cq-core";

export class HlUserAcceptPriceProposalCommand extends BuildClientInvocation({
    type: _HlUserAcceptPriceProposalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class HlUserCancelManagementPeriodRequestCommand extends BuildClientInvocation({
    type: _HlUserCancelManagementPeriodRequestCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class HlUserRejectPriceProposalCommand extends BuildClientInvocation({
    type: _HlUserRejectPriceProposalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class HlUserRequestNewManagementPeriodCommand extends BuildClientInvocation({
    type: _HlUserRequestNewManagementPeriodCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
