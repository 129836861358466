export type QuizQuestion = QuizCoverQuestion;
export type QuestionFormat = QuizQuestion["format"];

export interface QuizFormSchema {
    id: string;
    version: number;
    questions: QuizQuestion[];
}

export interface QuizBaseQuestion {
    id: string;
    resId: string;
    type: "single" | "many";
    format: string;
    expected: string[];
    explanation: QuizQuestionExplanation;
}

export interface QuizQuestionExplanation {
    resId: string;
}

export interface QuizBaseChoice {
    id: string;
    resId: string;
}

export interface QuizCoverQuestion extends QuizBaseQuestion {
    format: "cover-question";
    choices: QuizBaseChoice[];
}

export class QuizModel {
    schemaId!: string;
    version: number = 1;
    answers: QuizAnswerModel[] = [];
}

export class QuizAnswerModel {
    questionId!: string;
    answer: string[] = [];
    correct: boolean = false;
}
