













































import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvLightContainer } from "@fastoche/ui-kit/components";
import { AllUserOnboardingsStore } from "@/modules/onboarding/common/AllUserOnboardingsStore";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import ClockIcon from "../ui/svg/ClockIcon.vue";
import WomanTravelerPlane from "../ui/svg/WomanTravelerPlane.vue";
import { OnboardingId, UserOnboardingStatus, UserOnboardingStepStatus } from "@/modules/onboarding/common/domain";
import LvProgressBar from "@fastoche/ui-kit/components/progress-bar/LvProgressBar.vue";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";

interface CardType {
    id: OnboardingId,
    picture: string,
    title: string,
    description: string,
    url: string;
}

interface CardModel extends CardType {
    status?: UserOnboardingStatus;
    progress: number;
    progressState?: "danger" | "success";
}

export const COMPONENT_NAME = "AppHomePage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvProgressBar,
        WomanTravelerPlane,
        ClockIcon,
        LvLightContainer,
    },
})
export default class AppHomePage extends BaseComponent {
    @InjectState(AllUserOnboardingsStore)
    private readonly state!: StateOf<AllUserOnboardingsStore>;

    private get cardTypes(): CardType[] {
        return [
            {
                id: OnboardingId.HL,
                picture: require("@/app/assets/illu_hl.svg"),
                title: this.$t("app.home.hl.title"),
                description: this.$t("app.home.hl.description"),
                url: "/app/happy-leaver",
            },
            {
                id: OnboardingId.HOD,
                picture: require("@/app/assets/illu_hod.svg"),
                title: this.$t("app.home.hod.title"),
                description: this.$t("app.home.hod.description"),
                url: "/app/host-on-demand",
            },
        ];
    }

    private get cards(): CardModel[] {
        return this.cardTypes.map((c) => {
            const userOnboarding = this.state[c.id];

            const status = userOnboarding?.status;

            const progress = !userOnboarding ? 0 :
                status == UserOnboardingStatus.ACCEPTED || status == UserOnboardingStatus.REJECTED ? 100 :
                    Math.round(
                        (userOnboarding.steps.filter((x) => x.status == UserOnboardingStepStatus.COMPLETE).length /
                            userOnboarding.steps.length) *
                        100,
                    );

            const progressState = status == UserOnboardingStatus.ACCEPTED ? "success" :
                status == UserOnboardingStatus.REJECTED ? "danger" :
                    undefined;

            return {
                ...c,
                status,
                progress,
                progressState,
            };
        });
    }

    private shouldShowCard(card: CardModel) {
        const hlOnboarding = this.state[OnboardingId.HL];
        const hodOnboarding = this.state[OnboardingId.HOD];

        if (hlOnboarding && hodOnboarding) return true;

        switch (card.id) {
            case OnboardingId.HL:
                return true;

            case OnboardingId.HOD:
                return (hodOnboarding || !hlOnboarding);
        }
    }

    @LoaderTask
    private async goTo(url: string) {
        await this.$router.push(url).catch(this.handleNavigationError);
    }
}
