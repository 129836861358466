


















































import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";

export const COMPONENT_NAME = "EmailVerifiedIcon";

@Component({
    name: COMPONENT_NAME,
})
export default class EmailVerifiedIcon extends BaseComponent {
    @Prop({ type: Boolean, required: false, default: () => false })
    isVerified!: boolean;
}
