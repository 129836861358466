import { Command, RpcInvocable } from "@leavy/cq-core";
import { Bad, Ok, OkVal } from "@leavy/result";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { IsEmail, IsString } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

export type DeleteUserCommandResult = Ok | Bad<"USER_NOT_DELETABLE">;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "delete_user",
})
export class DeleteUserCommand extends Command<DeleteUserCommandResult, DeleteUserEvent> {
    @IsString()
    userId!: string;
}

@DomainEvent({ namespace: HOMESHARING_BACKEND_SERVICE_NAME, name: "delete_user", version: 1 })
export class DeleteUserEvent extends DomainEventBase {
    @EventProperty()
    userId!: string;

    constructor(data: PayloadOf<DeleteUserEvent>) {
        super(data);
    }
}

