














import { Component } from "vue-property-decorator";
import { LvContainer, MessageBox } from "@fastoche/ui-kit/components";
import { InjectService } from "@fastoche/ui-core/di";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";
import HlRegistrationSteps from "@/app/ui/hl-registration-steps/HlRegistrationSteps.vue";
import HlEstimationRequestForm, { HlEstimationRequestFormViewModel } from "@/app/components/onboarding/hl/estimation/HlEstimationRequestForm.vue";
import { HlPricingService } from "@/modules/pricing/services/HlPricingService";
import { HlSimpleRevenueEstimationRequestModel } from "@leavy/lv-homesharing-backend-srv/lib/pricing/model/HlSimpleRevenueEstimationRequestModel";
import { GlobalTranslationStore } from "@fastoche/ui-core/i18n/global";
import { AnyHlOnboardingStepId, RevenueEstimation } from "@/modules/onboarding/hl/domain";
import { validate } from "@leavy/validator";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";

export const COMPONENT_NAME = "OnboardingEstimationPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        HlEstimationRequestForm,
        HlRegistrationSteps,
        LvButton,
        LvContainer,
    },
})
export default class OnboardingEstimationPage extends BaseHlOnboardingPage {
    @InjectService(HlPricingService)
    private readonly pricingService!: HlPricingService;

    @InjectState(LocalizationContext)
    private readonly localizationContext!: StateOf<LocalizationContext>;

    private lastSavedEstimation: RevenueEstimation | null = null;
    private estimationRequest: HlSimpleRevenueEstimationRequestModel = new HlEstimationRequestFormViewModel();

    private get revenueEstimationStep() {
        return this.hlState.onboarding.revenueEstimation;
    }

    private mounted() {
        void this.initialize();
    }

    @LoaderTask
    private async initialize() {
        await this.onboardingService.loadStep(AnyHlOnboardingStepId.HL_REVENUE_ESTIMATION);

        if (this.revenueEstimationStep.isComplete()) {
            void MessageBox.prompt({
                type: "info",
                confirmText: this.$t("common.i_understand"),
                isCancellable: false,
                title: this.$t("estimation_step.already_done.title"),
                message: this.$t("estimation_step.already_done.message"),
            });

            await this.nextStep();
            return;
        }

        this.lastSavedEstimation = this.revenueEstimationStep.data ?? null;
        const existingRequest = this.getEstimationRequestFromQueryString();

        if (existingRequest) {
            this.estimationRequest = existingRequest;
            await this.submitStep();
        }
        else {
            this.loadRequestFromLastEstimation();
        }

        if (!this.estimationRequest.currency) {
            this.estimationRequest.currency = this.localizationContext.currentCurrency;
        }
    }

    private loadRequestFromLastEstimation() {
        const revenueEstimation = this.revenueEstimationStep.data;
        this.lastSavedEstimation = revenueEstimation ?? null;

        this.estimationRequest = Object.assign(new HlEstimationRequestFormViewModel(), {
            address: revenueEstimation?.address?.address,
            bedrooms: revenueEstimation?.bedrooms ?? 0,
            bathrooms: revenueEstimation?.bathrooms,
            beds: revenueEstimation?.beds,
            maxOccupancy: revenueEstimation?.maxOccupancy ?? 0,
        });

        if (revenueEstimation?.hasResult()) {
            this.estimationRequest.currency = this.localizationContext.currentCurrency;
        }
    }

    private getEstimationRequestFromQueryString() {
        if ("estimation" in this.$route.query) {
            const validationResult = validate(HlSimpleRevenueEstimationRequestModel, this.$route.query.estimation);
            if (validationResult.success) return validationResult.value;
        }

        return null;
    }

    private isSameEstimation() {
        if (
            !this.lastSavedEstimation
            || !this.lastSavedEstimation.hasBaseAccommodationInfo()
        ) {
            return false;
        }

        const {
            bedrooms: lastBedrooms,
            maxOccupancy: lastMaxOccupancy,
            bathrooms: lastBathrooms,
            beds: lastBeds,
            address: lastAddress,
        } = this.lastSavedEstimation;

        const { bedrooms, maxOccupancy, bathrooms, beds, address } = this.estimationRequest;

        return (
            lastBedrooms == bedrooms
            && lastMaxOccupancy == maxOccupancy
            && lastBathrooms == bathrooms
            && lastBeds == beds
            && lastAddress.address == address
        );
    }

    onEstimationFormSubmit() {
        void this.submitStep();
    }

    @LoaderTask
    private async submitStep() {
        try {
            if (this.revenueEstimationStep.status == "COMPLETE" || this.isSameEstimation()) {
                await this.nextStep();
                return;
            }

            const submitResult = await this.hlOnboardingService.submitRevenueEstimationStep(this.estimationRequest);

            if (submitResult.success) {
                await this.nextStep();
            }
            else {
                switch (submitResult.reason) {
                    case "ESTIMATION_FAILED":
                    case "INVALID_LOCATION":
                        void MessageBox.prompt({
                            type: "warning",
                            confirmText: GlobalTranslationStore.$t("common.i_understand"),
                            isCancellable: false,
                            title: GlobalTranslationStore.$t("hl_estimation.error.title"),
                            message: GlobalTranslationStore.$t("hl_estimation.error.message"),
                        });

                        return;

                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    private async nextStep() {
        const nextStepLocation = this.hlStore.getStepLocation(AnyHlOnboardingStepId.HL_UPCOMING_DEPARTURE);
        await this.$router.push(nextStepLocation).catch(this.handleNavigationError);
    }
}
