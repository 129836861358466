






























































































































import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import LocaleSelector from "@/app/ui/locale-selector/LocaleSelector.vue";
import CurrencySelector from "@/app/ui/currency-selector/CurrencySelector.vue";
import { LvIcon } from "@fastoche/ui-kit/components";

type FooterLinkTarget = "linkedin" | "instagram" | "facebook" | "faq" | "contact_us";

export const COMPONENT_NAME = "PageFooter";

@Component({
    name: COMPONENT_NAME,
    components: { CurrencySelector, LocaleSelector, LvIcon },
})
export default class PageFooter extends BaseComponent {
    onFooterLinkClick(ev: MouseEvent, target: FooterLinkTarget) {
        void this.$track("FooterLinkClickEvent", { target });
    }
}
