
































import { Component, ModelSync, Ref, Vue } from "vue-property-decorator";
import { StepFormComponent } from "@/modules/onboarding/hl/StepForm";
import { HlAccommodationLocationModel } from "@/modules/onboarding/hl/model";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import AddressInput from "@/app/ui/address-input/AddressInput.vue";
import { LvCheckbox, LvNumberInput, LvSwitch } from "@fastoche/ui-kit/components";
import { construct } from "@leavy/utils";
import { APP_LOGGER } from "@/app/logger";

const getDefaultModel = (): HlAccommodationLocationModel => {
    return construct(HlAccommodationLocationModel, {
        floor: 0,
        elevator: false,
        address: null!
    });
};

export const COMPONENT_NAME = "AccommodationLocationForm";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvButton,
        LvFormItem,
        LvForm,
        LvField,
        AddressInput,
        LvNumberInput,
        LvSwitch
    }
})
export default class AccommodationLocationForm extends StepFormComponent<HlAccommodationLocationModel> {
    @Ref()
    readonly form!: LvForm;

    @ModelSync("value", "change", { type: HlAccommodationLocationModel, required: false, default: () => getDefaultModel() })
    model!: HlAccommodationLocationModel;

    async submit() {
        if (!(await this.form.submit())) return;
        return this.model;
    }
}
