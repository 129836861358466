










import { Component, ModelSync, Prop, Watch } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import ListingPriceFilter from "@/app/components/listing/price-filter/ListingPriceFilter.vue";
import ListingAccommodationTypeFilter from "@/app/components/listing/accommodation-type-filter/ListingAccommodationTypeFilter.vue";
import ListingBedroomFilter from "@/app/components/listing/bedroom-filter/ListingBedroomFilter.vue";
import ListingAmenityFilter from "@/app/components/listing/amenity-filter/ListingAmenityFilter.vue";
import VueRouter from "vue-router";
import { validate } from "@leavy/validator";
import { ListingFilterContext } from "@/modules/listing/context/ListingFilterContext";
import { classToPlain } from "@leavy/validator/lib/base/transformer";

export const COMPONENT_NAME = "ListingFilterBar";

@Component({
    name: COMPONENT_NAME,
    components: { ListingAmenityFilter, ListingBedroomFilter, ListingAccommodationTypeFilter, ListingPriceFilter },
})
export default class ListingFilterBar extends BaseComponent {
    @ModelSync("value", "change", { type: Object, required: true })
    model!: ListingFilterContext;

    @Prop({ type: String, required: false, default: null })
    readonly syncQueryString?: string;

    @Watch("model", { deep: true })
    onModelChange() {
        if (this.syncQueryString) {
            this.updateQueryString();
        }
    }

    readQueryString() {
        if (!this.syncQueryString) return;

        if (this.syncQueryString in this.$route.query) {
            const queryProp = this.$route.query[this.syncQueryString];

            if (typeof queryProp == "object" && !(queryProp instanceof Array)) {
                const validationResult = validate(ListingFilterContext, queryProp);

                if (validationResult.success) {
                    return validationResult.value;
                }
            }
        }

        return null;
    }

    updateQueryString() {
        if (!this.syncQueryString) return;

        this.$router
            .replace({
                query: {
                    ...this.$route.query,
                    [this.syncQueryString]: classToPlain(this.model),
                } as any,
            })
            .catch(this.handleNavigationError);
    }
}
