import { CommonStepError, StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../../base";
import { Ok } from "@leavy/result";
import { RemoveIdentityDocumentCommand } from "@leavy/lv-onboarding-srv/lib/identity/command";
import { CommonEditOnboardingError } from "../../ForwardedOnboardingCommand";
import { IsUUID } from "@leavy/validator";

export type RemoveIdentityDocumentLocalCommandResult =
    | Ok
    | CommonEditOnboardingError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "remove_identity_document",
})
export class RemoveIdentityDocumentLocalCommand extends StepCommand<RemoveIdentityDocumentLocalCommandResult> {
    @IsUUID("4")
    fileId!: string;
}
