import { Command, RpcInvocable } from "@leavy/cq-core";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { Bad, Ok } from "@leavy/result";
import { IsEmail, LowerCase, Trim } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

export type PasswordLostCommandResult =
    Ok
    | Bad<"email_not_found" | "unexpected_failure">;

/**
 * PasswordLostCommand command description
 */
@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "password_lost",
})
export class PasswordLostCommand extends Command<PasswordLostCommandResult, PasswordLostReportedEvent> {
    @IsEmail()
    @Trim()
    @LowerCase()
    email!: string;
}

@DomainEvent({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    name: "password_lost_reported",
    version: 1,
})
export class PasswordLostReportedEvent extends DomainEventBase {
    @EventProperty()
    email!: string;

    @EventProperty()
    code!: string;

    @EventProperty()
    expirationDate!: string;

    constructor(data: PayloadOf<PasswordLostReportedEvent>) {
        super(data);
    }
}
