import { injectable } from "inversify";
import { OnboardingId } from "../domain";
import { PaymentInfoModel, UserIdentityModel } from "../model";
import { Bad, Ok, OkVal } from "@leavy/result";
import { CommonOnboardingErrors } from "@leavy/lv-onboarding-srv/lib/common/command";
import { CommonEditOnboardingError } from "@leavy/lv-homesharing-backend-srv/lib/onboarding/ForwardedOnboardingCommand";

export { CommonOnboardingErrors, CommonEditOnboardingError };

export type CommonEditStepError =
    | CommonEditOnboardingError;

export type CommonSubmitStepError =
    | CommonEditStepError
    | CommonOnboardingErrors.StepIncomplete;

export type CommonEditExtraDataError =
    | CommonEditOnboardingError;

@injectable()
export abstract class OnboardingService {
    abstract loadStore(): Promise<void>;

    abstract loadStep(stepId: string): Promise<void>;

    abstract loadExtraData(dataTypeId: string): Promise<void>;

    abstract startOnboarding(onboardingId: OnboardingId): Promise<Ok | Bad<"ONBOARDING_ALREADY_STARTED">>;

    abstract restartOnboarding(onboardingId: OnboardingId): Promise<Ok | CommonEditOnboardingError | Bad<"ONBOARDING_DEFINITIVE_REJECTION">>;

    abstract submitOnboarding(onboardingId: OnboardingId): Promise<Ok | Bad<"ONBOARDING_INCOMPLETE">>;

    abstract addIdentityDocument(onboardingId: OnboardingId, document: File): Promise<OkVal<{ fileId: string }> | CommonEditStepError>;

    abstract removeIdentityDocument(onboardingId: OnboardingId, fileId: string): Promise<Ok | CommonEditStepError>;

    abstract submitIdentityStep(onboardingId: OnboardingId, model: UserIdentityModel): Promise<Ok | CommonSubmitStepError>;

    abstract submitPaymentInfoStep(onboardingId: OnboardingId, model: PaymentInfoModel): Promise<Ok | CommonSubmitStepError>;
}
