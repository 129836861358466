import { BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { LuxonLocaleAdapter } from "./libs/LuxonLocaleAdapter";
import { I18nLocalizedResourceService } from "./services/I18nLocalizedResourceService";
import { CqI18nLocalizedResourceService } from "./platform/cq/CqI18nLocalizedResourceService";
import { LibraryLocaleAdapter } from "@fastoche/ui-core/i18n/libs/LibraryLocaleAdapter";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { HOMESHARING_HTTP_CLIENT } from "@/modules/core/http";

@Module()
export class AppI18nModule extends BaseModule {
    registerServices(container: ModuleContainer): void {
        // I18n library adapters
        container.bind(LibraryLocaleAdapter).to(LuxonLocaleAdapter).inSingletonScope();

        container.bind(I18nLocalizedResourceService).to(CqI18nLocalizedResourceService).inSingletonScope();
    }

    configureServices(container: ModuleContainer): void | Promise<void> {
        this.configureLocaleHeaderInterceptor(container);
    }

    private configureLocaleHeaderInterceptor(container: ModuleContainer) {
        const localizationContext = container.get(LocalizationContext);

        let currentLocale = localizationContext.value.currentLocale.toString();

        localizationContext.value$.subscribe(x => {
            currentLocale = x.currentLocale.toString();
        });

        HOMESHARING_HTTP_CLIENT.interceptors.request.use(
            (config) => {
                config.headers["x-lv-locale"] = currentLocale;
                return config;
            },
            (error) => Promise.reject(error),
        );
    }
}
