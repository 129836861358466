import { BuildClientInvocation, UserIdAmbientValue } from "@leavy/cq-core";
import * as OnboardingService from "@leavy/lv-onboarding-srv/lib/identity/command";
import { AddIdentityDocumentLocalCommand } from "./AddIdentityDocumentLocalCommand";
import { RemoveIdentityDocumentLocalCommand } from "./RemoveIdentityDocumentLocalCommand";
import { ForwardedOnboardingCommand } from "../../ForwardedOnboardingCommand";

export class SubmitIdentityStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitIdentityStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class AddIdentityDocumentCommand extends BuildClientInvocation({
    type: AddIdentityDocumentLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}

export class RemoveIdentityDocumentCommand extends BuildClientInvocation({
    type: RemoveIdentityDocumentLocalCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
