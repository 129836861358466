




























import { Component, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { PasswordService } from "@/modules/auth/services/PasswordService";
import { InjectService } from "@fastoche/ui-core/di";
import LvForm from "@fastoche/ui-kit/components/form/LvForm.vue";
import LvFormItem from "@fastoche/ui-kit/components/form-item/LvFormItem.vue";
import LvField from "@fastoche/ui-kit/components/field/LvField.vue";
import { Equals, IsEmail, IsNotEmpty, IsString, MinLength } from "@leavy/validator";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import { MessageBox } from "@fastoche/ui-kit/components";
import { EqualsProperty } from "@fastoche/ui-core/validator/decorators/EqualsProperty";

class ResetPasswordViewModel {
    @IsEmail()
    email!: string;

    @IsString()
    @IsNotEmpty()
    recoveryCode!: string;

    @IsString()
    @IsNotEmpty()
    @MinLength(6)
    password!: string;

    @IsString()
    @IsNotEmpty()
    @MinLength(6)
    @EqualsProperty("password")
    confirmPassword!: string;
}

export const COMPONENT_NAME = "ResetPasswordPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: { LvButton, LvField, LvFormItem, LvForm },
})
export default class ResetPasswordPage extends BaseComponent {
    @Ref()
    form!: LvForm;

    @InjectService(PasswordService)
    passwordService!: PasswordService;

    model = new ResetPasswordViewModel();

    created() {
        const { email, code } = this.$route.query;

        if (!email || !code || email instanceof Array || code instanceof Array) {
            void this.$router.replace("/login").catch(this.handleNavigationError);
            return;
        }

        this.model.email = email;
        this.model.recoveryCode = code;
    }

    @LoaderTask
    async onSubmit() {
        try {
            if (!await this.form.submit()) return;

            const resetResult = await this.passwordService.endReset(this.model);

            if (!resetResult.success) {
                void MessageBox.prompt({
                    type: "warning",
                    confirmText: this.$t("common.confirm"),
                    isCancellable: false,
                    title: this.$t("reset_password.error.title"),
                    message: this.$t("reset_password.error.message"),
                });

                void this.$router.replace("/").catch(this.handleNavigationError);
            }
            else {
                void MessageBox.prompt({
                    type: "success",
                    confirmText: this.$t("common.confirm"),
                    isCancellable: false,
                    title: this.$t("reset_password.success.title"),
                    message: this.$t("reset_password.success.message"),
                });

                void this.$router.replace("/login").catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
