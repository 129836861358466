import { RouteConfig } from "vue-router";
import { RouterContainer } from "@/app/pages/RouterContainer";
import { applyCategoryMeta } from "@fastoche/ui-tracking/core/router/applyCategoryMeta";
import OnboardingAccommodationPhotosPage from "@/app/pages/onboarding/hl/OnboardingAccommodationPhotosPage.vue";
import OnboardingPaymentInfoPage from "@/app/pages/onboarding/common/OnboardingPaymentInfoPage.vue";
import HlOnboardingRejectedPage from "@/app/pages/onboarding/hl/HlOnboardingRejectedPage.vue";
import HlOnboardingOverviewPage from "@/app/pages/onboarding/hl/HlOnboardingOverviewPage.vue";
import HlOnboardingPendingPage from "@/app/pages/onboarding/hl/HlOnboardingPendingPage.vue";
import OnboardingPaymentPreferencesPage from "@/app/pages/onboarding/hl/OnboardingPaymentPreferencesPage.vue";
import OnboardingUpcomingDeparturePage from "@/app/pages/onboarding/hl/OnboardingUpcomingDeparturePage.vue";
import OnboardingAccommodationDescriptionPage from "@/app/pages/onboarding/hl/OnboardingAccommodationDescriptionPage.vue";
import OnboardingInventoryPage from "@/app/pages/onboarding/hl/OnboardingInventoryPage.vue";
import OnboardingEmailVerifyPage from "@/app/pages/onboarding/common/OnboardingEmailVerifyPage.vue";
import OnboardingIdentityPage from "@/app/pages/onboarding/common/OnboardingIdentityPage.vue";
import OnboardingAdministrativeInfoPage from "@/app/pages/onboarding/hl/OnboardingAdministrativeInfoPage.vue";
import OnboardingEstimationPage from "@/app/pages/onboarding/hl/OnboardingEstimationPage.vue";
import OnboardingAppointmentRequestPage from "@/app/pages/onboarding/hl/OnboardingAppointmentRequestPage.vue";
import { GlobalApplicationContext } from "@fastoche/ui-core/application/GlobalApplicationContext";
import { HlOnboardingStore } from "@/modules/onboarding/hl/HlOnboardingStore";
import { assert } from "@leavy/utils";
import { UserOnboardingStatus } from "@/modules/onboarding/common/domain";
import { HL_ONBOARDING_END_LOCATION, HL_ONBOARDING_STEPS_LOCATION, HL_ONBOARDING_REJECTED_LOCATION, HL_ONBOARDING_ROOT_LOCATION, HL_ONBOARDING_SUMMARY_LOCATION } from "@/modules/onboarding/hl/workflow";
import { AnyHlOnboardingStepId } from "@/modules/onboarding/hl/domain";
import { HlOnboardingService } from "@/modules/onboarding/hl/services/HlOnboardingService";

const getCurrentOnboardingOrThrow = () => {
    const { rootContainer } = GlobalApplicationContext.default.fastoche;
    const hlOnboardingStore = rootContainer.get(HlOnboardingStore);
    assert(hlOnboardingStore.value, "Onboarding should exist");
    return { store: hlOnboardingStore, userOnboarding: hlOnboardingStore.value };
};

const getHlOnboardingService = () => {
    const { rootContainer } = GlobalApplicationContext.default.fastoche;
    return rootContainer.get(HlOnboardingService)
}

export const HL_ONBOARDING_ROUTES: RouteConfig[] = [
    {
        path: "",
        beforeEnter: (to, from, next) => {
            const { userOnboarding } = getCurrentOnboardingOrThrow();

            switch (userOnboarding.status) {
                case UserOnboardingStatus.STARTED:
                    return void next(HL_ONBOARDING_STEPS_LOCATION);

                case UserOnboardingStatus.ACCEPTED:
                    return void next("/app/happy-leaver");

                case UserOnboardingStatus.REJECTED:
                    return void next(HL_ONBOARDING_REJECTED_LOCATION);

                case UserOnboardingStatus.SUBMITTED:
                    if (from.path.startsWith(HL_ONBOARDING_STEPS_LOCATION)) {
                        return void next(HL_ONBOARDING_SUMMARY_LOCATION);
                    }
                    else {
                        return void next(HL_ONBOARDING_END_LOCATION);
                    }
            }
        },
    },
    {
        path: "summary",
        name: "HlOnboardingOverviewPage",
        component: HlOnboardingOverviewPage,
        beforeEnter: (to, from, next) => {
            const { userOnboarding } = getCurrentOnboardingOrThrow();

            switch (userOnboarding.status) {
                case UserOnboardingStatus.SUBMITTED:
                    return void next();

                default:
                    return void next(HL_ONBOARDING_ROOT_LOCATION);
            }
        },
    },
    {
        path: "steps",
        component: RouterContainer,
        beforeEnter: async (to, from, next) => {
            const { userOnboarding, store } = getCurrentOnboardingOrThrow();

            switch (userOnboarding.status) {
                case UserOnboardingStatus.STARTED:
                case UserOnboardingStatus.SUBMITTED:
                    const hasEstimation = userOnboarding.onboarding.revenueEstimation.data?.hasResult() === true;
                    const estimationStepPath = store.getStepLocation(AnyHlOnboardingStepId.HL_REVENUE_ESTIMATION);

                    if (!hasEstimation && !to.path.startsWith(estimationStepPath)) {
                        return void next(estimationStepPath);
                    }
                    else {
                        return void next();
                    }

                default:
                    return void next(HL_ONBOARDING_ROOT_LOCATION);
            }
        },
        children: [
            {
                path: "",
                beforeEnter: async (to, from, next) => {
                    const { userOnboarding, store } = getCurrentOnboardingOrThrow();
                    const hasEstimation = userOnboarding.onboarding.revenueEstimation.data?.hasResult() === true;

                    if(!hasEstimation) {
                        return void next(
                            store.getStepLocation(AnyHlOnboardingStepId.HL_REVENUE_ESTIMATION)
                        );
                    }
                    else {
                        return void next(
                            store.getStepLocation(AnyHlOnboardingStepId.HL_UPCOMING_DEPARTURE)
                        );
                    }
                },
            },
            {
                path: "estimation",
                name: "HlOnboardingEstimationPage",
                component: OnboardingEstimationPage,
            },
            {
                path: "next-departure",
                name: "HlOnboardingUpcomingDeparturePage",
                component: OnboardingUpcomingDeparturePage,
            },
            {
                path: "appointment-request",
                name: "HlOnboardingAppointmentRequestPage",
                component: OnboardingAppointmentRequestPage,
            },
            {
                path: "payment/plan",
                name: "HlOnboardingPaymentPreferences",
                component: OnboardingPaymentPreferencesPage,
            },
            {
                path: "email-verification",
                name: "HlOnboardingEmailVerifyPage",
                component: OnboardingEmailVerifyPage,
            },
            {
                path: "identity",
                name: "HlOnboardingIdentityPage",
                component: OnboardingIdentityPage,
            },
            {
                path: "accommodation/description",
                name: "HlOnboardingAccommodationDescriptionPage",
                component: OnboardingAccommodationDescriptionPage,
            },
            {
                path: "accommodation/photos",
                name: "HlOnboardingAccommodationPhotosPage",
                component: OnboardingAccommodationPhotosPage,
            },
            {
                path: "inventory",
                name: "HlOnboardingInventoryPage",
                component: OnboardingInventoryPage,
            },
            {
                path: "administrative",
                name: "HlOnboardingAdministrativeInfoPage",
                component: OnboardingAdministrativeInfoPage,
            },
            {
                path: "payment/info",
                name: "HlOnboardingPaymentInfoPage",
                component: OnboardingPaymentInfoPage,
            },
        ].map(route => applyCategoryMeta("HlOnboarding", route)),
    },
    {
        path: "pending",
        name: "HlOnboardingPendingPage",
        component: HlOnboardingPendingPage,
        beforeEnter: async (to, from, next) => {
            const { userOnboarding } = getCurrentOnboardingOrThrow();
            const hlOnboardingService = getHlOnboardingService();

            switch (userOnboarding.status) {
                case UserOnboardingStatus.STARTED:
                    if (userOnboarding.onboarding.canSubmit) {
                        const result = await hlOnboardingService.submitOnboarding();
                        if (result.success) return void next();
                    }

                    return void next(HL_ONBOARDING_ROOT_LOCATION);

                case UserOnboardingStatus.SUBMITTED:
                    return void next();

                default:
                    return void next(HL_ONBOARDING_ROOT_LOCATION);
            }
        },
    },
    {
        path: "rejected",
        name: "HlOnboardingRejectedPage",
        component: HlOnboardingRejectedPage,
        beforeEnter: (to, from, next) => {
            const { userOnboarding } = getCurrentOnboardingOrThrow();

            switch (userOnboarding.status) {
                case UserOnboardingStatus.REJECTED:
                    return void next();

                default:
                    return void next(HL_ONBOARDING_ROOT_LOCATION);
            }
        },
    },
].map(route => applyCategoryMeta("HlOnboarding", route));
