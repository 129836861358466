














































import { Component, Ref } from "vue-property-decorator";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { LvButton, LvContainer, LvDateRangePicker, LvForm, LvFormItem } from "@fastoche/ui-kit/components";
import { LvDateRangePickerInterval } from "@fastoche/ui-kit/components/date-range-picker/model";
import { DateTime } from "luxon";
import { IsArray, IsDate, IsDateString, IsDefined } from "@leavy/validator";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import { AnyHlOnboardingStepId } from "@/modules/onboarding/hl/domain";
import HlRegistrationSteps from "@/app/ui/hl-registration-steps/HlRegistrationSteps.vue";

class HlUpcomingDepartureViewModel {
    @IsArray()
    @IsDateString(undefined, { each: true })
    dates?: [string | null, string | null] = undefined;

    get departureDate() {
        return this.dates?.[0];
    }

    get returnDate() {
        return this.dates?.[1];
    }
}

export const COMPONENT_NAME = "OnboardingUpcomingDeparturePage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        HlRegistrationSteps,
        PageHeader,
        LvContainer,
        LvForm,
        LvFormItem,
        LvDateRangePicker,
        LvButton,
    },
})
export default class OnboardingUpcomingDeparturePage extends BaseHlOnboardingPage {
    @Ref()
    readonly form!: LvForm;

    private readonly minBookingDuration: LvDateRangePickerInterval = { value: 1, unit: "days" };
    private readonly minDate = DateTime.local().startOf("day").plus({ day: 1 }).toISO();
    private model: HlUpcomingDepartureViewModel = new HlUpcomingDepartureViewModel();

    get isOnboardingSubmitted() {
        return this.hlState.status == "SUBMITTED";
    }

    mounted() {
        void this.loadModel();
    }

    @LoaderTask
    async loadModel() {
        await this.onboardingService.loadStep(AnyHlOnboardingStepId.HL_UPCOMING_DEPARTURE);

        const model = new HlUpcomingDepartureViewModel();
        const { data } = this.hlState.onboarding.upcomingDeparture;

        if (data) {
            model.dates = [data.departureDate ?? null, data.returnDate ?? null];
        }

        this.model = model;
    }

    @LoaderTask
    async onSubmit() {
        try {
            if (!(await this.form.submit())) return;

            const result = await this.hlOnboardingService.submitUpcomingDepartureStep({
                departureDate: this.model.departureDate!,
                returnDate: this.model.returnDate!,
            });

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
            else {
                const nextPage = this.isOnboardingSubmitted ?
                    this.hlStore.getNextStepLocation(AnyHlOnboardingStepId.HL_UPCOMING_DEPARTURE) :
                    this.hlStore.getStepLocation(AnyHlOnboardingStepId.HL_PAYMENT_PREFERENCES);

                await this.$router.push(nextPage).catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
