






import { Component, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import IntercomFrame from "@/app/components/intercom/IntercomFrame.vue";

export const COMPONENT_NAME = "ChatPage";

@Component({
    name: COMPONENT_NAME,
    components: { IntercomFrame },
})
export default class ChatPage extends BaseComponent {

}
