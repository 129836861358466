import { AnyHlOnboardingStepId } from "@/modules/onboarding/hl/domain";
import { AnyHodOnboardingStepId } from "@/modules/onboarding/hod/domain";
import { BaseTrackingEvent } from "@fastoche/ui-tracking";

export namespace AppTrackingEvents {
    export class HlOnboardingStartClickEvent extends BaseTrackingEvent {}

    export class HlOnboardingResumeClickEvent extends BaseTrackingEvent {}

    export class HlOnboardingSubmitClickEvent extends BaseTrackingEvent {}

    export class HlOnboardingStepClickEvent extends BaseTrackingEvent {
        stepId!: AnyHlOnboardingStepId;
    }

    export class HodOnboardingStartClickEvent extends BaseTrackingEvent {}

    export class HodOnboardingResumeClickEvent extends BaseTrackingEvent {}

    export class HodOnboardingSubmitClickEvent extends BaseTrackingEvent {}

    export class HodOnboardingStepClickEvent extends BaseTrackingEvent {
        stepId!: AnyHodOnboardingStepId;
    }

    export class AppNavigationClickEvent extends BaseTrackingEvent {
        target!: "hl" | "hod" | "my_bookings" | "my_account" | "customer_service";
    }

    export class FooterLinkClickEvent extends BaseTrackingEvent {
        target!: "linkedin" | "instagram" | "facebook" | "faq" | "contact_us";
    }

    export class HlRegisterSubmitEvent extends BaseTrackingEvent {}

    export class HodRegisterSubmitEvent extends BaseTrackingEvent {}

    export class UserConfirmLogOutEvent extends BaseTrackingEvent {}

    export class UserConfirmDeleteAccountEvent extends BaseTrackingEvent {}
}

export type AppTrackingEvents = typeof AppTrackingEvents;

declare module "@fastoche/ui-tracking/core/TrackingEventAmbientRegistry" {
    export interface TrackingEventAmbientRegistry extends AppTrackingEvents {}
}

