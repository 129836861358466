















































import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { HlService } from "@/modules/hl/services/HlService";
import { InjectService } from "@fastoche/ui-core/di";
import { HlUserAccommodation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodation";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { FileService } from "@/modules/file/services/FileService";
import { LvIcon } from "@fastoche/ui-kit";

export const COMPONENT_NAME = "HlUserAccommodationSelector";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvIcon,
    },
})
export default class HlUserAccommodationSelector extends BaseComponent {
    @InjectService(HlService)
    protected readonly hlService!: HlService;

    @InjectService(FileService)
    protected readonly fileService!: FileService;

    private accommodations: HlUserAccommodation[] = [];

    @ModelSync("selected", "change", { type: String })
    selectedId!: string | null;
    declare readonly selected: string | null;

    mounted() {
        void this.initialize();
    }

    @LoaderTask
    async initialize() {
        try {
            this.accommodations = await this.hlService.getUserAccommodations();

            if (this.accommodations.length == 0) {
                this.selectedId = null;
            }
            else if (this.accommodations.length == 1) {
                this.selectedId = this.accommodations[0]!.id;
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    isSelected(acc: HlUserAccommodation) {
        return acc.id == this.selectedId;
    }

    select(acc: HlUserAccommodation) {
        const isConfirm = this.selectedId == acc.id;
        this.selectedId = acc.id;

        if (isConfirm) {
            this.$emit("confirm");
        }
    }

    getCoverPhoto(acc: HlUserAccommodation) {
        const [firstPhoto] = acc.photos;
        if (!firstPhoto) return null;
        return this.fileService.getFileUrl(firstPhoto).url;
    }
}
