import { MutableStore } from "@fastoche/ui-core/store";
import { injectable } from "inversify";
import * as qs from "qs";
import { Expose, IsOptional, IsString, validate } from "@leavy/validator";

export class UtmParametersState {
    @Expose({ name: "utm_campaign" })
    @IsOptional()
    @IsString()
    utmCampaign?: string;

    @Expose({ name: "utm_medium" })
    @IsOptional()
    @IsString()
    utmMedium?: string;

    @Expose({ name: "utm_source" })
    @IsOptional()
    @IsString()
    utmSource?: string;
}

@injectable()
export class UtmParametersStore extends MutableStore<UtmParametersState> {
    constructor() {
        super(new UtmParametersState());
    }

    snapshotFromCurrentUrl() {
        const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

        const validationResult = validate(UtmParametersState, parsed, {
            transformer: {
                excludeExtraneousValues: true,
            },
        });

        if (validationResult.success) {
            this.set(validationResult.value);
        }
    }
}
