import { RouteConfig } from "vue-router";
import HlRegistrationPage from "@/app/pages/registration/hl/HlRegistrationPage.vue";
import HodRegistrationPage from "@/app/pages/registration/hod/HodRegistrationPage.vue";
import HlRegistrationFlowLayout from "@/app/pages/registration/hl/HlRegistrationFlowLayout.vue";
import HodRegistrationFlowLayout from "@/app/pages/registration/hod/HodRegistrationFlowLayout.vue";
import { GlobalApplicationContext } from "@fastoche/ui-core/application/GlobalApplicationContext";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";

export const REGISTRATION_ROUTES: RouteConfig[] = [
    {
        path: "",
        redirect: "hl",
    },
    {
        path: "hl",
        component: HlRegistrationFlowLayout,
        children: [
            {
                path: "",
                component: HlRegistrationPage,
                meta: {
                    category: "HlRegistration",
                },
            },
        ],
    },
    {
        path: "hod",
        component: HodRegistrationFlowLayout,
        children: [
            {
                path: "",
                component: HodRegistrationPage,
                meta: {
                    category: "HodRegistration",
                },
            },
        ],
    },
];
