import { RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../../base";
import { CommonEditOnboardingError } from "../../ForwardedOnboardingCommand";
import { StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { Equals, IsUUID } from "@leavy/validator";
import { Bad, Ok } from "@leavy/result";
import { OnboardingId } from "@leavy/lv-onboarding-srv/lib/common/domain/OnboardingId";

export type DeleteInventorySubjectPhotoLocalCommandResult =
    | Ok
    | Bad<"FILE_NOT_FOUND">
    | Bad<"SUBJECT_NOT_FOUND">
    | CommonEditOnboardingError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "delete_inventory_subject_photo",
})
export class DeleteInventorySubjectPhotoLocalCommand extends StepCommand<DeleteInventorySubjectPhotoLocalCommandResult> {
    @Equals(OnboardingId.HL)
    override onboardingId: OnboardingId.HL = OnboardingId.HL;

    @IsUUID("4")
    subjectId!: string;

    @IsUUID("4")
    photoId!: string;
}

