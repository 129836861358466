import { Command, RpcInvocable } from "@leavy/cq-core";
import { Bad, Ok, OkVal } from "@leavy/result";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { IsEmail, IsString, LowerCase, Trim } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";

export type UpdateUserEmailCommandBadResult = "update_email_failed";
export type UpdateUserEmailCommandResult = Ok | Bad<UpdateUserEmailCommandBadResult>;

/**
 * UpdateUserEmailCommand command description
 */
@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "update_user_email",
})
export class UpdateUserEmailCommand extends Command<UpdateUserEmailCommandResult, UserEmailUpdatedEvent> {
    @IsString()
    userId!: string;

    @IsEmail()
    @Trim()
    @LowerCase()
    email!: string;
}

@DomainEvent({ namespace: HOMESHARING_BACKEND_SERVICE_NAME, name: "user_email_updated", version: 1 })
export class UserEmailUpdatedEvent extends DomainEventBase {
    @EventProperty()
    userId!: string;

    @EventProperty()
    email!: string;

    @EventProperty()
    verificationCode!: string;

    constructor(data: PayloadOf<UserEmailUpdatedEvent>) {
        super(data);
    }
}

