



































































































































import { Component, Prop, Ref } from "vue-property-decorator";
import { BaseComponent } from "../../BaseComponent";
import StickyElement from "@/app/ui/sticky-element/StickyElement.vue";
import { LvButton, LvSpin, LvCarousel, LvCarouselSlide, LvContainer, LvFloatingSlideCard, LvIcon, LvMap, LvMapMarker, LvTag } from "@fastoche/ui-kit/components";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { InjectService } from "@fastoche/ui-core/di";
import AmenityIcon from "@/app/components/amenity/icon/AmenityIcon.vue";
import { APP_CONFIG } from "@/app/config";
import {
    HotelQueryResult,
    HouseListingQueryResult,
    ListingPriceQueryResult,
} from "@/modules/listing/model";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { ListingHouseService } from "@/modules/listing/services/ListingHouseService";
import BookingCard from "@/app/components/listing/booking-card/BookingCard.vue";
import RoomPreviewCard from "@/app/components/listing/room/RoomPreviewCard.vue";
import { ListingHotelService } from "@/modules/listing/services/ListingHotelService";
import { BookingStore } from "@/modules/booking/BookingStore";
import { ListingStore } from "@/modules/listing/ListingStore";
import { StartBookingCheckoutCommand } from "@leavy/lv-homesharing-backend-srv/lib/booking/command/clients/StartBookingCheckout.client";
import { DateTime } from "luxon";
import { CheckoutService } from "@/modules/checkout/services/CheckoutService";
import { UserContext, UserContextStore } from "@/modules/user/UserContext";

const styles = require("@/app/vendors/google-maps/styles.json");

export const COMPONENT_NAME = "ListingAccommodationViewPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    responsive: true,
    components: {
        BookingCard,
        AmenityIcon,
        LvContainer,
        LvIcon,
        LvTag,
        LvMap,
        LvMapMarker,
        LvCarousel,
        LvCarouselSlide,
        LvFloatingSlideCard,
        LvButton,
        RoomPreviewCard,
        LvSpin,
        StickyElement,
    },
})
export default class ListingAccommodationViewPage extends BaseComponent {

    @InjectState(UserContextStore)
    readonly userContext!: UserContext;

    @InjectService(ListingHouseService)
    private readonly listingHouseService!: ListingHouseService;

    @InjectService(ListingHotelService)
    private readonly listingHotelService!: ListingHotelService;

    @InjectState(BookingStore)
    readonly bookingState!: StateOf<BookingStore>;

    @InjectState(ListingStore)
    readonly listingState!: StateOf<ListingStore>;
    @InjectService(ListingStore)
    readonly listingStore!: ListingStore;

    @InjectService(CheckoutService)
    readonly checkoutService!: CheckoutService;

    @Prop({ type: String, required: true })
    readonly listingId!: string;

    @Prop({ type: String, required: true })
    readonly type!: string;

    @Ref()
    readonly floatingSlideCard!: LvFloatingSlideCard;

    housePrice = {} as ListingPriceQueryResult;

    private readonly mapConfig = {
        apiKey: APP_CONFIG.GOOGLE_MAPS_API_KEY,
        options: {
            center: { lat: 48.8581952, lng: 2.3504018 },
            zoom: 15,
        },
    };
    private model: HouseListingQueryResult | HotelQueryResult | null = null;

    get photos() {
        if (!this.model) return [];
        return this.model.photos;
    }

    get address() {
        if (!this.model) return null;

        return [
            this.model.location.city,
            this.model.location.country,
        ].filter(x => !!x).join(", ");
    }

    mounted() {
        if (this.bookingState.bookingQuote) {
            this.scrollToElement("rooms-card-container");
        }
    }

    created() {
        this.loadModel();
    }

    formatToLocateString(date) {
        return DateTime.fromJSDate(new Date(date)).toLocaleString();
    }

    @LoaderTask
    async loadModel() {
        try {
            let result;
            if (this.type === "house") {
                result = await this.listingHouseService.getHouseListingById(this.listingId);
                this.listingStore.setHouse(result.value);
            }
            else if (this.type === "hotel") {
                result = await this.listingHotelService.getHotelListingById(this.listingId);
                this.listingStore.setHotel(result.value);
            }

            if (result.success) {
                this.model = result.value;
                if (this.model)
                    this.mapConfig.options.center = { lat: this.model.location.lat, lng: this.model.location.lon };
            }
            else {
                switch (result.reason) {
                    case "NOT_FOUND":
                        await this.redirectNotFound();
                        return;
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    scrollToElement(elementId: string) {
        setTimeout(function () {
            // @ts-ignore
            const roomCardYPos = window.scrollY + document.querySelector(`#${elementId}`).getBoundingClientRect().top;
            const margin = 200;

            window.scrollTo({ top: roomCardYPos - margin, behavior: "smooth" });
        }, 500);
    }

    async goToCheckoutPage() {
        if (this.bookingState.bookingQuote && this.bookingState.bookingQuote.to && this.bookingState.bookingQuote.from && this.bookingState.bookingPrice && this.model) {
            const bookingRequestSummary: StartBookingCheckoutCommand = {
                listingId: this.listingId,
                guests: this.bookingState.bookingQuote.guests,
                arrivalDate: DateTime.fromISO(this.bookingState.bookingQuote.from.toString()).toFormat("yyyy-MM-dd"),
                departureDate: DateTime.fromISO(this.bookingState.bookingQuote.to.toString()).toFormat("yyyy-MM-dd"),
                accommodationType: this.model.type,
                currency: this.bookingState.bookingPrice.period.currency,
                roomId: this.listingState.room?.roomId,
            };

            await this.checkoutService.startBookingCheckout(bookingRequestSummary).then((response) => {
                if (response.success) {
                    const checkoutId = response.value;
                    this.$router.push({
                        path: `/checkout/${checkoutId}`,
                    }).catch(this.handleNavigationError);
                }
            })
                .catch((e) => {
                    console.log(e);
                });
        }
    }
}


