import { RouteConfig } from "vue-router";
import { HOD_ONBOARDING_MATCHING_ROUTES } from "./matching/routes";
import { hodOnboardingGuard, hodOnboardingStepGuard } from "@/modules/onboarding/hod/guard";
import { RouterContainer } from "../../RouterContainer";
import { applyCategoryMeta } from "@fastoche/ui-tracking/core/router/applyCategoryMeta";
import OnboardingAdministrativeDocsPage from "@/app/pages/onboarding/hod/OnboardingAdministrativeDocsPage.vue";
import HodOnboardingOverviewPage from "@/app/pages/onboarding/hod/HodOnboardingOverviewPage.vue";
import HodOnboardingPendingPage from "@/app/pages/onboarding/hod/HodOnboardingPendingPage.vue";
import OnboardingPaymentInfoPage from "@/app/pages/onboarding/common/OnboardingPaymentInfoPage.vue";
import OnboardingEmailVerifyPage from "@/app/pages/onboarding/common/OnboardingEmailVerifyPage.vue";
import OnboardingOnlineMeetingPage from "@/app/pages/onboarding/hod/OnboardingOnlineMeetingPage.vue";
import HodOnboardingAcceptedPage from "@/app/pages/onboarding/hod/HodOnboardingAcceptedPage.vue";
import OnboardingIdentityPage from "@/app/pages/onboarding/common/OnboardingIdentityPage.vue";
import HodOnboardingRejectedPage from "@/app/pages/onboarding/hod/HodOnboardingRejectedPage.vue";
import OnboardingVettingPage from "@/app/pages/onboarding/hod/OnboardingVettingPage.vue";

export const HOD_ONBOARDING_ROUTES: RouteConfig[] = [
    {
        path: "",
        redirect: "summary",
    },
    {
        path: "summary",
        name: "HodOnboardingOverviewPage",
        beforeEnter: hodOnboardingGuard,
        component: HodOnboardingOverviewPage,
    },
    {
        path: "steps",
        component: RouterContainer,
        beforeEnter: hodOnboardingStepGuard,
        children: [
            {
                path: "email-verification",
                name: "HodOnboardingEmailVerifyPage",
                component: OnboardingEmailVerifyPage,
            },
            {
                path: "identity",
                name: "HodOnboardingIdentityPage",
                component: OnboardingIdentityPage,
            },
            {
                path: "get-to-know-us",
                name: "HodOnboardingVettingPage",
                component: OnboardingVettingPage,
            },
            {
                path: "administrative-docs",
                name: "HodOnboardingAdministrativeDocsPage",
                component: OnboardingAdministrativeDocsPage,
            },
            {
                path: "payment/info",
                name: "HodOnboardingPaymentInfoPage",
                component: OnboardingPaymentInfoPage,
            },
            {
                path: "online-meeting",
                name: "HodOnboardingOnlineMeetingPage",
                component: OnboardingOnlineMeetingPage,
            },
            ...HOD_ONBOARDING_MATCHING_ROUTES,
        ].map(route => applyCategoryMeta("HodOnboarding", route)),
    },
    {
        path: "pending",
        name: "HodOnboardingPendingPage",
        component: HodOnboardingPendingPage,
    },
    {
        path: "accepted",
        name: "HodOnboardingAcceptedPage",
        component: HodOnboardingAcceptedPage,
    },
    {
        path: "rejected",
        name: "HodOnboardingRejectedPage",
        component: HodOnboardingRejectedPage,
    },
].map(route => applyCategoryMeta("HodOnboarding", route));
