






















import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import {LvSpin, LvCard, LvIcon, LvBadge, LvModal, LvButton} from "@fastoche/ui-kit/components";
import BookingInvoice from "@/app/components/booking/my-bookings/invoice/BookingInvoice.vue";
import { lazyBgImg } from "@fastoche/ui-kit/directives";
import { currencySymbol } from "@fastoche/ui-core/i18n/currency/filters/currencySymbol";
import { money } from  "@/app/ui/filters/money.js";
import {ListingBooking} from "@leavy/lv-homesharing-backend-srv/lib/booking/domain/BookedListing";
export const COMPONENT_NAME = "InvoiceModal";

@Component({
    name: COMPONENT_NAME,
    responsive: true,
    components: {
        LvSpin,
        LvCard,
        LvIcon,
        LvBadge,
        LvModal,
        LvButton,
        BookingInvoice
    },
    directives: {
        lazyBgImg,
    },
    filters: {
        currencySymbol,
        money
    }
})
export default class InvoiceModal extends BaseComponent {
    @Ref()
    private readonly modal!: LvModal;

    @Prop({required: true})
    private booking!: ListingBooking;

    show() {
        this.modal.show();
    }

    shake() {
        this.modal.shake();
    }

    hide() {
        this.modal.hide();
    }
    print(){
        window.print();
    }
}
