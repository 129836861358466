

































import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { LvButton, LvContainer, LvIcon, LvIntersectionObserver, LvSpin, LvTag } from "@fastoche/ui-kit";
import { hugeLocaleDate } from "@/app/filters/date";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { InjectService } from "@fastoche/ui-core/di";
import { HlService } from "@/modules/hl/services/HlService";
import { HlUserPayment } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserPayment";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { HlUserManagementPeriod } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriod";
import HlUserManagementPeriodRowCard from "@/app/components/hl/management-period/HlUserManagementPeriodRowCard.vue";
import { HlUserAccommodationStore } from "@/modules/hl/stores/HlUserAccommodationStore";
import { HlUserAccommodation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodation";

export const COMPONENT_NAME = "HlPeriodListPage";

@Component({
    name: COMPONENT_NAME,
    components: {
        HlUserManagementPeriodRowCard,
        PageHeader,
        LvIntersectionObserver,
        LvContainer,
        LvTag,
        LvSpin,
        LvButton,
        LvIcon,
    },
    filters: {
        hugeLocaleDate,
        price,
    },
})
export default class HlPeriodListPage extends BaseComponent {
    @InjectService(HlService)
    readonly hlService!: HlService;

    @InjectService(HlUserAccommodationStore)
    readonly hlUserAccommodationStore!: HlUserAccommodationStore;

    paginationOptions = {
        page: 0,
        perPage: 25,
    };

    accommodations: HlUserAccommodation[] = [];
    data: HlUserManagementPeriod[] = [];
    isLoading = false;
    canLoadMore = false;

    mounted() {
        void this.initialize();
    }

    @LoaderTask
    async initialize() {
        const [paginatedPeriods, accommodations] = await Promise.all([
            this.hlService.getUserManagementPeriods(this.paginationOptions),
            this.hlUserAccommodationStore.refresh(),
        ]);

        const { data } = paginatedPeriods;

        this.accommodations = accommodations as HlUserAccommodation[];
        this.data = data;
        this.canLoadMore = data.length > 0;
    }

    async loadMore() {
        if (this.isLoading || !this.canLoadMore) return;

        this.isLoading = true;
        this.paginationOptions.page++;

        const { data } = await this.hlService.getUserManagementPeriods(this.paginationOptions);
        this.canLoadMore = data.length > 0;
        this.data.push(...data);

        this.isLoading = false;
    }

    getPeriodAccommodation(period: HlUserManagementPeriod) {
        return this.accommodations.find(x => x.id == period.accommodationId);
    }
}
