import { Query, RpcInvocable } from "@leavy/cq-core";
import { Bad, OkVal } from "@leavy/result";
import { IsDateString, IsIn, IsNumber, IsOptional, IsString, IsUUID, Min, TryParseNumber } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { HotelRoom } from "../domain/Listing";
import { Currency, SUPPORTED_CURRENCIES } from "../../money/domain/Currency";


export type FindAvailableHotelRoomsQueryResult =
    | OkVal<HotelRoom[]>
    | Bad<"INVALID_DATE_INTERVAL">
    ;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "find_available_hotel_rooms_query",
})
export class FindAvailableHotelRoomsQuery extends Query<FindAvailableHotelRoomsQueryResult> {
    @IsUUID()
    hotelId!: string;

    @IsString()
    @IsDateString()
    arrivalDate!: string;

    @IsString()
    @IsDateString()
    departureDate!: string;

    @IsNumber()
    @Min(1)
    @TryParseNumber()
    guests!: number;

    @IsIn(SUPPORTED_CURRENCIES)
    currency!: Currency;
}
