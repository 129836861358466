import { orderBy } from "lodash";
import { GetIndexedStep, IndexedStep } from "./model/IndexedStep";
import { AnyUserOnboarding, UserOnboardingStepStatus } from "./domain";

export class UserOnboardingState<TOnboarding extends AnyUserOnboarding, TStep extends string> {
    private readonly _indexedStep: (step: TStep) => IndexedStep<TStep>;

    constructor(
        private readonly _onboarding: TOnboarding,
        private readonly workflow: ReadonlyArray<TStep>,
    ) {
        this._indexedStep = GetIndexedStep(this.workflow);
    }

    get onboarding(): TOnboarding {
        return this._onboarding;
    }

    get id() {
        return this.onboarding.onboardingId;
    }

    get steps() {
        return this.onboarding.steps;
    }

    get orderedSteps() {
        return orderBy(
            this.steps,
            x => this._indexedStep(x.stepId as TStep).index,
            "asc",
        );
    }

    get progress() {
        return Math.round(
            this.steps.filter(x => x.status == UserOnboardingStepStatus.COMPLETE).length / this.steps.length * 100,
        );
    }

    get status() {
        return this.onboarding.status;
    }

    get hasPendingSteps() {
        return this.steps.some(x => x.status != UserOnboardingStepStatus.COMPLETE);
    }

    get firstPendingStep() {
        const orderedSteps = this.orderedSteps;

        return (
            orderedSteps.find(x => x.status == UserOnboardingStepStatus.NOT_STARTED) ??
            orderedSteps.find(x => x.status == UserOnboardingStepStatus.STARTED)
        );
    }
}
