import { BookingService } from "@/modules/booking/services/BookingService";
import { injectable } from "inversify";
import { BookingRequestModel, GetBookingRequestSummaryModel } from "@/modules/booking/model";
import { Bad, Ok } from "@leavy/result";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { GetBookingRequestSummaryQuery } from "@leavy/lv-homesharing-backend-srv/lib/booking/query/clients/GetBookingRequestSummaryQuery.client";
import { RequestBookingCommand } from "./command";
import { ListBookingsQuery, ListBookingsQueryResult } from "@leavy/lv-homesharing-backend-srv/lib/booking/query/ListBookingsQuery";
import { ListBookingsQuery as ListBookingsQueryClient } from "@leavy/lv-homesharing-backend-srv/lib/booking/query/clients/ListBookingsQuery.client";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";
import { LocalizationOptionsStore } from "@fastoche/ui-core/i18n/options/LocalizationOptions";
import { ListingBooking } from "@leavy/lv-homesharing-backend-srv/lib/booking/domain/BookedListing";

@injectable()
export class CqBookingService extends BookingService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
        private readonly authStore: AuthenticationStore,
        private readonly localizationStore: LocalizationOptionsStore,
    ) {
        super();
    }

    async getBookingRequestSummary(model: GetBookingRequestSummaryModel) {
        const result = await this.cq.invoke(GetBookingRequestSummaryQuery, {
            listingId: model.listingId,
            arrivalDate: model.arrivalDate,
            departureDate: model.departureDate,
            guests: model.guests,
        });

        if (result.success) {
            return Ok(result.value);
        }
        else {
            switch (result.reason) {
                case "USER_NOT_HL":
                    return Bad("USER_NOT_HL");

                case "INVALID_LISTING_ID":
                case "LISTING_NOT_FOUND":
                    return Bad("LISTING_NOT_FOUND");

                // TODO: typo here
                case "LISTING_AVAILABLE":
                    return Bad("LISTING_NOT_AVAILABLE");

                case "OCCUPANCY_EXCEEDED":
                    return Bad("OCCUPANCY_EXCEEDED");
            }
        }
    }

    async getBookingsList(model: ListBookingsQueryClient): Promise<ListBookingsQueryResult> {
        const result = await this.cq.invoke(ListBookingsQuery, {
            page: model.page,
            perPage: model.perPage,
            userId: this.authStore.value.clientId,
            locale: this.localizationStore.value.defaultLocale.localeCode,
        });
        return Ok(result.value);
    }

    async getBookingInfo(bookingId: string): Promise<ListingBooking | null> {
        const result = await this.cq.invoke(ListBookingsQuery, {
            page: 1,
            perPage: 10,
            userId: this.authStore.value.clientId,
            locale: this.localizationStore.value.defaultLocale.localeCode,
        });

        const bookings = result.value.data;

        for (let booking of bookings) {
            if (booking.id == bookingId) {
                return booking;
            }
        }

        return null;
    }

    async requestBooking(model: BookingRequestModel) {
        const result = await this.cq.invoke(RequestBookingCommand, {
            listingId: model.listingId,
            arrivalDate: model.arrivalDate,
            departureDate: model.departureDate,
            guests: model.guests,
        });

        if (result.success) {
            return Ok(result.value);
        }
        else {
            switch (result.reason) {
                case "USER_NOT_HL":
                    return Bad("USER_NOT_HL");

                case "INVALID_LISTING_ID":
                case "LISTING_UNAVAILABLE_OR_NOT_FOUND":
                    return Bad("LISTING_NOT_FOUND");

                case "BOOKING_OVERLAP":
                    return Bad("LISTING_NOT_AVAILABLE");

                case "INVALID_DATE_INTERVAL":
                    return Bad("DURATION_NOT_SUPPORTED");

                case "OCCUPANCY_EXCEEDED":
                    return Bad("OCCUPANCY_EXCEEDED");
            }
        }
    }
}
