import { UserIdAmbientValue } from "@leavy/cq-core";
import * as OnboardingService from "@leavy/lv-onboarding-srv/lib/payment/command";
import { ForwardedOnboardingCommand } from "../ForwardedOnboardingCommand";

export class SubmitPaymentInfoStepCommand extends ForwardedOnboardingCommand({
    type: OnboardingService.SubmitPaymentInfoStepCommand,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
