


















import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { CityKnowledge } from "@/modules/onboarding/hod/domain";
import { HodMatchingSubstep, MatchingDataModel } from "@/modules/onboarding/hod/model";
import { BaseHodOnboardingPage } from "@/app/pages/onboarding/hod/BaseHodOnboardingPage";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";

export const COMPONENT_NAME = "OnboardingMatchingCityKnowledgePage";

@Component({
    name: COMPONENT_NAME,
    components: { LvButton },
    loader: true,
})
export default class OnboardingMatchingCityKnowledgePage extends BaseHodOnboardingPage {
    choices: CityKnowledge[] = Object.values(CityKnowledge);
    selected: CityKnowledge | null = null;

    @Prop({ type: Object })
    matchingData!: MatchingDataModel;

    mounted() {
        if (this.matchingData.cityKnowledge != undefined) {
            this.selected = this.matchingData.cityKnowledge;
        }
    }

    isSelected(choice: CityKnowledge) {
        return this.selected == choice;
    }

    toggle(choice: CityKnowledge) {
        if (this.selected == choice) this.selected = null;
        else this.selected = choice;
    }

    get isValid() {
        return this.selected != null;
    }

    nextStep() {
        this.matchingData.cityKnowledge = this.selected ?? undefined;
        this.$emit("next", { from: HodMatchingSubstep.CITY_KNOWLEDGE });
    }
}
