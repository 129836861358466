

































































import { Component, Ref } from "vue-property-decorator";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { LvButton, LvContainer, LvField, LvFilePicker, LvFilePreview, LvForm, LvFormItem, LvIcon } from "@fastoche/ui-kit/components";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { AdministrativeDocumentType, AnyHlOnboardingStepId } from "@/modules/onboarding/hl/domain";
import { HlAdministrativeDocumentModel, HlAdministrativeInfoModel } from "@/modules/onboarding/hl/model";
import { InjectService } from "@fastoche/ui-core/di";
import { FileService } from "@/modules/file/services/FileService";
import { ArrayMinSize, Type, ValidateNested } from "@leavy/validator";
import { construct } from "@leavy/utils";
import { CommonErrorDialogs } from "@/app/ui/error/CommonErrorDialogs";
import OnboardingDocumentCollection from "@/app/components/onboarding/common/OnboardingDocumentCollection.vue";
import HelpTooltip from "@/app/ui/help-tooltip/HelpTooltip.vue";

class AdministrativeDocumentsViewModel {
    @ArrayMinSize(1)
    [AdministrativeDocumentType.FLAT_ADDRESS_PROOF]: HlAdministrativeDocumentModel[] = [];

    @ArrayMinSize(0)
    [AdministrativeDocumentType.OTHER]: HlAdministrativeDocumentModel[] = [];
}

class AdministrativeInfoViewModel extends HlAdministrativeInfoModel {
    @Type(() => AdministrativeDocumentsViewModel)
    @ValidateNested()
    documents: AdministrativeDocumentsViewModel = new AdministrativeDocumentsViewModel();
}

export const COMPONENT_NAME = "OnboardingAdministrativeInfoPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        HelpTooltip,
        OnboardingDocumentCollection,
        PageHeader,
        LvContainer,
        LvForm,
        LvFormItem,
        LvField,
        LvFilePicker,
        LvFilePreview,
        LvButton,
        LvIcon,
    },
})
export default class OnboardingAdministrativeInfoPage extends BaseHlOnboardingPage {
    @Ref()
    readonly form!: LvForm;

    @InjectService(FileService)
    readonly fileService!: FileService;

    readonly documentTypes: AdministrativeDocumentType[] = Object.values(AdministrativeDocumentType);

    model: AdministrativeInfoViewModel = new AdministrativeInfoViewModel();

    created() {
        void this.loadModel();
    }

    @LoaderTask
    async loadModel() {
        await this.onboardingService.loadStep(AnyHlOnboardingStepId.HL_ADMINISTRATIVE_INFO);

        const model = new AdministrativeInfoViewModel();
        const { data } = this.hlState.onboarding.administrative;

        if (data) {
            model.regulatoryFlatRegistrationCode = data.regulatoryFlatRegistrationCode;

            for (const document of data.documents) {
                // TODO: what to do in this case? The document exists on the server but is not complete...
                if (!document.isComplete()) continue;

                model.documents[document.documentType].push(construct(HlAdministrativeDocumentModel, {
                    documentType: document.documentType,
                    fileId: document.fileId,
                    label: document.label ?? "",
                }));
            }
        }

        this.model = model;
    }

    get documentTypeTooltips(): Record<AdministrativeDocumentType, string | null> {
        return {
            [AdministrativeDocumentType.FLAT_ADDRESS_PROOF]: this.$t('app.onboarding.hl.steps.administrative_documents.flat_address_proof.tooltip'),
            [AdministrativeDocumentType.OTHER]: this.$t('app.onboarding.hl.steps.administrative_documents.other.tooltip')
        };
    }

    getDocumentsOfType(documentType: AdministrativeDocumentType) {
        return this.model.documents[documentType];
    }

    isRequired(documentType: AdministrativeDocumentType) {
        switch (documentType) {
            case AdministrativeDocumentType.FLAT_ADDRESS_PROOF:
                return true;

            case AdministrativeDocumentType.OTHER:
                return false;
        }
    }

    getFileUrl(doc: HlAdministrativeDocumentModel) {
        return this.fileService.getFileUrl(doc.fileId).url;
    }

    @LoaderTask
    async addAdministrativeDocument(documentType: AdministrativeDocumentType, document: File) {
        try {
            const result = await this.hlOnboardingService.addAdministrativeDocument({
                document,
                documentType,
            });

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE": {
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;
                    }
                }
            }
            else {
                const { fileId } = result.value;

                const docModel = construct(HlAdministrativeDocumentModel, {
                    documentType,
                    fileId,
                    label: "",
                });

                this.getDocumentsOfType(documentType).push(docModel);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async removeAdministrativeDocument(doc: HlAdministrativeDocumentModel) {
        try {
            const result = await this.hlOnboardingService.removeAdministrativeDocument({
                ...doc,
            });

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE": {
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;
                    }
                }
            }
            else {
                const documentList = this.getDocumentsOfType(doc.documentType);

                const existingIdx = documentList.findIndex(x => x.fileId == doc.fileId);

                if (existingIdx > -1) {
                    documentList.splice(existingIdx, 1);
                }
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    async onSubmit() {
        try {
            if (!(await this.form.submit())) return;

            const result = await this.hlOnboardingService.submitAdministrativeInfoStep(this.model);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_NOT_FOUND":
                    case "ONBOARDING_WRONG_STATE":
                        CommonErrorDialogs.unexpectedError();
                        await this.$router.replace(this.onboardingRootLink).catch(this.handleNavigationError);
                        return;

                    case "STEP_INCOMPLETE":
                        CommonErrorDialogs.validationError();
                        return;
                }
            }
            else {
                const nextPage = this.hlStore.getNextStepLocation(AnyHlOnboardingStepId.HL_ADMINISTRATIVE_INFO);
                await this.$router.push(nextPage).catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
