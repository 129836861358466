import { GlobalTranslationStore } from "@fastoche/ui-core/i18n/global";
import { MessageBox } from "@fastoche/ui-kit/components";

export namespace CommonErrorDialogs {
    export function validationError(): void {
        void MessageBox.prompt({
            type: "warning",
            confirmText: GlobalTranslationStore.$t("common.i_understand"),
            isCancellable: false,
            title: GlobalTranslationStore.$t("common.error.validation.title"),
            message: GlobalTranslationStore.$t("common.error.validation.message"),
        });
    }

    export function networkError(): void {
        void MessageBox.prompt({
            type: "warning",
            confirmText: GlobalTranslationStore.$t("common.i_understand"),
            isCancellable: false,
            title: GlobalTranslationStore.$t("common.error.network.title"),
            message: GlobalTranslationStore.$t("common.error.network.message"),
        });
    }

    export function unexpectedError(): void {
        void MessageBox.prompt({
            type: "warning",
            confirmText: GlobalTranslationStore.$t("common.i_understand"),
            isCancellable: false,
            title: GlobalTranslationStore.$t("common.error.unexpected.title"),
            message: GlobalTranslationStore.$t("common.error.unexpected.message"),
        });
    }
}
