import { GetHouseListingByIdQuery as query } from "../GetHouseListingByIdQuery";
import { BuildClientInvocation, LocaleAmbientValue } from "@leavy/cq-core";

export class GetHouseListingByIdQuery extends BuildClientInvocation({
    type: query,
    ambientValues: {
        locale: LocaleAmbientValue,
    },
}) {
}
