



















































































import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { HlUserManagementPeriodRequest } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriodRequest";
import { HlUserAccommodation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodation";
import { LvIcon } from "@fastoche/ui-kit";
import { hugeLocaleDate } from "@/app/filters/date";
import { ManagementPeriodRequestStatus } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlManagementPeriodRequest";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { InjectService } from "@fastoche/ui-core/di";
import { FileService } from "@/modules/file/services/FileService";

export const COMPONENT_NAME = "HlUserManagementPeriodRequestRowCard";

@Component({
    name: COMPONENT_NAME,
    components: {
        LvIcon,
    },
    filters: {
        price,
        hugeLocaleDate,
    },
})
export default class HlUserManagementPeriodRequestRowCard extends BaseComponent {
    @InjectService(FileService)
    private readonly fileService!: FileService;

    @Prop({ type: Object, required: true })
    readonly request!: HlUserManagementPeriodRequest;

    @Prop({ type: Object, required: true })
    readonly accommodation!: HlUserAccommodation;

    @Prop({ type: Boolean, required: false, default: false })
    readonly clickable!: boolean;

    private get cardClassList() {
        return [
            this.clickable ? "clickable" : null,
        ].filter(x => x != null) as string[];
    }

    private get statusColor() {
        switch (this.request.status) {
            case ManagementPeriodRequestStatus.PRICE_PROPOSED:
                return "violet";

            case ManagementPeriodRequestStatus.PRICE_ACCEPTED:
                return "success";

            case ManagementPeriodRequestStatus.PRICE_REJECTED:
                return "danger";

            default:
                return null;
        }
    }

    private get coverPhoto() {
        const [firstPhoto] = this.accommodation.photos;
        if (!firstPhoto) return null;
        return this.fileService.getFileUrl(firstPhoto).url;
    }

    private onCardClick(ev: MouseEvent) {
        if (this.clickable) {
            this.$emit("click", ev);
        }
    }
}
