import { Ok } from "@leavy/result";
import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { AllUserOnboardingsStore } from "../../AllUserOnboardingsStore";
import { OnboardingService } from "@/modules/onboarding/common/services/OnboardingService";
import { AddIdentityDocumentCommand, RemoveIdentityDocumentCommand, RestartOnboardingCommand, StartOnboardingCommand, SubmitIdentityStepCommand, SubmitOnboardingCommand, SubmitPaymentInfoStepCommand } from "./command";
import { GetAllUserOnboardingsQuery, GetUserOnboardingExtraDataQuery, GetUserOnboardingStepQuery } from "./query";
import { AllUserOnboardings, CommonOnboardingStepId, OnboardingId, UserOnboardingStatus } from "../../domain";
import { PaymentInfoModel, UserIdentityModel } from "../../model";
import { plainToClass } from "@leavy/validator/lib/base/transformer";

@injectable()
export class CqOnboardingService extends OnboardingService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
        private readonly store: AllUserOnboardingsStore,
    ) {
        super();
    }

    async loadStore(): Promise<void> {
        const result = await this.cq.invoke(GetAllUserOnboardingsQuery, {});
        const allUserOnboardings = plainToClass(AllUserOnboardings, result.value);

        this.store.set({
            [OnboardingId.HL]: allUserOnboardings[OnboardingId.HL],
            [OnboardingId.HOD]: allUserOnboardings[OnboardingId.HOD],
        });
    }

    async loadStep(stepId: string): Promise<void> {
        const result = await this.cq.invoke(GetUserOnboardingStepQuery, { stepId });

        if (result.success) {
            const step = result.value;

            if (step) {
                this.store.updateStep(stepId, step);
            }
        }
    }

    async loadExtraData(dataTypeId: string): Promise<void> {
        const result = await this.cq.invoke(GetUserOnboardingExtraDataQuery, { dataTypeId });

        if (result.success) {
            const extraData = result.value;

            if (extraData) {
                this.store.updateExtraData(dataTypeId, extraData);
            }
        }
    }

    async startOnboarding(onboardingId: OnboardingId) {
        const result = await this.cq.invoke(StartOnboardingCommand, { onboardingId });

        if (!result.success) {
            switch (result.reason) {
                case "ONBOARDING_ALREADY_STARTED":
                    await this.loadStore();
                    return result;
            }
        }

        await this.loadStore();

        return Ok();
    }

    async restartOnboarding(onboardingId: OnboardingId) {
        const result = await this.cq.invoke(RestartOnboardingCommand, { onboardingId });
        if (!result.success) return result;

        await this.loadStore();
        return Ok();
    }

    async submitOnboarding(onboardingId: OnboardingId) {
        const result = await this.cq.invoke(SubmitOnboardingCommand, { onboardingId });
        if (!result.success) return result;

        this.store.updateOnboardingStatus(onboardingId, UserOnboardingStatus.SUBMITTED);

        return Ok();
    }

    async addIdentityDocument(onboardingId: OnboardingId, document: File) {
        const stepId = CommonOnboardingStepId.IDENTITY;

        const result = await this.cq.invoke(AddIdentityDocumentCommand, {
            onboardingId,
            document,
        });

        if (!result.success) return result;

        await this.loadStep(stepId);

        const { fileId } = result.value;
        return Ok({ fileId });
    }

    async removeIdentityDocument(onboardingId: OnboardingId, fileId: string) {
        const stepId = CommonOnboardingStepId.IDENTITY;

        const result = await this.cq.invoke(RemoveIdentityDocumentCommand, {
            onboardingId,
            fileId,
        });

        if (!result.success) return result;

        await this.loadStep(stepId);
        return Ok();
    }

    async submitIdentityStep(onboardingId: OnboardingId, data: UserIdentityModel) {
        const stepId = CommonOnboardingStepId.IDENTITY;

        const result = await this.cq.invoke(SubmitIdentityStepCommand, {
            onboardingId,
            data,
        });

        if (!result.success) return result;

        await this.loadStep(stepId);
        return Ok();
    }

    async submitPaymentInfoStep(onboardingId: OnboardingId, data: PaymentInfoModel) {
        const stepId = CommonOnboardingStepId.PAYMENT_INFO;

        const result = await this.cq.invoke(SubmitPaymentInfoStepCommand, {
            onboardingId,
            data,
        });

        if (!result.success) return result;

        await this.loadStep(stepId);
        return Ok();
    }
}
