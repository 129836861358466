import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { HodOnboardingService } from "../../services/HodOnboardingService";
import { AdministrativeDocumentModel, MatchingDataModel, MeetingPreferenceModel } from "../../model";
import { AdministrativeDocumentType, HodOnboardingStepId } from "../../domain";
import { AddAdministrativeDocumentCommand, EditMatchingStepCommand, RemoveAdministrativeDocumentCommand, SubmitAdministrativeDocumentsStepCommand, SubmitGetToKnowUsStepCommand, SubmitMatchingStepCommand, SubmitMeetingStepCommand } from "./command";
import { OnboardingId } from "@/modules/onboarding/common/domain";
import { Bad, Ok } from "@leavy/result";

@injectable()
export class CqHodOnboardingService extends HodOnboardingService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    async editMatchingStep(model: MatchingDataModel) {
        const stepId = HodOnboardingStepId.HOD_MATCHING;

        const result = await this.cq.invoke(EditMatchingStepCommand, {
            onboardingId: OnboardingId.HOD,
            data: model,
        });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }

    async submitMatchingStep() {
        const stepId = HodOnboardingStepId.HOD_MATCHING;

        const result = await this.cq.invoke(SubmitMatchingStepCommand, { onboardingId: OnboardingId.HOD });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }

    async submitGetToKnowUsStep() {
        const stepId = HodOnboardingStepId.HOD_GET_TO_KNOW_US;

        const result = await this.cq.invoke(SubmitGetToKnowUsStepCommand, { onboardingId: OnboardingId.HOD });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }

    async addAdministrativeDocument(document: File, documentType: AdministrativeDocumentType) {
        const stepId = HodOnboardingStepId.HOD_ADMINISTRATIVE_DOCS;

        const result = await this.cq.invoke(AddAdministrativeDocumentCommand, {
            onboardingId: OnboardingId.HOD,
            document,
            documentType,
        });

        if (!result.success) return result;

        const { fileId } = result.value;
        await this.onboardingService.loadStep(stepId);
        return Ok({ fileId });
    }

    async removeAdministrativeDocument(document: AdministrativeDocumentModel) {
        const stepId = HodOnboardingStepId.HOD_ADMINISTRATIVE_DOCS;

        const result = await this.cq.invoke(RemoveAdministrativeDocumentCommand, {
            onboardingId: OnboardingId.HOD,
            document,
        });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }

    async submitAdministrativeDocumentsStep() {
        const stepId = HodOnboardingStepId.HOD_ADMINISTRATIVE_DOCS;

        const result = await this.cq.invoke(SubmitAdministrativeDocumentsStepCommand, { onboardingId: OnboardingId.HOD });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }

    async submitMeetingStep(data: MeetingPreferenceModel) {
        const stepId = HodOnboardingStepId.HOD_MEETING;

        const result = await this.cq.invoke(SubmitMeetingStepCommand, { onboardingId: OnboardingId.HOD, data });

        if (!result.success)  return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }
}
