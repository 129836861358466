import { Env, loadConfig } from "@leavy/env";
import { APP_LOGGER } from "@/app/logger";

export class ProjectConfigModel {
    @Env("NODE_ENV")
    NODE_ENV!: string;

    @Env("VUE_APP_ENV")
    APP_ENV!: string;

    @Env("VUE_APP_HOMESHARING_BACKEND_URL")
    HOMESHARING_BACKEND_URL!: string;

    @Env("VUE_APP_GOOGLE_MAPS_KEY")
    GOOGLE_MAPS_API_KEY!: string;

    @Env("VUE_APP_CHECKOUT_PUBLIC_KEY")
    CHECKOUT_PUBLIC_KEY!: string;

    @Env("VUE_APP_CLIEND_ID")
    CLIENT_APP_CLIEND_ID!: string;

    @Env("VUE_APP_INTERCOM_APP_ID")
    INTERCOM_APP_ID!: string;

    @Env("VUE_APP_ENABLE_OLD_TRAVEL_APP")
    ENABLE_OLD_TRAVEL_APP = false;

    @Env("VUE_APP_SEGMENT_WRITE_KEY")
    SEGMENT_WRITE_KEY!: string;
}

export const APP_CONFIG = (() => {
    try {
        return loadConfig(ProjectConfigModel);
    }
    catch (error) {
        APP_LOGGER.fatal(
            "Error happened while loading environment configuration",
            { error, details: error.properties },
        );

        throw error;
    }
})();

if (APP_CONFIG.APP_ENV != "production") {
    APP_LOGGER.info("Application configuration", { config: APP_CONFIG });
}
