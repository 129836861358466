import { BinaryFile } from "@leavy/cq-core";
import { IsDefined, IsEnum, IsOptional, IsString } from "@leavy/validator";
import { AdministrativeDocumentType } from "../domain";

export class HlUploadAdministrativeDocumentModel {
    @BinaryFile()
    @IsDefined()
    document!: File;

    @IsEnum(AdministrativeDocumentType)
    documentType!: AdministrativeDocumentType;

    @IsOptional()
    @IsString()
    label?: string;
}
