import Router, { RouteConfig } from "vue-router";
import * as qs from "qs";
import PublicLayout from "@/app/pages/PublicLayout.vue";
import AuthLayout from "./pages/auth/AuthLayout.vue";
import AppLayout from "./pages/AppLayout.vue";
import { appGuard } from "@/modules/auth/guard";
import { RouterContainer } from "./pages/RouterContainer";
import { USER_ROUTES } from "@/app/pages/user/routes";
import { GlobalApplicationContext } from "@fastoche/ui-core/application/GlobalApplicationContext";
import { AUTH_ROUTES } from "@/app/pages/auth/routes";
import { MY_BOOKINGS_ROUTES } from "@/app/pages/booking/my-bookings/routes";
import { REGISTRATION_ROUTES } from "@/app/pages/registration/routes";
import { CUSTOMER_SERVICE_ROUTES } from "@/app/pages/customer-service/routes";
import { APP_CONFIG } from "@/app/config";
import { APP_LOGGER } from "@/app/logger";
import { LISTING_ROUTES } from "@/app/pages/listing/routes";
import { ERROR_ROUTES } from "@/app/pages/error/routes";
import { SANDBOX_ROUTES } from "@/app/pages/sandbox/routes";
import { HL_ROUTES } from "@/app/pages/hl/routes";
import { HOD_ROUTES } from "@/app/pages/hod/routes";
import AppHomePage from "@/app/pages/AppHomePage.vue";

// TODO: Travel App Temporary Shutdown
// import { BOOKING_FLOW_ROUTES } from "@/app/pages/booking/booking-flow/routes";
// import { CHECKOUT_ROUTES } from "@/app/pages/checkout/routes";

const ROOT_ROUTES: RouteConfig[] = [
    {
        path: "/",
        component: PublicLayout,
        children: [...LISTING_ROUTES],
    },
    // TODO: Travel App Temporary Shutdown
    // {
    //     path: "/",
    //     component: PublicLayout,
    //     children: [
    //         {
    //             path: "booking",
    //             component: RouterContainer,
    //             beforeEnter: appGuard,
    //             children: [...BOOKING_FLOW_ROUTES],
    //         },
    //     ],
    // },
    // {
    //     path: "/",
    //     component: PublicLayout,
    //     children: [...CHECKOUT_ROUTES],
    // },
    {
        path: "/",
        component: AuthLayout,
        children: [...AUTH_ROUTES],
    },
    {
        path: "/register",
        component: RouterContainer,
        children: [...REGISTRATION_ROUTES],
    },
    {
        path: "/invite/:referrerCode",
        redirect: (to) => {
            const { referrerCode } = to.params;

            return {
                path: "/register/hl",
                query: { referrerCode },
            };
        },
    },
    {
        path: "/app",
        component: AppLayout,
        beforeEnter: appGuard,
        children: [
            {
                path: "",
                component: AppHomePage,
            },
            {
                path: "happy-leaver",
                component: RouterContainer,
                children: [...HL_ROUTES],
            },
            {
                path: "host-on-demand",
                component: RouterContainer,
                children: [...HOD_ROUTES],
            },
            {
                path: "my-bookings",
                component: RouterContainer,
                children: [...MY_BOOKINGS_ROUTES],
            },
            {
                path: "user",
                component: RouterContainer,
                children: [...USER_ROUTES],
            },
            {
                path: "support",
                component: RouterContainer,
                children: [...CUSTOMER_SERVICE_ROUTES],
            },
        ],
    },
    {
        path: "/error",
        component: RouterContainer,
        children: [...ERROR_ROUTES],
    },
    {
        path: "*",
        redirect: "/error/not-found",
    },
];

if (APP_CONFIG.APP_ENV !== "production") {
    ROOT_ROUTES.push({
        path: "/sandbox",
        component: RouterContainer,
        children: [...SANDBOX_ROUTES],
    });
}

const router = new Router({
    linkActiveClass: "active",
    mode: "history",
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        else {
            return { x: 0, y: 0 };
        }
    },
    parseQuery: (query) => qs.parse(query),
    stringifyQuery: (query) => qs.stringify(query, { addQueryPrefix: true }),
    routes: ROOT_ROUTES,
});

router.onError(async (error) => {
    APP_LOGGER.fatal("Error happened while navigating to route", error);

    if (window.location.pathname != "/error") {
        void router.replace("/error");
    }
});

GlobalApplicationContext.default.router = router;

export default router;
