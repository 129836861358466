import { inject, injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { OnboardingId } from "@/modules/onboarding/common/domain";
import { Bad, Ok } from "@leavy/result";
import { HlOnboardingService } from "@/modules/onboarding/hl/services/HlOnboardingService";
import { HlOnboardingDataTypeId, HlOnboardingStepId, InventorySubjectType, PaymentPlan, RevenueEstimationResult } from "../../domain";
import { HlAccommodationDescriptionDataModel, HlAdministrativeDocumentModel, HlAdministrativeInfoModel, HlAppointmentRequestModel, HlPaymentPreferencesModel, HlUpcomingDepartureModel, HlUploadAdministrativeDocumentModel } from "../../model";
import * as C from "./command";
import * as Q from "./query";
import { Currency } from "@/modules/payment/domain";
import { plainToClass } from "@leavy/validator/lib/base/transformer";
import { HlSimpleRevenueEstimationRequestModel } from "@/modules/pricing/model";

@injectable()
export class CqHlOnboardingService extends HlOnboardingService {
    @inject(HttpRemoteCqInvoker)
    private readonly cq!: HttpRemoteCqInvoker;

    async submitUpcomingDepartureStep(model: HlUpcomingDepartureModel) {
        const stepId = HlOnboardingStepId.HL_UPCOMING_DEPARTURE;

        const result = await this.cq.invoke(C.SubmitUpcomingDepartureStepCommand, {
            onboardingId: OnboardingId.HL,
            data: model,
        });

        if (!result.success) return result;

        await Promise.all([
            this.onboardingService.loadStep(stepId),
            this.onboardingService.loadStep(HlOnboardingStepId.HL_REVENUE_ESTIMATION),
            this.onboardingService.loadExtraData(HlOnboardingDataTypeId.HL_PAYMENT_PLAN),
        ]);

        return Ok();
    }

    async saveAccommodationDescriptionStep(model: HlAccommodationDescriptionDataModel) {
        const stepId = HlOnboardingStepId.HL_ACCOMMODATION_DESCRIPTION;

        const result = await this.cq.invoke(C.SaveAccommodationDescriptionStepCommand, {
            onboardingId: OnboardingId.HL,
            data: model,
        });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }

    async submitAccommodationDescriptionStep() {
        const stepId = HlOnboardingStepId.HL_ACCOMMODATION_DESCRIPTION;

        const result = await this.cq.invoke(C.SubmitAccommodationDescriptionStepCommand, {
            onboardingId: OnboardingId.HL,
        });

        if (!result.success) return result;

        await Promise.all([
            this.onboardingService.loadStep(stepId),
            this.onboardingService.loadStep(HlOnboardingStepId.HL_REVENUE_ESTIMATION),
            this.onboardingService.loadExtraData(HlOnboardingDataTypeId.HL_PAYMENT_PLAN),
        ]);

        return Ok();
    }

    async addAccomodationPhoto(photo: File) {
        const stepId = HlOnboardingStepId.HL_ACCOMMODATION_PHOTOS;

        const result = await this.cq.invoke(C.SaveAccommodationPhotoStepCommand, {
            onboardingId: OnboardingId.HL,
            photo,
        });

        if (result.success) {
            const { fileId } = result.value;
            await this.onboardingService.loadStep(stepId);
            return Ok({ fileId });
        }
        else {
            switch (result.reason) {
                case "PHOTO_ALREADY_ADDED":
                    return Bad("DUPLICATE_FILE");

                default:
                    return result;
            }
        }
    }

    async removeAccomodationPhoto(fileId: string) {
        const stepId = HlOnboardingStepId.HL_ACCOMMODATION_PHOTOS;

        const result = await this.cq.invoke(C.DeleteAccommodationPhotoCommand, {
            onboardingId: OnboardingId.HL,
            fileId,
        });

        if (result.success) {
            await this.onboardingService.loadStep(stepId);
            return Ok();
        }
        else {
            switch (result.reason) {
                case "FILE_NOT_FOUND":
                    await this.onboardingService.loadStep(stepId);
                    return Ok();

                default:
                    return result;
            }
        }
    }

    async submitAccomodationPhotoStep() {
        const stepId = HlOnboardingStepId.HL_ACCOMMODATION_PHOTOS;

        const result = await this.cq.invoke(C.SubmitAccommodationPhotosStepCommand, { onboardingId: OnboardingId.HL });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }

    async addInventorySubject(subjectType: InventorySubjectType, subjectName: string) {
        const stepId = HlOnboardingStepId.HL_INVENTORY;

        const result = await this.cq.invoke(C.AddInventorySubjectCommand, {
            onboardingId: OnboardingId.HL,
            subjectName,
            subjectType,
        });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok({ subjectId: result.value.subjectId });
    }

    async editInventorySubject(subjectId: string, subjectName: string) {
        const stepId = HlOnboardingStepId.HL_INVENTORY;

        const result = await this.cq.invoke(C.EditInventorySubjectCommand, {
            onboardingId: OnboardingId.HL,
            subjectId,
            subjectName,
        });

        if (result.success) {
            await this.onboardingService.loadStep(stepId);
            return Ok();
        }
        else {
            switch (result.reason) {
                case "SUBJECT_NOT_FOUND":
                    await this.onboardingService.loadStep(stepId);
                    return result;

                default:
                    return result;
            }
        }
    }

    async removeInventorySubject(subjectId: string) {
        const stepId = HlOnboardingStepId.HL_INVENTORY;

        const result = await this.cq.invoke(C.DeleteInventorySubjectCommand, {
            onboardingId: OnboardingId.HL,
            subjectId,
        });

        if (result.success) {
            await this.onboardingService.loadStep(stepId);
            return Ok();
        }
        else {
            switch (result.reason) {
                case "SUBJECT_NOT_FOUND":
                    await this.onboardingService.loadStep(stepId);
                    return Ok();

                default:
                    return result;
            }
        }
    }

    async addInventoryPhoto(subjectId: string, photo: File) {
        const stepId = HlOnboardingStepId.HL_INVENTORY;

        const result = await this.cq.invoke(C.AddInventorySubjectPhotoCommand, {
            onboardingId: OnboardingId.HL,
            subjectId,
            photo,
        });

        if (result.success) {
            const { fileId } = result.value;
            await this.onboardingService.loadStep(stepId);
            return Ok({ fileId });
        }
        else {
            switch (result.reason) {
                case "SUBJECT_NOT_FOUND":
                    await this.onboardingService.loadStep(stepId);
                    return result;

                case "PHOTO_ALREADY_ADDED":
                    return Bad("DUPLICATE_FILE");

                default:
                    return result;
            }
        }
    }

    async removeInventoryPhoto(subjectId: string, fileId: string) {
        const stepId = HlOnboardingStepId.HL_INVENTORY;

        const result = await this.cq.invoke(C.DeleteInventorySubjectPhotoCommand, {
            onboardingId: OnboardingId.HL,
            subjectId,
            photoId: fileId,
        });

        if (result.success) {
            await this.onboardingService.loadStep(stepId);
            return Ok();
        }
        else {
            switch (result.reason) {
                case "SUBJECT_NOT_FOUND":
                    await this.onboardingService.loadStep(stepId);
                    return result;

                case "FILE_NOT_FOUND":
                    await this.onboardingService.loadStep(stepId);
                    return Ok();

                default:
                    return result;
            }
        }
    }

    async submitInventoryStep() {
        const stepId = HlOnboardingStepId.HL_INVENTORY;

        const result = await this.cq.invoke(C.SubmitInventoryStepCommand, {
            onboardingId: OnboardingId.HL,
        });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }

    async submitRevenueEstimationStep(model: HlSimpleRevenueEstimationRequestModel) {
        const stepId = HlOnboardingStepId.HL_REVENUE_ESTIMATION;

        const result = await this.cq.invoke(C.SubmitRevenueEstimationStepCommand, {
            onboardingId: OnboardingId.HL,
            data: model,
        });

        if (!result.success) return result;

        await Promise.all([
            this.onboardingService.loadStep(stepId),
            this.onboardingService.loadExtraData(HlOnboardingDataTypeId.HL_PAYMENT_PLAN),
        ]);

        return Ok();
    }

    async addAdministrativeDocument(model: HlUploadAdministrativeDocumentModel) {
        const stepId = HlOnboardingStepId.HL_ADMINISTRATIVE_INFO;

        const result = await this.cq.invoke(C.AddAdministrativeDocumentCommand, {
            onboardingId: OnboardingId.HL,
            document: model.document,
            documentType: model.documentType,
            label: model.label,
        });

        if (!result.success) return result;

        const { fileId } = result.value;
        await this.onboardingService.loadStep(stepId);
        return Ok({ fileId });
    }

    async removeAdministrativeDocument(model: HlAdministrativeDocumentModel) {
        const stepId = HlOnboardingStepId.HL_ADMINISTRATIVE_INFO;

        const result = await this.cq.invoke(C.RemoveAdministrativeDocumentCommand, {
            onboardingId: OnboardingId.HL,
            document: {
                ...model,
            },
        });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }

    async submitAdministrativeInfoStep(model: HlAdministrativeInfoModel) {
        const stepId = HlOnboardingStepId.HL_ADMINISTRATIVE_INFO;

        const result = await this.cq.invoke(C.SubmitAdministrativeInfoStepCommand, {
            onboardingId: OnboardingId.HL,
            data: {
                ...model,
            },
        });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }

    async submitPaymentPreferencesStep(model: HlPaymentPreferencesModel) {
        const stepId = HlOnboardingStepId.HL_PAYMENT_PREFERENCES;

        const result = await this.cq.invoke(C.SubmitPaymentPreferencesStepCommand, {
            onboardingId: OnboardingId.HL,
            data: model,
        });

        if (!result.success) return result;

        await Promise.all([
            this.onboardingService.loadStep(stepId),
            this.onboardingService.loadExtraData(HlOnboardingDataTypeId.HL_PAYMENT_PLAN),
        ]);

        return Ok();
    }

    async getCurrentEstimation(currency: Currency) {
        return await this.cq.invoke(Q.GetHlOnboardingCurrentRevenueEstimationQuery, {
            currency,
        });
    }

    async getSelectedPaymentPlan(currency: Currency) {
        const result = await this.cq.invoke(Q.GetHlOnboardingSelectedPaymentPlanQuery, {
            currency,
        });

        if (!result.value) {
            return Ok(null);
        }
        else {
            return Ok(
                plainToClass(PaymentPlan, result.value),
            );
        }
    }

    async getPossiblePaymentPlans(currency: Currency) {
        const result = await this.cq.invoke(Q.GetHlOnboardingPossiblePaymentPlansQuery, {
            currency,
        });

        return Ok(
            result.value.map(x => plainToClass(PaymentPlan, x)),
        );
    }

    async submitAppointmentRequestStep(model: HlAppointmentRequestModel) {
        const stepId = HlOnboardingStepId.HL_APPOINTMENT_REQUEST;

        const result = await this.cq.invoke(C.SubmitAppointmentRequestStepCommand, {
            onboardingId: OnboardingId.HL,
            data: model,
        });

        if (!result.success) return result;

        await this.onboardingService.loadStep(stepId);
        return Ok();
    }
}
