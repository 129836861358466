import { BaseModule, Module, ModuleContainer } from "@fastoche/core";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { HOMESHARING_CQ_INVOKER } from "@/modules/core/cq";

@Module()
export class CoreModule extends BaseModule {
    registerServices(container: ModuleContainer): void {
        // CQ
        container.bind(HttpRemoteCqInvoker).toConstantValue(HOMESHARING_CQ_INVOKER);
    }
}
