import { GetUserInfoQuery as BaseQuery } from "../GetUserInfo";
import { BuildClientInvocation, UserIdAmbientValue } from "@leavy/cq-core";

export class GetUserInfoQuery extends BuildClientInvocation({
    type: BaseQuery,
    ambientValues: {
        userId: UserIdAmbientValue,
    },
}) {
}
