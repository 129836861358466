










































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import {LvSpin, LvCard, LvIcon, LvBadge, LvModal, LvButton} from "@fastoche/ui-kit/components";
import { lazyBgImg } from "@fastoche/ui-kit/directives";
import { currencySymbol } from "@fastoche/ui-core/i18n/currency/filters/currencySymbol";
import { money } from  "@/app/ui/filters/money.js";
import {ListingBooking} from "@leavy/lv-homesharing-backend-srv/lib/booking/domain/BookedListing";
import {DateTime} from "luxon";
export const COMPONENT_NAME = "BookingInvoice";

@Component({
    name: COMPONENT_NAME,
    responsive: true,
    components: {
        LvSpin,
        LvCard,
        LvIcon,
        LvBadge,
        LvModal,
        LvButton
    },
    directives: {
        lazyBgImg,
    },
    filters: {
        currencySymbol,
        money
    }
})
export default class BookingInvoice extends BaseComponent {
    @Prop({required: true})
    private booking!: ListingBooking;

    get bookingDuration(){
        const fromDate = DateTime.fromString(this.booking.checkout.checkInDate, "yyyy-MM-dd").startOf("day");
        const toDate = DateTime.fromString(this.booking.checkout.checkOutDate, "yyyy-MM-dd").startOf("day");

        return toDate.diff(fromDate, "days").days;
    }
    formatDate(date: string) {
        return DateTime.fromFormat(date, "yyyy-MM-dd").toLocaleString({
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    }
}
