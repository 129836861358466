




















































































import { Component } from "vue-property-decorator";
import { LvContainer, LvProgressBar } from "@fastoche/ui-kit/components";
import { InjectState, StateOf } from "@fastoche/ui-core/store";
import { LoaderTask } from "@fastoche/ui-kit/plugins/loader";
import { HL_ONBOARDING_END_LOCATION, HL_ONBOARDING_WORKFLOW } from "@/modules/onboarding/hl/workflow";
import { AnyHlOnboardingStepId } from "@/modules/onboarding/hl/domain";
import { OnboardingId, UserOnboardingStatus, UserOnboardingStepStatus } from "@/modules/onboarding/common/domain";
import { BaseHlOnboardingPage } from "@/app/pages/onboarding/hl/BaseHlOnboardingPage";
import { assert } from "@leavy/utils";
import LvIcon from "@fastoche/ui-kit/components/icon/LvIcon.vue";
import LvButton from "@fastoche/ui-kit/components/button/LvButton.vue";
import LvProgressCircle from "@fastoche/ui-kit/components/progress-circle/LvProgressCircle.vue";
import HlRegistrationSteps from "@/app/ui/hl-registration-steps/HlRegistrationSteps.vue";
import { LocalizationContext } from "@fastoche/ui-core/i18n/context";
import { currencySymbol, price } from "@fastoche/ui-core/i18n/currency/filters";

interface RevenueEstimationViewModel {
    type: "PER_MONTH" | "FOR_PERIOD";
    amount: number;
    currency: string;
}

export const COMPONENT_NAME = "HlOnboardingOverviewPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        LvProgressCircle,
        LvProgressBar,
        LvButton,
        LvIcon,
        LvContainer,
        HlRegistrationSteps,
    },
    filters: {
        price,
        currencySymbol,
    },
})
export default class HlOnboardingOverviewPage extends BaseHlOnboardingPage {
    @InjectState(LocalizationContext)
    private readonly localizationContext!: StateOf<LocalizationContext>;

    private readonly workflow = HL_ONBOARDING_WORKFLOW;

    private readonly groupedSteps: Record<string | AnyHlOnboardingStepId, () => string>[] = [
        {
            [AnyHlOnboardingStepId.HL_UPCOMING_DEPARTURE]: () => this.$t("app.onboarding.steps.upcoming_departure"),
            [AnyHlOnboardingStepId.HL_ACCOMMODATION_DESCRIPTION]: () => this.$t("app.onboarding.hl.steps.accommodation_description"),
        },
        {
            [AnyHlOnboardingStepId.HL_ACCOMMODATION_PHOTOS]: () => this.$t("app.onboarding.hl.steps.accommodation_photos"),
            [AnyHlOnboardingStepId.HL_INVENTORY]: () => this.$t("app.onboarding.hl.steps.inventory_of_fixtures"),
            [AnyHlOnboardingStepId.HL_ADMINISTRATIVE_INFO]: () => this.$t("app.onboarding.hl.steps.administrative_info"),
            [AnyHlOnboardingStepId.EMAIL_VERIFICATION]: () => this.$t("app.onboarding.steps.email_verification"),
            [AnyHlOnboardingStepId.IDENTITY]: () => this.$t("app.onboarding.steps.identity_proof"),
            [AnyHlOnboardingStepId.PAYMENT_INFO]: () => this.$t("app.onboarding.steps.payment_informations"),
            [AnyHlOnboardingStepId.HL_PAYMENT_PREFERENCES]: () => this.$t("app.onboarding.steps.hl.payment_plan"),
            [AnyHlOnboardingStepId.HL_APPOINTMENT_REQUEST]: () => this.$t("app.onboarding.steps.hl.appointment_request"),
        },
    ];

    private revenueEstimation: RevenueEstimationViewModel | null = null;

    private get progress() {
        return this.hlState?.progress ?? 0;
    }

    private get progressStatus() {
        if (this.progress < 100) {
            return "active";
        }
        else {
            if (this.canSubmit) {
                return "active";
            }
            else {
                return "success";
            }
        }
    }

    private get hasPendingSteps() {
        if (!this.hlState) return false;
        return this.hlState.hasPendingSteps;
    }

    private get isEditable() {
        return this.hlState?.status == UserOnboardingStatus.STARTED || this.hlState?.status == UserOnboardingStatus.SUBMITTED;
    }

    private get canSubmit() {
        return this.hlState?.status == UserOnboardingStatus.STARTED && this.hlState?.onboarding.canSubmit === true;
    }

    private onStepLinkClick(ev: MouseEvent, stepId: AnyHlOnboardingStepId) {
        void this.$track("HlOnboardingStepClickEvent", { stepId });
        void this.goToStepLocation(stepId);
    }

    private onStartButtonClick(ev: MouseEvent) {
        void this.$track("HlOnboardingStartClickEvent");
        void this.startAndResume();
    }

    private onResumeButtonClick(ev: MouseEvent) {
        void this.$track("HlOnboardingResumeClickEvent");
        void this.resume();
    }

    private onSubmitButtonClick(ev: MouseEvent) {
        void this.$track("HlOnboardingSubmitClickEvent");
        void this.submit();
    }

    private isStepsGroupUnlocked(groupIdx: number) {
        if (groupIdx === 0) return true;

        const prevGroup = this.groupedSteps[groupIdx - 1]!;
        const prevGroupSteps = Object.keys(prevGroup) as AnyHlOnboardingStepId[];

        return prevGroupSteps.map(x => this.getStepStatus(x)).every(x => x === UserOnboardingStepStatus.COMPLETE);
    }

    private getStepStatus(step: AnyHlOnboardingStepId) {
        const stepModel = this.hlState?.steps.find((x) => x.stepId == step);
        return stepModel?.status ?? UserOnboardingStepStatus.NOT_STARTED;
    }

    @LoaderTask
    private async goToStepLocation(stepId: AnyHlOnboardingStepId) {
        try {
            if (!this.isEditable) return;
            if (!this.hlState) await this.start();

            const location = this.hlStore.getStepLocation(stepId);
            await this.$router.push(location).catch(this.handleNavigationError);
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    private async start() {
        try {
            const startResult = await this.onboardingService.startOnboarding(OnboardingId.HL);

            if (!startResult.success) {
                switch (startResult.reason) {
                    case "ONBOARDING_ALREADY_STARTED": {
                        await this.onboardingService.loadStore();
                        break;
                    }

                    default:
                        throw new Error("Unexpected error when starting onboarding");
                }
            }

            assert(this.hlState, "State should be set if starting onboarding was successful");
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }

    @LoaderTask
    private async startAndResume() {
        await this.start();
        await this.resume();
    }

    @LoaderTask
    private async resume() {
        if (!this.hlState || !this.hasPendingSteps) return;
        const firstPending = this.hlState.firstPendingStep!;
        await this.goToStepLocation(firstPending.stepId as AnyHlOnboardingStepId);
    }

    @LoaderTask
    private async submit() {
        try {
            const result = await this.onboardingService.submitOnboarding(OnboardingId.HL);

            if (!result.success) {
                switch (result.reason) {
                    case "ONBOARDING_INCOMPLETE":
                        await this.onboardingService.loadStore();
                        await this.resume();
                        break;
                }
            }
            else {
                await this.$router.replace(HL_ONBOARDING_END_LOCATION).catch(this.handleNavigationError);
            }
        }
        catch (e) {
            this.catchAndNotifyError(e);
        }
    }
}
