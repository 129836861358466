import { Equals, IsDefined, ValidateNested } from "@leavy/validator";
import { OnboardingId } from "@leavy/lv-onboarding-srv/lib/common/domain/OnboardingId";
import { RpcInvocable } from "@leavy/cq-core";
import { StepCommand } from "@leavy/lv-onboarding-srv/lib/common/command/StepCommand";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../../base";
import { HlSimpleRevenueEstimationRequestModel } from "../../../pricing/model/HlSimpleRevenueEstimationRequestModel";
import { Type } from "class-transformer";
import { SubmitRevenueEstimationStepCommandResult } from "@leavy/lv-onboarding-srv/lib/hl/command";
import { CommonEditOnboardingError } from "../../ForwardedOnboardingCommand";

export type SubmitRevenueEstimationStepLocalCommandResult =
    | SubmitRevenueEstimationStepCommandResult
    | CommonEditOnboardingError;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "submit_revenue_estimation_step",
})
export class SubmitRevenueEstimationStepLocalCommand extends StepCommand<SubmitRevenueEstimationStepLocalCommandResult> {
    @Equals(OnboardingId.HL)
    onboardingId: OnboardingId.HL = OnboardingId.HL;

    @Type(() => HlSimpleRevenueEstimationRequestModel)
    @IsDefined()
    @ValidateNested()
    data!: HlSimpleRevenueEstimationRequestModel;
}


