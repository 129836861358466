import { Query, RpcInvocable } from "@leavy/cq-core";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { IsUUID } from "@leavy/validator";
import { OkVal } from "@leavy/result";
import { HlUserAccommodation } from "../domain/HlUserAccommodation";

export type HlUserGetAccommodationsQueryResult =
    OkVal<HlUserAccommodation[]>;

@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "hl_user_get_accommodations",
})
export class HlUserGetAccommodationsQuery extends Query<HlUserGetAccommodationsQueryResult> {
    @IsUUID()
    userId!: string;
}
