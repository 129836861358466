import { IsArray, IsDate, IsDateString, IsDefined, IsNumber, Min } from "@leavy/validator";
import { SearchLocationModel } from "@/modules/listing/model";

export class SearchBarViewModel {
    @IsNumber()
    @Min(1)
    guests: number = 1;

    @IsDefined()
    location?: SearchLocationModel = undefined;

    @IsArray()
    @IsDateString(undefined, { each: true })
    dates?: [string | null, string | null] = undefined;

    @IsDateString()
    get from() {
        return this.dates?.[0] ?? null;
    }

    set from(value: string | null) {
        if (value) {
            if (this.dates) {
                this.dates = [
                    value,
                    this.to
                ];
            }
            else {
                this.dates = [value, null];
            }
        }
        else {
            this.dates = undefined;
        }
    }

    @IsDateString()
    get to() {
        return this.dates?.[1] ?? null;
    }

    set to(value: string | null) {
        if (value) {
            if (this.dates) {
                this.dates = [
                    this.from,
                    value
                ];
            }
            else {
                this.dates = [null, value];
            }
        }
        else {
            if (this.dates) {
                this.dates = [
                    this.from,
                    null
                ];
            }
        }
    }
}
