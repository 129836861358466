import { Authorize, Command, RpcInvocable } from "@leavy/cq-core";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { Bad, Ok, OkVal } from "@leavy/result";
import { IsDateString, IsEnum, IsIn, IsLocale, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID, Min } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "../../base";
import { ListingId } from "../../listing/domain/Listing";
import { AccommodationType } from "../../listing/domain/AccommodationType";
import { Currency } from "@leavy/lv-homesharing-pricing-srv/lib/money/domain/Currency";
import { SUPPORTED_CURRENCIES } from "../../money/domain/Currency";
import { HotelRoomId } from "../../listing/domain/Hotel";

export type StartBookingCheckoutCommandResult =
    OkVal<{ checkoutId: string }>
    | Bad<"INVALID_DATES">
    | Bad<"UNAVAILABLE_HOTEL_ROOM">
    | Bad<"MISSING_HOTEL_ROOM">
    | Bad<"INVALID_LISTING">;
;

/**
 * StartBookingCheckoutCommand
 */
@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "start_booking_checkout_command",
})
export class StartBookingCheckoutCommand extends Command<StartBookingCheckoutCommandResult, BookingCheckoutStartedEvent> {
    @IsUUID()
    userId!: string;

    @IsNumber()
    @Min(1)
    guests!: number;

    @IsString()
    @IsNotEmpty()
    listingId!: ListingId;

    @IsEnum(AccommodationType)
    accommodationType!: AccommodationType;

    @IsDateString()
    arrivalDate!: string;

    @IsDateString()
    departureDate!: string;

    @IsIn(SUPPORTED_CURRENCIES)
    currency!: Currency;

    @IsString()
    @IsNotEmpty()
    @IsOptional()
    roomId?: HotelRoomId;
}

@DomainEvent({ name: "booking_checkout_started", version: 1 })
export class BookingCheckoutStartedEvent extends DomainEventBase {
    checkoutId!: string;
    userId!: string;

    constructor(data: PayloadOf<BookingCheckoutStartedEvent>) {
        super(data);
    }
}
