import { HlPaymentSchedulingOption } from "@leavy/lv-homesharing-pricing-srv/lib/pricing/domain/HlPaymentSchedulingOption";
import { HlAccommodationId } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlAccommodation";
import { HlManagementPeriodRequest, HlManagementPeriodRequestId, ManagementPeriodRequestStatus } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlManagementPeriodRequest";
import { Money } from "@leavy/lv-homesharing-pricing-srv/lib/money/domain/Money";
import { MultiCurrencyMoney } from "@leavy/lv-homesharing-pricing-srv/lib/money/domain/MultiCurrencyMoney";
import { PriceProposalScheduledPayment, PriceProposalStatus } from "@leavy/lv-homesharing-srv/lib/hl/domain/HlManagementPeriodRequestPriceProposal";
import { HlUserPaymentPlan, HlUserPaymentPlanMapper } from "./HlUserPaymentPlan";
import { MathUtils } from "@leavy/utils";
import { CURRENCY_MAX_DECIMAL_PRECISION } from "../../money/domain/Currency";

export interface HlUserManagementPeriodRequest {
    id: HlManagementPeriodRequestId;
    creationDate: string;
    accommodationId: HlAccommodationId;
    departureDate: string;
    returnDate: string;
    paymentSchedulingOption: HlPaymentSchedulingOption;
    estimation?: ManagementPeriodRequestEstimation;
    proposal?: ManagementPeriodRequestPriceProposal;
    status: ManagementPeriodRequestStatus;
}

export interface ManagementPeriodRequestEstimation {
    readonly hasExpired: boolean;
    estimationDate: string;
    maxValidityDate: string;
    baseRevenue: MultiCurrencyMoney;
    adjustedRevenue: MultiCurrencyMoney;
    paymentPlan: HlUserPaymentPlan;
}

export interface ManagementPeriodRequestPriceProposal {
    readonly hasExpired: boolean;
    proposalDate: string;
    maxValidityDate: string;
    status: PriceProposalStatus;
    guaranteedRevenue: Money;
    schedule: PriceProposalScheduledPayment[];
}

export namespace HlUserManagementPeriodRequestMapper {
    export function fromManagementPeriodRequest(req: HlManagementPeriodRequest): HlUserManagementPeriodRequest {
        const { estimation, proposal } = req;

        return {
            id: req.id,
            accommodationId: req.accommodationId,
            paymentSchedulingOption: req.paymentSchedulingOption,
            creationDate: req.creationDate as unknown as string,
            returnDate: req.returnDate as unknown as string,
            departureDate: req.departureDate as unknown as string,
            status: req.status,
            proposal: proposal ? {
                status: proposal.status,
                guaranteedRevenue: proposal.guaranteedRevenue,
                maxValidityDate: proposal.maxValidityDate as unknown as string,
                schedule: proposal.schedule,
                proposalDate: proposal.proposalDate as unknown as string,
                hasExpired: proposal.hasExpired,
            } : undefined,
            estimation: estimation ? {
                hasExpired: estimation.hasExpired,
                maxValidityDate: estimation.maxValidityDate as unknown as string,
                estimationDate: estimation.estimationDate as unknown as string,
                baseRevenue: estimation.baseRevenue,
                adjustedRevenue: {
                    base: {
                        value: MathUtils.round(
                            estimation.baseRevenue.base.value * estimation.paymentPlan.priceFactorApplied,
                            CURRENCY_MAX_DECIMAL_PRECISION,
                        ),
                        currency: estimation.baseRevenue.base.currency,
                    },
                    currencies: Object.entries(estimation.baseRevenue.currencies).reduce((prev, [currency, value]) => {
                        prev[currency] = MathUtils.round(
                            value * estimation.paymentPlan.priceFactorApplied,
                            CURRENCY_MAX_DECIMAL_PRECISION,
                        );

                        return prev;
                    }, {}),
                },
                paymentPlan: HlUserPaymentPlanMapper.fromPaymentPlan(estimation.paymentPlan),
            } : undefined,
        };
    }
}
