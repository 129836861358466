





































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";

export const COMPONENT_NAME = "WomanTravelerPlane";

@Component({
    name: COMPONENT_NAME,
})
export default class WomanTravelerPlane extends BaseComponent {}
