import { ApplicationReadyHook, BaseModule, Dependency, Module, ModuleContainer } from "@fastoche/core";
import { UserService } from "@/modules/user/services/UserService";
import { CqUserService } from "@/modules/user/platform/cq/CqUserService";
import { UserContextStore } from "@/modules/user/UserContext";
import { AuthModule } from "@/modules/auth/module";

@Module()
export class UserModule extends BaseModule {
    @Dependency(AuthModule)
    readonly authModule!: AuthModule;

    registerServices(container: ModuleContainer): void {
        container.bind(UserService).to(CqUserService).inSingletonScope();
        container.bind(UserContextStore).toSelf().inSingletonScope();
    }

    async applicationReady(hook: ApplicationReadyHook) {
        const container = hook.applicationContext.rootContainer;
        const store = container.get(UserContextStore);

        await store.initialize();
        store.registerListeners();
    }
}
