



































































import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import PageHeader from "@/app/ui/page-header/PageHeader.vue";
import { LvButton, LvContainer, LvIcon, LvIntersectionObserver, LvSpin } from "@fastoche/ui-kit/components";
import { InjectService } from "@fastoche/ui-core/di";
import { BookingService } from "@/modules/booking/services/BookingService";
import { Booking } from "@/modules/booking/domain";
import { lazyBgImg } from "@fastoche/ui-kit/directives";
import { DateTime } from "luxon";
import { currencySymbol } from "@fastoche/ui-core/i18n/currency/filters/currencySymbol";
import BookingRequestPreviewCard from "@/app/components/booking/my-bookings/BookingRequestPreviewCard.vue";
import BookingDetails from "@/app/components/booking/my-bookings/BookingDetails.vue";
import StickyElement from "@/app/ui/sticky-element/StickyElement.vue";

const DEFAULT_PAGINATION_MODEL = { page: 0, perPage: 10, total: 0 };

export const COMPONENT_NAME = "MyBookingsListPage";

@Component({
    name: COMPONENT_NAME,
    responsive: true,
    components: {
        PageHeader,
        LvContainer,
        LvButton,
        LvIcon,
        LvIntersectionObserver,
        LvSpin,
        BookingRequestPreviewCard,
        BookingDetails,
        StickyElement,
    },
    directives: {
        lazyBgImg,
    },
    filters: {
        currencySymbol,
    },
})
export default class MyBookingsListPage extends BaseComponent {
    @InjectService(BookingService)
    readonly bookingService!: BookingService;

    @Prop({ required: true })
    readonly id!: string | null;

    private paginationModel = { ...DEFAULT_PAGINATION_MODEL };
    bookings: Booking[] = [];
    private isLoading = false;
    private preventLoadingMore = false;

    windowWidth = 1200;
    fixedContainer = false;
    relativeContainer = true;

    mounted() {
        this.resetList();
        void this.loadMore(true);

        this.pageOnScroll();
        document.addEventListener("scroll", this.pageOnScroll);

        this.getWindowWidth();
        window.addEventListener("resize", this.getWindowWidth);
    }

    pageOnScroll() {
        if (this.$responsive.breakpoint > this.$responsive.breakpoints.sm) {
            let cardContainer = document.getElementById("fixed-container");
            let pageContent = document.getElementById("my-bookings-page-content");
            let margin = 500;
            let containerTopValue = 90;
            if (cardContainer && pageContent) {
                let containerPosY = cardContainer.getBoundingClientRect();
                let maxScrollPosY = pageContent.offsetHeight + margin - window.innerHeight;
                let scrollPosY = window.scrollY;

                this.fixedContainer = scrollPosY >= containerPosY.top;
                this.relativeContainer = scrollPosY <= maxScrollPosY + containerTopValue;
            }
        }
    }

    getWindowWidth() {
        this.windowWidth = window.innerWidth;
    }

    resetList() {
        this.bookings = [];
        this.paginationModel = { ...DEFAULT_PAGINATION_MODEL };
        this.isLoading = false;
        this.preventLoadingMore = false;
    }

    async loadMore(forced?: boolean) {
        if ((this.paginationModel.page) * this.paginationModel.perPage <= this.paginationModel.total || forced) {
            this.paginationModel.page++;
            try {
                await this.bookingService.getBookingsList({
                    page: this.paginationModel.page,
                    perPage: this.paginationModel.perPage,
                }).then((resp) => {
                    if (resp.success) {
                        const { value } = resp;
                        this.bookings = value.data.slice(0, this.paginationModel.perPage * (this.paginationModel.page));
                        this.paginationModel.total = value.total;
                    }
                });

                this.preventLoadingMore = false;
            }
            catch (e) {
                this.preventLoadingMore = true;
                this.catchAndNotifyError(e);
            }
            finally {
                this.isLoading = false;
            }
        }
    }

    formatDate(date: string) {
        return DateTime.fromFormat(date, "yyyy-MM-dd").toLocaleString();
    }
}
