import { RouteConfig } from "vue-router";
import { loginGuard } from "@/modules/auth/guard";
import { applyCategoryMeta } from "@fastoche/ui-tracking/core/router/applyCategoryMeta";
import ResetPasswordPage from "@/app/pages/auth/ResetPasswordPage.vue";
import EmailVerificationPage from "@/app/pages/auth/EmailVerificationPage.vue";
import PasswordLostPage from "@/app/pages/auth/PasswordLostPage.vue";
import LoginPage from "@/app/pages/auth/LoginPage.vue";

export const AUTH_ROUTES: RouteConfig[] = [
    {
        path: "/login",
        name: "LoginPage",
        beforeEnter: loginGuard,
        component: LoginPage,
    },
    {
        path: "/password-lost",
        name: "PasswordLostPage",
        beforeEnter: loginGuard,
        component: PasswordLostPage,
    },
    {
        path: "/password-reset",
        name: "ResetPasswordPage",
        beforeEnter: loginGuard,
        component: ResetPasswordPage,
    },
    {
        path: "/email-verification",
        name: "EmailVerificationPage",
        component: EmailVerificationPage,
    },
].map(route => applyCategoryMeta("Authentication", route));
