import { inject, injectable } from "inversify";
import { HlAccommodationDescriptionDataModel, HlAdministrativeDocumentModel, HlAdministrativeInfoModel, HlAppointmentRequestModel, HlPaymentPreferencesModel, HlUpcomingDepartureModel, HlUploadAdministrativeDocumentModel } from "../model";
import { Bad, Ok, OkVal } from "@leavy/result";
import { CommonEditStepError, CommonSubmitStepError, OnboardingService } from "@/modules/onboarding/common/services/OnboardingService";
import { InventorySubjectType, PaymentPlan } from "../domain";
import { Currency } from "@/modules/payment/domain";
import { AllUserOnboardingsStore } from "@/modules/onboarding/common/AllUserOnboardingsStore";
import { HlOnboardingStore } from "@/modules/onboarding/hl/HlOnboardingStore";
import { OnboardingId } from "@/modules/onboarding/common/domain";
import { HlSimpleRevenueEstimationRequestModel } from "@/modules/pricing/model";
import { RevenueEstimationResult } from "../domain";

@injectable()
export abstract class HlOnboardingService {
    @inject(AllUserOnboardingsStore)
    protected readonly rootStore!: AllUserOnboardingsStore;

    @inject(HlOnboardingStore)
    protected readonly hlStore!: HlOnboardingStore;

    @inject(OnboardingService)
    protected readonly onboardingService!: OnboardingService;

    async ensureStore() {
        if (this.hlStore.value != null) return;
        await this.onboardingService.loadStore();
    }

    async startOnboarding() {
        return this.onboardingService.startOnboarding(OnboardingId.HL);
    }

    async restartOnboarding() {
        return this.onboardingService.restartOnboarding(OnboardingId.HL);
    }

    async submitOnboarding() {
        return this.onboardingService.submitOnboarding(OnboardingId.HL);
    }

    abstract submitUpcomingDepartureStep(model: HlUpcomingDepartureModel): Promise<Ok | CommonSubmitStepError>;

    abstract saveAccommodationDescriptionStep(model: HlAccommodationDescriptionDataModel): Promise<Ok | CommonEditStepError>;

    abstract submitAccommodationDescriptionStep(): Promise<Ok | Bad<"INVALID_ACCOMMODATION_LOCATION"> | Bad<"ESTIMATION_FAILED"> | CommonSubmitStepError>;

    abstract addAccomodationPhoto(photo: File): Promise<OkVal<{ fileId: string }> | Bad<"DUPLICATE_FILE"> | CommonEditStepError>;

    abstract removeAccomodationPhoto(fileId: string): Promise<Ok | CommonEditStepError>;

    abstract submitAccomodationPhotoStep(): Promise<Ok | CommonSubmitStepError>;

    abstract addInventorySubject(
        subjectType: InventorySubjectType,
        subjectName: string,
    ): Promise<OkVal<{ subjectId: string }> | CommonEditStepError>;

    abstract editInventorySubject(
        subjectId: string,
        subjectName: string,
    ): Promise<Ok | Bad<"SUBJECT_NOT_FOUND"> | CommonEditStepError>;

    abstract removeInventorySubject(
        subjectId: string,
    ): Promise<Ok | CommonEditStepError>;

    abstract addInventoryPhoto(
        subjectId: string,
        photo: File,
    ): Promise<OkVal<{ fileId: string }> | Bad<"SUBJECT_NOT_FOUND" | "DUPLICATE_FILE"> | CommonEditStepError>;

    abstract removeInventoryPhoto(
        subjectId: string,
        fileId: string,
    ): Promise<Ok | Bad<"SUBJECT_NOT_FOUND"> | CommonEditStepError>;

    abstract submitInventoryStep(): Promise<Ok | CommonSubmitStepError>;

    abstract addAdministrativeDocument(model: HlUploadAdministrativeDocumentModel): Promise<OkVal<{ fileId: string }> | CommonEditStepError>;

    abstract removeAdministrativeDocument(model: HlAdministrativeDocumentModel): Promise<Ok | CommonEditStepError>;

    abstract submitAdministrativeInfoStep(model: HlAdministrativeInfoModel): Promise<Ok | CommonSubmitStepError>;

    abstract submitPaymentPreferencesStep(model: HlPaymentPreferencesModel): Promise<Ok | Bad<"UNACCEPTABLE_SCHEDULING_OPTION_FOR_PERIOD"> | CommonSubmitStepError>;

    abstract getCurrentEstimation(currency: Currency): Promise<OkVal<RevenueEstimationResult | null>>;

    abstract getSelectedPaymentPlan(currency: Currency): Promise<OkVal<PaymentPlan | null>>;

    abstract getPossiblePaymentPlans(currency: Currency): Promise<OkVal<PaymentPlan[]>>;

    abstract submitAppointmentRequestStep(model: HlAppointmentRequestModel): Promise<Ok | CommonSubmitStepError>;

    abstract submitRevenueEstimationStep(model: HlSimpleRevenueEstimationRequestModel): Promise<Ok | Bad<"INVALID_LOCATION"> | Bad<"ESTIMATION_FAILED"> | CommonSubmitStepError>;
}
