















































































































import { Component } from "vue-property-decorator";
import { BaseComponent } from "@/app/BaseComponent";
import { LvBadge, LvButton, LvCard, LvContainer, LvIcon } from "@fastoche/ui-kit";
import { HlUserManagementPeriodRequest } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriodRequest";
import { InjectService } from "@fastoche/ui-core/di";
import { HlService } from "@/modules/hl/services/HlService";
import { price } from "@fastoche/ui-core/i18n/currency/filters";
import { HlUserPayment } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserPayment";
import { HlUserManagementPeriod } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserManagementPeriod";
import { HlUserAccommodation } from "@leavy/lv-homesharing-backend-srv/lib/hl/domain/HlUserAccommodation";
import { UserContext, UserContextStore } from "@/modules/user/UserContext";
import { InjectState } from "@fastoche/ui-core/store";
import { HlUserAccommodationStore } from "@/modules/hl/stores/HlUserAccommodationStore";
import { hugeLocaleDate } from "@/app/filters/date";
import HlUserManagementPeriodRequestRowCard from "@/app/components/hl/management-period-request/HlUserManagementPeriodRequestRowCard.vue";
import HlUserManagementPeriodPreviewCard from "@/app/components/hl/management-period/HlUserManagementPeriodPreviewCard.vue";

export const COMPONENT_NAME = "HlOverviewPage";

@Component({
    name: COMPONENT_NAME,
    loader: true,
    components: {
        HlUserManagementPeriodPreviewCard,
        HlUserManagementPeriodRequestRowCard,
        LvContainer,
        LvCard,
        LvButton,
        LvIcon,
        LvBadge,
    },
    filters: {
        price,
        hugeLocaleDate,
    },
})
export default class HlOverviewPage extends BaseComponent {
    @InjectService(HlService)
    readonly hlService!: HlService;

    @InjectService(HlUserAccommodationStore)
    readonly hlUserAccommodationStore!: HlUserAccommodationStore;

    @InjectState(UserContextStore)
    readonly userContext!: UserContext;

    requests: HlUserManagementPeriodRequest[] = [];
    periods: HlUserManagementPeriod[] = [];
    payments: HlUserPayment[] = [];
    accommodations: HlUserAccommodation[] = [];

    mounted() {
        void this.initialize();
    }

    get pageTitle() {
        return this.$t('hl_summary_page.title')
            .replace("{{FIRST_NAME}}", this.userContext.firstName);
    }

    async initialize() {
        const [summary, accommodations] = await Promise.all([
            this.hlService.getUserManagementSummary(),
            this.hlUserAccommodationStore.refresh(),
        ]);

        const { requests, periods, payments } = summary;

        this.accommodations = accommodations as HlUserAccommodation[];
        this.requests = requests;
        this.periods = periods;
        this.payments = payments;
    }

    getRequestAccommodation(req: HlUserManagementPeriodRequest) {
        return this.accommodations.find(x => x.id == req.accommodationId);
    }

    getPeriodAccommodation(period: HlUserManagementPeriod) {
        return this.accommodations.find(x => x.id == period.accommodationId);
    }
}
