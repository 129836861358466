import { NavigationGuard } from "vue-router";
import { GlobalApplicationContext } from "@fastoche/ui-core/application/GlobalApplicationContext";
import { AuthenticationStore } from "@/modules/auth/AuthenticationStore";
import { OnboardingService } from "@/modules/onboarding/common/services/OnboardingService";

export const appGuard: NavigationGuard = async (to, from, next) => {
    const { rootContainer } = GlobalApplicationContext.default.fastoche;
    const authStore = rootContainer.get(AuthenticationStore);
    const onboardingService = rootContainer.get(OnboardingService);

    if (authStore.isAuthenticated()) {
        await onboardingService.loadStore();
        next();
    }
    else {
        next({
            path: "/login",
            query: { returnUrl: to.path, ...from.query },
        });
    }
};

export const loginGuard: NavigationGuard = (to, from, next) => {
    const { rootContainer } = GlobalApplicationContext.default.fastoche;
    const authStore = rootContainer.get(AuthenticationStore);

    if (authStore.isAuthenticated()) {
        next("/app");
    }
    else {
        next();
    }
};
