import { HlPricingService } from "@/modules/pricing/services/HlPricingService";
import { injectable } from "inversify";
import { HlSimpleRevenueEstimation, HlSimpleRevenueEstimationRequestModel } from "@/modules/pricing/model";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { HlSimpleRevenueEstimationQuery } from "./query";
import { Bad, Ok, OkVal } from "@leavy/result";
import { Address } from "@/modules/core/domain";

@injectable()
export class CqHlPricingService extends HlPricingService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    async getPricingEstimation(request: HlSimpleRevenueEstimationRequestModel): Promise<OkVal<HlSimpleRevenueEstimation> | Bad<"ESTIMATION_NOT_POSSIBLE">> {
        const result = await this.cq.invoke(HlSimpleRevenueEstimationQuery, {
            ...request,
        });

        if (!result.success) {
            return Bad("ESTIMATION_NOT_POSSIBLE");
        }
        else {
            return Ok({
                address: new Address(result.value.address),
                perDay: result.value.perDay,
                perMonth: result.value.perMonth,
                currency: result.value.currency,
            });
        }
    }
}
