import { injectable } from "inversify";
import { HttpRemoteCqInvoker } from "@leavy/cq-client";
import { PasswordLostCommand, ResetPasswordCommand, UpdateUserPasswordCommand } from "./command";
import { PasswordChange, PasswordReset, PasswordService } from "@/modules/auth/services/PasswordService";
import { Bad, Ok } from "@leavy/result";

@injectable()
export class CqPasswordService extends PasswordService {
    constructor(
        private readonly cq: HttpRemoteCqInvoker,
    ) {
        super();
    }

    change(model: PasswordChange): Promise<Ok | Bad<"WRONG_CURRENT_PASSWORD">> {
        return this.cq.invoke(UpdateUserPasswordCommand, { ...model });
    }

    beginReset(email: string) {
        return this.cq.invoke(PasswordLostCommand, { email });
    }

    endReset(model: PasswordReset) {
        return this.cq.invoke(ResetPasswordCommand, { ...model });
    }
}
