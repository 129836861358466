import { Command, RpcInvocable } from "@leavy/cq-core";
import { DomainEvent, DomainEventBase, EventProperty, PayloadOf } from "@leavy/domain-events-core";
import { Bad, Ok } from "@leavy/result";
import { IsLocale, IsNotEmpty, IsString, IsUUID, Length } from "@leavy/validator";
import { HOMESHARING_BACKEND_SERVICE_NAME } from "@leavy/lv-homesharing-backend-srv/lib/base";


export type BookListingCommandResult =
    | Ok
    | Bad<"CHECKOUT_NOT_FOUND">
    | Bad<"CHECKOUT_EXPIRED">
    | Bad<"INVALID_LISTING_TYPE">
    | Bad<"PAYMENT_FAILED">
    | Bad<"USER_MISMATCH">
    ;

/**
 * BookHouseListingCommand
 */
@RpcInvocable({
    namespace: HOMESHARING_BACKEND_SERVICE_NAME,
    alias: "book_listing_command",
})
export class BookListingCommand extends Command<BookListingCommandResult, ListingBookedEvent> {
    @IsUUID()
    userId!: string;

    @IsLocale()
    locale!: string;

    @IsUUID()
    checkoutId!: string;

    @IsString()
    @IsNotEmpty()
    cardToken!: string;
}

@DomainEvent({
    name: "listing_booked",
    version: 1 })
export class ListingBookedEvent extends DomainEventBase {
    @EventProperty()
    userId!: string;

    @EventProperty()
    bookingId!: string;

    @EventProperty()
    paymentId!: string;

    @EventProperty()
    accommodationType!: string;

    constructor(data: PayloadOf<ListingBookedEvent>) {
        super(data);
    }
}


@DomainEvent({
    name: "booking_confirmed",
    version: 1 })
export class BookingConfirmedEvent extends DomainEventBase {
    @EventProperty()
    userId!: string;

    @EventProperty()
    bookingId!: string;

    @EventProperty()
    paymentId!: string;

    @EventProperty()
    accommodationType!: string;

    constructor(data: PayloadOf<BookingConfirmedEvent>) {
        super(data);
    }
}

@DomainEvent({ name: "booking_canceled", version: 1 })
export class BookingCanceled extends DomainEventBase {
    @EventProperty()
    userId!: string;

    @EventProperty()
    bookingId!: string;

    constructor(data: PayloadOf<BookingCanceled>) {
        super(data);
    }
}

